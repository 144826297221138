export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A field whose value conforms to the standard cuid format as specified in https://github.com/ericelliott/cuid#broken-down */
    Cuid: string;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: Date | string;
    /** Decimal custom scalar type */
    Decimal: number;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: string;
    /** Represents a JSON array of strings */
    JSONStringArray: any;
    /** Represents true values */
    true: true;
};

export type AwrCreateInput = {
    active?: Scalars['Boolean'];
    endRaise?: Scalars['Boolean'];
    flex?: Scalars['Int'];
    level: Scalars['Int'];
    maxHour?: InputMaybe<Scalars['Float']>;
    maxLastLogin?: InputMaybe<Scalars['Int']>;
    minHour?: InputMaybe<Scalars['Float']>;
    minLastLogin?: InputMaybe<Scalars['Int']>;
    priority?: Scalars['Int'];
    projectName: Scalars['String'];
    skipNewUser?: Scalars['Boolean'];
    timeFrom: Scalars['Float'];
    timeTo: Scalars['Float'];
    type?: AwrType;
};

export const AwrType = {
    All: 'All',
    NotPayed: 'NotPayed',
    Payed: 'Payed',
} as const;

export type AwrType = (typeof AwrType)[keyof typeof AwrType];
export type AwrUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    endRaise?: InputMaybe<Scalars['Boolean']>;
    flex?: InputMaybe<Scalars['Int']>;
    level: Scalars['Int'];
    maxHour?: InputMaybe<Scalars['Float']>;
    maxLastLogin?: InputMaybe<Scalars['Int']>;
    minHour?: InputMaybe<Scalars['Float']>;
    minLastLogin?: InputMaybe<Scalars['Int']>;
    priority?: InputMaybe<Scalars['Int']>;
    projectName: Scalars['String'];
    skipNewUser?: InputMaybe<Scalars['Boolean']>;
    timeFrom: Scalars['Float'];
    timeTo: Scalars['Float'];
    type?: InputMaybe<AwrType>;
};

export type AwrWhereInput = {
    projectName?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<AwrType>;
};

export type AwrWhereUniqueInput = {
    level: Scalars['Int'];
    projectName: Scalars['String'];
};

export type Acquisition = {
    __typename: 'Acquisition';
    acquisitionId: Scalars['String'];
    blocklist: Scalars['String'];
    createdAt: Scalars['DateTime'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    executionDate: Scalars['DateTime'];
    finishedAt?: Maybe<Scalars['DateTime']>;
    gift?: Maybe<Gift>;
    giftId?: Maybe<Scalars['String']>;
    model: AcquisitionType;
    picture?: Maybe<Picture>;
    pictureId?: Maybe<Scalars['String']>;
    profile: ModeratedProfile;
    profileId: Scalars['String'];
    projectName?: Maybe<Scalars['String']>;
    projectedReceivers?: Maybe<Scalars['Int']>;
    reachedReceivers: Scalars['Int'];
    search: Scalars['String'];
    teams: Array<ModerationTeam>;
    text?: Maybe<Scalars['String']>;
};

export type AcquisitionCreateInput = {
    blocklist: Scalars['String'];
    gift?: InputMaybe<GiftWhereUniqueInput>;
    model: AcquisitionType;
    moderators?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
    picture?: InputMaybe<PictureWhereUniqueInput>;
    profile: ProfileWhereUniqueInput;
    search: UserWhereInput;
    teams?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
    text?: InputMaybe<Scalars['String']>;
};

export type AcquisitionDelayedCreateInput = {
    blocklist: Scalars['String'];
    executionDate: Scalars['DateTime'];
    gift?: InputMaybe<GiftWhereUniqueInput>;
    model: AcquisitionType;
    moderators?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
    picture?: InputMaybe<PictureWhereUniqueInput>;
    profile: ProfileWhereUniqueInput;
    search: UserRelativeWhereInput;
    teams?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
    text?: InputMaybe<Scalars['String']>;
};

export type AcquisitionListRelationFilter = {
    every?: InputMaybe<AcquisitionWhereInput>;
    none?: InputMaybe<AcquisitionWhereInput>;
    some?: InputMaybe<AcquisitionWhereInput>;
};

export type AcquisitionOrderByInput = {
    blocklist?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    executionDate?: InputMaybe<SortOrder>;
    giftId?: InputMaybe<SortOrder>;
    model?: InputMaybe<SortOrder>;
    pictureId?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    reachedReceivers?: InputMaybe<SortOrder>;
    search?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
};

export const AcquisitionType = {
    Awr: 'Awr',
    Friendship: 'Friendship',
    Gift: 'Gift',
    Message: 'Message',
    Picture: 'Picture',
    Poke: 'Poke',
    Visit: 'Visit',
} as const;

export type AcquisitionType = (typeof AcquisitionType)[keyof typeof AcquisitionType];
export type AcquisitionTypeFilter = {
    equals?: InputMaybe<AcquisitionType>;
    in?: InputMaybe<Array<AcquisitionType>>;
    not?: InputMaybe<AcquisitionType>;
    notIn?: InputMaybe<Array<AcquisitionType>>;
};

export type AcquisitionUpdateInput = {
    executionDate?: InputMaybe<Scalars['DateTime']>;
    moderators?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
    search?: InputMaybe<Scalars['String']>;
    teams?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
    text?: InputMaybe<Scalars['String']>;
};

export type AcquisitionWhereInput = {
    acquisitionId?: InputMaybe<StringFilter>;
    blocklist?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<StringFilter>;
    executionDate?: InputMaybe<DateTimeFilter>;
    finishedAt?: InputMaybe<DateTimeNullableFilter>;
    giftId?: InputMaybe<StringNullableFilter>;
    model?: InputMaybe<AcquisitionTypeFilter>;
    pictureId?: InputMaybe<StringNullableFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    reachedReceivers?: InputMaybe<IntFilter>;
    search?: InputMaybe<StringFilter>;
    startedAt?: InputMaybe<DateTimeNullableFilter>;
    text?: InputMaybe<StringNullableFilter>;
};

export type AcquisitionWhereUniqueInput = {
    acquisitionId: Scalars['String'];
};

export type AdminActivity = {
    __typename: 'AdminActivity';
    admin: AdminUser;
    adminActivityId: Scalars['String'];
    adminId: Scalars['String'];
    date: Scalars['String'];
    delete?: Maybe<Scalars['Int']>;
    flirtTextVerify?: Maybe<Scalars['Int']>;
    imageVerify?: Maybe<Scalars['Int']>;
    support?: Maybe<Scalars['Int']>;
    transaction?: Maybe<Scalars['Int']>;
};

export type AdminActivityListRelationFilter = {
    every?: InputMaybe<AdminActivityWhereInput>;
    none?: InputMaybe<AdminActivityWhereInput>;
    some?: InputMaybe<AdminActivityWhereInput>;
};

export type AdminActivityOrderByInput = {
    adminActivityId?: InputMaybe<SortOrder>;
    adminId?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    delete?: InputMaybe<SortOrder>;
    flirtTextVerify?: InputMaybe<SortOrder>;
    imageVerify?: InputMaybe<SortOrder>;
    support?: InputMaybe<SortOrder>;
    transaction?: InputMaybe<SortOrder>;
};

export type AdminActivityWhereInput = {
    admin?: InputMaybe<AdminUserWhereInput>;
    adminId?: InputMaybe<StringFilter>;
    date?: InputMaybe<StringFilter>;
    delete?: InputMaybe<IntNullableFilter>;
    flirtTextVerify?: InputMaybe<IntNullableFilter>;
    imageVerify?: InputMaybe<IntNullableFilter>;
    support?: InputMaybe<IntNullableFilter>;
    transaction?: InputMaybe<IntNullableFilter>;
};

export type AdminActivityWhereUniqueInput = {
    adminActivityId?: InputMaybe<Scalars['String']>;
    date_adminId?: InputMaybe<DateAdminIdCompoundUniqueInput>;
};

export type AdminLog = {
    __typename: 'AdminLog';
    adminLogId: Scalars['String'];
    adminName: Scalars['String'];
    createdAt: Scalars['DateTime'];
    logData: Scalars['String'];
    projectName: Scalars['String'];
    type: Scalars['String'];
};

export type AdminLogOrderByInput = {
    adminLogId?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export type AdminLogResult = AdminLogResultSuccess | ResultError;

export type AdminLogResultSuccess = {
    __typename: 'AdminLogResultSuccess';
    adminLogId?: Maybe<Scalars['String']>;
};

export type AdminLogWhereInput = {
    adminName?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    type?: InputMaybe<EnumAdminLogTypeFilter>;
};

export type AdminLogWhereUniqueInput = {
    adminLogId?: InputMaybe<Scalars['String']>;
};

export type AdminLoginResult = AdminLoginResultSuccess | ResultError;

export type AdminLoginResultSuccess = {
    __typename: 'AdminLoginResultSuccess';
    adminUserId: Scalars['String'];
    adminUserSetting: Array<AdminUserSetting>;
    adminUserTeams: Array<AdminUserTeam>;
    agency?: Maybe<Agency>;
    c4fAuth: Array<C4F_Auth>;
    languageCode: Scalars['String'];
    name: Scalars['String'];
    privileges: Array<Privilege>;
    projects: Array<Project>;
    token: Scalars['String'];
};

export type AdminRole = {
    __typename: 'AdminRole';
    AdminRolePrivilege: Array<AdminRolePrivilege>;
    AdminUser: Array<AdminUser>;
    adminRoleId: Scalars['String'];
    name: Scalars['String'];
};

export type AdminRoleAdminRolePrivilegeArgs = {
    cursor?: InputMaybe<AdminRolePrivilegeWhereUniqueInput>;
    distinct?: InputMaybe<AdminRolePrivilegeDistinctFieldEnum>;
    orderBy?: InputMaybe<AdminRolePrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminRolePrivilegeWhereInput>;
};

export type AdminRoleAdminUserArgs = {
    cursor?: InputMaybe<AdminUserWhereUniqueInput>;
    distinct?: InputMaybe<AdminUserDistinctFieldEnum>;
    orderBy?: InputMaybe<AdminUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserWhereInput>;
};

export type AdminRoleCreateInput = {
    adminRolePrivileges?: InputMaybe<AdminRolePrivilegeCreateManyWithoutAdminRoleInput>;
    name: Scalars['String'];
};

export type AdminRoleCreateOneWithoutAdminUserInput = {
    connect: AdminRoleWhereUniqueInput;
};

export type AdminRoleNamePrivilegeCompoundUniqueInput = {
    adminRoleName: Scalars['String'];
    privilege: Privilege;
};

export type AdminRoleOrderByInput = {
    adminRoleId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
};

export type AdminRolePrivilege = {
    __typename: 'AdminRolePrivilege';
    adminRole: AdminRole;
    adminRoleName: Scalars['String'];
    adminRolePrivilegeId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    privilege: Privilege;
    updatedAt: Scalars['DateTime'];
};

export type AdminRolePrivilegeCreateInput = {
    adminRole: AdminRoleWhereUniqueInput;
    privilege: Privilege;
};

export type AdminRolePrivilegeCreateManyWithoutAdminRoleInput = {
    create?: InputMaybe<Array<AdminRolePrivilegeCreateWithoutAdminRoleInput>>;
};

export type AdminRolePrivilegeCreateWithoutAdminRoleInput = {
    privilege: Privilege;
};

export const AdminRolePrivilegeDistinctFieldEnum = {
    AdminRoleName: 'adminRoleName',
    AdminRolePrivilegeId: 'adminRolePrivilegeId',
    CreatedAt: 'createdAt',
    Privilege: 'privilege',
    UpdatedAt: 'updatedAt',
} as const;

export type AdminRolePrivilegeDistinctFieldEnum = (typeof AdminRolePrivilegeDistinctFieldEnum)[keyof typeof AdminRolePrivilegeDistinctFieldEnum];
export type AdminRolePrivilegeListRelationFilter = {
    every?: InputMaybe<AdminRolePrivilegeWhereInput>;
    none?: InputMaybe<AdminRolePrivilegeWhereInput>;
    some?: InputMaybe<AdminRolePrivilegeWhereInput>;
};

export type AdminRolePrivilegeOrderByInput = {
    adminRoleName?: InputMaybe<SortOrder>;
    adminRolePrivilegeId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    privilege?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AdminRolePrivilegeScalarWhereInput = {
    adminRoleName?: InputMaybe<StringFilter>;
    adminRolePrivilegeId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    privilege?: InputMaybe<PrivilegeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRolePrivilegeUpdateManyWithoutAdminRoleInput = {
    create?: InputMaybe<Array<AdminRolePrivilegeCreateWithoutAdminRoleInput>>;
    delete?: InputMaybe<Array<AdminRolePrivilegeWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AdminRolePrivilegeScalarWhereInput>>;
};

export type AdminRolePrivilegeWhereInput = {
    adminRole?: InputMaybe<AdminRoleWhereInput>;
    adminRoleName?: InputMaybe<StringFilter>;
    adminRolePrivilegeId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    privilege?: InputMaybe<PrivilegeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRolePrivilegeWhereUniqueInput = {
    adminRoleName_privilege?: InputMaybe<AdminRoleNamePrivilegeCompoundUniqueInput>;
    adminRolePrivilegeId?: InputMaybe<Scalars['String']>;
};

export type AdminRoleUpdateInput = {
    name?: InputMaybe<Scalars['String']>;
    privileges?: InputMaybe<Array<Privilege>>;
};

export type AdminRoleUpdateOneRequiredWithoutAdminUserInput = {
    connect?: InputMaybe<AdminRoleWhereUniqueInput>;
};

export type AdminRoleWhereInput = {
    AdminRolePrivilege?: InputMaybe<AdminRolePrivilegeListRelationFilter>;
    AdminUser?: InputMaybe<AdminUserListRelationFilter>;
    adminRoleId?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
};

export type AdminRoleWhereUniqueInput = {
    adminRoleId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type AdminSession = {
    __typename: 'AdminSession';
    adminName: Scalars['String'];
    adminSessionId: Scalars['String'];
    click: Scalars['Int'];
    end: Scalars['DateTime'];
    image: Scalars['Int'];
    modComplain: Scalars['Int'];
    projectName: Scalars['String'];
    start: Scalars['DateTime'];
    supportMessage: Scalars['Int'];
    supportTicket: Scalars['Int'];
    systemComplain: Scalars['Int'];
    transaction: Scalars['Int'];
    userComplain: Scalars['Int'];
    verifyUser: Scalars['Int'];
};

export type AdminSession24 = {
    __typename: 'AdminSession24';
    admin: AdminUser;
    adminName: Scalars['String'];
    adminSession24Id: Scalars['String'];
    adminSessions: Array<AdminSession>;
    click: Scalars['Int'];
    date: Scalars['DateTime'];
    image: Scalars['Int'];
    modComplain: Scalars['Int'];
    projectName: Scalars['String'];
    supportMessage: Scalars['Int'];
    supportTicket: Scalars['Int'];
    systemComplain: Scalars['Int'];
    transaction: Scalars['Int'];
    userComplain: Scalars['Int'];
    verifyUser: Scalars['Int'];
};

export type AdminSession24AdminSessionsArgs = {
    cursor?: InputMaybe<AdminSessionWhereUniqueInput>;
    orderBy?: InputMaybe<AdminSessionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminSessionWhereInput>;
};

export type AdminSession24OrderByInput = {
    adminSession24Id?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
};

export type AdminSession24Result = AdminSession24ResultSuccess | ResultError;

export type AdminSession24ResultSuccess = {
    __typename: 'AdminSession24ResultSuccess';
    adminSession24Id?: Maybe<Scalars['String']>;
};

export type AdminSession24WhereInput = {
    adminName?: InputMaybe<StringFilter>;
    date?: InputMaybe<DateTimeFilter>;
};

export type AdminSession24WhereUniqueInput = {
    adminSession24Id: Scalars['String'];
};

export type AdminSessionCountAggregateInputType = {
    click?: InputMaybe<Scalars['true']>;
    image?: InputMaybe<Scalars['true']>;
    modComplain?: InputMaybe<Scalars['true']>;
    supportMessage?: InputMaybe<Scalars['true']>;
    supportTicket?: InputMaybe<Scalars['true']>;
    systemComplain?: InputMaybe<Scalars['true']>;
    transaction?: InputMaybe<Scalars['true']>;
    userComplain?: InputMaybe<Scalars['true']>;
    verifyUser?: InputMaybe<Scalars['true']>;
};

export type AdminSessionCountAggregateOutputType = {
    __typename: 'AdminSessionCountAggregateOutputType';
    _all: Scalars['Int'];
    adminName: Scalars['Int'];
    adminSessionId: Scalars['Int'];
    click: Scalars['Int'];
    image: Scalars['Int'];
    modComplain: Scalars['Int'];
    projectName: Scalars['Int'];
    supportMessage: Scalars['Int'];
    supportTicket: Scalars['Int'];
    systemComplain: Scalars['Int'];
    transaction: Scalars['Int'];
    userComplain: Scalars['Int'];
    verifyUser: Scalars['Int'];
};

export type AdminSessionCountOrderByAggregateInput = {
    adminName?: InputMaybe<SortOrder>;
    adminSessionId?: InputMaybe<SortOrder>;
    click?: InputMaybe<SortOrder>;
    end?: InputMaybe<SortOrder>;
    image?: InputMaybe<SortOrder>;
    modComplain?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    start?: InputMaybe<SortOrder>;
    supportMessage?: InputMaybe<SortOrder>;
    supportTicket?: InputMaybe<SortOrder>;
    systemComplain?: InputMaybe<SortOrder>;
    transaction?: InputMaybe<SortOrder>;
    userComplain?: InputMaybe<SortOrder>;
    verifyUser?: InputMaybe<SortOrder>;
};

export type AdminSessionGroupByResult = {
    __typename: 'AdminSessionGroupByResult';
    _count?: Maybe<AdminSessionCountAggregateOutputType>;
    _sum?: Maybe<AdminSessionSumAggregateOutputType>;
};

export type AdminSessionOrderByInput = {
    adminSessionId?: InputMaybe<SortOrder>;
    start?: InputMaybe<SortOrder>;
};

export type AdminSessionOrderByWithAggregationInput = {
    _count?: InputMaybe<AdminSessionCountOrderByAggregateInput>;
    _sum?: InputMaybe<AdminSessionSumOrderByAggregateInput>;
    adminName?: InputMaybe<SortOrder>;
    adminSessionId?: InputMaybe<SortOrder>;
    click?: InputMaybe<SortOrder>;
    end?: InputMaybe<SortOrder>;
    image?: InputMaybe<SortOrder>;
    modComplain?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    start?: InputMaybe<SortOrder>;
    supportMessage?: InputMaybe<SortOrder>;
    supportTicket?: InputMaybe<SortOrder>;
    systemComplain?: InputMaybe<SortOrder>;
    transaction?: InputMaybe<SortOrder>;
    userComplain?: InputMaybe<SortOrder>;
    verifyUser?: InputMaybe<SortOrder>;
};

export type AdminSessionResult = AdminSessionResultSuccess | ResultError;

export type AdminSessionResultSuccess = {
    __typename: 'AdminSessionResultSuccess';
    adminSessionId?: Maybe<Scalars['String']>;
};

export const AdminSessionScalarFieldEnum = {
    AdminName: 'adminName',
    AdminSession24Id: 'adminSession24Id',
    AdminSessionId: 'adminSessionId',
    Click: 'click',
    End: 'end',
    Image: 'image',
    ModComplain: 'modComplain',
    ProjectName: 'projectName',
    Start: 'start',
    SupportMessage: 'supportMessage',
    SupportTicket: 'supportTicket',
    SystemComplain: 'systemComplain',
    Transaction: 'transaction',
    UserComplain: 'userComplain',
    VerifyUser: 'verifyUser',
} as const;

export type AdminSessionScalarFieldEnum = (typeof AdminSessionScalarFieldEnum)[keyof typeof AdminSessionScalarFieldEnum];
export type AdminSessionSumAggregateInputType = {
    click?: InputMaybe<Scalars['true']>;
    image?: InputMaybe<Scalars['true']>;
    modComplain?: InputMaybe<Scalars['true']>;
    supportMessage?: InputMaybe<Scalars['true']>;
    supportTicket?: InputMaybe<Scalars['true']>;
    systemComplain?: InputMaybe<Scalars['true']>;
    transaction?: InputMaybe<Scalars['true']>;
    userComplain?: InputMaybe<Scalars['true']>;
    verifyUser?: InputMaybe<Scalars['true']>;
};

export type AdminSessionSumAggregateOutputType = {
    __typename: 'AdminSessionSumAggregateOutputType';
    _all?: Maybe<Scalars['Int']>;
    adminName?: Maybe<Scalars['Int']>;
    adminSessionId?: Maybe<Scalars['Int']>;
    click?: Maybe<Scalars['Int']>;
    image?: Maybe<Scalars['Int']>;
    modComplain?: Maybe<Scalars['Int']>;
    projectName?: Maybe<Scalars['Int']>;
    supportMessage?: Maybe<Scalars['Int']>;
    supportTicket?: Maybe<Scalars['Int']>;
    systemComplain?: Maybe<Scalars['Int']>;
    transaction?: Maybe<Scalars['Int']>;
    userComplain?: Maybe<Scalars['Int']>;
    verifyUser?: Maybe<Scalars['Int']>;
};

export type AdminSessionSumOrderByAggregateInput = {
    adminName?: InputMaybe<SortOrder>;
    adminSessionId?: InputMaybe<SortOrder>;
    click?: InputMaybe<SortOrder>;
    end?: InputMaybe<SortOrder>;
    image?: InputMaybe<SortOrder>;
    modComplain?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    start?: InputMaybe<SortOrder>;
    supportMessage?: InputMaybe<SortOrder>;
    supportTicket?: InputMaybe<SortOrder>;
    systemComplain?: InputMaybe<SortOrder>;
    transaction?: InputMaybe<SortOrder>;
    userComplain?: InputMaybe<SortOrder>;
    verifyUser?: InputMaybe<SortOrder>;
};

export type AdminSessionWhereInput = {
    adminName?: InputMaybe<StringFilter>;
    end?: InputMaybe<DateTimeFilter>;
    start?: InputMaybe<DateTimeFilter>;
};

export type AdminSessionWhereUniqueInput = {
    adminSessionId: Scalars['String'];
};

export type AdminText = {
    __typename: 'AdminText';
    adminTextId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    entityId: Scalars['String'];
    entityType: AdminTextEntityType;
    projectName: Scalars['String'];
    text: Scalars['String'];
    type: AdminTextType;
};

export type AdminTextCreateInput = {
    entityId: Scalars['String'];
    entityType: AdminTextEntityType;
    project: ProjectWhereUniqueInput;
    text: Scalars['String'];
};

export const AdminTextDistinctFieldEnum = {
    AdminTextId: 'adminTextId',
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    EntityId: 'entityId',
    EntityType: 'entityType',
    ProjectName: 'projectName',
    Text: 'text',
    Type: 'type',
} as const;

export type AdminTextDistinctFieldEnum = (typeof AdminTextDistinctFieldEnum)[keyof typeof AdminTextDistinctFieldEnum];
export const AdminTextEntityType = {
    AdminUser: 'AdminUser',
    ModeratedProfile: 'ModeratedProfile',
    ModerationUser: 'ModerationUser',
    User: 'User',
} as const;

export type AdminTextEntityType = (typeof AdminTextEntityType)[keyof typeof AdminTextEntityType];
export type AdminTextEntityTypeFilter = {
    equals?: InputMaybe<AdminTextEntityType>;
    in?: InputMaybe<Array<AdminTextEntityType>>;
    not?: InputMaybe<AdminTextEntityType>;
    notIn?: InputMaybe<Array<AdminTextEntityType>>;
};

export type AdminTextOrderByInput = {
    adminTextId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    entityId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
};

export const AdminTextType = {
    Automated: 'Automated',
    Manual: 'Manual',
} as const;

export type AdminTextType = (typeof AdminTextType)[keyof typeof AdminTextType];
export type AdminTextTypeFilter = {
    equals?: InputMaybe<AdminTextType>;
    in?: InputMaybe<Array<AdminTextType>>;
    not?: InputMaybe<AdminTextType>;
    notIn?: InputMaybe<Array<AdminTextType>>;
};

export type AdminTextWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserNestedWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    entityId?: InputMaybe<StringFilter>;
    entityType?: InputMaybe<AdminTextEntityTypeFilter>;
    projectName?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
    type?: InputMaybe<AdminTextTypeFilter>;
};

export type AdminTextWhereUniqueInput = {
    adminTextId: Scalars['String'];
};

export type AdminUser = {
    __typename: 'AdminUser';
    adminRole: AdminRole;
    adminRoleName: Scalars['String'];
    adminTexts: Array<AdminText>;
    adminUserId: Scalars['String'];
    adminUserSetting: Array<AdminUserSetting>;
    adminUserTeam: Array<AdminUserTeam>;
    agencyId?: Maybe<Scalars['String']>;
    c4fAuth: Array<C4F_Auth>;
    createdAt: Scalars['DateTime'];
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    languageCode: Scalars['String'];
    lastLogin?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    online: Scalars['Boolean'];
    privileges: Array<AdminUserPrivilege>;
    projects: Array<Project>;
    status: UserStatus;
    updatedAt: Scalars['DateTime'];
};

export type AdminUserAdminTextsArgs = {
    cursor?: InputMaybe<AdminTextWhereUniqueInput>;
    distinct?: InputMaybe<Array<AdminTextDistinctFieldEnum>>;
    orderBy?: InputMaybe<AdminTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminTextWhereInput>;
};

export type AdminUserPrivilegesArgs = {
    cursor?: InputMaybe<AdminUserPrivilegeWhereUniqueInput>;
    distinct?: InputMaybe<AdminUserPrivilegeDistinctFieldEnum>;
    orderBy?: InputMaybe<AdminUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserPrivilegeWhereInput>;
};

export type AdminUserCreateInput = {
    adminRole: AdminRoleCreateOneWithoutAdminUserInput;
    adminUserTeams?: InputMaybe<AdminUserTeamCreateNestedManyWithoutAdminUserInput>;
    agency: AgencyUpdateOneRequiredWithoutX;
    language: LanguageWhereUniqueInput;
    name: Scalars['String'];
    password: Scalars['String'];
    projects?: InputMaybe<Array<ProjectWhereUniqueInput>>;
    status: UserStatus;
};

export const AdminUserDistinctFieldEnum = {
    AdminRoleName: 'adminRoleName',
    AdminUserId: 'adminUserId',
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    LanguageCode: 'languageCode',
    LastLogin: 'lastLogin',
    Name: 'name',
    Password: 'password',
    Status: 'status',
    UpdatedAt: 'updatedAt',
} as const;

export type AdminUserDistinctFieldEnum = (typeof AdminUserDistinctFieldEnum)[keyof typeof AdminUserDistinctFieldEnum];
export type AdminUserListRelationFilter = {
    every?: InputMaybe<AdminUserWhereInput>;
    none?: InputMaybe<AdminUserWhereInput>;
    some?: InputMaybe<AdminUserWhereInput>;
};

export type AdminUserNestedWhereInput = {
    adminRole?: InputMaybe<AdminRoleWhereInput>;
    adminRoleName?: InputMaybe<StringFilter>;
    adminUserId?: InputMaybe<StringFilter>;
    created?: InputMaybe<AdminUserListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    name?: InputMaybe<StringFilter>;
    online?: InputMaybe<BoolFilter>;
    privileges?: InputMaybe<AdminUserPrivilegeListRelationFilter>;
    status?: InputMaybe<UserStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUserNestedWithoutCreatorWhereInput = {
    adminRole?: InputMaybe<AdminRoleWhereInput>;
    adminRoleName?: InputMaybe<StringFilter>;
    adminUserId?: InputMaybe<StringFilter>;
    created?: InputMaybe<AdminUserListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<StringNullableFilter>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    name?: InputMaybe<StringFilter>;
    online?: InputMaybe<BoolFilter>;
    privileges?: InputMaybe<AdminUserPrivilegeListRelationFilter>;
    status?: InputMaybe<UserStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUserOrderByInput = {
    adminRoleName?: InputMaybe<SortOrder>;
    adminUserId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    lastLogin?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AdminUserPrivilege = {
    __typename: 'AdminUserPrivilege';
    adminUser: AdminUser;
    adminUserId: Scalars['String'];
    adminUserPrivilegeId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    privilege: Privilege;
};

export type AdminUserPrivilegeCreateInput = {
    adminUserId: Scalars['String'];
    privilege: Privilege;
};

export const AdminUserPrivilegeDistinctFieldEnum = {
    AdminUserId: 'adminUserId',
    AdminUserPrivilegeId: 'adminUserPrivilegeId',
    CreatedAt: 'createdAt',
    Privilege: 'privilege',
} as const;

export type AdminUserPrivilegeDistinctFieldEnum = (typeof AdminUserPrivilegeDistinctFieldEnum)[keyof typeof AdminUserPrivilegeDistinctFieldEnum];
export type AdminUserPrivilegeListRelationFilter = {
    every?: InputMaybe<AdminUserPrivilegeWhereInput>;
    none?: InputMaybe<AdminUserPrivilegeWhereInput>;
    some?: InputMaybe<AdminUserPrivilegeWhereInput>;
};

export type AdminUserPrivilegeOrderByInput = {
    adminUserId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    privilege?: InputMaybe<SortOrder>;
};

export type AdminUserPrivilegeScalarWhereInput = {
    adminUserId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    privilege?: InputMaybe<PrivilegeFilter>;
};

export type AdminUserPrivilegeUpdateManyWithoutAdminUserInput = {
    delete?: InputMaybe<Array<AdminUserPrivilegeWhereUniqueInput>>;
    deleteMany?: InputMaybe<AdminUserPrivilegeScalarWhereInput>;
};

export type AdminUserPrivilegeWhereInput = {
    adminUser?: InputMaybe<AdminUserWhereInput>;
    adminUserId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    privilege?: InputMaybe<PrivilegeFilter>;
};

export type AdminUserPrivilegeWhereUniqueInput = {
    adminUserPrivilegeId?: InputMaybe<Scalars['String']>;
    privilege_adminUserId?: InputMaybe<PrivilegeAdminUserIdCompoundUniqueInput>;
};

export type AdminUserSetting = {
    __typename: 'AdminUserSetting';
    adminUser: AdminUser;
    adminUserId: Scalars['String'];
    adminUserSettingId: Scalars['String'];
    label: Scalars['String'];
    setting: Scalars['JSON'];
    type: Scalars['String'];
};

export type AdminUserSettingCreateInput = {
    label: Scalars['String'];
    setting: Scalars['String'];
    type: Scalars['String'];
};

export type AdminUserSettingUpdateInput = {
    label?: InputMaybe<Scalars['String']>;
    setting?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
};

export type AdminUserSettingWhereInput = {
    creatorId?: InputMaybe<StringNullableFilter>;
    label?: InputMaybe<StringFilter>;
    type?: InputMaybe<StringFilter>;
};

export type AdminUserSettingWhereUniqueInput = {
    adminUserSettingId: Scalars['String'];
};

export type AdminUserTeam = {
    __typename: 'AdminUserTeam';
    adminUserId: Scalars['String'];
    adminUserTeamId: Scalars['Cuid'];
    createdAt: Scalars['DateTime'];
    moderationTeamId: Scalars['String'];
};

export type AdminUserTeamAdminUserIdModerationTeamIdCompoundUniqueInput = {
    adminUserId: Scalars['String'];
    moderationTeamId: Scalars['String'];
};

export type AdminUserTeamCreateNestedManyWithoutAdminUserInput = {
    create: Array<AdminUserTeamUncheckedCreateWithoutAdminUserInput>;
};

export type AdminUserTeamUncheckedCreateWithoutAdminUserInput = {
    moderationTeamId: Scalars['String'];
};

export type AdminUserTeamUpdateManyWithoutAdminUserNestedInput = {
    set: Array<AdminUserTeamWhereUniqueInput>;
};

export type AdminUserTeamWhereUniqueInput = {
    adminUserId_moderationTeamId: AdminUserTeamAdminUserIdModerationTeamIdCompoundUniqueInput;
};

export type AdminUserText = {
    __typename: 'AdminUserText';
    adminUserTextId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    text: Scalars['String'];
    user: User;
    userId: Scalars['String'];
};

export type AdminUserTextCreateInput = {
    project: ProjectWhereUniqueInput;
    text: Scalars['String'];
    user: UserWhereUniqueInput;
};

export const AdminUserTextDistinctFieldEnum = {
    AdminUserTextId: 'adminUserTextId',
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    ProjectName: 'projectName',
    Text: 'text',
    UserId: 'userId',
} as const;

export type AdminUserTextDistinctFieldEnum = (typeof AdminUserTextDistinctFieldEnum)[keyof typeof AdminUserTextDistinctFieldEnum];
export type AdminUserTextOrderByInput = {
    adminUserTextId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type AdminUserTextWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserNestedWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
    user?: InputMaybe<UserWhereInput>;
    userId?: InputMaybe<StringFilter>;
};

export type AdminUserTextWhereUniqueInput = {
    adminUserTextId: Scalars['String'];
};

export type AdminUserUpdateInput = {
    adminRole?: InputMaybe<AdminRoleUpdateOneRequiredWithoutAdminUserInput>;
    adminUserTeams?: InputMaybe<AdminUserTeamUpdateManyWithoutAdminUserNestedInput>;
    agency?: InputMaybe<AgencyUpdateOneRequiredWithoutX>;
    name?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    privileges?: InputMaybe<AdminUserPrivilegeUpdateManyWithoutAdminUserInput>;
    projects?: InputMaybe<Array<ProjectWhereUniqueInput>>;
    status?: InputMaybe<UserStatus>;
};

export type AdminUserWhereInput = {
    AND?: InputMaybe<Array<AdminUserNestedWhereInput>>;
    NOT?: InputMaybe<Array<AdminUserNestedWhereInput>>;
    OR?: InputMaybe<Array<AdminUserNestedWhereInput>>;
    adminRole?: InputMaybe<AdminRoleWhereInput>;
    adminRoleName?: InputMaybe<StringFilter>;
    adminUserId?: InputMaybe<StringFilter>;
    created?: InputMaybe<AdminUserListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserNestedWithoutCreatorWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    name?: InputMaybe<StringFilter>;
    online?: InputMaybe<BoolFilter>;
    privileges?: InputMaybe<AdminUserPrivilegeListRelationFilter>;
    status?: InputMaybe<UserStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUserWhereUniqueInput = {
    adminUserId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type AffiliateUser = {
    __typename: 'AffiliateUser';
    affiliateUserId: Scalars['String'];
    c4fAuth: C4F_Auth;
    createdAt: Scalars['DateTime'];
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    lastLogin?: Maybe<Scalars['DateTime']>;
    linkCode?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    password: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type AffiliateUserCreate = {
    __typename: 'AffiliateUserCreate';
    active: Scalars['Boolean'];
    newUser: Scalars['Boolean'];
    user: AffiliateUser;
};

export type AffiliateUserCreateInput = {
    email: Scalars['String'];
    name: Scalars['String'];
    password: Scalars['String'];
    projectName: Scalars['String'];
};

export type AffiliateUserListRelationFilter = {
    every?: InputMaybe<AffiliateUserWhereInput>;
    none?: InputMaybe<AffiliateUserWhereInput>;
    some?: InputMaybe<AffiliateUserWhereInput>;
};

export type AffiliateUserOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    lastLogin?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    password?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AffiliateUserUpdateInput = {
    email?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
};

export type AffiliateUserWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    email?: InputMaybe<StringFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    name?: InputMaybe<StringFilter>;
    password?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AffiliateUserWhereUniqueInput = {
    affiliateUserId: Scalars['String'];
};

export type AgeFilter = {
    gt?: InputMaybe<Scalars['Int']>;
    gte?: InputMaybe<Scalars['Int']>;
    lt?: InputMaybe<Scalars['Int']>;
    lte?: InputMaybe<Scalars['Int']>;
};

export type Agency = {
    __typename: 'Agency';
    _count: AgencyCountOutputType;
    agencyId: Scalars['String'];
    agencyStatistics: Array<AgencyStatistic>;
    createdAt: Scalars['DateTime'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    factor: Scalars['Int'];
    moderationUsers: Array<ModerationUser>;
    name: Scalars['String'];
    projects: Array<AgencyProject>;
    updatedAt: Scalars['DateTime'];
};

export type Agency_CountArgs = {
    select: AgencyCountOutputTypeSelect;
};

export type AgencyAgencyStatisticsArgs = {
    cursor?: InputMaybe<AgencyStatisticWhereUniqueInput>;
    distinct?: InputMaybe<AgencyStatisticDistinctFieldEnum>;
    orderBy?: InputMaybe<AgencyStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyStatisticWhereInput>;
};

export type AgencyModerationUsersArgs = {
    cursor?: InputMaybe<ModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<ModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserWhereInput>;
};

export type AgencyProjectsArgs = {
    cursor?: InputMaybe<AgencyProjectWhereUniqueInput>;
    distinct?: InputMaybe<AgencyProjectDistinctFieldEnum>;
    orderBy?: InputMaybe<AgencyProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectWhereInput>;
};

export type AgencyCountOutputType = {
    __typename: 'AgencyCountOutputType';
    adminUsers: Scalars['Int'];
    agencyHourStatistics: Scalars['Int'];
    agencyStatistics: Scalars['Int'];
    moderationUsers: Scalars['Int'];
    projects: Scalars['Int'];
};

export type AgencyCountOutputTypeSelect = {
    adminUsers: Scalars['Boolean'];
    agencyHourStatistics: Scalars['Boolean'];
    agencyStatistics: Scalars['Boolean'];
    moderationUsers: Scalars['Boolean'];
    projects: Scalars['Boolean'];
};

export type AgencyCreateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']>;
    factor: Scalars['Int'];
    name: Scalars['String'];
    projects?: InputMaybe<Array<ProjectWhereUniqueInput>>;
    updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AgencyHourStatistic = {
    __typename: 'AgencyHourStatistic';
    agency: Agency;
    agencyHourStatisticId: Scalars['String'];
    agencyId: Scalars['String'];
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    hour: Scalars['Int'];
    ins?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
};

export type AgencyHourStatisticHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export const AgencyHourStatisticDistinctFieldEnum = {
    AgencyHourStatisticId: 'agencyHourStatisticId',
    AgencyId: 'agencyId',
    Bonus: 'bonus',
    Coins: 'coins',
    Date: 'date',
    Hour: 'hour',
    Ins: 'ins',
    Outs: 'outs',
} as const;

export type AgencyHourStatisticDistinctFieldEnum = (typeof AgencyHourStatisticDistinctFieldEnum)[keyof typeof AgencyHourStatisticDistinctFieldEnum];
export type AgencyHourStatisticListRelationFilter = {
    every?: InputMaybe<AgencyHourStatisticWhereInput>;
    none?: InputMaybe<AgencyHourStatisticWhereInput>;
    some?: InputMaybe<AgencyHourStatisticWhereInput>;
};

export type AgencyHourStatisticOrderByInput = {
    agencyHourStatisticId?: InputMaybe<SortOrder>;
    agencyId?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
};

export type AgencyHourStatisticWhereInput = {
    agency?: InputMaybe<AgencyWhereInput>;
    agencyHourStatisticId?: InputMaybe<StringFilter>;
    agencyId?: InputMaybe<StringFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    hour?: InputMaybe<IntFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    outs?: InputMaybe<IntNullableFilter>;
};

export type AgencyHourStatisticWhereUniqueInput = {
    agencyHourStatisticId?: InputMaybe<Scalars['String']>;
    date_hour_agencyId?: InputMaybe<DateHourAgencyIdCompoundUniqueInput>;
};

export type AgencyIdProjectNameCompoundUniqueInput = {
    agencyId: Scalars['String'];
    projectName: Scalars['String'];
};

export type AgencyOrderByInput = {
    agencyId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    factor?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AgencyProject = {
    __typename: 'AgencyProject';
    agency: Agency;
    agencyId: Scalars['String'];
    agencyProjectId: Scalars['String'];
    agencyProjectStatistics: Array<AgencyProjectStatistic>;
    projectName: Scalars['String'];
};

export type AgencyProjectAgencyProjectStatisticsArgs = {
    cursor?: InputMaybe<AgencyProjectStatisticWhereUniqueInput>;
    distinct?: InputMaybe<AgencyProjectStatisticDistinctFieldEnum>;
    orderBy?: InputMaybe<AgencyProjectStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectStatisticWhereInput>;
};

export type AgencyProjectCreateInput = {
    agency: AgencyWhereUniqueInput;
    project: ProjectWhereUniqueInput;
};

export const AgencyProjectDistinctFieldEnum = {
    AgencyId: 'agencyId',
    AgencyProjectId: 'agencyProjectId',
    ProjectName: 'projectName',
} as const;

export type AgencyProjectDistinctFieldEnum = (typeof AgencyProjectDistinctFieldEnum)[keyof typeof AgencyProjectDistinctFieldEnum];
export type AgencyProjectHourStatistic = {
    __typename: 'AgencyProjectHourStatistic';
    agencyProject: AgencyProject;
    agencyProjectHourStatisticId: Scalars['String'];
    agencyProjectId: Scalars['String'];
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    hour: Scalars['Int'];
    ins?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
};

export type AgencyProjectHourStatisticHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export const AgencyProjectHourStatisticDistinctFieldEnum = {
    AgencyProjectHourStatisticId: 'agencyProjectHourStatisticId',
    AgencyProjectId: 'agencyProjectId',
    Bonus: 'bonus',
    Coins: 'coins',
    Date: 'date',
    Hour: 'hour',
    Ins: 'ins',
    Outs: 'outs',
    ProjectName: 'projectName',
} as const;

export type AgencyProjectHourStatisticDistinctFieldEnum =
    (typeof AgencyProjectHourStatisticDistinctFieldEnum)[keyof typeof AgencyProjectHourStatisticDistinctFieldEnum];
export type AgencyProjectHourStatisticListRelationFilter = {
    every?: InputMaybe<AgencyProjectHourStatisticWhereInput>;
    none?: InputMaybe<AgencyProjectHourStatisticWhereInput>;
    some?: InputMaybe<AgencyProjectHourStatisticWhereInput>;
};

export type AgencyProjectHourStatisticOrderByInput = {
    agencyProjectHourStatisticId?: InputMaybe<SortOrder>;
    agencyProjectId?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type AgencyProjectHourStatisticWhereInput = {
    agencyProject?: InputMaybe<AgencyProjectWhereInput>;
    agencyProjectHourStatisticId?: InputMaybe<StringFilter>;
    agencyProjectId?: InputMaybe<StringFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    hour?: InputMaybe<IntFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type AgencyProjectHourStatisticWhereUniqueInput = {
    agencyProjectHourStatisticId?: InputMaybe<Scalars['String']>;
    d_h_aPI_pN?: InputMaybe<D_H_APi_PNCompoundUniqueInput>;
};

export type AgencyProjectListRelationFilter = {
    every?: InputMaybe<AgencyProjectWhereInput>;
    none?: InputMaybe<AgencyProjectWhereInput>;
    some?: InputMaybe<AgencyProjectWhereInput>;
};

export type AgencyProjectOrderByInput = {
    agencyId?: InputMaybe<SortOrder>;
    agencyProjectId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type AgencyProjectStatistic = {
    __typename: 'AgencyProjectStatistic';
    agencyProject: AgencyProject;
    agencyProjectId: Scalars['String'];
    agencyProjectStatisticId: Scalars['String'];
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    ins?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
};

export const AgencyProjectStatisticDistinctFieldEnum = {
    AgencyProjectId: 'agencyProjectId',
    AgencyProjectStatisticId: 'agencyProjectStatisticId',
    Bonus: 'bonus',
    Coins: 'coins',
    Date: 'date',
    Ins: 'ins',
    Outs: 'outs',
    ProjectName: 'projectName',
} as const;

export type AgencyProjectStatisticDistinctFieldEnum =
    (typeof AgencyProjectStatisticDistinctFieldEnum)[keyof typeof AgencyProjectStatisticDistinctFieldEnum];
export type AgencyProjectStatisticListRelationFilter = {
    every?: InputMaybe<AgencyProjectStatisticWhereInput>;
    none?: InputMaybe<AgencyProjectStatisticWhereInput>;
    some?: InputMaybe<AgencyProjectStatisticWhereInput>;
};

export type AgencyProjectStatisticOrderByInput = {
    agencyProjectId?: InputMaybe<SortOrder>;
    agencyProjectStatisticId?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type AgencyProjectStatisticWhereInput = {
    agencyProject?: InputMaybe<AgencyProjectWhereInput>;
    agencyProjectId?: InputMaybe<StringFilter>;
    agencyProjectStatisticId?: InputMaybe<StringFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type AgencyProjectStatisticWhereUniqueInput = {
    agencyProjectStatisticId?: InputMaybe<Scalars['String']>;
    d_aPI_pN?: InputMaybe<D_APi_PNCompoundUniqueInput>;
};

export type AgencyProjectUpdateManyWithoutAgencyInput = {
    create?: InputMaybe<Array<ProjectNameInput>>;
    delete?: InputMaybe<Array<AgencyProjectWhereUniqueInput>>;
};

export type AgencyProjectWhereInput = {
    agency?: InputMaybe<AgencyWhereInput>;
    agencyId?: InputMaybe<StringFilter>;
    agencyProjectId?: InputMaybe<StringFilter>;
    agencyProjectStatistics?: InputMaybe<AgencyProjectStatisticListRelationFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type AgencyProjectWhereUniqueInput = {
    agencyId_projectName?: InputMaybe<AgencyIdProjectNameCompoundUniqueInput>;
    agencyProjectId?: InputMaybe<Scalars['String']>;
};

export type AgencyStatistic = {
    __typename: 'AgencyStatistic';
    agency: Agency;
    agencyId: Scalars['String'];
    agencyStatisticId: Scalars['String'];
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    ins?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
};

export const AgencyStatisticDistinctFieldEnum = {
    AgencyId: 'agencyId',
    AgencyStatisticId: 'agencyStatisticId',
    Bonus: 'bonus',
    Coins: 'coins',
    Date: 'date',
    Ins: 'ins',
    Outs: 'outs',
} as const;

export type AgencyStatisticDistinctFieldEnum = (typeof AgencyStatisticDistinctFieldEnum)[keyof typeof AgencyStatisticDistinctFieldEnum];
export type AgencyStatisticListRelationFilter = {
    every?: InputMaybe<AgencyStatisticWhereInput>;
    none?: InputMaybe<AgencyStatisticWhereInput>;
    some?: InputMaybe<AgencyStatisticWhereInput>;
};

export type AgencyStatisticOrderByInput = {
    agencyId?: InputMaybe<SortOrder>;
    agencyStatisticId?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
};

export type AgencyStatisticWhereInput = {
    agency?: InputMaybe<AgencyWhereInput>;
    agencyId?: InputMaybe<StringFilter>;
    agencyStatisticId?: InputMaybe<StringFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    outs?: InputMaybe<IntNullableFilter>;
};

export type AgencyStatisticWhereUniqueInput = {
    agencyStatisticId?: InputMaybe<Scalars['String']>;
    date_agencyId?: InputMaybe<DateAgencyIdCompoundUniqueInput>;
};

export type AgencyUpdateInput = {
    factor?: InputMaybe<Scalars['Int']>;
    moderationUsers?: InputMaybe<ModerationUserUpdateManyWithoutXInput>;
    name?: InputMaybe<Scalars['String']>;
    projects?: InputMaybe<AgencyProjectUpdateManyWithoutAgencyInput>;
};

export type AgencyUpdateOneRequiredWithoutX = {
    connect: AgencyWhereUniqueInput;
};

export type AgencyWhereInput = {
    agencyId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringFilter>;
    factor?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    projects?: InputMaybe<AgencyProjectListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AgencyWhereUniqueInput = {
    agencyId: Scalars['String'];
};

export type AggregateModerationMessage = {
    __typename: 'AggregateModerationMessage';
    _avg?: Maybe<ModerationMessageAvgSumAggregateOutputType>;
    _count?: Maybe<ModerationMessageCountAggregateOutputType>;
    _sum?: Maybe<ModerationMessageAvgSumAggregateOutputType>;
};

export type AggregateProfile = {
    __typename: 'AggregateProfile';
    _avg?: Maybe<ProfileAvgAggregateOutputType>;
    _count?: Maybe<ProfileCountAggregateOutputType>;
    _max?: Maybe<ProfileMaxMinAggregateOutputType>;
    _min?: Maybe<ProfileMaxMinAggregateOutputType>;
    _sum?: Maybe<ProfileSumAggregateOutputType>;
};

export type AggregateUser = {
    __typename: 'AggregateUser';
    _avg?: Maybe<UserAvgSumAggregateOutputType>;
    _count?: Maybe<UserCountAggregateOutputType>;
    _max?: Maybe<UserMaxMinAggregateOutputType>;
    _min?: Maybe<UserMaxMinAggregateOutputType>;
    _sum?: Maybe<UserAvgSumAggregateOutputType>;
};

export type AggregateUserStatistic = {
    __typename: 'AggregateUserStatistic';
    _avg?: Maybe<UserStatisticAvgSumAggregateOutputType>;
    _count?: Maybe<UserStatisticCountAggregateOutputType>;
    _max?: Maybe<UserStatisticMinMaxAggregateOutputType>;
    _min?: Maybe<UserStatisticMinMaxAggregateOutputType>;
    _sum?: Maybe<UserStatisticAvgSumAggregateOutputType>;
};

export type AllEnum = {
    __typename: 'AllEnum';
    AWRType: Array<AwrType>;
    AcquisitionType: Array<AcquisitionType>;
    AdminTextEntityType: Array<AdminTextEntityType>;
    AdminTextType: Array<AdminTextType>;
    ChatStatus: Array<ChatStatus>;
    ComplainStatus: Array<ComplainStatus>;
    DateTimeRelativeUnit: Array<DateTimeRelativeUnit>;
    FeatureName: Array<FeatureName>;
    FlagGender: Array<FlagGender>;
    FlagType: Array<FlagType>;
    Gender: Array<Gender>;
    GiftStatus: Array<GiftStatus>;
    MessageType: Array<MessageType>;
    ModerationComplainStatus: Array<ModerationComplainStatus>;
    ModerationComplainType: Array<ModerationComplainType>;
    ModerationMessageType: Array<ModerationMessageType>;
    ModerationPrivilege: Array<ModerationPrivilege>;
    ModerationRole: Array<ModerationRole>;
    ModerationType: Array<ModerationType>;
    NotificationLogChannel: Array<NotificationLogChannel>;
    NotificationLogPayloadType: Array<NotificationLogPayloadType>;
    NotificationLogSource: Array<NotificationLogSource>;
    NotificationLogStatus: Array<NotificationLogStatus>;
    OperatingSystem: Array<OperatingSystem>;
    PeriodUnit: Array<PeriodUnit>;
    PeriodUnitOffer: Array<PeriodUnitOffer>;
    PeriodUnitPool: Array<PeriodUnitPool>;
    Privilege: Array<Privilege>;
    ProfileStatus: Array<ProfileStatus>;
    QueueType: Array<QueueType>;
    ScenarioActionType: Array<ScenarioActionType>;
    ScenarioType: Array<ScenarioType>;
    SortOrder: Array<SortOrder>;
    SupportCategory: Array<SupportCategory>;
    SupportTicketStatus: Array<SupportTicketStatus>;
    SystemComplainType: Array<SystemComplainType>;
    TransactionStatus: Array<TransactionStatus>;
    TranslationType: Array<TranslationType>;
    TranslationView: Array<TranslationView>;
    UserRole: Array<UserRole>;
    UserStatus: Array<UserStatus>;
    test: Scalars['String'];
};

export type AutomaticWriteRequest = {
    __typename: 'AutomaticWriteRequest';
    active: Scalars['Boolean'];
    automaticWriteRequestId: Scalars['ID'];
    endRaise: Scalars['Boolean'];
    /** number of messages before raise */
    flex: Scalars['Int'];
    level: Scalars['Int'];
    /** hh.mm */
    maxHour: Scalars['Float'];
    /** days last login */
    maxLastLogin?: Maybe<Scalars['Int']>;
    /** hh.mm */
    minHour: Scalars['Float'];
    /** days last login */
    minLastLogin?: Maybe<Scalars['Int']>;
    /** normal message: 1, default: 3 */
    priority: Scalars['Int'];
    projectName: Scalars['String'];
    skipNewUser: Scalars['Boolean'];
    /** hh.mm */
    timeFrom: Scalars['Float'];
    /** hh.mm */
    timeTo: Scalars['Float'];
    type: AwrType;
};

export type Avatar = {
    __typename: 'Avatar';
    avatarId: Scalars['String'];
    gender: Gender;
    name?: Maybe<Scalars['String']>;
    path: Scalars['String'];
    translation?: Maybe<Translation>;
    translations: Array<Translation>;
};

export type AvatarPathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type AvatarTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type AvatarTranslationsArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
};

export type AvatarCreateNestedOneWithoutProfilesInput = {
    connect: AvatarWhereUniqueInput;
};

export type AvatarUpdateOneWithoutProfilesNestedInput = {
    connect: AvatarWhereUniqueInput;
};

export type AvatarWhereInput = {
    gender?: InputMaybe<EnumGenderFilter>;
    projectName?: InputMaybe<Scalars['String']>;
};

export type AvatarWhereUniqueInput = {
    avatarId: Scalars['String'];
};

export type BadwordCreateInput = {
    badword: Scalars['String'];
};

export type BadwordResult = BadwordResultSuccess | ResultError;

export type BadwordResultSuccess = {
    __typename: 'BadwordResultSuccess';
    badwordId?: Maybe<Scalars['String']>;
};

export type BatchPayload = {
    __typename: 'BatchPayload';
    count: Scalars['Int'];
};

export type BoolFilter = {
    equals?: InputMaybe<Scalars['Boolean']>;
    not?: InputMaybe<Scalars['Boolean']>;
};

export type BoolNullableFilter = {
    equals?: InputMaybe<Scalars['Boolean']>;
    not?: InputMaybe<Scalars['Boolean']>;
};

export type BoolWithAggregatesFilter = {
    _count?: InputMaybe<IntFilter>;
    _max?: InputMaybe<BoolFilter>;
    _min?: InputMaybe<BoolFilter>;
    equals?: InputMaybe<Scalars['Boolean']>;
    not?: InputMaybe<Scalars['Boolean']>;
};

export type C4F_Auth = {
    __typename: 'C4F_Auth';
    checksum: Scalars['String'];
    productid: Scalars['Int'];
    projectName: Scalars['String'];
    userid: Scalars['Int'];
};

export type Ci_PI_PNCompoundUniqueInput = {
    conversationId: Scalars['String'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
};

export const ChatStatus = {
    Answer: 'Answer',
    Break: 'Break',
    Offline: 'Offline',
    Online: 'Online',
} as const;

export type ChatStatus = (typeof ChatStatus)[keyof typeof ChatStatus];
export type ChatStatusFilter = {
    equals?: InputMaybe<ChatStatus>;
    in?: InputMaybe<Array<ChatStatus>>;
    not?: InputMaybe<ChatStatus>;
    notIn?: InputMaybe<Array<ChatStatus>>;
};

export type CodeProjectNameCompoundUniqueInput = {
    code: Scalars['String'];
    projectName: Scalars['String'];
};

export type CoinsHistory = {
    __typename: 'CoinsHistory';
    action: Scalars['String'];
    bonusChanged: Scalars['Int'];
    coinsChanged: Scalars['Int'];
    coinsHistoryId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    projectName: Scalars['String'];
    user?: Maybe<User>;
    userId: Scalars['String'];
};

export const CoinsHistoryDistinctFieldEnum = {
    Action: 'action',
    BonusChanged: 'bonusChanged',
    CoinsChanged: 'coinsChanged',
    CoinsHistoryId: 'coinsHistoryId',
    CreatedAt: 'createdAt',
    ProjectName: 'projectName',
    UserId: 'userId',
} as const;

export type CoinsHistoryDistinctFieldEnum = (typeof CoinsHistoryDistinctFieldEnum)[keyof typeof CoinsHistoryDistinctFieldEnum];
export type CoinsHistoryIdProjectNameCompoundUniqueInput = {
    coinsHistoryId: Scalars['String'];
    projectName: Scalars['String'];
};

export type CoinsHistoryListRelationFilter = {
    every?: InputMaybe<CoinsHistoryWhereInput>;
    none?: InputMaybe<CoinsHistoryWhereInput>;
    some?: InputMaybe<CoinsHistoryWhereInput>;
};

export type CoinsHistoryOrderByInput = {
    action?: InputMaybe<SortOrder>;
    bonusChanged?: InputMaybe<SortOrder>;
    coinsChanged?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type CoinsHistoryWhereInput = {
    action?: InputMaybe<StringFilter>;
    bonusChanged?: InputMaybe<IntFilter>;
    coinsChanged?: InputMaybe<IntFilter>;
    coinsHistoryId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    projectName?: InputMaybe<StringFilter>;
    userId?: InputMaybe<StringFilter>;
};

export type CoinsHistoryWhereUniqueInput = {
    coinsHistoryId_projectName: CoinsHistoryIdProjectNameCompoundUniqueInput;
};

export type Comment = {
    __typename: 'Comment';
    commentFields: Array<CommentCommentField>;
    commentId: Scalars['String'];
    commentLogs: Array<CommentLog>;
    conversation?: Maybe<Conversation>;
    conversationId?: Maybe<Scalars['String']>;
    moderated: Scalars['Boolean'];
    profile: Profile;
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    text: Scalars['String'];
};

export type CommentCommentFieldsArgs = {
    cursor?: InputMaybe<CommentCommentFieldWhereUniqueInput>;
    distinct?: InputMaybe<CommentCommentFieldDistinctFieldEnum>;
    orderBy?: InputMaybe<CommentCommentFieldOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentCommentFieldWhereInput>;
};

export type CommentCommentLogsArgs = {
    cursor?: InputMaybe<CommentLogWhereUniqueInput>;
    distinct?: InputMaybe<CommentLogDistinctFieldEnum>;
    orderBy?: InputMaybe<CommentLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentLogWhereInput>;
};

export type CommentCommentField = {
    __typename: 'CommentCommentField';
    comment: Comment;
    commentCommentFieldId: Scalars['String'];
    commentField: CommentField;
    commentFieldId: Scalars['String'];
    commentId: Scalars['String'];
    value: Scalars['String'];
};

export type CommentCommentFieldCreateInput = {
    commentField: CommentFieldWhereUniqueInput;
    conversation: ConversationWhereUniqueInput;
    languageCode: Scalars['String'];
    profile: ProfileWhereUniqueInput;
    value: Scalars['String'];
};

export const CommentCommentFieldDistinctFieldEnum = {
    CommentCommentFieldId: 'commentCommentFieldId',
    CommentFieldId: 'commentFieldId',
    CommentId: 'commentId',
    Value: 'value',
} as const;

export type CommentCommentFieldDistinctFieldEnum = (typeof CommentCommentFieldDistinctFieldEnum)[keyof typeof CommentCommentFieldDistinctFieldEnum];
export type CommentCommentFieldListRelationFilter = {
    every?: InputMaybe<CommentCommentFieldWhereInput>;
    none?: InputMaybe<CommentCommentFieldWhereInput>;
    some?: InputMaybe<CommentCommentFieldWhereInput>;
};

export type CommentCommentFieldOrderByInput = {
    commentCommentFieldId?: InputMaybe<SortOrder>;
    commentFieldId?: InputMaybe<SortOrder>;
    commentId?: InputMaybe<SortOrder>;
    value?: InputMaybe<SortOrder>;
};

export type CommentCommentFieldUpdateInput = {
    value: Scalars['String'];
};

export type CommentCommentFieldWhereInput = {
    comment?: InputMaybe<CommentWhereInput>;
    commentCommentFieldId?: InputMaybe<StringFilter>;
    commentField?: InputMaybe<CommentFieldWhereInput>;
    commentFieldId?: InputMaybe<StringFilter>;
    commentId?: InputMaybe<StringFilter>;
    value?: InputMaybe<StringFilter>;
};

export type CommentCommentFieldWhereUniqueInput = {
    commentCommentFieldId?: InputMaybe<Scalars['String']>;
    commentId_commentFieldId?: InputMaybe<CommentIdCommentFieldIdCompoundUniqueInput>;
};

export type CommentCreateInput = {
    conversation?: InputMaybe<ConversationWhereUniqueInput>;
    profile: ProfileWhereUniqueInput;
    text: Scalars['String'];
};

export const CommentDistinctFieldEnum = {
    CommentId: 'commentId',
    ModeratedProfileId: 'moderatedProfileId',
    ProjectName: 'projectName',
    Text: 'text',
    UserId: 'userId',
} as const;

export type CommentDistinctFieldEnum = (typeof CommentDistinctFieldEnum)[keyof typeof CommentDistinctFieldEnum];
export type CommentField = {
    __typename: 'CommentField';
    active: Scalars['Boolean'];
    commentFieldId: Scalars['String'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    label: Scalars['String'];
    languageCode: Scalars['String'];
    name: Scalars['String'];
    projectName: Scalars['String'];
};

export type CommentFieldCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    label: Scalars['String'];
    language: LanguageWhereUniqueInput;
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
};

export type CommentFieldListRelationFilter = {
    every?: InputMaybe<CommentFieldWhereInput>;
    none?: InputMaybe<CommentFieldWhereInput>;
    some?: InputMaybe<CommentFieldWhereInput>;
};

export type CommentFieldOrderByInput = {
    active?: InputMaybe<SortOrder>;
    commentFieldId?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    label?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type CommentFieldUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CommentFieldUpdateManyMutationInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CommentFieldWhereInput = {
    active?: InputMaybe<BoolFilter>;
    commentFieldId?: InputMaybe<StringFilter>;
    creatorId?: InputMaybe<StringFilter>;
    label?: InputMaybe<StringFilter>;
    languageCode?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type CommentFieldWhereUniqueInput = {
    commentFieldId: Scalars['String'];
};

export type CommentIdCommentFieldIdCompoundUniqueInput = {
    commentFieldId: Scalars['String'];
    commentId: Scalars['String'];
};

export type CommentListRelationFilter = {
    every?: InputMaybe<CommentWhereInput>;
    none?: InputMaybe<CommentWhereInput>;
    some?: InputMaybe<CommentWhereInput>;
};

export type CommentLog = {
    __typename: 'CommentLog';
    comment: Comment;
    commentId: Scalars['String'];
    commentLogId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    creator: ModerationUser;
    creatorId: Scalars['String'];
    serializedCommentFields: Scalars['String'];
    text: Scalars['String'];
};

export const CommentLogDistinctFieldEnum = {
    CommentId: 'commentId',
    CommentLogId: 'commentLogId',
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    SerializedCommentFields: 'serializedCommentFields',
    Text: 'text',
} as const;

export type CommentLogDistinctFieldEnum = (typeof CommentLogDistinctFieldEnum)[keyof typeof CommentLogDistinctFieldEnum];
export type CommentLogListRelationFilter = {
    every?: InputMaybe<CommentLogWhereInput>;
    none?: InputMaybe<CommentLogWhereInput>;
    some?: InputMaybe<CommentLogWhereInput>;
};

export type CommentLogOrderByInput = {
    commentId?: InputMaybe<SortOrder>;
    commentLogId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    serializedCommentFields?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
};

export type CommentLogWhereInput = {
    comment?: InputMaybe<CommentWhereInput>;
    commentId?: InputMaybe<StringFilter>;
    commentLogId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<ModerationUserWhereInput>;
    creatorId?: InputMaybe<StringFilter>;
    serializedCommentFields?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
};

export type CommentLogWhereUniqueInput = {
    commentLogId: Scalars['String'];
};

export type CommentOrderByInput = {
    commentId?: InputMaybe<SortOrder>;
    moderatedProfileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type CommentUpdateInput = {
    text: Scalars['String'];
};

export type CommentWhereInput = {
    commentId?: InputMaybe<StringFilter>;
    conversationId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
};

export type CommentWhereUniqueInput = {
    commentId: Scalars['String'];
};

export const ComplainStatus = {
    Deleted: 'Deleted',
    Pending: 'Pending',
} as const;

export type ComplainStatus = (typeof ComplainStatus)[keyof typeof ComplainStatus];
export type ComplainStatusFilter = {
    equals?: InputMaybe<ComplainStatus>;
    in?: InputMaybe<Array<ComplainStatus>>;
    not?: InputMaybe<ComplainStatus>;
    notIn?: InputMaybe<Array<ComplainStatus>>;
};

export type ComplainType = {
    __typename: 'ComplainType';
    complainTypeId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    name: Scalars['String'];
    projectName: Scalars['String'];
    type: ComplainTypeType;
    updatedAt: Scalars['DateTime'];
    userComplains: Array<UserComplain>;
};

export type ComplainTypeUserComplainsArgs = {
    cursor?: InputMaybe<UserComplainWhereUniqueInput>;
    distinct?: InputMaybe<UserComplainDistinctFieldEnum>;
    orderBy?: InputMaybe<UserComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserComplainWhereInput>;
};

export type ComplainTypeCreateInput = {
    name: Scalars['String'];
    projectName: Scalars['String'];
    translations: TranslationCreateNestedManyWithoutXInput;
    type?: InputMaybe<ComplainTypeType>;
};

export type ComplainTypeIdProjectNameCompoundUniqueInput = {
    name: Scalars['String'];
    projectName: Scalars['String'];
};

export type ComplainTypeOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export const ComplainTypeType = {
    Conversation: 'Conversation',
    Image: 'Image',
    Message: 'Message',
    Picture: 'Picture',
    Profile: 'Profile',
} as const;

export type ComplainTypeType = (typeof ComplainTypeType)[keyof typeof ComplainTypeType];
export type ComplainTypeUpdateInput = {
    name?: InputMaybe<Scalars['String']>;
    translations?: InputMaybe<TranslationUpdateManyWithoutXNestedInput>;
};

export type ComplainTypeWhereInput = {
    complainTypeId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    name?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ComplainTypeWhereUniqueInput = {
    complainTypeId: Scalars['String'];
};

export type Config = {
    __typename: 'Config';
    /** completion bonuses */
    completeProfile?: Maybe<ConfigCompleteProfile>;
    /** all costs */
    cost: ConfigCost;
    countries: Array<Scalars['String']>;
    /** amount of coins user gets after login per day */
    dailyLogin?: Maybe<ConfigCoins>;
};

export type ConfigCoins = {
    __typename: 'ConfigCoins';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
};

export type ConfigCompleteProfile = {
    __typename: 'ConfigCompleteProfile';
    email: ConfigCoins;
    flirtText?: Maybe<ConfigCoins>;
    image?: Maybe<ConfigCoins>;
    interests?: Maybe<ConfigCoins>;
    mobileNumber?: Maybe<ConfigCoins>;
    profileFlags?: Maybe<ConfigCoins>;
};

export type ConfigCost = {
    __typename: 'ConfigCost';
    eyeCatcher: ConfigCostNode;
    friendship: ConfigCostNode;
    message: ConfigCostNode;
    picture?: Maybe<ConfigCostNode>;
    poke: ConfigCostNode;
    predefineMessage?: Maybe<ConfigCostNode>;
};

export type ConfigCostNode = {
    __typename: 'ConfigCostNode';
    cost: Scalars['Int'];
    free?: Maybe<Scalars['Int']>;
    length?: Maybe<Scalars['Int']>;
};

export type Conversation = {
    __typename: 'Conversation';
    acquisitionId?: Maybe<Scalars['String']>;
    allowUnmoderated: Scalars['Boolean'];
    awr: Scalars['Boolean'];
    blind?: Maybe<Scalars['Boolean']>;
    comment?: Maybe<Scalars['String']>;
    comments: Array<Comment>;
    conversationId: Scalars['String'];
    conversationTeams: Array<ConversationTeam>;
    firstAction?: Maybe<Scalars['DateTime']>;
    firstMessageId?: Maybe<Scalars['String']>;
    free?: Maybe<Scalars['Boolean']>;
    lastAction?: Maybe<Scalars['DateTime']>;
    lastMessageId?: Maybe<Scalars['String']>;
    messageCount: Scalars['Int'];
    messages: Array<Message>;
    moderated?: Maybe<Scalars['Boolean']>;
    moderationMessages: Array<ModerationMessage>;
    moderationTeam?: Maybe<ModerationTeam>;
    moderationTeamId?: Maybe<Scalars['String']>;
    moderationUser?: Maybe<ModerationUser>;
    moderationUserId?: Maybe<Scalars['String']>;
    moderationUsers: Array<ConversationModerationUser>;
    payed?: Maybe<Scalars['Boolean']>;
    profiles: Array<ConversationProfile>;
    projectName: Scalars['String'];
    queue?: Maybe<Queue>;
    scenarioId?: Maybe<Scalars['String']>;
    support?: Maybe<Scalars['Boolean']>;
    type?: Maybe<ModerationType>;
};

export type ConversationConversationTeamsArgs = {
    cursor?: InputMaybe<ConversationTeamWhereUniqueInput>;
    distinct?: InputMaybe<ConversationTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<ConversationTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationTeamWhereInput>;
};

export type ConversationMessagesArgs = {
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    distinct?: InputMaybe<MessageDistinctFieldEnum>;
    orderBy?: InputMaybe<MessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MessageWhereInput>;
};

export type ConversationModerationUsersArgs = {
    cursor?: InputMaybe<ConversationModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<ConversationModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<ConversationModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationModerationUserWhereInput>;
};

export type ConversationProfilesArgs = {
    cursor?: InputMaybe<ConversationProfileWhereUniqueInput>;
    distinct?: InputMaybe<ConversationProfileDistinctFieldEnum>;
    orderBy?: InputMaybe<ConversationProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationProfileWhereInput>;
};

export type ConversationIdProjectNameCompoundUniqueInput = {
    conversationId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ConversationListRelationFilter = {
    every?: InputMaybe<ConversationWhereInput>;
    none?: InputMaybe<ConversationWhereInput>;
    some?: InputMaybe<ConversationWhereInput>;
};

export type ConversationModerationUser = {
    __typename: 'ConversationModerationUser';
    conversation: Conversation;
    conversationId: Scalars['String'];
    conversationModerationUserId: Scalars['String'];
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    projectName: Scalars['String'];
};

export const ConversationModerationUserDistinctFieldEnum = {
    ConversationId: 'conversationId',
    ConversationModerationUserId: 'conversationModerationUserId',
    ModerationUserId: 'moderationUserId',
    ProjectName: 'projectName',
} as const;

export type ConversationModerationUserDistinctFieldEnum =
    (typeof ConversationModerationUserDistinctFieldEnum)[keyof typeof ConversationModerationUserDistinctFieldEnum];
export type ConversationModerationUserListRelationFilter = {
    every?: InputMaybe<ConversationModerationUserWhereInput>;
    none?: InputMaybe<ConversationModerationUserWhereInput>;
    some?: InputMaybe<ConversationModerationUserWhereInput>;
};

export type ConversationModerationUserOrderByInput = {
    conversationId?: InputMaybe<SortOrder>;
    conversationModerationUserId?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ConversationModerationUserWhereInput = {
    conversation?: InputMaybe<ConversationWhereInput>;
    conversationId?: InputMaybe<StringFilter>;
    conversationModerationUserId?: InputMaybe<StringFilter>;
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ConversationModerationUserWhereUniqueInput = {
    conversationModerationUserId?: InputMaybe<Scalars['String']>;
    mUI_cI_pN?: InputMaybe<Mui_CI_PNCompoundUniqueInput>;
};

export type ConversationOrderByInput = {
    blind?: InputMaybe<SortOrder>;
    free?: InputMaybe<SortOrder>;
    lastAction?: InputMaybe<SortOrder>;
    lastMessageId?: InputMaybe<SortOrder>;
    moderated?: InputMaybe<SortOrder>;
    moderationTeamId?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    payed?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    support?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export type ConversationProfile = {
    __typename: 'ConversationProfile';
    blocked?: Maybe<Scalars['Boolean']>;
    conversation: Conversation;
    conversationId: Scalars['String'];
    conversationProfileId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    freeMessages?: Maybe<Scalars['Int']>;
    giftsSend?: Maybe<Scalars['Int']>;
    giftsUnread?: Maybe<Scalars['Int']>;
    hidden?: Maybe<Scalars['Boolean']>;
    messagesSend?: Maybe<Scalars['Int']>;
    messagesUnread?: Maybe<Scalars['Int']>;
    picturesSend?: Maybe<Scalars['Int']>;
    picturesUnread?: Maybe<Scalars['Int']>;
    pokesSend?: Maybe<Scalars['Int']>;
    pokesUnread?: Maybe<Scalars['Int']>;
    profile: Profile;
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    send?: Maybe<Scalars['Int']>;
    unread?: Maybe<Scalars['Int']>;
    updatedAt: Scalars['DateTime'];
};

export const ConversationProfileDistinctFieldEnum = {
    Blocked: 'blocked',
    ConversationId: 'conversationId',
    ConversationProfileId: 'conversationProfileId',
    CreatedAt: 'createdAt',
    FreeMessages: 'freeMessages',
    GiftsSend: 'giftsSend',
    GiftsUnread: 'giftsUnread',
    MessagesSend: 'messagesSend',
    MessagesUnread: 'messagesUnread',
    PicturesSend: 'picturesSend',
    PicturesUnread: 'picturesUnread',
    PokesSend: 'pokesSend',
    PokesUnread: 'pokesUnread',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    Send: 'send',
    Unread: 'unread',
    UpdatedAt: 'updatedAt',
} as const;

export type ConversationProfileDistinctFieldEnum = (typeof ConversationProfileDistinctFieldEnum)[keyof typeof ConversationProfileDistinctFieldEnum];
export type ConversationProfileIdProjectNameCompoundUniqueInput = {
    conversationProfileId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ConversationProfileListRelationFilter = {
    every?: InputMaybe<ConversationProfileWhereInput>;
    none?: InputMaybe<ConversationProfileWhereInput>;
    some?: InputMaybe<ConversationProfileWhereInput>;
};

export type ConversationProfileOrderByInput = {
    blocked?: InputMaybe<SortOrder>;
    conversationId?: InputMaybe<SortOrder>;
    conversationProfileId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    freeMessages?: InputMaybe<SortOrder>;
    giftsSend?: InputMaybe<SortOrder>;
    giftsUnread?: InputMaybe<SortOrder>;
    messagesSend?: InputMaybe<SortOrder>;
    messagesUnread?: InputMaybe<SortOrder>;
    picturesSend?: InputMaybe<SortOrder>;
    picturesUnread?: InputMaybe<SortOrder>;
    pokesSend?: InputMaybe<SortOrder>;
    pokesUnread?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    send?: InputMaybe<SortOrder>;
    unread?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ConversationProfileOtherListRelationFilter = {
    hasReceived?: InputMaybe<Scalars['Int']>;
    hasSent?: InputMaybe<Scalars['Int']>;
    hasUnread?: InputMaybe<Scalars['Int']>;
    ownProfileId: Scalars['String'];
};

export type ConversationProfileRelativeWhereInput = {
    blocked?: InputMaybe<BoolNullableFilter>;
    conversationId?: InputMaybe<StringFilter>;
    conversationProfileId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeRelativeFilter>;
    freeMessages?: InputMaybe<IntNullableFilter>;
    giftsSend?: InputMaybe<IntNullableFilter>;
    giftsUnread?: InputMaybe<IntNullableFilter>;
    hidden?: InputMaybe<BoolNullableFilter>;
    messagesSend?: InputMaybe<IntNullableFilter>;
    messagesUnread?: InputMaybe<IntNullableFilter>;
    picturesSend?: InputMaybe<IntNullableFilter>;
    picturesUnread?: InputMaybe<IntNullableFilter>;
    pokesSend?: InputMaybe<IntNullableFilter>;
    pokesUnread?: InputMaybe<IntNullableFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    send?: InputMaybe<IntNullableFilter>;
    unread?: InputMaybe<IntNullableFilter>;
    updatedAt?: InputMaybe<DateTimeRelativeFilter>;
};

export type ConversationProfileWhereInput = {
    blocked?: InputMaybe<BoolNullableFilter>;
    conversation?: InputMaybe<ConversationWhereInput>;
    conversationId?: InputMaybe<StringFilter>;
    conversationProfileId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    freeMessages?: InputMaybe<IntNullableFilter>;
    giftsSend?: InputMaybe<IntNullableFilter>;
    giftsUnread?: InputMaybe<IntNullableFilter>;
    hidden?: InputMaybe<BoolNullableFilter>;
    messagesSend?: InputMaybe<IntNullableFilter>;
    messagesUnread?: InputMaybe<IntNullableFilter>;
    picturesSend?: InputMaybe<IntNullableFilter>;
    picturesUnread?: InputMaybe<IntNullableFilter>;
    pokesSend?: InputMaybe<IntNullableFilter>;
    pokesUnread?: InputMaybe<IntNullableFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    send?: InputMaybe<IntNullableFilter>;
    unread?: InputMaybe<IntNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ConversationProfileWhereUniqueInput = {
    cI_pI_pN?: InputMaybe<Ci_PI_PNCompoundUniqueInput>;
    conversationProfileId_projectName?: InputMaybe<ConversationProfileIdProjectNameCompoundUniqueInput>;
};

export type ConversationReceiversWhereUniqueInput = {
    conversation?: InputMaybe<ConversationWhereUniqueInput>;
    receivers?: InputMaybe<Array<ProfileWhereUniqueInput>>;
};

export type ConversationRelativeWhereInput = {
    acquisitionId?: InputMaybe<StringNullableFilter>;
    allowUnmoderated?: InputMaybe<BoolFilter>;
    awr?: InputMaybe<BoolFilter>;
    blind?: InputMaybe<BoolFilter>;
    conversationId?: InputMaybe<StringFilter>;
    firstAction?: InputMaybe<DateTimeRelativeFilter>;
    free?: InputMaybe<BoolFilter>;
    ins?: InputMaybe<DecimalNullableFilter>;
    lastAction?: InputMaybe<DateTimeRelativeFilter>;
    lastMessageId?: InputMaybe<StringNullableFilter>;
    messageCount?: InputMaybe<IntFilter>;
    moderated?: InputMaybe<BoolFilter>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    payed?: InputMaybe<BoolFilter>;
    projectName?: InputMaybe<StringFilter>;
    scenarioId?: InputMaybe<StringNullableFilter>;
    support?: InputMaybe<BoolFilter>;
    type?: InputMaybe<EnumModerationTypeNullableFilter>;
};

export type ConversationTeam = {
    __typename: 'ConversationTeam';
    conversation: Conversation;
    conversationId: Scalars['String'];
    conversationTeamId: Scalars['String'];
    projectName: Scalars['String'];
    team: ModerationTeam;
    teamId: Scalars['String'];
};

export const ConversationTeamDistinctFieldEnum = {
    ConversationId: 'conversationId',
    ConversationTeamId: 'conversationTeamId',
    ProjectName: 'projectName',
    TeamId: 'teamId',
} as const;

export type ConversationTeamDistinctFieldEnum = (typeof ConversationTeamDistinctFieldEnum)[keyof typeof ConversationTeamDistinctFieldEnum];
export type ConversationTeamListRelationFilter = {
    every?: InputMaybe<ConversationTeamWhereInput>;
    none?: InputMaybe<ConversationTeamWhereInput>;
    some?: InputMaybe<ConversationTeamWhereInput>;
};

export type ConversationTeamOrderByInput = {
    conversationId?: InputMaybe<SortOrder>;
    conversationTeamId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    teamId?: InputMaybe<SortOrder>;
};

export type ConversationTeamWhereInput = {
    conversation?: InputMaybe<ConversationWhereInput>;
    conversationId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    team?: InputMaybe<ModerationTeamWhereInput>;
    teamId?: InputMaybe<StringFilter>;
};

export type ConversationTeamWhereUniqueInput = {
    conversationTeamId?: InputMaybe<Scalars['String']>;
    tI_cI_pN?: InputMaybe<Ti_CI_PNCompoundUniqueInput>;
};

export type ConversationUpdateInput = {
    allowUnmoderated?: InputMaybe<Scalars['Boolean']>;
    awr?: InputMaybe<Scalars['Boolean']>;
    blind?: InputMaybe<Scalars['Boolean']>;
    comment?: InputMaybe<Scalars['String']>;
    conversationTeam?: InputMaybe<ModerationTeamWhereUniqueInput>;
    free?: InputMaybe<Scalars['Boolean']>;
    moderated?: InputMaybe<Scalars['Boolean']>;
    moderationUser?: InputMaybe<ModerationUserWhereUniqueInput>;
    type?: InputMaybe<ModerationType>;
};

export type ConversationWhereInput = {
    acquisitionId?: InputMaybe<StringNullableFilter>;
    allowUnmoderated?: InputMaybe<BoolFilter>;
    awr?: InputMaybe<BoolFilter>;
    blind?: InputMaybe<BoolFilter>;
    conversationId?: InputMaybe<StringFilter>;
    firstAction?: InputMaybe<DateTimeNullableFilter>;
    free?: InputMaybe<BoolFilter>;
    ins?: InputMaybe<DecimalNullableFilter>;
    lastAction?: InputMaybe<DateTimeNullableFilter>;
    lastMessageId?: InputMaybe<StringNullableFilter>;
    messageCount?: InputMaybe<IntFilter>;
    moderated?: InputMaybe<BoolFilter>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    payed?: InputMaybe<BoolFilter>;
    profiles?: InputMaybe<ConversationProfileListRelationFilter>;
    projectName?: InputMaybe<StringFilter>;
    scenarioId?: InputMaybe<StringNullableFilter>;
    support?: InputMaybe<BoolFilter>;
    type?: InputMaybe<EnumModerationTypeNullableFilter>;
};

export type ConversationWhereInputWithProfiles = {
    acquisitionId?: InputMaybe<StringNullableFilter>;
    allowUnmoderated?: InputMaybe<BoolFilter>;
    awr?: InputMaybe<BoolFilter>;
    blind?: InputMaybe<BoolFilter>;
    conversationId?: InputMaybe<StringFilter>;
    free?: InputMaybe<BoolFilter>;
    lastAction?: InputMaybe<DateTimeNullableFilter>;
    lastMessageId?: InputMaybe<StringNullableFilter>;
    messageCount?: InputMaybe<IntFilter>;
    moderated?: InputMaybe<BoolFilter>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    payed?: InputMaybe<BoolFilter>;
    profiles?: InputMaybe<ConversationProfileListRelationFilter>;
    projectName?: InputMaybe<StringFilter>;
    scenarioId?: InputMaybe<StringNullableFilter>;
    support?: InputMaybe<BoolFilter>;
    type?: InputMaybe<EnumModerationTypeNullableFilter>;
};

export type ConversationWhereOnlyScalarInput = {
    acquisitionId?: InputMaybe<StringNullableFilter>;
    allowUnmoderated?: InputMaybe<BoolFilter>;
    awr?: InputMaybe<BoolFilter>;
    blind?: InputMaybe<BoolFilter>;
    conversationId?: InputMaybe<StringFilter>;
    free?: InputMaybe<BoolFilter>;
    lastAction?: InputMaybe<DateTimeNullableFilter>;
    lastMessageId?: InputMaybe<StringNullableFilter>;
    messageCount?: InputMaybe<IntFilter>;
    moderated?: InputMaybe<BoolFilter>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    payed?: InputMaybe<BoolFilter>;
    projectName?: InputMaybe<StringFilter>;
    scenarioId?: InputMaybe<StringNullableFilter>;
    support?: InputMaybe<BoolFilter>;
    type?: InputMaybe<EnumModerationTypeNullableFilter>;
};

export type ConversationWhereUniqueInput = {
    conversationId_projectName: ConversationIdProjectNameCompoundUniqueInput;
};

export type CountProfile = {
    __typename: 'CountProfile';
    _avg?: Maybe<ProfileAvgAggregateOutputType>;
    _count?: Maybe<ProfileCountAggregateOutputType>;
    _max?: Maybe<ProfileMaxMinAggregateOutputType>;
    _min?: Maybe<ProfileMaxMinAggregateOutputType>;
    _sum?: Maybe<ProfileSumAggregateOutputType>;
    active?: Maybe<Scalars['Boolean']>;
    age?: Maybe<Scalars['Int']>;
    completed?: Maybe<Scalars['Boolean']>;
    country?: Maybe<Scalars['String']>;
    county?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    hidden?: Maybe<Scalars['Boolean']>;
    languageCode?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    profileName?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    searches?: Maybe<Gender>;
    shownLocation?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
    town?: Maybe<Scalars['String']>;
    zodiacSign?: Maybe<Scalars['String']>;
};

export type Coupon = {
    __typename: 'Coupon';
    bonus: Scalars['Int'];
    code: Scalars['String'];
    coins: Scalars['Int'];
    couponId: Scalars['String'];
    limit: Scalars['Int'];
    projectName: Scalars['String'];
    userCouponCount: Scalars['Int'];
    userCoupons: Array<UserCoupon>;
    validFrom: Scalars['DateTime'];
    validTo: Scalars['DateTime'];
};

export type CouponUserCouponsArgs = {
    cursor?: InputMaybe<UserCouponWhereUniqueInput>;
    orderBy?: InputMaybe<UserCouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserCouponWhereInput>;
};

export type CouponCreateInput = {
    bonus?: InputMaybe<Scalars['Int']>;
    code: Scalars['String'];
    coins?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    project: ProjectWhereUniqueInput;
    validFrom: Scalars['DateTime'];
    validTo: Scalars['DateTime'];
};

export const CouponDistinctFieldEnum = {
    Bonus: 'bonus',
    Code: 'code',
    Coins: 'coins',
    CouponId: 'couponId',
    Limit: 'limit',
    ProjectName: 'projectName',
    ValidFrom: 'validFrom',
    ValidTo: 'validTo',
} as const;

export type CouponDistinctFieldEnum = (typeof CouponDistinctFieldEnum)[keyof typeof CouponDistinctFieldEnum];
export type CouponIdProjectNameCompoundUniqueInput = {
    couponId: Scalars['String'];
    projectName: Scalars['String'];
};

export type CouponOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    code?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    limit?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    validFrom?: InputMaybe<SortOrder>;
    validTo?: InputMaybe<SortOrder>;
};

export type CouponUpdateInput = {
    bonus?: InputMaybe<Scalars['Int']>;
    code?: InputMaybe<Scalars['String']>;
    coins?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    validFrom?: InputMaybe<Scalars['DateTime']>;
    validTo?: InputMaybe<Scalars['DateTime']>;
};

export type CouponWhereInput = {
    bonus?: InputMaybe<IntFilter>;
    code?: InputMaybe<StringFilter>;
    coins?: InputMaybe<IntFilter>;
    couponId?: InputMaybe<StringFilter>;
    limit?: InputMaybe<IntFilter>;
    projectName?: InputMaybe<StringFilter>;
    users?: InputMaybe<UserListRelationFilter>;
    validFrom?: InputMaybe<DateTimeFilter>;
    validTo?: InputMaybe<DateTimeFilter>;
};

export type CouponWhereUniqueInput = {
    code_projectName?: InputMaybe<CodeProjectNameCompoundUniqueInput>;
    couponId_projectName?: InputMaybe<CouponIdProjectNameCompoundUniqueInput>;
};

/** Result for createMatchProfile */
export type CreateMatchProfileResult = CreateMatchProfileResultSuccess | Error;

export type CreateMatchProfileResultSuccess = {
    __typename: 'CreateMatchProfileResultSuccess';
    success?: Maybe<Scalars['Boolean']>;
};

export type D_APi_PNCompoundUniqueInput = {
    agencyProjectId: Scalars['String'];
    date: Scalars['String'];
    projectName: Scalars['String'];
};

export type D_H_APi_PNCompoundUniqueInput = {
    agencyProjectId: Scalars['String'];
    date: Scalars['String'];
    hour: Scalars['Int'];
    projectName: Scalars['String'];
};

export type D_H_MI_PNCompoundUniqueInput = {
    date: Scalars['String'];
    hour: Scalars['Int'];
    moderatorId: Scalars['String'];
    projectName: Scalars['String'];
};

export type D_MI_PNCompoundUniqueInput = {
    date: Scalars['String'];
    moderatorId: Scalars['String'];
    projectName: Scalars['String'];
};

export type DateAdminIdCompoundUniqueInput = {
    adminId: Scalars['String'];
    date: Scalars['String'];
};

export type DateAgencyIdCompoundUniqueInput = {
    agencyId: Scalars['String'];
    date: Scalars['String'];
};

export type DateHourAgencyIdCompoundUniqueInput = {
    agencyId: Scalars['String'];
    date: Scalars['String'];
    hour: Scalars['Int'];
};

export type DateTimeFilter = {
    equals?: InputMaybe<Scalars['DateTime']>;
    gt?: InputMaybe<Scalars['DateTime']>;
    gte?: InputMaybe<Scalars['DateTime']>;
    in?: InputMaybe<Array<Scalars['DateTime']>>;
    lt?: InputMaybe<Scalars['DateTime']>;
    lte?: InputMaybe<Scalars['DateTime']>;
    not?: InputMaybe<Scalars['DateTime']>;
    notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
    equals?: InputMaybe<Scalars['DateTime']>;
    gt?: InputMaybe<Scalars['DateTime']>;
    gte?: InputMaybe<Scalars['DateTime']>;
    in?: InputMaybe<Array<Scalars['DateTime']>>;
    lt?: InputMaybe<Scalars['DateTime']>;
    lte?: InputMaybe<Scalars['DateTime']>;
    not?: InputMaybe<Scalars['DateTime']>;
    notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeRelative = {
    unit: DateTimeRelativeUnit;
    value: Scalars['Int'];
};

export type DateTimeRelativeFilter = {
    gt?: InputMaybe<DateTimeRelative>;
    gte?: InputMaybe<DateTimeRelative>;
    lt?: InputMaybe<DateTimeRelative>;
    lte?: InputMaybe<DateTimeRelative>;
};

export const DateTimeRelativeUnit = {
    D: 'd',
    Days: 'days',
    H: 'h',
    Hours: 'hours',
    M: 'm',
    Minutes: 'minutes',
    S: 's',
    Seconds: 'seconds',
    W: 'w',
    Weeks: 'weeks',
} as const;

export type DateTimeRelativeUnit = (typeof DateTimeRelativeUnit)[keyof typeof DateTimeRelativeUnit];
export type DecimalNullableFilter = {
    equals?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    gte?: InputMaybe<Scalars['String']>;
    lt?: InputMaybe<Scalars['String']>;
    lte?: InputMaybe<Scalars['String']>;
};

export type DefaultComment = {
    __typename: 'DefaultComment';
    defaultCommentId: Scalars['String'];
    languageCode: Scalars['String'];
    projectName: Scalars['String'];
    text: Scalars['String'];
};

export type DefaultCommentCreateInput = {
    languageCode: Scalars['String'];
    project: ProjectWhereUniqueInput;
    text: Scalars['String'];
};

export type DefaultCommentOrderByInput = {
    languageCode?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type DefaultCommentUpdateInput = {
    text: Scalars['String'];
};

export type DefaultCommentWhereInput = {
    defaultCommentId?: InputMaybe<StringFilter>;
    languageCode?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
};

export type DefaultCommentWhereUniqueInput = {
    defaultCommentId: Scalars['String'];
};

export type DeleteUserComplainResult = ResultError | UserComplain;

export type EnumAdminLogTypeFilter = {
    equals?: InputMaybe<LogTypeInputEnum>;
    in?: InputMaybe<Array<LogTypeInputEnum>>;
    not?: InputMaybe<LogTypeInputEnum>;
    notIn?: InputMaybe<Array<LogTypeInputEnum>>;
};

export type EnumGenderFilter = {
    equals?: InputMaybe<Gender>;
    in?: InputMaybe<Array<Gender>>;
    not?: InputMaybe<Gender>;
    notIn?: InputMaybe<Array<Gender>>;
};

export type EnumModerationTypeNullableFilter = {
    equals?: InputMaybe<ModerationType>;
    in?: InputMaybe<Array<ModerationType>>;
    not?: InputMaybe<ModerationType>;
    notIn?: InputMaybe<Array<ModerationType>>;
};

export type EnumProfileStatusFilter = {
    equals?: InputMaybe<ProfileStatus>;
    in?: InputMaybe<Array<ProfileStatus>>;
    not?: InputMaybe<ProfileStatus>;
    notIn?: InputMaybe<Array<ProfileStatus>>;
};

export type EnumScenarioTypeFilter = {
    equals?: InputMaybe<ScenarioType>;
    in?: InputMaybe<Array<ScenarioType>>;
    not?: InputMaybe<ScenarioType>;
    notIn?: InputMaybe<Array<ScenarioType>>;
};

export type EnumSupportTicketStatusFilter = {
    equals?: InputMaybe<SupportTicketStatus>;
    in?: InputMaybe<Array<SupportTicketStatus>>;
    not?: InputMaybe<SupportTicketStatus>;
    notIn?: InputMaybe<Array<SupportTicketStatus>>;
};

export type Error = {
    __typename: 'Error';
    errorCode?: Maybe<Scalars['Int']>;
    errorMessage?: Maybe<Scalars['String']>;
};

export type ErrorCode = {
    __typename: 'ErrorCode';
    code: Scalars['Int'];
    name: Scalars['String'];
};

export type Feature = {
    __typename: 'Feature';
    featureId: Scalars['String'];
    name: FeatureName;
    projectName: Scalars['String'];
};

export const FeatureName = {
    Avatar: 'Avatar',
    DailySong: 'DailySong',
    LegacyProject: 'LegacyProject',
    Like: 'Like',
    OptionPoll: 'OptionPoll',
    PredefinedMessage: 'PredefinedMessage',
    ProfileText: 'ProfileText',
} as const;

export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName];
export type Flag = {
    __typename: 'Flag';
    flagId: Scalars['String'];
    flagValues: Array<FlagValue>;
    gender: FlagGender;
    max?: Maybe<Scalars['Int']>;
    maxLength: Scalars['Int'];
    min?: Maybe<Scalars['Int']>;
    minLength: Scalars['Int'];
    name: Scalars['String'];
    numberStep?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    regEx?: Maybe<Scalars['String']>;
    translation: Translation;
    translations: Array<Translation>;
    type: FlagType;
};

export type FlagTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type FlagCreateInput = {
    flagValues?: InputMaybe<FlagValueCreateNestedManyWithoutFlagInput>;
    gender?: InputMaybe<FlagGender>;
    max?: InputMaybe<Scalars['Int']>;
    maxLength?: InputMaybe<Scalars['Int']>;
    min?: InputMaybe<Scalars['Int']>;
    minLength?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    numberStep?: InputMaybe<Scalars['String']>;
    projectName: Scalars['String'];
    regEx?: InputMaybe<Scalars['String']>;
    translations: TranslationCreateNestedManyWithoutXInput;
    type: FlagType;
};

export type FlagCreateNestedOneWithoutProfileFlagsInput = {
    connect: FlagWhereUniqueInput;
};

export const FlagGender = {
    All: 'All',
    Divers: 'Divers',
    Female: 'Female',
    Male: 'Male',
} as const;

export type FlagGender = (typeof FlagGender)[keyof typeof FlagGender];
export const FlagType = {
    InputNumber: 'InputNumber',
    InputString: 'InputString',
    Select: 'Select',
} as const;

export type FlagType = (typeof FlagType)[keyof typeof FlagType];
export type FlagUpdateInput = {
    flagValues?: InputMaybe<FlagValueUpdateManyWithoutFlagNestedInput>;
    gender?: InputMaybe<FlagGender>;
    max?: InputMaybe<Scalars['Int']>;
    maxLength?: InputMaybe<Scalars['Int']>;
    min?: InputMaybe<Scalars['Int']>;
    minLength?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    numberStep?: InputMaybe<Scalars['String']>;
    regEx?: InputMaybe<Scalars['String']>;
    translations?: InputMaybe<TranslationUpdateManyWithoutXNestedInput>;
    type?: InputMaybe<FlagType>;
};

export type FlagValue = {
    __typename: 'FlagValue';
    flagId: Scalars['String'];
    flagValueId: Scalars['String'];
    translation: Translation;
    translations: Array<Translation>;
};

export type FlagValueTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type FlagValueCreateNestedManyWithoutFlagInput = {
    create: Array<FlagValueCreateWithoutFlagInput>;
};

export type FlagValueCreateNestedOneWithoutProfileFlagsInput = {
    connect: FlagValueWhereUniqueInput;
};

export type FlagValueCreateWithoutFlagInput = {
    translations: TranslationCreateNestedManyWithoutXInput;
};

export type FlagValueUpdateManyWithoutFlagNestedInput = {
    create?: InputMaybe<Array<FlagValueCreateWithoutFlagInput>>;
    delete?: InputMaybe<Array<FlagValueWhereUniqueInput>>;
    update?: InputMaybe<Array<FlagValueUpdateWithWhereUniqueWithoutFlagInput>>;
};

export type FlagValueUpdateOneWithoutProfileFlagsNestedInput = {
    connect: FlagValueWhereUniqueInput;
};

export type FlagValueUpdateWithWhereUniqueWithoutFlagInput = {
    data: FlagValueUpdateWithoutFlagInput;
    where: FlagValueWhereUniqueInput;
};

export type FlagValueUpdateWithoutFlagInput = {
    translations: TranslationUpdateManyWithoutXNestedInput;
};

export type FlagValueWhereUniqueInput = {
    flagValueId: Scalars['String'];
};

export type FlagWhereInput = {
    flagId?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type FlagWhereUniqueInput = {
    flagId: Scalars['String'];
};

export type FloatFilter = {
    equals?: InputMaybe<Scalars['Float']>;
    gt?: InputMaybe<Scalars['Float']>;
    gte?: InputMaybe<Scalars['Float']>;
    in?: InputMaybe<Array<Scalars['Float']>>;
    lt?: InputMaybe<Scalars['Float']>;
    lte?: InputMaybe<Scalars['Float']>;
    not?: InputMaybe<Scalars['Float']>;
    notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
    equals?: InputMaybe<Scalars['Float']>;
    gt?: InputMaybe<Scalars['Float']>;
    gte?: InputMaybe<Scalars['Float']>;
    in?: InputMaybe<Array<Scalars['Float']>>;
    lt?: InputMaybe<Scalars['Float']>;
    lte?: InputMaybe<Scalars['Float']>;
    not?: InputMaybe<Scalars['Float']>;
    notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export const Gender = {
    Divers: 'Divers',
    Female: 'Female',
    Male: 'Male',
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];
export type GenderProject = {
    __typename: 'GenderProject';
    gender: Gender;
    genderProjectId: Scalars['String'];
    projectName: Scalars['String'];
};

export type GenderProjectNameCompoundUniqueInput = {
    gender: Gender;
    projectName: Scalars['String'];
};

export type GenderProjectOrderByInput = {
    gender?: InputMaybe<SortOrder>;
    genderProjectId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type GenderProjectWhereInput = {
    gender?: InputMaybe<EnumGenderFilter>;
    genderProjectId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type GenderProjectWhereUniqueInput = {
    genderProjectId?: InputMaybe<Scalars['String']>;
    gender_projectName?: InputMaybe<GenderProjectNameCompoundUniqueInput>;
};

export type Geolocation = {
    __typename: 'Geolocation';
    iso: Scalars['String'];
    latitude: Scalars['String'];
    location: Scalars['String'];
    longitude: Scalars['String'];
    postcode: Scalars['String'];
    timezone: Scalars['String'];
};

export type GeolocationFindManyByRangeResult = {
    __typename: 'GeolocationFindManyByRangeResult';
    distance?: Maybe<Scalars['String']>;
    iso: Scalars['String'];
    latitude: Scalars['String'];
    location: Scalars['String'];
    longitude: Scalars['String'];
    postcode: Scalars['String'];
    timezone: Scalars['String'];
};

export type GeolocationOrderByInput = {
    distance?: InputMaybe<SortOrder>;
};

export type GeolocationWhereInput = {
    iso: Scalars['String'];
    latitude?: InputMaybe<Scalars['String']>;
    location?: InputMaybe<Scalars['String']>;
    longitude?: InputMaybe<Scalars['String']>;
    postcode?: InputMaybe<Scalars['String']>;
    rangeFrom: Scalars['String'];
    rangeTo: Scalars['String'];
};

export type Gift = {
    __typename: 'Gift';
    categories: Array<GiftCategory>;
    cost: Scalars['Int'];
    giftId: Scalars['String'];
    name: Scalars['String'];
    path: Scalars['String'];
    projectName: Scalars['String'];
    status: GiftStatus;
    translations: Array<Translation>;
};

export type GiftPathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type GiftTranslationsArgs = {
    languagCode?: InputMaybe<Scalars['String']>;
};

export type GiftCategory = {
    __typename: 'GiftCategory';
    giftCategoryId: Scalars['String'];
    gifts?: Maybe<Array<Gift>>;
    name: Scalars['String'];
    private: Scalars['Boolean'];
    projectName: Scalars['String'];
    status: GiftStatus;
    translations: Array<Translation>;
};

export type GiftCategoryGiftsArgs = {
    cursor?: InputMaybe<GiftWhereUniqueInput>;
    distinct?: InputMaybe<GiftDistinctFieldEnum>;
    orderBy?: InputMaybe<GiftOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<GiftWhereInput>;
};

export type GiftCategoryTranslationsArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
};

export type GiftCategoryCreateInput = {
    name: Scalars['String'];
    private?: InputMaybe<Scalars['Boolean']>;
    project: ProjectWhereUniqueInput;
    status?: InputMaybe<GiftStatus>;
    translations?: InputMaybe<TranslationCreateNestedManyWithoutXInput>;
};

export type GiftCategoryCreateManyWithoutGiftsInput = {
    connect: Array<GiftCategoryWhereUniqueInput>;
};

export const GiftCategoryDistinctFieldEnum = {
    GiftCategoryId: 'giftCategoryId',
    Name: 'name',
    Private: 'private',
    ProjectName: 'projectName',
    Status: 'status',
} as const;

export type GiftCategoryDistinctFieldEnum = (typeof GiftCategoryDistinctFieldEnum)[keyof typeof GiftCategoryDistinctFieldEnum];
export type GiftCategoryListRelationFilter = {
    every?: InputMaybe<GiftCategoryWhereInput>;
    none?: InputMaybe<GiftCategoryWhereInput>;
    some?: InputMaybe<GiftCategoryWhereInput>;
};

export type GiftCategoryOrderByInput = {
    giftCategoryId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    private?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type GiftCategoryUpdateInput = {
    name?: InputMaybe<Scalars['String']>;
    private?: InputMaybe<Scalars['Boolean']>;
    status?: InputMaybe<GiftStatus>;
};

export type GiftCategoryUpdateManyWithoutGiftsInput = {
    set: Array<GiftCategoryWhereUniqueInput>;
};

export type GiftCategoryWhereInput = {
    giftCategoryId?: InputMaybe<StringFilter>;
    gifts?: InputMaybe<GiftListRelationFilter>;
    name?: InputMaybe<StringFilter>;
    private?: InputMaybe<BoolFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    status?: InputMaybe<GiftStatusFilter>;
};

export type GiftCategoryWhereUniqueInput = {
    giftCategoryId: Scalars['String'];
};

export type GiftCreateInput = {
    base64Image: Scalars['String'];
    categories?: InputMaybe<GiftCategoryCreateManyWithoutGiftsInput>;
    cost: Scalars['Int'];
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
    status?: InputMaybe<GiftStatus>;
    translations?: InputMaybe<TranslationCreateNestedManyWithoutXInput>;
};

export const GiftDistinctFieldEnum = {
    Cost: 'cost',
    GiftId: 'giftId',
    Name: 'name',
    Path: 'path',
    ProjectName: 'projectName',
    Status: 'status',
} as const;

export type GiftDistinctFieldEnum = (typeof GiftDistinctFieldEnum)[keyof typeof GiftDistinctFieldEnum];
export type GiftListRelationFilter = {
    every?: InputMaybe<GiftWhereInput>;
    none?: InputMaybe<GiftWhereInput>;
    some?: InputMaybe<GiftWhereInput>;
};

export type GiftOrderByInput = {
    cost?: InputMaybe<SortOrder>;
    giftId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    path?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export const GiftStatus = {
    Deleted: 'Deleted',
    Disabled: 'Disabled',
    Enabled: 'Enabled',
} as const;

export type GiftStatus = (typeof GiftStatus)[keyof typeof GiftStatus];
export type GiftStatusFilter = {
    equals?: InputMaybe<GiftStatus>;
    in?: InputMaybe<Array<GiftStatus>>;
    not?: InputMaybe<GiftStatus>;
    notIn?: InputMaybe<Array<GiftStatus>>;
};

export type GiftUpdateInput = {
    categories?: InputMaybe<GiftCategoryUpdateManyWithoutGiftsInput>;
    cost?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<GiftStatus>;
};

export type GiftWhereInput = {
    categories?: InputMaybe<GiftCategoryListRelationFilter>;
    cost?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    path?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    status?: InputMaybe<GiftStatusFilter>;
};

export type GiftWhereUniqueInput = {
    giftId: Scalars['String'];
};

export type Image = {
    __typename: 'Image';
    fsk18: Scalars['Boolean'];
    imageId: Scalars['String'];
    main: Scalars['Boolean'];
    path: Scalars['String'];
    private: Scalars['Boolean'];
    profile: Profile;
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    userChanged: Scalars['DateTime'];
    verified: Scalars['Boolean'];
};

export type ImagePathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type ImageCreateInput = {
    fsk18?: InputMaybe<Scalars['Boolean']>;
    image: Scalars['String'];
    main: Scalars['Boolean'];
    private: Scalars['Boolean'];
    profile: ProfileWhereUniqueInput;
    projectName: Scalars['String'];
    verified?: InputMaybe<Scalars['Boolean']>;
};

export const ImageDistinctFieldEnum = {
    Fsk18: 'fsk18',
    ImageId: 'imageId',
    Main: 'main',
    Path: 'path',
    Private: 'private',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    UserChanged: 'userChanged',
    Verified: 'verified',
} as const;

export type ImageDistinctFieldEnum = (typeof ImageDistinctFieldEnum)[keyof typeof ImageDistinctFieldEnum];
export type ImageIdProjectNameCompoundUniqueInput = {
    imageId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ImageListRelationFilter = {
    every?: InputMaybe<ImageWhereInput>;
    none?: InputMaybe<ImageWhereInput>;
    some?: InputMaybe<ImageWhereInput>;
};

export type ImageOrderByInput = {
    fsk18?: InputMaybe<SortOrder>;
    main?: InputMaybe<SortOrder>;
    path?: InputMaybe<SortOrder>;
    private?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    userChanged?: InputMaybe<SortOrder>;
    verified?: InputMaybe<SortOrder>;
};

export type ImageScalarWhereInput = {
    fsk18?: InputMaybe<BoolFilter>;
    imageId?: InputMaybe<StringFilter>;
    main?: InputMaybe<BoolFilter>;
    path?: InputMaybe<StringFilter>;
    private?: InputMaybe<BoolFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    userChanged?: InputMaybe<DateTimeFilter>;
    verified?: InputMaybe<BoolFilter>;
};

export type ImageUpdateInput = {
    fsk18?: InputMaybe<Scalars['Boolean']>;
    main?: InputMaybe<Scalars['Boolean']>;
    private?: InputMaybe<Scalars['Boolean']>;
    verified?: InputMaybe<Scalars['Boolean']>;
};

export type ImageUpdateManyDataInput = {
    fsk18?: InputMaybe<Scalars['Boolean']>;
    main?: InputMaybe<Scalars['Boolean']>;
    path?: InputMaybe<Scalars['String']>;
    private?: InputMaybe<Scalars['Boolean']>;
    userChanged?: InputMaybe<Scalars['DateTime']>;
    verified?: InputMaybe<Scalars['Boolean']>;
};

export type ImageUpdateManyInput = {
    data: ImageUpdateInput;
    where: ImageWhereUniqueInput;
};

export type ImageUpdateManyWithWhereNestedInput = {
    data: ImageUpdateManyDataInput;
    where: ImageScalarWhereInput;
};

export type ImageUpdateManyWithoutProfileInput = {
    connect?: InputMaybe<Array<ImageWhereUniqueInput>>;
    delete?: InputMaybe<Array<ImageWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ImageScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ImageWhereUniqueInput>>;
    set?: InputMaybe<Array<ImageWhereUniqueInput>>;
    updateMany?: InputMaybe<Array<ImageUpdateManyWithWhereNestedInput>>;
};

export type ImageWhereInput = {
    fsk18?: InputMaybe<BoolFilter>;
    main?: InputMaybe<BoolFilter>;
    path?: InputMaybe<StringFilter>;
    private?: InputMaybe<BoolFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    userChanged?: InputMaybe<DateTimeFilter>;
    verified?: InputMaybe<BoolFilter>;
};

export type ImageWhereUniqueInput = {
    imageId_projectName: ImageIdProjectNameCompoundUniqueInput;
};

export type ImageWithoutProfileListRelationFilter = {
    every?: InputMaybe<ImageWithoutProfileWhereInput>;
    none?: InputMaybe<ImageWithoutProfileWhereInput>;
    some?: InputMaybe<ImageWithoutProfileWhereInput>;
};

export type ImageWithoutProfileWhereInput = {
    fsk18?: InputMaybe<BoolFilter>;
    main?: InputMaybe<BoolFilter>;
    path?: InputMaybe<StringFilter>;
    private?: InputMaybe<BoolFilter>;
    projectName?: InputMaybe<StringFilter>;
    userChanged?: InputMaybe<DateTimeFilter>;
    verified?: InputMaybe<BoolFilter>;
};

export type InputError = {
    __typename: 'InputError';
    errors: Array<InputErrorEnum>;
    fieldName: Scalars['String'];
};

export const InputErrorEnum = {
    Format: 'FORMAT',
    Invalid: 'INVALID',
    MaxLength: 'MAX_LENGTH',
    MinLength: 'MIN_LENGTH',
    Required: 'REQUIRED',
    TooLarge: 'TOO_LARGE',
    TooSmall: 'TOO_SMALL',
    Unique: 'UNIQUE',
} as const;

export type InputErrorEnum = (typeof InputErrorEnum)[keyof typeof InputErrorEnum];
export type InputErrors = {
    __typename: 'InputErrors';
    errors: Array<InputError>;
};

export type IntFilter = {
    equals?: InputMaybe<Scalars['Int']>;
    gt?: InputMaybe<Scalars['Int']>;
    gte?: InputMaybe<Scalars['Int']>;
    in?: InputMaybe<Array<Scalars['Int']>>;
    lt?: InputMaybe<Scalars['Int']>;
    lte?: InputMaybe<Scalars['Int']>;
    not?: InputMaybe<Scalars['Int']>;
    notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
    equals?: InputMaybe<Scalars['Int']>;
    gt?: InputMaybe<Scalars['Int']>;
    gte?: InputMaybe<Scalars['Int']>;
    in?: InputMaybe<Array<Scalars['Int']>>;
    lt?: InputMaybe<Scalars['Int']>;
    lte?: InputMaybe<Scalars['Int']>;
    not?: InputMaybe<Scalars['Int']>;
    notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
    _avg?: InputMaybe<FloatNullableFilter>;
    _count?: InputMaybe<IntNullableFilter>;
    _max?: InputMaybe<IntNullableFilter>;
    _min?: InputMaybe<IntNullableFilter>;
    _sum?: InputMaybe<IntNullableFilter>;
    equals?: InputMaybe<Scalars['Int']>;
    gt?: InputMaybe<Scalars['Int']>;
    gte?: InputMaybe<Scalars['Int']>;
    in?: InputMaybe<Array<Scalars['Int']>>;
    lt?: InputMaybe<Scalars['Int']>;
    lte?: InputMaybe<Scalars['Int']>;
    not?: InputMaybe<Scalars['Int']>;
    notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Interest = {
    __typename: 'Interest';
    active: Scalars['Boolean'];
    category?: Maybe<InterestCategory>;
    categoryId: Scalars['String'];
    class?: Maybe<Scalars['String']>;
    interestId: Scalars['String'];
    name: Scalars['String'];
    projectName: Scalars['String'];
    translation: Translation;
    translations: Array<Translation>;
};

export type InterestTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type InterestCategory = {
    __typename: 'InterestCategory';
    active: Scalars['Boolean'];
    interestCategoryId: Scalars['String'];
    interests?: Maybe<Array<Interest>>;
    name: Scalars['String'];
    projectName: Scalars['String'];
    translation: Translation;
    translations: Array<Translation>;
};

export type InterestCategoryInterestsArgs = {
    cursor?: InputMaybe<InterestWhereUniqueInput>;
    distinct?: InputMaybe<InterestDistinctFieldEnum>;
    orderBy?: InputMaybe<InterestOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<InterestWhereInput>;
};

export type InterestCategoryTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type InterestCategoryCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
    translations?: InputMaybe<TranslationCreateNestedManyWithoutXInput>;
};

export type InterestCategoryCreateManyWithoutInterestsInput = {
    connect?: InputMaybe<Array<InterestCategoryWhereUniqueInput>>;
    create?: InputMaybe<Array<InterestCategoryCreateWithoutInterestsInput>>;
};

export type InterestCategoryCreateWithoutInterestsInput = {
    aInterestCategoryId?: InputMaybe<Scalars['String']>;
    active?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    interestCategoryId: Scalars['String'];
    name: Scalars['String'];
};

export const InterestCategoryDistinctFieldEnum = {
    Active: 'active',
    Description: 'description',
    InterestCategoryId: 'interestCategoryId',
    Name: 'name',
    ProjectName: 'projectName',
} as const;

export type InterestCategoryDistinctFieldEnum = (typeof InterestCategoryDistinctFieldEnum)[keyof typeof InterestCategoryDistinctFieldEnum];
export type InterestCategoryListRelationFilter = {
    every?: InputMaybe<InterestCategoryWhereInput>;
    none?: InputMaybe<InterestCategoryWhereInput>;
    some?: InputMaybe<InterestCategoryWhereInput>;
};

export type InterestCategoryNameProjectNameCompoundUniqueInput = {
    /** interestCategoryName */
    name: Scalars['String'];
    projectName: Scalars['String'];
};

export type InterestCategoryOrderByInput = {
    active?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type InterestCategoryUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    interests?: InputMaybe<InterestUpdateManyWithoutCategoryInput>;
    name?: InputMaybe<Scalars['String']>;
};

export type InterestCategoryUpdateManyWithoutInterestsInput = {
    connect?: InputMaybe<Array<InterestCategoryWhereUniqueInput>>;
};

export type InterestCategoryWhereInput = {
    active?: InputMaybe<BoolFilter>;
    description?: InputMaybe<StringNullableFilter>;
    interests?: InputMaybe<InterestListRelationFilter>;
    name?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type InterestCategoryWhereUniqueInput = {
    name_projectName: InterestCategoryNameProjectNameCompoundUniqueInput;
};

export type InterestCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category: InterestCategoryWhereUniqueInput;
    class?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
    translations?: InputMaybe<TranslationCreateNestedManyWithoutXInput>;
};

export type InterestCreateOneWithoutProfileInterestsInput = {
    connect: InterestWhereUniqueInput;
};

export type InterestCreateWithoutCategoryInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    class?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
};

export const InterestDistinctFieldEnum = {
    Active: 'active',
    Class: 'class',
    InterestId: 'interestId',
    Name: 'name',
    ProjectName: 'projectName',
} as const;

export type InterestDistinctFieldEnum = (typeof InterestDistinctFieldEnum)[keyof typeof InterestDistinctFieldEnum];
export type InterestListRelationFilter = {
    every?: InputMaybe<InterestWhereInput>;
    none?: InputMaybe<InterestWhereInput>;
    some?: InputMaybe<InterestWhereInput>;
};

export type InterestNameProjectNameCompoundUniqueInput = {
    /** interestName */
    name: Scalars['String'];
    projectName: Scalars['String'];
};

export type InterestOrderByInput = {
    active?: InputMaybe<SortOrder>;
    class?: InputMaybe<SortOrder>;
    interestId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type InterestUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    class?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type InterestUpdateManyWithoutCategoryInput = {
    connect?: InputMaybe<Array<InterestWhereUniqueInput>>;
    create?: InputMaybe<Array<InterestCreateWithoutCategoryInput>>;
    disconnect?: InputMaybe<Array<InterestWhereUniqueInput>>;
    set?: InputMaybe<Array<InterestWhereUniqueInput>>;
};

export type InterestWhereInput = {
    active?: InputMaybe<BoolFilter>;
    category?: InputMaybe<InterestCategoryWhereInput>;
    class?: InputMaybe<StringNullableFilter>;
    interestId?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    profileInterests?: InputMaybe<ProfileInterestListRelationFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type InterestWhereUniqueInput = {
    name_projectName: InterestNameProjectNameCompoundUniqueInput;
};

export type JsonNullableArrayFilter = {
    array_contains?: InputMaybe<Scalars['JSONStringArray']>;
    array_ends_with?: InputMaybe<Scalars['JSONStringArray']>;
    array_starts_with?: InputMaybe<Scalars['JSONStringArray']>;
};

export type Language = {
    __typename: 'Language';
    code: Scalars['String'];
    name: Scalars['String'];
    projects: Array<Project>;
};

export type LanguageOrderByInput = {
    code?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
};

export type LanguageUpdateOneRequiredWithoutX = {
    connect: LanguageWhereUniqueInput;
};

export type LanguageWhereInput = {
    AdminUser?: InputMaybe<AdminUserListRelationFilter>;
    ModerationUser?: InputMaybe<ModerationUserListRelationFilter>;
    code?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    projects?: InputMaybe<ProjectListRelationFilter>;
};

export type LanguageWhereUniqueInput = {
    code: Scalars['String'];
};

export type LegacyUser = {
    __typename: 'LegacyUser';
    createdAt: Scalars['DateTime'];
    email: Scalars['String'];
    necomUserId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    /** auth_user_id */
    userId: Scalars['Int'];
    username: Scalars['String'];
};

export type LegacyUserImportInput = {
    legacyUserId: Scalars['Int'];
    projectName: Scalars['String'];
};

export type LegacyUserOrderByInput = {
    auth_user_id?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    registrationtime?: InputMaybe<SortOrder>;
    username?: InputMaybe<SortOrder>;
};

export type LegacyUserWhereInput = {
    auth_user_id?: InputMaybe<IntNullableFilter>;
    email?: InputMaybe<StringNullableFilter>;
    projectName: Scalars['String'];
    registrationtime?: InputMaybe<DateTimeNullableFilter>;
    username?: InputMaybe<StringNullableFilter>;
};

export type LogCreateInput = {
    clicks?: InputMaybe<Scalars['Int']>;
    logData: LogDataInput;
    projectName: Scalars['String'];
    type: LogTypeInputEnum;
};

export type LogDataInput = {
    filter?: InputMaybe<Scalars['String']>;
    newValue?: InputMaybe<Scalars['String']>;
    oldValue?: InputMaybe<Scalars['String']>;
    target?: InputMaybe<Scalars['String']>;
};

export const LogTypeInputEnum = {
    AdminUserCreate: 'adminUserCreate',
    AdminUserDelete: 'adminUserDelete',
    AdminUserEdit: 'adminUserEdit',
    AwrCreate: 'awrCreate',
    AwrDelete: 'awrDelete',
    AwrEdit: 'awrEdit',
    DefaultCommentEdit: 'defaultCommentEdit',
    GiftCategoryCreate: 'giftCategoryCreate',
    GiftCategoryDelete: 'giftCategoryDelete',
    GiftCategoryEdit: 'giftCategoryEdit',
    GiftCreate: 'giftCreate',
    GiftDelete: 'giftDelete',
    GiftEdit: 'giftEdit',
    MatchConfigEdit: 'matchConfigEdit',
    ModUserCreate: 'modUserCreate',
    ModUserDelete: 'modUserDelete',
    ModUserEdit: 'modUserEdit',
    ModUserProfileCreate: 'modUserProfileCreate',
    OfferCreate: 'offerCreate',
    OfferEdit: 'offerEdit',
    Pageview: 'pageview',
    ProjectFlagsEdit: 'projectFlagsEdit',
    ProviderCreate: 'providerCreate',
    ProviderEdit: 'providerEdit',
    QueueAssign: 'queueAssign',
    QueueDelete: 'queueDelete',
    RoleCreate: 'roleCreate',
    RoleDelete: 'roleDelete',
    RoleEdit: 'roleEdit',
    SupportTextDelete: 'supportTextDelete',
    SupportTextUpdate: 'supportTextUpdate',
    SupportTicketUpdate: 'supportTicketUpdate',
    UserBonusChange: 'userBonusChange',
    UserCoinsChange: 'userCoinsChange',
    UserEdit: 'userEdit',
    UserSearch: 'userSearch',
    UserSocialUnbind: 'userSocialUnbind',
    UserStatusChange: 'userStatusChange',
} as const;

export type LogTypeInputEnum = (typeof LogTypeInputEnum)[keyof typeof LogTypeInputEnum];
export type LoginInput = {
    password: Scalars['String'];
    username: Scalars['String'];
};

export type Mpi_PN_TICompoundUniqueInput = {
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    teamId: Scalars['String'];
};

export type Mui_CI_PNCompoundUniqueInput = {
    conversationId: Scalars['String'];
    moderationUserId: Scalars['String'];
    projectName: Scalars['String'];
};

export type Mui_MPi_PNCompoundUniqueInput = {
    moderationUserId: Scalars['String'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
};

export type Mui_PN_MPCompoundUniqueInput = {
    moderationPrivilege: ModerationPrivilege;
    moderationUserId: Scalars['String'];
    projectName: Scalars['String'];
};

export type MatchConfig = {
    __typename: 'MatchConfig';
    admin?: Maybe<AdminUser>;
    adminUserId?: Maybe<Scalars['Cuid']>;
    buyMore: Scalars['Boolean'];
    chanceBefore: Scalars['Int'];
    chanceMatch: Scalars['Int'];
    cost: Scalars['Int'];
    /** Days */
    deleteAfter: Scalars['Int'];
    freeMessages: Scalars['Int'];
    matchConfigId: Scalars['Cuid'];
    maxMatches: Scalars['Int'];
    name: Scalars['String'];
    raiseAwr: Scalars['Boolean'];
    updatedAt: Scalars['DateTime'];
};

export type MatchConfigUpdateInput = {
    buyMore?: InputMaybe<Scalars['Boolean']>;
    chanceBefore?: InputMaybe<Scalars['Int']>;
    chanceMatch?: InputMaybe<Scalars['Int']>;
    cost?: InputMaybe<Scalars['Int']>;
    deleteAfter?: InputMaybe<Scalars['Int']>;
    freeMessages?: InputMaybe<Scalars['Int']>;
    maxMatches?: InputMaybe<Scalars['Int']>;
    raiseAwr?: InputMaybe<Scalars['Boolean']>;
    updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchConfigWhereUniqueInput = {
    name: Scalars['String'];
};

export type MatchProfileCreateInput = {
    hide?: InputMaybe<Scalars['Boolean']>;
    moderated?: InputMaybe<Scalars['Boolean']>;
    profileId: Scalars['String'];
    project: ProjectWhereUniqueInput;
};

export type Message = {
    __typename: 'Message';
    conversation?: Maybe<Conversation>;
    conversationId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    gift?: Maybe<Gift>;
    giftId?: Maybe<Scalars['String']>;
    messageId: Scalars['String'];
    moderationMessage?: Maybe<ModerationMessage>;
    moderatorName: Scalars['String'];
    path?: Maybe<Scalars['String']>;
    picture?: Maybe<Picture>;
    pictureId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    sender?: Maybe<Profile>;
    senderId: Scalars['String'];
    text?: Maybe<Scalars['String']>;
    type: MessageType;
};

export type MessagePathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type MessageBadword = {
    __typename: 'MessageBadword';
    messageBadwordId: Scalars['String'];
    word: Scalars['String'];
};

export type MessageBadwordOrderByInput = {
    messageBadwordId?: InputMaybe<SortOrder>;
    word?: InputMaybe<SortOrder>;
};

export type MessageBadwordWhereInput = {
    messageBadwordId?: InputMaybe<StringFilter>;
    word?: InputMaybe<StringFilter>;
};

export type MessageBadwordWhereUniqueInput = {
    messageBadwordId?: InputMaybe<Scalars['String']>;
    word?: InputMaybe<Scalars['String']>;
};

export const MessageDistinctFieldEnum = {
    ConversationId: 'conversationId',
    CreatedAt: 'createdAt',
    MessageId: 'messageId',
    ProjectName: 'projectName',
    SenderId: 'senderId',
    Text: 'text',
    Type: 'type',
} as const;

export type MessageDistinctFieldEnum = (typeof MessageDistinctFieldEnum)[keyof typeof MessageDistinctFieldEnum];
export type MessageIdProjectNameCompoundUniqueInput = {
    messageId: Scalars['String'];
    projectName: Scalars['String'];
};

export type MessageListRelationFilter = {
    every?: InputMaybe<MessageWhereInput>;
    none?: InputMaybe<MessageWhereInput>;
    some?: InputMaybe<MessageWhereInput>;
};

export type MessageOrderByInput = {
    conversationId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    messageId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    senderId?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export const MessageType = {
    Awr: 'Awr',
    Delete: 'Delete',
    FriendshipAccept: 'FriendshipAccept',
    FriendshipSent: 'FriendshipSent',
    Gift: 'Gift',
    Match: 'Match',
    Message: 'Message',
    Nawr: 'Nawr',
    Picture: 'Picture',
    Poke: 'Poke',
    PredefinedMessage: 'PredefinedMessage',
    Skip: 'Skip',
    Visit: 'Visit',
} as const;

export type MessageType = (typeof MessageType)[keyof typeof MessageType];
export type MessageTypeFilter = {
    equals?: InputMaybe<MessageType>;
    in?: InputMaybe<Array<MessageType>>;
    not?: InputMaybe<MessageType>;
    notIn?: InputMaybe<Array<MessageType>>;
};

export type MessageUpdateInput = {
    text?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateManyMutationInput = {
    text?: InputMaybe<Scalars['String']>;
};

export type MessageWhereInput = {
    conversation?: InputMaybe<ConversationWhereInputWithProfiles>;
    conversationId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    messageId?: InputMaybe<StringFilter>;
    moderationMessage?: InputMaybe<ModerationMessageWhereOnlyScalarInput>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    sender?: InputMaybe<ProfileScalarWhereInput>;
    senderId?: InputMaybe<StringFilter>;
    systemComplain?: InputMaybe<SystemComplainWhereOnlyScalarInput>;
    text?: InputMaybe<StringNullableFilter>;
    type?: InputMaybe<MessageTypeFilter>;
};

export type MessageWhereOnlyScalarInput = {
    conversationId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    messageId?: InputMaybe<StringFilter>;
    moderationMessageId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    senderId?: InputMaybe<StringFilter>;
    systemComplainId?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringNullableFilter>;
    type?: InputMaybe<MessageTypeFilter>;
};

export type MessageWhereUniqueInput = {
    messageId_projectName: MessageIdProjectNameCompoundUniqueInput;
};

export type ModeratedProfile = {
    __typename: 'ModeratedProfile';
    adminTexts: Array<AdminText>;
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    defaultComment?: Maybe<Comment>;
    dynamic: Scalars['Boolean'];
    fixed: ModerationType;
    imageSource?: Maybe<Scalars['String']>;
    isFSK18: Scalars['Boolean'];
    moderatedProfileId: Scalars['String'];
    moderators: Array<ModeratedProfileModerationUser>;
    noNAWR: Scalars['Boolean'];
    profile: Profile;
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    receivedByUser: Array<UserStatistic>;
    sendToUser: Array<UserStatistic>;
    support: Scalars['Boolean'];
    teams: Array<ModeratedProfileTeam>;
};

export type ModeratedProfileAdminTextsArgs = {
    cursor?: InputMaybe<AdminTextWhereUniqueInput>;
    distinct?: InputMaybe<Array<AdminTextDistinctFieldEnum>>;
    orderBy?: InputMaybe<AdminTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminTextWhereInput>;
};

export type ModeratedProfileModeratorsArgs = {
    cursor?: InputMaybe<ModeratedProfileModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<ModeratedProfileModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<ModeratedProfileModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
};

export type ModeratedProfileTeamsArgs = {
    cursor?: InputMaybe<ModeratedProfileTeamWhereUniqueInput>;
    distinct?: InputMaybe<ModeratedProfileTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<ModeratedProfileTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileTeamWhereInput>;
};

export type ModeratedProfileCreateInput = {
    dynamic?: InputMaybe<Scalars['Boolean']>;
    fixed?: InputMaybe<ModerationType>;
    imageSource?: InputMaybe<Scalars['String']>;
    isFSK18?: InputMaybe<Scalars['Boolean']>;
    moderators?: InputMaybe<ModeratedProfileModerationUserUpdateManyWithoutModeratedProfileInput>;
    noNAWR?: InputMaybe<Scalars['Boolean']>;
    profile: ProfileCreateOneWithoutModeratedProfileInput;
    project: ProjectWhereUniqueInput;
    support?: InputMaybe<Scalars['Boolean']>;
    teams?: InputMaybe<ModeratedProfileTeamUpdateManyWithoutModeratedProfileInput>;
};

export type ModeratedProfileCreateOneWithoutX = {
    connect: ModeratedProfileWhereUniqueInput;
};

export type ModeratedProfileCreateUpdateNestedOneWithoutXInput = {
    connect: Array<ModeratedProfileWhereUniqueInput>;
};

export const ModeratedProfileDistinctFieldEnum = {
    CreatorId: 'creatorId',
    Dynamic: 'dynamic',
    Fixed: 'fixed',
    IsFsk18: 'isFSK18',
    IsHidden: 'isHidden',
    ModeratedProfileId: 'moderatedProfileId',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
} as const;

export type ModeratedProfileDistinctFieldEnum = (typeof ModeratedProfileDistinctFieldEnum)[keyof typeof ModeratedProfileDistinctFieldEnum];
export type ModeratedProfileListRelationUniqueFilter = {
    every?: InputMaybe<ModeratedProfileWhereUniqueRelationInput>;
    none?: InputMaybe<ModeratedProfileWhereUniqueRelationInput>;
    some?: InputMaybe<ModeratedProfileWhereUniqueRelationInput>;
};

export type ModeratedProfileModerationUser = {
    __typename: 'ModeratedProfileModerationUser';
    moderatedProfile: ModeratedProfile;
    moderatedProfileModerationUserId: Scalars['String'];
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ModeratedProfileModerationUserCreateInput = {
    moderatedProfile: ModeratedProfileWhereUniqueInput;
    moderationUser: ModerationUserWhereUniqueInput;
    project: ProjectWhereUniqueInput;
};

export type ModeratedProfileModerationUserCreateWithoutModeratedProfileInput = {
    moderationUser: ModerationUserCreateOneWithoutX;
    project: ProjectCreateOneWithoutX;
};

export const ModeratedProfileModerationUserDistinctFieldEnum = {
    ModeratedProfileModerationUserId: 'moderatedProfileModerationUserId',
    ModerationUserId: 'moderationUserId',
    ProjectName: 'projectName',
} as const;

export type ModeratedProfileModerationUserDistinctFieldEnum =
    (typeof ModeratedProfileModerationUserDistinctFieldEnum)[keyof typeof ModeratedProfileModerationUserDistinctFieldEnum];
export type ModeratedProfileModerationUserListRelationFilter = {
    every?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
    none?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
    some?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
};

export type ModeratedProfileModerationUserOrderByInput = {
    moderatedProfileId?: InputMaybe<SortOrder>;
    moderatedProfileModerationUserId?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ModeratedProfileModerationUserScalarWhereInput = {
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderatedProfileModerationUserId?: InputMaybe<StringFilter>;
    moderationUserId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModeratedProfileModerationUserUpdateManyWithoutModeratedProfileInput = {
    create?: InputMaybe<Array<ModeratedProfileModerationUserCreateWithoutModeratedProfileInput>>;
    delete?: InputMaybe<Array<ModeratedProfileModerationUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ModeratedProfileModerationUserScalarWhereInput>>;
};

export type ModeratedProfileModerationUserWhereInput = {
    moderatedProfile?: InputMaybe<ModeratedProfileWhereInput>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderatedProfileModerationUserId?: InputMaybe<StringFilter>;
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModeratedProfileModerationUserWhereUniqueInput = {
    mUI_mPI_pN?: InputMaybe<Mui_MPi_PNCompoundUniqueInput>;
    moderatedProfileModerationUserId?: InputMaybe<Scalars['String']>;
};

export type ModeratedProfileOrderByInput = {
    creatorId?: InputMaybe<SortOrder>;
    dynamic?: InputMaybe<SortOrder>;
    fixed?: InputMaybe<SortOrder>;
    isFSK18?: InputMaybe<SortOrder>;
    isHidden?: InputMaybe<SortOrder>;
    moderatedProfileId?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ModeratedProfileProjectWhereUniqueInput = {
    profileId_projectName: ProfileIdProjectNameCompoundUniqueInput;
};

export type ModeratedProfileResult = InputErrors | ModeratedProfile;

export type ModeratedProfileScalarWhereInput = {
    creatorId?: InputMaybe<StringNullableFilter>;
    dynamic?: InputMaybe<BoolFilter>;
    fixed?: InputMaybe<ModerationTypeFilter>;
    isFSK18?: InputMaybe<BoolFilter>;
    isHidden?: InputMaybe<BoolFilter>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModeratedProfileSetUpdateNestedOneWithoutXInput = {
    set: Array<ModeratedProfileWhereUniqueInput>;
};

export type ModeratedProfileTeam = {
    __typename: 'ModeratedProfileTeam';
    moderatedProfile: ModeratedProfile;
    moderatedProfileTeamId: Scalars['String'];
    oldTeams?: Maybe<Scalars['JSON']>;
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    team: ModerationTeam;
    teamId: Scalars['String'];
};

export type ModeratedProfileTeamCreateInput = {
    moderatedProfile: ModeratedProfileWhereUniqueInput;
    project: ProjectWhereUniqueInput;
    team: ModerationTeamWhereUniqueInput;
};

export type ModeratedProfileTeamCreateWithoutModeratedProfileInput = {
    project: ProjectCreateOneWithoutX;
    team: ModerationTeamCreateOneWithoutX;
};

export const ModeratedProfileTeamDistinctFieldEnum = {
    ModeratedProfileTeamId: 'moderatedProfileTeamId',
    ProjectName: 'projectName',
    TeamId: 'teamId',
} as const;

export type ModeratedProfileTeamDistinctFieldEnum =
    (typeof ModeratedProfileTeamDistinctFieldEnum)[keyof typeof ModeratedProfileTeamDistinctFieldEnum];
export type ModeratedProfileTeamListRelationFilter = {
    every?: InputMaybe<ModeratedProfileTeamWhereInput>;
    none?: InputMaybe<ModeratedProfileTeamWhereInput>;
    some?: InputMaybe<ModeratedProfileTeamWhereInput>;
};

export type ModeratedProfileTeamOrderByInput = {
    moderatedProfileId?: InputMaybe<SortOrder>;
    moderatedProfileTeamId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    teamId?: InputMaybe<SortOrder>;
};

export type ModeratedProfileTeamScalarWhereInput = {
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderatedProfileTeamId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    teamId?: InputMaybe<StringFilter>;
};

export type ModeratedProfileTeamUpdateManyWithoutModeratedProfileInput = {
    create?: InputMaybe<Array<ModeratedProfileTeamCreateWithoutModeratedProfileInput>>;
    delete?: InputMaybe<Array<ModeratedProfileTeamWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ModeratedProfileTeamScalarWhereInput>>;
};

export type ModeratedProfileTeamWhereInput = {
    moderatedProfile?: InputMaybe<ModeratedProfileWhereInput>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderatedProfileTeamId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    team?: InputMaybe<ModerationTeamWhereInput>;
    teamId?: InputMaybe<StringFilter>;
};

export type ModeratedProfileTeamWhereUniqueInput = {
    mPI_pN_tI?: InputMaybe<Mpi_PN_TICompoundUniqueInput>;
    moderatedProfileTeamId?: InputMaybe<Scalars['String']>;
};

export type ModeratedProfileUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    dynamic?: InputMaybe<Scalars['Boolean']>;
    fixed?: InputMaybe<ModerationType>;
    imageSource?: InputMaybe<Scalars['String']>;
    isFSK18?: InputMaybe<Scalars['Boolean']>;
    moderators?: InputMaybe<ModeratedProfileModerationUserUpdateManyWithoutModeratedProfileInput>;
    noNAWR?: InputMaybe<Scalars['Boolean']>;
    poolSourceProfiles?: InputMaybe<PoolSourceProfileUpdateManyWithoutModeratedProfileInput>;
    support?: InputMaybe<Scalars['Boolean']>;
    teams?: InputMaybe<ModeratedProfileTeamUpdateManyWithoutModeratedProfileInput>;
};

export type ModeratedProfileUpdateManyInput = {
    dynamic?: InputMaybe<Scalars['Boolean']>;
    fixed?: InputMaybe<ModerationType>;
    isFSK18?: InputMaybe<Scalars['Boolean']>;
    noNAWR?: InputMaybe<Scalars['Boolean']>;
    teams?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
};

export type ModeratedProfileWhereInput = {
    acquisitions?: InputMaybe<AcquisitionListRelationFilter>;
    comments?: InputMaybe<CommentListRelationFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringNullableFilter>;
    dynamic?: InputMaybe<BoolFilter>;
    fixed?: InputMaybe<ModerationTypeFilter>;
    imageSource?: InputMaybe<StringNullableFilter>;
    isFSK18?: InputMaybe<BoolFilter>;
    isHidden?: InputMaybe<BoolFilter>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderators?: InputMaybe<ModeratedProfileModerationUserListRelationFilter>;
    noNAWR?: InputMaybe<BoolFilter>;
    oldTeams?: InputMaybe<OldTeamsWhereInput>;
    poolSourceProfiles?: InputMaybe<PoolSourceProfileListRelationFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    queues?: InputMaybe<QueueListRelationFilter>;
    teams?: InputMaybe<ModeratedProfileTeamListRelationFilter>;
};

export type ModeratedProfileWhereUniqueInput = {
    profileId: Scalars['String'];
};

export type ModeratedProfileWhereUniqueRelationInput = {
    profileId?: InputMaybe<Scalars['String']>;
};

export type ModerationChat = {
    __typename: 'ModerationChat';
    admin?: Maybe<AdminUser>;
    adminId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    message: Scalars['String'];
    moderationChatId: Scalars['String'];
    moderationChatTeams: Array<ModerationChatTeam>;
    moderator?: Maybe<ModerationUser>;
    moderatorId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
};

export type ModerationChatModerationChatTeamsArgs = {
    cursor?: InputMaybe<ModerationChatTeamWhereUniqueInput>;
    distinct?: InputMaybe<ModerationChatTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationChatTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationChatTeamWhereInput>;
};

export type ModerationChatCreateInput = {
    message: Scalars['String'];
    project: ProjectWhereUniqueInput;
    team?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
};

export type ModerationChatListRelationFilter = {
    every?: InputMaybe<ModerationChatWhereInput>;
    none?: InputMaybe<ModerationChatWhereInput>;
    some?: InputMaybe<ModerationChatWhereInput>;
};

export type ModerationChatOrderByInput = {
    adminId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    message?: InputMaybe<SortOrder>;
    moderationChatId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ModerationChatTeam = {
    __typename: 'ModerationChatTeam';
    chat: ModerationChat;
    chatId: Scalars['String'];
    moderationChatTeamId: Scalars['String'];
    team: ModerationTeam;
    teamId: Scalars['String'];
};

export const ModerationChatTeamDistinctFieldEnum = {
    ChatId: 'chatId',
    ModerationChatTeamId: 'moderationChatTeamId',
    TeamId: 'teamId',
} as const;

export type ModerationChatTeamDistinctFieldEnum = (typeof ModerationChatTeamDistinctFieldEnum)[keyof typeof ModerationChatTeamDistinctFieldEnum];
export type ModerationChatTeamListRelationFilter = {
    every?: InputMaybe<ModerationChatTeamWhereInput>;
    none?: InputMaybe<ModerationChatTeamWhereInput>;
    some?: InputMaybe<ModerationChatTeamWhereInput>;
};

export type ModerationChatTeamOrderByInput = {
    chatId?: InputMaybe<SortOrder>;
    moderationChatTeamId?: InputMaybe<SortOrder>;
    teamId?: InputMaybe<SortOrder>;
};

export type ModerationChatTeamWhereInput = {
    chat?: InputMaybe<ModerationChatWhereInput>;
    chatId?: InputMaybe<StringFilter>;
    team?: InputMaybe<ModerationTeamWhereInput>;
    teamId?: InputMaybe<StringFilter>;
};

export type ModerationChatTeamWhereUniqueInput = {
    moderationChatTeamId?: InputMaybe<Scalars['String']>;
    teamId_chatId?: InputMaybe<TeamIdChatIdCompoundUniqueInput>;
};

export type ModerationChatWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    moderationChatTeams?: InputMaybe<ModerationChatTeamListRelationFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModerationChatWhereUniqueInput = {
    moderationChatId: Scalars['String'];
};

export const ModerationComplainStatus = {
    Deleted: 'Deleted',
    Pending: 'Pending',
} as const;

export type ModerationComplainStatus = (typeof ModerationComplainStatus)[keyof typeof ModerationComplainStatus];
export type ModerationComplainStatusFilter = {
    equals?: InputMaybe<ModerationComplainStatus>;
    in?: InputMaybe<Array<ModerationComplainStatus>>;
    not?: InputMaybe<ModerationComplainStatus>;
    notIn?: InputMaybe<Array<ModerationComplainStatus>>;
};

export const ModerationComplainType = {
    Age: 'Age',
    Moderator: 'Moderator',
    MultiAccount: 'MultiAccount',
    Other: 'Other',
} as const;

export type ModerationComplainType = (typeof ModerationComplainType)[keyof typeof ModerationComplainType];
export type ModerationComplainTypeFilter = {
    equals?: InputMaybe<ModerationComplainType>;
    in?: InputMaybe<Array<ModerationComplainType>>;
    not?: InputMaybe<ModerationComplainType>;
    notIn?: InputMaybe<Array<ModerationComplainType>>;
};

export type ModerationMessage = {
    __typename: 'ModerationMessage';
    adminName?: Maybe<Scalars['String']>;
    conversationId: Scalars['String'];
    length: Scalars['Int'];
    message: Message;
    messageId: Scalars['String'];
    moderationMessageId: Scalars['String'];
    moderator?: Maybe<ModerationUser>;
    moderatorName?: Maybe<Scalars['String']>;
    processingTime: Scalars['Int'];
    projectName: Scalars['String'];
    queueAvailableAt?: Maybe<Scalars['DateTime']>;
    queueType?: Maybe<Scalars['String']>;
    type: ModerationMessageType;
};

export type ModerationMessageAvgSumAggregateInputType = {
    length?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
};

export type ModerationMessageAvgSumAggregateOutputType = {
    __typename: 'ModerationMessageAvgSumAggregateOutputType';
    length: Scalars['Float'];
    processingTime: Scalars['Float'];
};

export type ModerationMessageAvgSumOrderByAggregateInput = {
    length?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
};

export type ModerationMessageCountAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    acquisitionId?: InputMaybe<Scalars['true']>;
    adminName?: InputMaybe<Scalars['true']>;
    conversationId?: InputMaybe<Scalars['true']>;
    length?: InputMaybe<Scalars['true']>;
    messageId?: InputMaybe<Scalars['true']>;
    moderationMessageId?: InputMaybe<Scalars['true']>;
    moderatorName?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    queueType?: InputMaybe<Scalars['true']>;
    scenarioId?: InputMaybe<Scalars['true']>;
    type?: InputMaybe<Scalars['true']>;
};

export type ModerationMessageCountAggregateOutputType = {
    __typename: 'ModerationMessageCountAggregateOutputType';
    _all: Scalars['Int'];
    acquisitionId: Scalars['Int'];
    adminName: Scalars['Int'];
    conversationId: Scalars['Int'];
    length: Scalars['Int'];
    messageId: Scalars['Int'];
    moderationMessageId: Scalars['Int'];
    moderatorName: Scalars['Int'];
    processingTime: Scalars['Int'];
    projectName: Scalars['Int'];
    queueType: Scalars['Int'];
    scenarioId: Scalars['Int'];
    type: Scalars['Int'];
};

export type ModerationMessageListRelationFilter = {
    every?: InputMaybe<ModerationMessageWhereInput>;
    none?: InputMaybe<ModerationMessageWhereInput>;
    some?: InputMaybe<ModerationMessageWhereInput>;
};

export type ModerationMessageOrderByInput = {
    adminName?: InputMaybe<SortOrder>;
    length?: InputMaybe<SortOrder>;
    messageId?: InputMaybe<SortOrder>;
    moderationMessageId?: InputMaybe<SortOrder>;
    moderatorName?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    queueType?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export type ModerationMessageOrderByWithAggregationInput = {
    _avg?: InputMaybe<SortOrder>;
    _sum?: InputMaybe<ModerationMessageAvgSumOrderByAggregateInput>;
    adminName?: InputMaybe<SortOrder>;
    length?: InputMaybe<SortOrder>;
    moderatorName?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export const ModerationMessageScalarFieldEnum = {
    AcquisitionId: 'acquisitionId',
    AdminName: 'adminName',
    ConversationId: 'conversationId',
    Length: 'length',
    MessageId: 'messageId',
    ModerationMessageId: 'moderationMessageId',
    ModeratorName: 'moderatorName',
    ProcessingTime: 'processingTime',
    ProjectName: 'projectName',
    QueueAvailableAt: 'queueAvailableAt',
    QueueType: 'queueType',
    ScenarioId: 'scenarioId',
    Type: 'type',
} as const;

export type ModerationMessageScalarFieldEnum = (typeof ModerationMessageScalarFieldEnum)[keyof typeof ModerationMessageScalarFieldEnum];
export const ModerationMessageType = {
    Incoming: 'Incoming',
    Outgoing: 'Outgoing',
} as const;

export type ModerationMessageType = (typeof ModerationMessageType)[keyof typeof ModerationMessageType];
export type ModerationMessageTypeFilter = {
    equals?: InputMaybe<ModerationMessageType>;
    in?: InputMaybe<Array<ModerationMessageType>>;
    not?: InputMaybe<ModerationMessageType>;
    notIn?: InputMaybe<Array<ModerationMessageType>>;
};

export type ModerationMessageWhereInput = {
    adminName?: InputMaybe<StringFilter>;
    conversationId?: InputMaybe<StringFilter>;
    length?: InputMaybe<IntFilter>;
    message?: InputMaybe<MessageWhereInput>;
    messageId?: InputMaybe<StringFilter>;
    moderationMessageId?: InputMaybe<StringFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorName?: InputMaybe<StringFilter>;
    processingTime?: InputMaybe<IntFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    queueType?: InputMaybe<QueueTypeFilter>;
    type?: InputMaybe<ModerationMessageTypeFilter>;
};

export type ModerationMessageWhereOnlyScalarInput = {
    adminName?: InputMaybe<StringFilter>;
    conversationId?: InputMaybe<StringFilter>;
    length?: InputMaybe<IntFilter>;
    messageId?: InputMaybe<StringFilter>;
    moderationMessageId?: InputMaybe<StringFilter>;
    moderatorName?: InputMaybe<StringFilter>;
    processingTime?: InputMaybe<IntFilter>;
    projectName?: InputMaybe<StringFilter>;
    queueType?: InputMaybe<QueueTypeFilter>;
    type?: InputMaybe<ModerationMessageTypeFilter>;
};

export type ModerationMessageWhereUniqueInput = {
    messageId_projectName?: InputMaybe<MessageIdProjectNameCompoundUniqueInput>;
    moderationMessageId?: InputMaybe<Scalars['String']>;
};

export const ModerationPrivilege = {
    DeleteQueue: 'DeleteQueue',
    DialogShow: 'DialogShow',
    DialogToQueue: 'DialogToQueue',
    PoolShow: 'PoolShow',
    PoolToQueue: 'PoolToQueue',
    ReassignQueue: 'ReassignQueue',
    SendGift: 'SendGift',
    SendPicture: 'SendPicture',
    SendPoke: 'SendPoke',
    ShowBonus: 'ShowBonus',
    SkipQueue: 'SkipQueue',
} as const;

export type ModerationPrivilege = (typeof ModerationPrivilege)[keyof typeof ModerationPrivilege];
export type ModerationPrivilegeFilter = {
    equals?: InputMaybe<ModerationPrivilege>;
    in?: InputMaybe<Array<ModerationPrivilege>>;
    not?: InputMaybe<ModerationPrivilege>;
    notIn?: InputMaybe<Array<ModerationPrivilege>>;
};

export const ModerationRole = {
    Blocked: 'Blocked',
    Moderator: 'Moderator',
} as const;

export type ModerationRole = (typeof ModerationRole)[keyof typeof ModerationRole];
export type ModerationRoleFilter = {
    equals?: InputMaybe<ModerationRole>;
    in?: InputMaybe<Array<ModerationRole>>;
    not?: InputMaybe<ModerationRole>;
    notIn?: InputMaybe<Array<ModerationRole>>;
};

export type ModerationSession = {
    __typename: 'ModerationSession';
    createdAt: Scalars['DateTime'];
    date: Scalars['String'];
    endedAt: Scalars['DateTime'];
    length: Scalars['Int'];
    moderationSessionId: Scalars['String'];
    moderator: ModerationUser;
    moderatorId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ModerationSessionListRelationFilter = {
    every?: InputMaybe<ModerationSessionWhereInput>;
    none?: InputMaybe<ModerationSessionWhereInput>;
    some?: InputMaybe<ModerationSessionWhereInput>;
};

export type ModerationSessionOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    endedAt?: InputMaybe<SortOrder>;
    length?: InputMaybe<SortOrder>;
    moderationSessionId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ModerationSessionWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    date?: InputMaybe<StringFilter>;
    endedAt?: InputMaybe<DateTimeFilter>;
    length?: InputMaybe<IntFilter>;
    moderationSessionId?: InputMaybe<StringFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModerationSessionWhereUniqueInput = {
    moderationSessionId?: InputMaybe<Scalars['String']>;
    moderatorId_date?: InputMaybe<ModeratorIdDateCompoundUniqueInput>;
};

export type ModerationTeam = {
    __typename: 'ModerationTeam';
    createdAt: Scalars['DateTime'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    default: Scalars['Boolean'];
    moderationTeamId: Scalars['String'];
    moderators: Array<ModerationUser>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ModerationTeamModeratorsArgs = {
    cursor?: InputMaybe<ModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<ModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserWhereInput>;
};

export type ModerationTeamCreateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']>;
    creator?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
    default?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModerationTeamCreateOneWithoutX = {
    connect: ModerationTeamWhereUniqueInput;
};

export type ModerationTeamCreateUpdateNestedManyWithoutXInput = {
    connect: Array<ModerationTeamWhereUniqueInput>;
};

export const ModerationTeamDistinctFieldEnum = {
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    ModerationTeamId: 'moderationTeamId',
    Name: 'name',
    UpdatedAt: 'updatedAt',
} as const;

export type ModerationTeamDistinctFieldEnum = (typeof ModerationTeamDistinctFieldEnum)[keyof typeof ModerationTeamDistinctFieldEnum];
export type ModerationTeamListRelationFilter = {
    every?: InputMaybe<ModerationTeamWhereInput>;
    none?: InputMaybe<ModerationTeamWhereInput>;
    some?: InputMaybe<ModerationTeamWhereInput>;
};

export type ModerationTeamOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    moderationTeamId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ModerationTeamUpdateInput = {
    default?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ModerationTeamUpdateManyWithoutXInput = {
    connect?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
    disconnect?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
    set?: InputMaybe<Array<ModerationTeamWhereUniqueInput>>;
};

export type ModerationTeamWhereInput = {
    conversationTeams?: InputMaybe<ConversationTeamListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringFilter>;
    moderatedProfileTeams?: InputMaybe<ModeratedProfileTeamListRelationFilter>;
    moderationChatTeams?: InputMaybe<ModerationChatTeamListRelationFilter>;
    moderationTeamId?: InputMaybe<StringFilter>;
    moderatorUserStatistics?: InputMaybe<ModeratorUserStatisticListRelationFilter>;
    moderators?: InputMaybe<ModerationUserListRelationFilter>;
    name?: InputMaybe<StringFilter>;
    pools?: InputMaybe<PoolTeamListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModerationTeamWhereUniqueInput = {
    moderationTeamId: Scalars['String'];
};

export const ModerationType = {
    Moderator: 'Moderator',
    Moderators: 'Moderators',
    None: 'None',
    Team: 'Team',
    Teams: 'Teams',
} as const;

export type ModerationType = (typeof ModerationType)[keyof typeof ModerationType];
export type ModerationTypeFilter = {
    equals?: InputMaybe<ModerationType>;
    in?: InputMaybe<Array<ModerationType>>;
    not?: InputMaybe<ModerationType>;
    notIn?: InputMaybe<Array<ModerationType>>;
};

export type ModerationUser = {
    __typename: 'ModerationUser';
    activeQueue?: Maybe<Queue>;
    adminTexts: Array<AdminText>;
    agency: Agency;
    agencyId: Scalars['String'];
    chatStatus: ChatStatus;
    createdAt: Scalars['DateTime'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    factor?: Maybe<Scalars['Int']>;
    fixedConversation: Scalars['Boolean'];
    languageCode: Scalars['String'];
    lastLogin?: Maybe<Scalars['DateTime']>;
    minCharAwr?: Maybe<Scalars['Int']>;
    minCharMessage?: Maybe<Scalars['Int']>;
    moderationUserId: Scalars['String'];
    name: Scalars['String'];
    privileges: Array<ModerationUserPrivilege>;
    projects: Array<ModerationUserProject>;
    role: ModerationRole;
    statsHour: ModeratorHourStatisticAggregateResult;
    statsToday: ModeratorStatisticAggregateResult;
    status: UserStatus;
    teams: Array<ModerationTeam>;
    updatedAt: Scalars['DateTime'];
};

export type ModerationUserAdminTextsArgs = {
    cursor?: InputMaybe<AdminTextWhereUniqueInput>;
    distinct?: InputMaybe<Array<AdminTextDistinctFieldEnum>>;
    orderBy?: InputMaybe<AdminTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminTextWhereInput>;
};

export type ModerationUserPrivilegesArgs = {
    cursor?: InputMaybe<ModerationUserPrivilegeWhereUniqueInput>;
    distinct?: InputMaybe<ModerationUserPrivilegeDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserPrivilegeWhereInput>;
};

export type ModerationUserProjectsArgs = {
    cursor?: InputMaybe<ModerationUserProjectWhereUniqueInput>;
    distinct?: InputMaybe<ModerationUserProjectDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationUserProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserProjectWhereInput>;
};

export type ModerationUserTeamsArgs = {
    cursor?: InputMaybe<ModerationTeamWhereUniqueInput>;
    distinct?: InputMaybe<ModerationTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<ModerationTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationTeamWhereInput>;
};

export type ModerationUserCreateInput = {
    agency: AgencyWhereUniqueInput;
    factor?: InputMaybe<Scalars['Int']>;
    fixedConversation?: InputMaybe<Scalars['Boolean']>;
    language: LanguageWhereUniqueInput;
    minCharAwr?: InputMaybe<Scalars['Int']>;
    minCharMessage?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    password: Scalars['String'];
    privilege?: InputMaybe<Array<ModerationPrivilege>>;
    project: Array<ProjectWhereUniqueInput>;
    role?: InputMaybe<ModerationRole>;
    team: Array<ModerationTeamWhereUniqueInput>;
};

export type ModerationUserCreateOneWithoutX = {
    connect: ModerationUserWhereUniqueInput;
};

export type ModerationUserCreateUpdateNestedManyWithoutXInput = {
    connect: Array<ModerationUserWhereUniqueInput>;
};

export const ModerationUserDistinctFieldEnum = {
    AgencyId: 'agencyId',
    CreatedAt: 'createdAt',
    CreatorId: 'creatorId',
    Factor: 'factor',
    FixedConversation: 'fixedConversation',
    LanguageCode: 'languageCode',
    LastLogin: 'lastLogin',
    MinCharAwr: 'minCharAwr',
    MinCharMessage: 'minCharMessage',
    ModerationUserId: 'moderationUserId',
    Name: 'name',
    Password: 'password',
    Role: 'role',
    UpdatedAt: 'updatedAt',
} as const;

export type ModerationUserDistinctFieldEnum = (typeof ModerationUserDistinctFieldEnum)[keyof typeof ModerationUserDistinctFieldEnum];
export type ModerationUserIdPoolIdCompoundUniqueInput = {
    moderationUserId: Scalars['String'];
    poolId: Scalars['String'];
};

export type ModerationUserListRelationFilter = {
    every?: InputMaybe<ModerationUserWhereInput>;
    none?: InputMaybe<ModerationUserWhereInput>;
    some?: InputMaybe<ModerationUserWhereInput>;
};

export type ModerationUserOrderByInput = {
    agencyId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    factor?: InputMaybe<SortOrder>;
    fixedConversation?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    lastLogin?: InputMaybe<SortOrder>;
    minCharAwr?: InputMaybe<SortOrder>;
    minCharMessage?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    role?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ModerationUserPrivilege = {
    __typename: 'ModerationUserPrivilege';
    moderationPrivilege: ModerationPrivilege;
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    moderationUserPrivilegeId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ModerationUserPrivilegeCreateWithoutModerationUserInput = {
    moderationPrivilege: ModerationPrivilege;
    project: ProjectCreateOneWithoutX;
};

export const ModerationUserPrivilegeDistinctFieldEnum = {
    ModerationPrivilege: 'moderationPrivilege',
    ModerationUserId: 'moderationUserId',
    ModerationUserPrivilegeId: 'moderationUserPrivilegeId',
    ProjectName: 'projectName',
} as const;

export type ModerationUserPrivilegeDistinctFieldEnum =
    (typeof ModerationUserPrivilegeDistinctFieldEnum)[keyof typeof ModerationUserPrivilegeDistinctFieldEnum];
export type ModerationUserPrivilegeListRelationFilter = {
    every?: InputMaybe<ModerationUserPrivilegeWhereInput>;
    none?: InputMaybe<ModerationUserPrivilegeWhereInput>;
    some?: InputMaybe<ModerationUserPrivilegeWhereInput>;
};

export type ModerationUserPrivilegeOrderByInput = {
    moderationPrivilege?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ModerationUserPrivilegeScalarWhereInput = {
    moderationPrivilege?: InputMaybe<ModerationPrivilegeFilter>;
    moderationUserId?: InputMaybe<StringFilter>;
    moderationUserPrivilegeId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModerationUserPrivilegeUpdateManyWithoutModerationUserInput = {
    create?: InputMaybe<Array<ModerationUserPrivilegeCreateWithoutModerationUserInput>>;
    delete?: InputMaybe<Array<ModerationUserPrivilegeWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ModerationUserPrivilegeScalarWhereInput>>;
};

export type ModerationUserPrivilegeWhereInput = {
    moderationPrivilege?: InputMaybe<ModerationPrivilegeFilter>;
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModerationUserPrivilegeWhereUniqueInput = {
    mUI_pN_mP?: InputMaybe<Mui_PN_MPCompoundUniqueInput>;
    moderationUserPrivilegeId?: InputMaybe<Scalars['String']>;
};

export type ModerationUserProject = {
    __typename: 'ModerationUserProject';
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    moderationUserProjectId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ModerationUserProjectCreateWithoutModerationUserInput = {
    project: ProjectCreateOneWithoutX;
};

export const ModerationUserProjectDistinctFieldEnum = {
    ModerationUserId: 'moderationUserId',
    ModerationUserProjectId: 'moderationUserProjectId',
    ProjectName: 'projectName',
} as const;

export type ModerationUserProjectDistinctFieldEnum =
    (typeof ModerationUserProjectDistinctFieldEnum)[keyof typeof ModerationUserProjectDistinctFieldEnum];
export type ModerationUserProjectListRelationFilter = {
    every?: InputMaybe<ModerationUserProjectWhereInput>;
    none?: InputMaybe<ModerationUserProjectWhereInput>;
    some?: InputMaybe<ModerationUserProjectWhereInput>;
};

export type ModerationUserProjectOrderByInput = {
    moderationUserId?: InputMaybe<SortOrder>;
    moderationUserProjectId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ModerationUserProjectScalarWhereInput = {
    moderationUserId?: InputMaybe<StringFilter>;
    moderationUserProjectId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModerationUserProjectUpdateManyWithoutModerationUserInput = {
    create?: InputMaybe<Array<ModerationUserProjectCreateWithoutModerationUserInput>>;
    delete?: InputMaybe<Array<ModerationUserProjectWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ModerationUserProjectScalarWhereInput>>;
};

export type ModerationUserProjectWhereInput = {
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    moderationUserProjectId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ModerationUserProjectWhereUniqueInput = {
    moderationUserProjectId?: InputMaybe<Scalars['String']>;
    projectName_moderationUserId?: InputMaybe<ProjectNameModerationUserIdCompoundUniqueInput>;
};

export type ModerationUserUpdateInput = {
    agency?: InputMaybe<AgencyUpdateOneRequiredWithoutX>;
    factor?: InputMaybe<Scalars['Int']>;
    fixedConversation?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<LanguageUpdateOneRequiredWithoutX>;
    minCharAwr?: InputMaybe<Scalars['Int']>;
    minCharMessage?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    poolModerationUsers?: InputMaybe<PoolModerationUserUpdateManyWithoutModerationUserInput>;
    privileges?: InputMaybe<ModerationUserPrivilegeUpdateManyWithoutModerationUserInput>;
    projects?: InputMaybe<ModerationUserProjectUpdateManyWithoutModerationUserInput>;
    role?: InputMaybe<ModerationRole>;
    status?: InputMaybe<UserStatus>;
    teams?: InputMaybe<ModerationTeamUpdateManyWithoutXInput>;
};

export type ModerationUserUpdateManyMutationInput = {
    factor?: InputMaybe<Scalars['Int']>;
    fixedConversation?: InputMaybe<Scalars['Boolean']>;
    minCharAwr?: InputMaybe<Scalars['Int']>;
    minCharMessage?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    role?: InputMaybe<ModerationRole>;
};

export type ModerationUserUpdateManyWithoutXInput = {
    connect?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
    disconnect?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
    set?: InputMaybe<Array<ModerationUserWhereUniqueInput>>;
};

export type ModerationUserWhereInput = {
    Pool?: InputMaybe<PoolListRelationFilter>;
    Queue?: InputMaybe<QueueListRelationFilter>;
    QueueModerationUser?: InputMaybe<QueueModerationUserListRelationFilter>;
    agency?: InputMaybe<AgencyWhereInput>;
    agencyId?: InputMaybe<StringFilter>;
    chatStatus?: InputMaybe<ChatStatusFilter>;
    commentLogs?: InputMaybe<CommentLogListRelationFilter>;
    conversation?: InputMaybe<ConversationListRelationFilter>;
    conversationModerationUsers?: InputMaybe<ConversationModerationUserListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringFilter>;
    factor?: InputMaybe<IntNullableFilter>;
    fixedConversation?: InputMaybe<BoolFilter>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    minCharAwr?: InputMaybe<IntNullableFilter>;
    minCharMessage?: InputMaybe<IntNullableFilter>;
    moderatedProfileModerationUsers?: InputMaybe<ModeratedProfileModerationUserListRelationFilter>;
    moderationChats?: InputMaybe<ModerationChatListRelationFilter>;
    moderationMessages?: InputMaybe<ModerationMessageListRelationFilter>;
    moderationSessions?: InputMaybe<ModerationSessionListRelationFilter>;
    moderationUserId?: InputMaybe<StringFilter>;
    moderatorStatistics?: InputMaybe<ModeratorStatisticListRelationFilter>;
    moderatorUserStatistics?: InputMaybe<ModeratorUserStatisticListRelationFilter>;
    name?: InputMaybe<StringFilter>;
    password?: InputMaybe<StringFilter>;
    poolModerationUsers?: InputMaybe<PoolModerationUserListRelationFilter>;
    privileges?: InputMaybe<ModerationUserPrivilegeListRelationFilter>;
    projects?: InputMaybe<ModerationUserProjectListRelationFilter>;
    role?: InputMaybe<ModerationRoleFilter>;
    status?: InputMaybe<UserStatusFilter>;
    teams?: InputMaybe<ModerationTeamListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModerationUserWhereUniqueInput = {
    moderationUserId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ModeratorComplain = {
    __typename: 'ModeratorComplain';
    createdAt: Scalars['DateTime'];
    languageCode: Scalars['String'];
    moderationComplainType: ModerationComplainType;
    moderatorComplainId: Scalars['String'];
    projectName: Scalars['String'];
    reporter: ModerationUser;
    reporterId: Scalars['String'];
    status: ModerationComplainStatus;
    target: User;
    targetId: Scalars['String'];
    text?: Maybe<Scalars['String']>;
};

export type ModeratorComplainOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    moderationComplainType?: InputMaybe<SortOrder>;
    moderatorComplainId?: InputMaybe<SortOrder>;
    reporterId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    targetId?: InputMaybe<SortOrder>;
};

export type ModeratorComplainUpdateInput = {
    moderationComplainType?: InputMaybe<ModerationComplainType>;
    status?: InputMaybe<ModerationComplainStatus>;
};

export type ModeratorComplainWhereInput = {
    createdAt?: InputMaybe<DateTimeNullableFilter>;
    moderationComplainType?: InputMaybe<ModerationComplainTypeFilter>;
    moderatorComplainId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    reporter?: InputMaybe<ModerationUserWhereInput>;
    reporterId?: InputMaybe<StringFilter>;
    status?: InputMaybe<ModerationComplainStatusFilter>;
    target?: InputMaybe<UserWhereInput>;
    targetId?: InputMaybe<StringFilter>;
};

export type ModeratorComplainWhereUniqueInput = {
    moderatorComplainId: Scalars['String'];
};

export type ModeratorHourStatistic = {
    __typename: 'ModeratorHourStatistic';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    hour: Scalars['Int'];
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorHourStatisticId: Scalars['String'];
    moderatorId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorHourStatisticHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticAggregateArgs = {
    _avg?: InputMaybe<ModeratorHourStatisticAvgAggregateInputType>;
    _count?: InputMaybe<ModeratorHourStatisticCountAggregateInputType>;
    _max?: InputMaybe<ModeratorHourStatisticMaxAggregateInputType>;
    _min?: InputMaybe<ModeratorHourStatisticMinAggregateInputType>;
    _sum?: InputMaybe<ModeratorHourStatisticSumAggregateInputType>;
    cursor?: InputMaybe<ModeratorHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ModeratorHourStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorHourStatisticWhereInput>;
};

export type ModeratorHourStatisticAggregateResult = {
    __typename: 'ModeratorHourStatisticAggregateResult';
    _avg?: Maybe<ModeratorHourStatisticAvgAggregateOutputType>;
    _count?: Maybe<ModeratorHourStatisticCountAggregateOutputType>;
    _max?: Maybe<ModeratorHourStatisticMaxAggregateOutputType>;
    _min?: Maybe<ModeratorHourStatisticMinAggregateOutputType>;
    _sum?: Maybe<ModeratorHourStatisticSumAggregateOutputType>;
};

export type ModeratorHourStatisticAvgAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorHourStatisticAvgAggregateOutputType = {
    __typename: 'ModeratorHourStatisticAvgAggregateOutputType';
    bonus?: Maybe<Scalars['Float']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Float']>;
    friendRequests?: Maybe<Scalars['Float']>;
    gifts?: Maybe<Scalars['Float']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Float']>;
    outs?: Maybe<Scalars['Float']>;
    pictures?: Maybe<Scalars['Float']>;
    pokes?: Maybe<Scalars['Float']>;
    processingTime?: Maybe<Scalars['Float']>;
    success?: Maybe<Scalars['Float']>;
    try?: Maybe<Scalars['Float']>;
};

export type ModeratorHourStatisticAvgOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticCountAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    hour?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorHourStatisticId?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorHourStatisticCountAggregateOutputType = {
    __typename: 'ModeratorHourStatisticCountAggregateOutputType';
    _all: Scalars['Int'];
    bonus: Scalars['Int'];
    chars: Scalars['Int'];
    coins: Scalars['Int'];
    date: Scalars['Int'];
    friendRequests: Scalars['Int'];
    gifts: Scalars['Int'];
    hour: Scalars['Int'];
    ins: Scalars['Int'];
    messages: Scalars['Int'];
    moderatorHourStatisticId: Scalars['Int'];
    moderatorId: Scalars['Int'];
    outs: Scalars['Int'];
    pictures: Scalars['Int'];
    pokes: Scalars['Int'];
    processingTime: Scalars['Int'];
    projectName: Scalars['Int'];
    success: Scalars['Int'];
    try: Scalars['Int'];
};

export type ModeratorHourStatisticCountAggregateOutputTypeHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticCountOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorHourStatisticId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticFindManyGroupByResult = {
    __typename: 'ModeratorHourStatisticFindManyGroupByResult';
    _avg?: Maybe<ModeratorHourStatisticAvgAggregateOutputType>;
    _count?: Maybe<ModeratorHourStatisticCountAggregateOutputType>;
    _max?: Maybe<ModeratorHourStatisticMaxAggregateOutputType>;
    _min?: Maybe<ModeratorHourStatisticMinAggregateOutputType>;
    _sum?: Maybe<ModeratorHourStatisticSumAggregateOutputType>;
    date?: Maybe<Scalars['String']>;
    hour?: Maybe<Scalars['Int']>;
    moderator?: Maybe<ModerationUser>;
    moderatorId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
};

export type ModeratorHourStatisticFindManyGroupByResultHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticFindManyResult = {
    __typename: 'ModeratorHourStatisticFindManyResult';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    hour: Scalars['Int'];
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorHourStatisticId: Scalars['String'];
    moderatorId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorHourStatisticFindManyResultHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticListRelationFilter = {
    every?: InputMaybe<ModeratorHourStatisticWhereInput>;
    none?: InputMaybe<ModeratorHourStatisticWhereInput>;
    some?: InputMaybe<ModeratorHourStatisticWhereInput>;
};

export type ModeratorHourStatisticMaxAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    hour?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorHourStatisticId?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorHourStatisticMaxAggregateOutputType = {
    __typename: 'ModeratorHourStatisticMaxAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    hour?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderatorHourStatisticId?: Maybe<Scalars['String']>;
    moderatorId?: Maybe<Scalars['String']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorHourStatisticMaxAggregateOutputTypeHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticMaxOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorHourStatisticId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticMinAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    hour?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorHourStatisticId?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorHourStatisticMinAggregateOutputType = {
    __typename: 'ModeratorHourStatisticMinAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    hour?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderatorHourStatisticId?: Maybe<Scalars['String']>;
    moderatorId?: Maybe<Scalars['String']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorHourStatisticMinAggregateOutputTypeHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorHourStatisticMinOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorHourStatisticId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorHourStatisticId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticOrderByWithAggregationInput = {
    _avg?: InputMaybe<ModeratorHourStatisticAvgOrderByAggregateInput>;
    _count?: InputMaybe<ModeratorHourStatisticCountOrderByAggregateInput>;
    _max?: InputMaybe<ModeratorHourStatisticMaxOrderByAggregateInput>;
    _min?: InputMaybe<ModeratorHourStatisticMinOrderByAggregateInput>;
    _sum?: InputMaybe<ModeratorHourStatisticSumOrderByAggregateInput>;
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorHourStatisticId?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export const ModeratorHourStatisticScalarFieldEnum = {
    Bonus: 'bonus',
    BonusIns: 'bonusIns',
    Chars: 'chars',
    Coins: 'coins',
    Date: 'date',
    FriendRequests: 'friendRequests',
    Gifts: 'gifts',
    Hour: 'hour',
    Ins: 'ins',
    Messages: 'messages',
    ModeratorHourStatisticId: 'moderatorHourStatisticId',
    ModeratorId: 'moderatorId',
    Nawr: 'nawr',
    NawrReaction: 'nawrReaction',
    Outs: 'outs',
    Pictures: 'pictures',
    Pokes: 'pokes',
    ProcessingTime: 'processingTime',
    ProjectName: 'projectName',
    Success: 'success',
    Try: 'try',
} as const;

export type ModeratorHourStatisticScalarFieldEnum =
    (typeof ModeratorHourStatisticScalarFieldEnum)[keyof typeof ModeratorHourStatisticScalarFieldEnum];
export type ModeratorHourStatisticSumAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    hour?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorHourStatisticSumAggregateOutputType = {
    __typename: 'ModeratorHourStatisticSumAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorHourStatisticSumOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorHourStatisticWhereInput = {
    bonus?: InputMaybe<IntNullableFilter>;
    chars?: InputMaybe<FloatNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    friendRequests?: InputMaybe<IntNullableFilter>;
    gifts?: InputMaybe<IntNullableFilter>;
    hour?: InputMaybe<IntFilter>;
    ins?: InputMaybe<FloatNullableFilter>;
    messages?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorHourStatisticId?: InputMaybe<StringFilter>;
    moderatorId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    pictures?: InputMaybe<IntNullableFilter>;
    pokes?: InputMaybe<IntNullableFilter>;
    processingTime?: InputMaybe<IntNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    success?: InputMaybe<IntNullableFilter>;
    try?: InputMaybe<IntNullableFilter>;
};

export type ModeratorHourStatisticWhereInputWithoutReference = {
    bonus?: InputMaybe<IntNullableFilter>;
    chars?: InputMaybe<FloatNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    friendRequests?: InputMaybe<IntNullableFilter>;
    gifts?: InputMaybe<IntNullableFilter>;
    hour?: InputMaybe<IntFilter>;
    ins?: InputMaybe<FloatNullableFilter>;
    messages?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorHourStatisticId?: InputMaybe<StringFilter>;
    moderatorId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    pictures?: InputMaybe<IntNullableFilter>;
    pokes?: InputMaybe<IntNullableFilter>;
    processingTime?: InputMaybe<IntNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    success?: InputMaybe<IntNullableFilter>;
    try?: InputMaybe<IntNullableFilter>;
};

export type ModeratorHourStatisticWhereUniqueInput = {
    d_h_mI_pN?: InputMaybe<D_H_MI_PNCompoundUniqueInput>;
    moderatorHourStatisticId?: InputMaybe<Scalars['String']>;
};

export type ModeratorIdDateCompoundUniqueInput = {
    date: Scalars['String'];
    moderatorId: Scalars['String'];
};

export type ModeratorStatistic = {
    __typename: 'ModeratorStatistic';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorId: Scalars['String'];
    moderatorStatisticId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticAggregateArgs = {
    _avg?: InputMaybe<ModeratorStatisticAvgAggregateInputType>;
    _count?: InputMaybe<ModeratorStatisticCountAggregateInputType>;
    _max?: InputMaybe<ModeratorStatisticMaxAggregateInputType>;
    _min?: InputMaybe<ModeratorStatisticMinAggregateInputType>;
    _sum?: InputMaybe<ModeratorStatisticSumAggregateInputType>;
    cursor?: InputMaybe<ModeratorStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ModeratorStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorStatisticWhereInput>;
};

export type ModeratorStatisticAggregateResult = {
    __typename: 'ModeratorStatisticAggregateResult';
    _avg?: Maybe<ModeratorStatisticAvgAggregateOutputType>;
    _count?: Maybe<ModeratorStatisticCountAggregateOutputType>;
    _max?: Maybe<ModeratorStatisticMaxAggregateOutputType>;
    _min?: Maybe<ModeratorStatisticMinAggregateOutputType>;
    _sum?: Maybe<ModeratorStatisticSumAggregateOutputType>;
};

export type ModeratorStatisticAvgAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorStatisticAvgAggregateOutputType = {
    __typename: 'ModeratorStatisticAvgAggregateOutputType';
    bonus?: Maybe<Scalars['Float']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Float']>;
    friendRequests?: Maybe<Scalars['Float']>;
    gifts?: Maybe<Scalars['Float']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Float']>;
    outs?: Maybe<Scalars['Float']>;
    pictures?: Maybe<Scalars['Float']>;
    pokes?: Maybe<Scalars['Float']>;
    processingTime?: Maybe<Scalars['Float']>;
    success?: Maybe<Scalars['Float']>;
    try?: Maybe<Scalars['Float']>;
};

export type ModeratorStatisticAvgOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticCountAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    moderatorStatisticId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorStatisticCountAggregateOutputType = {
    __typename: 'ModeratorStatisticCountAggregateOutputType';
    _all?: Maybe<Scalars['Int']>;
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['Int']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Int']>;
    messages?: Maybe<Scalars['Int']>;
    moderatorId?: Maybe<Scalars['Int']>;
    moderatorStatisticId?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    projectName?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticCountOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticFindManyGroupByResult = {
    __typename: 'ModeratorStatisticFindManyGroupByResult';
    _avg?: Maybe<ModeratorStatisticAvgAggregateOutputType>;
    _count?: Maybe<ModeratorStatisticCountAggregateOutputType>;
    _max?: Maybe<ModeratorStatisticMaxAggregateOutputType>;
    _min?: Maybe<ModeratorStatisticMinAggregateOutputType>;
    _sum?: Maybe<ModeratorStatisticSumAggregateOutputType>;
    moderator?: Maybe<ModerationUser>;
    moderatorId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
};

export type ModeratorStatisticFindManyResult = {
    __typename: 'ModeratorStatisticFindManyResult';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorId: Scalars['String'];
    moderatorStatisticId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticListRelationFilter = {
    every?: InputMaybe<ModeratorStatisticWhereInput>;
    none?: InputMaybe<ModeratorStatisticWhereInput>;
    some?: InputMaybe<ModeratorStatisticWhereInput>;
};

export type ModeratorStatisticMaxAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    moderatorStatisticId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorStatisticMaxAggregateOutputType = {
    __typename: 'ModeratorStatisticMaxAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderatorId?: Maybe<Scalars['String']>;
    moderatorStatisticId?: Maybe<Scalars['String']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticMaxOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticMinAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    date?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    moderatorId?: InputMaybe<Scalars['true']>;
    moderatorStatisticId?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorStatisticMinAggregateOutputType = {
    __typename: 'ModeratorStatisticMinAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    moderatorId?: Maybe<Scalars['String']>;
    moderatorStatisticId?: Maybe<Scalars['String']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticMinOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticOrderByWithAggregationInput = {
    _avg?: InputMaybe<ModeratorStatisticAvgOrderByAggregateInput>;
    _count?: InputMaybe<ModeratorStatisticCountOrderByAggregateInput>;
    _max?: InputMaybe<ModeratorStatisticMaxOrderByAggregateInput>;
    _min?: InputMaybe<ModeratorStatisticMinOrderByAggregateInput>;
    _sum?: InputMaybe<ModeratorStatisticSumOrderByAggregateInput>;
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export const ModeratorStatisticScalarFieldEnum = {
    Bonus: 'bonus',
    BonusIns: 'bonusIns',
    Chars: 'chars',
    Coins: 'coins',
    Date: 'date',
    FriendRequests: 'friendRequests',
    Gifts: 'gifts',
    Ins: 'ins',
    Messages: 'messages',
    ModeratorId: 'moderatorId',
    ModeratorStatisticId: 'moderatorStatisticId',
    Nawr: 'nawr',
    NawrReaction: 'nawrReaction',
    Outs: 'outs',
    Pictures: 'pictures',
    Pokes: 'pokes',
    ProcessingTime: 'processingTime',
    ProjectName: 'projectName',
    Success: 'success',
    Try: 'try',
} as const;

export type ModeratorStatisticScalarFieldEnum = (typeof ModeratorStatisticScalarFieldEnum)[keyof typeof ModeratorStatisticScalarFieldEnum];
export type ModeratorStatisticSumAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    chars?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    friendRequests?: InputMaybe<Scalars['true']>;
    gifts?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    messages?: InputMaybe<Scalars['true']>;
    outs?: InputMaybe<Scalars['true']>;
    pictures?: InputMaybe<Scalars['true']>;
    pokes?: InputMaybe<Scalars['true']>;
    processingTime?: InputMaybe<Scalars['true']>;
    success?: InputMaybe<Scalars['true']>;
    try?: InputMaybe<Scalars['true']>;
};

export type ModeratorStatisticSumAggregateOutputType = {
    __typename: 'ModeratorStatisticSumAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    chars?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    friendRequests?: Maybe<Scalars['Int']>;
    gifts?: Maybe<Scalars['Int']>;
    ins?: Maybe<Scalars['Decimal']>;
    messages?: Maybe<Scalars['Int']>;
    outs?: Maybe<Scalars['Int']>;
    pictures?: Maybe<Scalars['Int']>;
    pokes?: Maybe<Scalars['Int']>;
    processingTime?: Maybe<Scalars['Int']>;
    success?: Maybe<Scalars['Int']>;
    try?: Maybe<Scalars['Int']>;
};

export type ModeratorStatisticSumOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    chars?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    friendRequests?: InputMaybe<SortOrder>;
    gifts?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    messages?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    pictures?: InputMaybe<SortOrder>;
    pokes?: InputMaybe<SortOrder>;
    processingTime?: InputMaybe<SortOrder>;
    success?: InputMaybe<SortOrder>;
    try?: InputMaybe<SortOrder>;
};

export type ModeratorStatisticWhereInput = {
    bonus?: InputMaybe<IntNullableFilter>;
    chars?: InputMaybe<FloatNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    friendRequests?: InputMaybe<IntNullableFilter>;
    gifts?: InputMaybe<IntNullableFilter>;
    ins?: InputMaybe<FloatNullableFilter>;
    messages?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorId?: InputMaybe<StringFilter>;
    moderatorStatisticId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    pictures?: InputMaybe<IntNullableFilter>;
    pokes?: InputMaybe<IntNullableFilter>;
    processingTime?: InputMaybe<IntNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    success?: InputMaybe<IntNullableFilter>;
    try?: InputMaybe<IntNullableFilter>;
};

export type ModeratorStatisticWhereInputWithoutReference = {
    bonus?: InputMaybe<IntNullableFilter>;
    chars?: InputMaybe<FloatNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    friendRequests?: InputMaybe<IntNullableFilter>;
    gifts?: InputMaybe<IntNullableFilter>;
    ins?: InputMaybe<FloatNullableFilter>;
    messages?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorId?: InputMaybe<StringFilter>;
    moderatorStatisticId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    pictures?: InputMaybe<IntNullableFilter>;
    pokes?: InputMaybe<IntNullableFilter>;
    processingTime?: InputMaybe<IntNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    success?: InputMaybe<IntNullableFilter>;
    try?: InputMaybe<IntNullableFilter>;
};

export type ModeratorStatisticWhereUniqueInput = {
    d_mI_pN?: InputMaybe<D_MI_PNCompoundUniqueInput>;
    moderatorStatisticId?: InputMaybe<Scalars['String']>;
};

export type ModeratorUserHourStatistic = {
    __typename: 'ModeratorUserHourStatistic';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    hour: Scalars['Int'];
    ins?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorId: Scalars['String'];
    moderatorUserHourStatisticId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    team?: Maybe<ModerationTeam>;
    teamId?: Maybe<Scalars['String']>;
    type: ModerationMessageType;
    user: User;
    userId: Scalars['String'];
};

export type ModeratorUserHourStatisticHourArgs = {
    utcOffset?: InputMaybe<Scalars['Int']>;
};

export type ModeratorUserHourStatisticListRelationFilter = {
    every?: InputMaybe<ModeratorUserHourStatisticWhereInput>;
    none?: InputMaybe<ModeratorUserHourStatisticWhereInput>;
    some?: InputMaybe<ModeratorUserHourStatisticWhereInput>;
};

export type ModeratorUserHourStatisticOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    hour?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorUserHourStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    teamId?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ModeratorUserHourStatisticWhereInput = {
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    hour?: InputMaybe<IntFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorId?: InputMaybe<StringFilter>;
    moderatorUserHourStatisticId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    team?: InputMaybe<ModerationTeamWhereInput>;
    teamId?: InputMaybe<StringNullableFilter>;
    type?: InputMaybe<ModerationMessageTypeFilter>;
    user?: InputMaybe<UserWhereInput>;
    userId?: InputMaybe<StringFilter>;
};

export type ModeratorUserHourStatisticWhereUniqueInput = {
    moderatorUserHourStatisticId?: InputMaybe<Scalars['String']>;
    uI_d_h_pN_t?: InputMaybe<Ui_D_H_PN_TCompoundUniqueInput>;
};

export type ModeratorUserStatistic = {
    __typename: 'ModeratorUserStatistic';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    ins?: Maybe<Scalars['Int']>;
    moderator: ModerationUser;
    moderatorId: Scalars['String'];
    moderatorUserStatisticId: Scalars['String'];
    outs?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    team?: Maybe<ModerationTeam>;
    teamId?: Maybe<Scalars['String']>;
    type: ModerationMessageType;
    user: User;
    userId: Scalars['String'];
};

export type ModeratorUserStatisticListRelationFilter = {
    every?: InputMaybe<ModeratorUserStatisticWhereInput>;
    none?: InputMaybe<ModeratorUserStatisticWhereInput>;
    some?: InputMaybe<ModeratorUserStatisticWhereInput>;
};

export type ModeratorUserStatisticOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    moderatorId?: InputMaybe<SortOrder>;
    moderatorUserStatisticId?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    teamId?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ModeratorUserStatisticWhereInput = {
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntNullableFilter>;
    date?: InputMaybe<StringFilter>;
    ins?: InputMaybe<IntNullableFilter>;
    moderator?: InputMaybe<ModerationUserWhereInput>;
    moderatorId?: InputMaybe<StringFilter>;
    moderatorUserStatisticId?: InputMaybe<StringFilter>;
    outs?: InputMaybe<IntNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    team?: InputMaybe<ModerationTeamWhereInput>;
    teamId?: InputMaybe<StringNullableFilter>;
    type?: InputMaybe<ModerationMessageTypeFilter>;
    user?: InputMaybe<UserWhereInput>;
    userId?: InputMaybe<StringFilter>;
};

export type ModeratorUserStatisticWhereUniqueInput = {
    moderatorUserStatisticId?: InputMaybe<Scalars['String']>;
    uI_d_pN_t?: InputMaybe<Ui_D_PN_TCompoundUniqueInput>;
};

export type Mutation = {
    __typename: 'Mutation';
    bookTransaction: Transaction;
    changeCoins: User;
    createDefaultComment: DefaultComment;
    createMatchProfile: CreateMatchProfileResult;
    createOneAWR: AutomaticWriteRequest;
    createOneAcquisition: Acquisition;
    createOneAdminRole: AdminRole;
    createOneAdminRolePrivilege: AdminRolePrivilege;
    createOneAdminText: AdminText;
    createOneAdminUser: AdminUser;
    createOneAdminUserPrivilege: AdminUserPrivilege;
    createOneAdminUserSetting: AdminUserSetting;
    createOneAdminUserText: AdminUserText;
    createOneAffiliateUser: AffiliateUserCreate;
    createOneAgency: Agency;
    createOneAgencyProject: AgencyProject;
    createOneComment: Comment;
    createOneCommentCommentField: CommentCommentField;
    createOneCommentField: CommentField;
    createOneComplainType: ComplainType;
    createOneCoupon: Coupon;
    createOneDelayedAcquisition: Acquisition;
    createOneFlag: Flag;
    createOneGift: Gift;
    createOneGiftCategory: GiftCategory;
    createOneImage: Image;
    createOneInterest: Interest;
    createOneInterestCategory: InterestCategory;
    createOneLog?: Maybe<AdminLogResult>;
    createOneMessageBadword?: Maybe<BadwordResult>;
    createOneModeratedProfile: ModeratedProfileResult;
    createOneModeratedProfileModerationUser: ModeratedProfileModerationUser;
    createOneModeratedProfileTeam: ModeratedProfileTeam;
    createOneModerationChat: ModerationChat;
    createOneModerationTeam: ModerationTeam;
    createOneModerationUser: ModerationUser;
    createOneOffer: Offer;
    createOnePool: Pool;
    createOnePoolModerationUser: PoolModerationUser;
    createOnePoolSourceProfile: PoolSourceProfile;
    createOnePoolTargetProfile: PoolTargetProfile;
    createOneProvider: Provider;
    createOneProviderSetting: ProviderSetting;
    createOneQueue: Queue;
    createOneScenario: ScenarioResult;
    createOneScenarioCategory: ScenarioCategory;
    createOneSupportText: SupportText;
    createOneSupportTextAdmin: SupportTextAdmin;
    createOneSupportTextCategory: SupportTextCategory;
    createOneTheme: Theme;
    createOneThemeImage: ThemeImage;
    createOneTransaction: Transaction;
    createOneTranslation: Translation;
    createOneUsernameBadword?: Maybe<BadwordResult>;
    createOptionPoll?: Maybe<OptionPoll>;
    createPredefinedMessage: PredefinedMessage;
    createProfileText: ProfileText;
    createSupportTicket: SupportTicket;
    deleteManyAdminRolePrivilege?: Maybe<BatchPayload>;
    deleteManyAdminUserPrivilege?: Maybe<BatchPayload>;
    deleteManyCommentField?: Maybe<BatchPayload>;
    deleteManyCoupon?: Maybe<BatchPayload>;
    deleteManyMessage?: Maybe<BatchPayload>;
    deleteManyModerationChat?: Maybe<BatchPayload>;
    deleteManyOffer?: Maybe<BatchPayload>;
    deleteManyPool?: Maybe<BatchPayload>;
    deleteManyPoolModerationUser?: Maybe<BatchPayload>;
    deleteManyPoolSourceProfile?: Maybe<BatchPayload>;
    deleteManyPoolTargetProfile?: Maybe<BatchPayload>;
    deleteManySupportTextAdmin?: Maybe<BatchPayload>;
    deleteManyTranslation: BatchPayload;
    deleteManyUserComplain?: Maybe<BatchPayload>;
    deleteOneAWR: AutomaticWriteRequest;
    deleteOneAcquisition?: Maybe<Acquisition>;
    deleteOneAdminRole?: Maybe<AdminRole>;
    deleteOneAdminRolePrivilege?: Maybe<AdminRolePrivilege>;
    deleteOneAdminText?: Maybe<AdminText>;
    deleteOneAdminUserPrivilege?: Maybe<AdminUserPrivilege>;
    deleteOneAdminUserSetting: AdminUserSetting;
    deleteOneAdminUserText?: Maybe<AdminUserText>;
    deleteOneAgency?: Maybe<Agency>;
    deleteOneAgencyProject: AgencyProject;
    deleteOneCommentField?: Maybe<CommentField>;
    deleteOneCoupon?: Maybe<Coupon>;
    deleteOneFlag?: Maybe<Flag>;
    deleteOneGift?: Maybe<Gift>;
    deleteOneGiftCategory?: Maybe<GiftCategory>;
    deleteOneImage?: Maybe<Image>;
    deleteOneInterest?: Maybe<Interest>;
    deleteOneInterestCategory?: Maybe<InterestCategory>;
    deleteOneMessage?: Maybe<Message>;
    deleteOneModeratedProfileTeam?: Maybe<ModeratedProfileTeam>;
    deleteOneModerationChat?: Maybe<ModerationChat>;
    deleteOneModerationTeam?: Maybe<ModerationTeam>;
    deleteOneOffer?: Maybe<Offer>;
    deleteOnePool?: Maybe<Pool>;
    deleteOnePoolModerationUser?: Maybe<PoolModerationUser>;
    deleteOnePoolSourceProfile?: Maybe<PoolSourceProfile>;
    deleteOnePoolTargetProfile?: Maybe<PoolTargetProfile>;
    deleteOneQueue?: Maybe<Queue>;
    deleteOneScenario: Scenario;
    deleteOneScenarioCategory: ScenarioCategory;
    deleteOneSupportText?: Maybe<SupportText>;
    deleteOneSupportTextAdmin?: Maybe<SupportTextAdmin>;
    deleteOneSupportTextCategory?: Maybe<SupportTextCategory>;
    deleteOneTheme?: Maybe<Theme>;
    deleteOneThemeImage?: Maybe<ThemeImage>;
    deleteOneUserComplain?: Maybe<DeleteUserComplainResult>;
    deletePicture: Scalars['Boolean'];
    deleteTranslation: Translation;
    importLegacyUser: User;
    login?: Maybe<AdminLoginResult>;
    resetCache: Scalars['Boolean'];
    restartOneAcquisition?: Maybe<Acquisition>;
    sendSupportMessage?: Maybe<SupportMessage>;
    setProvision: Scalars['Boolean'];
    unbindSocial: Social;
    updateDefaultComment: DefaultComment;
    updateManyCommentField?: Maybe<BatchPayload>;
    updateManyImage: BatchPayload;
    updateManyMessage?: Maybe<BatchPayload>;
    updateManyModeratedProfile: BatchPayload;
    updateManyModerationUser?: Maybe<BatchPayload>;
    updateManyOffer?: Maybe<BatchPayload>;
    updateManyPicture: BatchPayload;
    updateManyProvider?: Maybe<BatchPayload>;
    updateManyProviderSetting?: Maybe<BatchPayload>;
    updateManyQueueTypePriority: BatchPayload;
    updateManySupportTextAdmin?: Maybe<BatchPayload>;
    updateManySystemComplain?: Maybe<BatchPayload>;
    updateMatchConfig: MatchConfig;
    updateOneAWR: AutomaticWriteRequest;
    updateOneAcquisition: Acquisition;
    updateOneAdminRole: AdminRole;
    updateOneAdminUser: AdminUser;
    updateOneAdminUserSetting: AdminUserSetting;
    updateOneAgency: Agency;
    updateOneComment: Comment;
    updateOneCommentCommentField: CommentCommentField;
    updateOneCommentField: CommentField;
    updateOneComplainType: ComplainType;
    updateOneConversation: Conversation;
    updateOneCoupon: Coupon;
    updateOneFlag: Flag;
    updateOneGift: Gift;
    updateOneGiftCategory: GiftCategory;
    updateOneImage: Image;
    updateOneInterest: Interest;
    updateOneInterestCategory: InterestCategory;
    updateOneMessage: Message;
    updateOneModeratedProfile: ModeratedProfile;
    updateOneModerationTeam: ModerationTeam;
    updateOneModerationUser: ModerationUser;
    updateOneModeratorComplain: ModeratorComplain;
    updateOneOffer: Offer;
    updateOnePicture: Picture;
    updateOnePool: Pool;
    updateOneProfile: ProfileResult;
    updateOneProvider: Provider;
    updateOneProviderSetting: ProviderSetting;
    updateOneQueue?: Maybe<Queue>;
    updateOneScenario: ScenarioResult;
    updateOneScenarioCategory: ScenarioCategory;
    updateOneSupportText: SupportText;
    updateOneSupportTextCategory: SupportTextCategory;
    updateOneSystemComplain: SystemComplain;
    updateOneTheme: Theme;
    updateOneTranslation: Translation;
    updateOneUser: User;
    updateSupportTicket?: Maybe<SupportTicket>;
    uploadPicture: Picture;
    validateEmail: User;
    validateMobileNumber: User;
    verifyOneUser: User;
};

export type MutationBookTransactionArgs = {
    where: TransactionWhereUniqueInput;
};

export type MutationChangeCoinsArgs = {
    data: UserCoinsUpdateInput;
    where: UserWhereUniqueInput;
};

export type MutationCreateDefaultCommentArgs = {
    data: DefaultCommentCreateInput;
};

export type MutationCreateMatchProfileArgs = {
    data: MatchProfileCreateInput;
};

export type MutationCreateOneAwrArgs = {
    data: AwrCreateInput;
};

export type MutationCreateOneAcquisitionArgs = {
    data: AcquisitionCreateInput;
};

export type MutationCreateOneAdminRoleArgs = {
    data: AdminRoleCreateInput;
};

export type MutationCreateOneAdminRolePrivilegeArgs = {
    data: AdminRolePrivilegeCreateInput;
};

export type MutationCreateOneAdminTextArgs = {
    data: AdminTextCreateInput;
};

export type MutationCreateOneAdminUserArgs = {
    data: AdminUserCreateInput;
};

export type MutationCreateOneAdminUserPrivilegeArgs = {
    data: AdminUserPrivilegeCreateInput;
};

export type MutationCreateOneAdminUserSettingArgs = {
    data: AdminUserSettingCreateInput;
};

export type MutationCreateOneAdminUserTextArgs = {
    data: AdminUserTextCreateInput;
};

export type MutationCreateOneAffiliateUserArgs = {
    data: AffiliateUserCreateInput;
};

export type MutationCreateOneAgencyArgs = {
    data: AgencyCreateInput;
};

export type MutationCreateOneAgencyProjectArgs = {
    data: AgencyProjectCreateInput;
};

export type MutationCreateOneCommentArgs = {
    data: CommentCreateInput;
};

export type MutationCreateOneCommentCommentFieldArgs = {
    data: CommentCommentFieldCreateInput;
};

export type MutationCreateOneCommentFieldArgs = {
    data: CommentFieldCreateInput;
};

export type MutationCreateOneComplainTypeArgs = {
    data: ComplainTypeCreateInput;
};

export type MutationCreateOneCouponArgs = {
    data: CouponCreateInput;
};

export type MutationCreateOneDelayedAcquisitionArgs = {
    data: AcquisitionDelayedCreateInput;
};

export type MutationCreateOneFlagArgs = {
    data: FlagCreateInput;
};

export type MutationCreateOneGiftArgs = {
    data: GiftCreateInput;
};

export type MutationCreateOneGiftCategoryArgs = {
    data: GiftCategoryCreateInput;
};

export type MutationCreateOneImageArgs = {
    data: ImageCreateInput;
};

export type MutationCreateOneInterestArgs = {
    data: InterestCreateInput;
};

export type MutationCreateOneInterestCategoryArgs = {
    data: InterestCategoryCreateInput;
};

export type MutationCreateOneLogArgs = {
    data: LogCreateInput;
};

export type MutationCreateOneMessageBadwordArgs = {
    data: BadwordCreateInput;
};

export type MutationCreateOneModeratedProfileArgs = {
    data: ModeratedProfileCreateInput;
};

export type MutationCreateOneModeratedProfileModerationUserArgs = {
    data: ModeratedProfileModerationUserCreateInput;
};

export type MutationCreateOneModeratedProfileTeamArgs = {
    data: ModeratedProfileTeamCreateInput;
};

export type MutationCreateOneModerationChatArgs = {
    data: ModerationChatCreateInput;
};

export type MutationCreateOneModerationTeamArgs = {
    data: ModerationTeamCreateInput;
};

export type MutationCreateOneModerationUserArgs = {
    data: ModerationUserCreateInput;
};

export type MutationCreateOneOfferArgs = {
    data: OfferCreateInput;
};

export type MutationCreateOnePoolArgs = {
    data: PoolCreateInput;
};

export type MutationCreateOnePoolModerationUserArgs = {
    data: PoolModerationUserCreateInput;
};

export type MutationCreateOnePoolSourceProfileArgs = {
    data: PoolSourceProfileCreateInput;
};

export type MutationCreateOnePoolTargetProfileArgs = {
    data: PoolTargetProfileCreateInput;
};

export type MutationCreateOneProviderArgs = {
    data: ProviderCreateInput;
};

export type MutationCreateOneProviderSettingArgs = {
    data: ProviderSettingCreateInput;
};

export type MutationCreateOneQueueArgs = {
    data: QueueCreateInput;
};

export type MutationCreateOneScenarioArgs = {
    data: ScenarioCreateInput;
};

export type MutationCreateOneScenarioCategoryArgs = {
    data: ScenarioCategoryCreateInput;
};

export type MutationCreateOneSupportTextArgs = {
    data: SupportTextCreateInput;
};

export type MutationCreateOneSupportTextAdminArgs = {
    data: SupportTextAdminCreateInput;
};

export type MutationCreateOneSupportTextCategoryArgs = {
    data: SupportTextCategoryCreateInput;
};

export type MutationCreateOneThemeArgs = {
    data: ThemeCreateInput;
};

export type MutationCreateOneThemeImageArgs = {
    data: ThemeImageCreateInput;
};

export type MutationCreateOneTransactionArgs = {
    data: TransactionCreateInput;
};

export type MutationCreateOneTranslationArgs = {
    data: TranslationCreateInput;
};

export type MutationCreateOneUsernameBadwordArgs = {
    data: BadwordCreateInput;
};

export type MutationCreateOptionPollArgs = {
    data: OptionPollCreateInput;
    projectName?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePredefinedMessageArgs = {
    data: PredefinedMessageCreateInput;
};

export type MutationCreateProfileTextArgs = {
    data: ProfileTextCreateInput;
};

export type MutationCreateSupportTicketArgs = {
    where: UserComplainWhereUniqueInput;
};

export type MutationDeleteManyAdminRolePrivilegeArgs = {
    where: AdminRolePrivilegeWhereInput;
};

export type MutationDeleteManyAdminUserPrivilegeArgs = {
    where: AdminUserPrivilegeWhereInput;
};

export type MutationDeleteManyCommentFieldArgs = {
    where?: InputMaybe<CommentFieldWhereInput>;
};

export type MutationDeleteManyCouponArgs = {
    where: CouponWhereInput;
};

export type MutationDeleteManyMessageArgs = {
    where: MessageWhereInput;
};

export type MutationDeleteManyModerationChatArgs = {
    where?: InputMaybe<ModerationChatWhereInput>;
};

export type MutationDeleteManyOfferArgs = {
    where?: InputMaybe<OfferWhereInput>;
};

export type MutationDeleteManyPoolArgs = {
    where?: InputMaybe<PoolWhereInput>;
};

export type MutationDeleteManyPoolModerationUserArgs = {
    where?: InputMaybe<PoolModerationUserWhereInput>;
};

export type MutationDeleteManyPoolSourceProfileArgs = {
    where?: InputMaybe<PoolSourceProfileWhereInput>;
};

export type MutationDeleteManyPoolTargetProfileArgs = {
    where?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type MutationDeleteManySupportTextAdminArgs = {
    where?: InputMaybe<SupportTextAdminWhereInput>;
};

export type MutationDeleteManyTranslationArgs = {
    where: TranslationWhereInput;
};

export type MutationDeleteManyUserComplainArgs = {
    where: UserComplainWhereInput;
};

export type MutationDeleteOneAwrArgs = {
    where: AwrWhereUniqueInput;
};

export type MutationDeleteOneAcquisitionArgs = {
    where: AcquisitionWhereUniqueInput;
};

export type MutationDeleteOneAdminRoleArgs = {
    where: AdminRoleWhereUniqueInput;
};

export type MutationDeleteOneAdminRolePrivilegeArgs = {
    where: AdminRolePrivilegeWhereUniqueInput;
};

export type MutationDeleteOneAdminTextArgs = {
    where: AdminTextWhereUniqueInput;
};

export type MutationDeleteOneAdminUserPrivilegeArgs = {
    where: AdminUserPrivilegeWhereUniqueInput;
};

export type MutationDeleteOneAdminUserSettingArgs = {
    where: AdminUserSettingWhereUniqueInput;
};

export type MutationDeleteOneAdminUserTextArgs = {
    where: AdminUserTextWhereUniqueInput;
};

export type MutationDeleteOneAgencyArgs = {
    where: AgencyWhereUniqueInput;
};

export type MutationDeleteOneAgencyProjectArgs = {
    where: AgencyProjectWhereUniqueInput;
};

export type MutationDeleteOneCommentFieldArgs = {
    where: CommentFieldWhereUniqueInput;
};

export type MutationDeleteOneCouponArgs = {
    where: CouponWhereUniqueInput;
};

export type MutationDeleteOneFlagArgs = {
    where: FlagWhereUniqueInput;
};

export type MutationDeleteOneGiftArgs = {
    where: GiftWhereUniqueInput;
};

export type MutationDeleteOneGiftCategoryArgs = {
    where: GiftCategoryWhereUniqueInput;
};

export type MutationDeleteOneImageArgs = {
    where: ImageWhereUniqueInput;
};

export type MutationDeleteOneInterestArgs = {
    where: InterestWhereUniqueInput;
};

export type MutationDeleteOneInterestCategoryArgs = {
    where: InterestCategoryWhereUniqueInput;
};

export type MutationDeleteOneMessageArgs = {
    where: MessageWhereUniqueInput;
};

export type MutationDeleteOneModeratedProfileTeamArgs = {
    where: ModeratedProfileTeamWhereUniqueInput;
};

export type MutationDeleteOneModerationChatArgs = {
    where: ModerationChatWhereUniqueInput;
};

export type MutationDeleteOneModerationTeamArgs = {
    where: ModerationTeamWhereUniqueInput;
};

export type MutationDeleteOneOfferArgs = {
    where: OfferWhereUniqueInput;
};

export type MutationDeleteOnePoolArgs = {
    where: PoolWhereUniqueInput;
};

export type MutationDeleteOnePoolModerationUserArgs = {
    where: PoolModerationUserWhereUniqueInput;
};

export type MutationDeleteOnePoolSourceProfileArgs = {
    where: PoolSourceProfileWhereUniqueInput;
};

export type MutationDeleteOnePoolTargetProfileArgs = {
    where: PoolTargetProfileWhereUniqueInput;
};

export type MutationDeleteOneQueueArgs = {
    where: QueueWhereUniqueInput;
};

export type MutationDeleteOneScenarioArgs = {
    where: ScenarioWhereUniqueInput;
};

export type MutationDeleteOneScenarioCategoryArgs = {
    where: ScenarioCategoryWhereUniqueInput;
};

export type MutationDeleteOneSupportTextArgs = {
    where: SupportTextWhereUniqueInput;
};

export type MutationDeleteOneSupportTextAdminArgs = {
    where: SupportTextAdminWhereUniqueInput;
};

export type MutationDeleteOneSupportTextCategoryArgs = {
    where: SupportTextCategoryWhereUniqueInput;
};

export type MutationDeleteOneThemeArgs = {
    where: ThemeWhereUniqueInput;
};

export type MutationDeleteOneThemeImageArgs = {
    where: ThemeImageWhereUniqueInput;
};

export type MutationDeleteOneUserComplainArgs = {
    where: UserComplainWhereUniqueInput;
};

export type MutationDeletePictureArgs = {
    where: PictureWhereUniqueInput;
};

export type MutationDeleteTranslationArgs = {
    where: TranslationWhereUniqueInput;
};

export type MutationImportLegacyUserArgs = {
    data: LegacyUserImportInput;
};

export type MutationLoginArgs = {
    data: LoginInput;
};

export type MutationResetCacheArgs = {
    projectNames?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationRestartOneAcquisitionArgs = {
    where: AcquisitionWhereUniqueInput;
};

export type MutationSendSupportMessageArgs = {
    data: SupportMessageCreateInput;
};

export type MutationSetProvisionArgs = {
    data: SetProvisionInput;
    where: AffiliateUserWhereUniqueInput;
};

export type MutationUnbindSocialArgs = {
    where: SocialWhereUniqueInput;
};

export type MutationUpdateDefaultCommentArgs = {
    data: DefaultCommentUpdateInput;
    where: DefaultCommentWhereUniqueInput;
};

export type MutationUpdateManyCommentFieldArgs = {
    data: CommentFieldUpdateManyMutationInput;
    where: CommentFieldWhereInput;
};

export type MutationUpdateManyImageArgs = {
    data: Array<ImageUpdateManyInput>;
};

export type MutationUpdateManyMessageArgs = {
    data: MessageUpdateManyMutationInput;
    where?: InputMaybe<MessageWhereOnlyScalarInput>;
};

export type MutationUpdateManyModeratedProfileArgs = {
    data: ModeratedProfileUpdateManyInput;
    where: ModeratedProfileWhereInput;
};

export type MutationUpdateManyModerationUserArgs = {
    data: ModerationUserUpdateManyMutationInput;
    where?: InputMaybe<ModerationUserWhereInput>;
};

export type MutationUpdateManyOfferArgs = {
    data: OfferUpdateManyMutationInput;
    where?: InputMaybe<OfferWhereOnlyScalarInput>;
};

export type MutationUpdateManyPictureArgs = {
    data: Array<PictureUpdateManyInput>;
};

export type MutationUpdateManyProviderArgs = {
    data: ProviderUpdateManyMutationInput;
    where?: InputMaybe<ProviderWhereInput>;
};

export type MutationUpdateManyProviderSettingArgs = {
    data: ProviderSettingUpdateManyMutationInput;
    where?: InputMaybe<ProviderSettingWhereInput>;
};

export type MutationUpdateManyQueueTypePriorityArgs = {
    data: Array<QueueTypePriorityUpdateManyMutationInput>;
};

export type MutationUpdateManySupportTextAdminArgs = {
    data: SupportTextAdminUpdateManyMutationInput;
    where?: InputMaybe<SupportTextAdminWhereInput>;
};

export type MutationUpdateManySystemComplainArgs = {
    data: SystemComplainUpdateManyMutationInput;
    where?: InputMaybe<SystemComplainWhereInput>;
};

export type MutationUpdateMatchConfigArgs = {
    data: MatchConfigUpdateInput;
    where?: MatchConfigWhereUniqueInput;
};

export type MutationUpdateOneAwrArgs = {
    data: AwrUpdateInput;
    where: AwrWhereUniqueInput;
};

export type MutationUpdateOneAcquisitionArgs = {
    data: AcquisitionUpdateInput;
    where: AcquisitionWhereUniqueInput;
};

export type MutationUpdateOneAdminRoleArgs = {
    data: AdminRoleUpdateInput;
    where: AdminRoleWhereUniqueInput;
};

export type MutationUpdateOneAdminUserArgs = {
    data: AdminUserUpdateInput;
    where: AdminUserWhereUniqueInput;
};

export type MutationUpdateOneAdminUserSettingArgs = {
    data: AdminUserSettingUpdateInput;
    where: AdminUserSettingWhereUniqueInput;
};

export type MutationUpdateOneAgencyArgs = {
    data: AgencyUpdateInput;
    where: AgencyWhereUniqueInput;
};

export type MutationUpdateOneCommentArgs = {
    data: CommentUpdateInput;
    where: CommentWhereUniqueInput;
};

export type MutationUpdateOneCommentCommentFieldArgs = {
    data: CommentCommentFieldUpdateInput;
    where: CommentCommentFieldWhereUniqueInput;
};

export type MutationUpdateOneCommentFieldArgs = {
    data: CommentFieldUpdateInput;
    where: CommentFieldWhereUniqueInput;
};

export type MutationUpdateOneComplainTypeArgs = {
    data: ComplainTypeUpdateInput;
    where: ComplainTypeWhereUniqueInput;
};

export type MutationUpdateOneConversationArgs = {
    data: ConversationUpdateInput;
    where: ConversationWhereUniqueInput;
};

export type MutationUpdateOneCouponArgs = {
    data: CouponUpdateInput;
    where: CouponWhereUniqueInput;
};

export type MutationUpdateOneFlagArgs = {
    data: FlagUpdateInput;
    where: FlagWhereUniqueInput;
};

export type MutationUpdateOneGiftArgs = {
    data: GiftUpdateInput;
    where: GiftWhereUniqueInput;
};

export type MutationUpdateOneGiftCategoryArgs = {
    data: GiftCategoryUpdateInput;
    where: GiftCategoryWhereUniqueInput;
};

export type MutationUpdateOneImageArgs = {
    data: ImageUpdateInput;
    where: ImageWhereUniqueInput;
};

export type MutationUpdateOneInterestArgs = {
    data: InterestUpdateInput;
    where: InterestWhereUniqueInput;
};

export type MutationUpdateOneInterestCategoryArgs = {
    data: InterestCategoryUpdateInput;
    where: InterestCategoryWhereUniqueInput;
};

export type MutationUpdateOneMessageArgs = {
    data: MessageUpdateInput;
    where: MessageWhereUniqueInput;
};

export type MutationUpdateOneModeratedProfileArgs = {
    data: ModeratedProfileUpdateInput;
    where: ModeratedProfileProjectWhereUniqueInput;
};

export type MutationUpdateOneModerationTeamArgs = {
    data: ModerationTeamUpdateInput;
    where: ModerationTeamWhereUniqueInput;
};

export type MutationUpdateOneModerationUserArgs = {
    data: ModerationUserUpdateInput;
    where: ModerationUserWhereUniqueInput;
};

export type MutationUpdateOneModeratorComplainArgs = {
    data: ModeratorComplainUpdateInput;
    where: ModeratorComplainWhereUniqueInput;
};

export type MutationUpdateOneOfferArgs = {
    data: OfferUpdateInput;
    where: OfferWhereUniqueInput;
};

export type MutationUpdateOnePictureArgs = {
    data: PictureUpdateInput;
    where: PictureWhereUniqueInput;
};

export type MutationUpdateOnePoolArgs = {
    data: PoolUpdateInput;
    where: PoolWhereUniqueInput;
};

export type MutationUpdateOneProfileArgs = {
    data: ProfileUpdateInput;
    where: ProfileWhereUniqueInput;
};

export type MutationUpdateOneProviderArgs = {
    data: ProviderUpdateInput;
    where: ProviderWhereUniqueInput;
};

export type MutationUpdateOneProviderSettingArgs = {
    data: ProviderSettingUpdateInput;
    where: ProviderSettingWhereUniqueInput;
};

export type MutationUpdateOneQueueArgs = {
    data: QueueUpdateInput;
    where: QueueWhereUniqueInput;
};

export type MutationUpdateOneScenarioArgs = {
    data: ScenarioUpdateInput;
    where: ScenarioWhereUniqueInput;
};

export type MutationUpdateOneScenarioCategoryArgs = {
    data: ScenarioCategoryUpdateInput;
    where: ScenarioCategoryWhereUniqueInput;
};

export type MutationUpdateOneSupportTextArgs = {
    data: SupportTextUpdateInput;
    where: SupportTextWhereUniqueInput;
};

export type MutationUpdateOneSupportTextCategoryArgs = {
    data: SupportTextCategoryUpdateInput;
    where: SupportTextCategoryWhereUniqueInput;
};

export type MutationUpdateOneSystemComplainArgs = {
    data: SystemComplainUpdateInput;
    where: SystemComplainWhereUniqueInput;
};

export type MutationUpdateOneThemeArgs = {
    data: ThemeUpdateInput;
    where: ThemeWhereUniqueInput;
};

export type MutationUpdateOneTranslationArgs = {
    data: TranslationUpdateInput;
    where: TranslationWhereUniqueInput;
};

export type MutationUpdateOneUserArgs = {
    data: UserUpdateInput;
    where: UserWhereUniqueInput;
};

export type MutationUpdateSupportTicketArgs = {
    data: SupportTicketUpdateInput;
    where: SupportTicketWhereUniqueInput;
};

export type MutationUploadPictureArgs = {
    data: PictureCreateInput;
    where: ProfileWhereUniqueInput;
};

export type MutationValidateEmailArgs = {
    where: UserWhereUniqueInput;
};

export type MutationValidateMobileNumberArgs = {
    where: UserWhereUniqueInput;
};

export type MutationVerifyOneUserArgs = {
    data: UserVerifyInput;
    where: UserWhereUniqueInput;
};

export type NameProjectNameCompoundUniqueInput = {
    name: Scalars['String'];
    projectName: Scalars['String'];
};

export type NestedEnumGenderWithAggregatesFilter = {
    _count?: InputMaybe<IntFilter>;
    _max?: InputMaybe<EnumGenderFilter>;
    _min?: InputMaybe<EnumGenderFilter>;
    equals?: InputMaybe<Gender>;
    in?: InputMaybe<Array<Gender>>;
    not?: InputMaybe<Gender>;
    notIn?: InputMaybe<Array<Gender>>;
};

export type NotificationLog = {
    __typename: 'NotificationLog';
    channel: NotificationLogChannel;
    createdAt: Scalars['DateTime'];
    notificationLogId: Scalars['String'];
    payload?: Maybe<Scalars['JSON']>;
    payloadType: NotificationLogPayloadType;
    projectName: Scalars['String'];
    response?: Maybe<Scalars['JSON']>;
    responseType?: Maybe<Scalars['String']>;
    source: NotificationLogSource;
    sourceId?: Maybe<Scalars['String']>;
    status: NotificationLogStatus;
    updatedAt: Scalars['DateTime'];
    user?: Maybe<User>;
    userId?: Maybe<Scalars['String']>;
};

export const NotificationLogChannel = {
    Default: 'Default',
    Email: 'Email',
    Push: 'Push',
    Sms: 'Sms',
    Subscription: 'Subscription',
} as const;

export type NotificationLogChannel = (typeof NotificationLogChannel)[keyof typeof NotificationLogChannel];
export type NotificationLogChannelFilter = {
    equals?: InputMaybe<NotificationLogChannel>;
    in?: InputMaybe<Array<NotificationLogChannel>>;
    not?: InputMaybe<NotificationLogChannel>;
    notIn?: InputMaybe<Array<NotificationLogChannel>>;
};

export type NotificationLogOrderByInput = {
    channel?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    notificationLogId?: InputMaybe<SortOrder>;
    payloadType?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export const NotificationLogPayloadType = {
    Awr: 'Awr',
    CoinsAdded: 'CoinsAdded',
    Delete: 'Delete',
    DeleteAccount: 'DeleteAccount',
    FriendshipAccept: 'FriendshipAccept',
    FriendshipDecline: 'FriendshipDecline',
    FriendshipDelete: 'FriendshipDelete',
    FriendshipRequest: 'FriendshipRequest',
    Gift: 'Gift',
    Match: 'Match',
    Message: 'Message',
    Nawr: 'Nawr',
    Picture: 'Picture',
    Poke: 'Poke',
    PredefinedMessage: 'PredefinedMessage',
    Register: 'Register',
    RegisterReminder: 'RegisterReminder',
    ResetPassword: 'ResetPassword',
    Skip: 'Skip',
    Support: 'Support',
    SystemMessage: 'SystemMessage',
    Visit: 'Visit',
} as const;

export type NotificationLogPayloadType = (typeof NotificationLogPayloadType)[keyof typeof NotificationLogPayloadType];
export type NotificationLogPayloadTypeFilter = {
    equals?: InputMaybe<NotificationLogPayloadType>;
    in?: InputMaybe<Array<NotificationLogPayloadType>>;
    not?: InputMaybe<NotificationLogPayloadType>;
    notIn?: InputMaybe<Array<NotificationLogPayloadType>>;
};

export const NotificationLogSource = {
    Acquisition: 'Acquisition',
    Default: 'Default',
    Scenario: 'Scenario',
} as const;

export type NotificationLogSource = (typeof NotificationLogSource)[keyof typeof NotificationLogSource];
export type NotificationLogSourceFilter = {
    equals?: InputMaybe<NotificationLogSource>;
    in?: InputMaybe<Array<NotificationLogSource>>;
    not?: InputMaybe<NotificationLogSource>;
    notIn?: InputMaybe<Array<NotificationLogSource>>;
};

export const NotificationLogStatus = {
    Delivered: 'Delivered',
    Error: 'Error',
    Pending: 'Pending',
} as const;

export type NotificationLogStatus = (typeof NotificationLogStatus)[keyof typeof NotificationLogStatus];
export type NotificationLogStatusFilter = {
    equals?: InputMaybe<NotificationLogStatus>;
    in?: InputMaybe<Array<NotificationLogStatus>>;
    not?: InputMaybe<NotificationLogStatus>;
    notIn?: InputMaybe<Array<NotificationLogStatus>>;
};

export type NotificationLogWhereInput = {
    channel?: InputMaybe<NotificationLogChannelFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    limit?: InputMaybe<IntFilter>;
    notificationLogId?: InputMaybe<StringFilter>;
    payloadType?: InputMaybe<NotificationLogPayloadTypeFilter>;
    projectName?: InputMaybe<StringFilter>;
    source?: InputMaybe<NotificationLogSourceFilter>;
    sourceId?: InputMaybe<StringFilter>;
    status?: InputMaybe<NotificationLogStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
    username?: InputMaybe<StringFilter>;
};

export type NotificationLogWhereUniqueInput = {
    notificationLogId?: InputMaybe<Scalars['String']>;
};

export type Offer = {
    __typename: 'Offer';
    active: Scalars['Boolean'];
    bonus: Scalars['Int'];
    coins: Scalars['Int'];
    cost: Scalars['Float'];
    currency: Scalars['String'];
    extra: Scalars['String'];
    fee?: Maybe<Scalars['Float']>;
    limit: Scalars['Int'];
    name: Scalars['String'];
    offerId: Scalars['String'];
    payed?: Maybe<Scalars['Boolean']>;
    periodUnit?: Maybe<PeriodUnitOffer>;
    profileStatus?: Maybe<ProfileStatus>;
    projectName: Scalars['String'];
    provider: Provider;
    providerName: Scalars['String'];
    statusPeriod?: Maybe<Scalars['Int']>;
};

export type OfferCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    bonus?: InputMaybe<Scalars['Int']>;
    coins?: InputMaybe<Scalars['Int']>;
    cost: Scalars['Float'];
    currency: Scalars['String'];
    extra: Scalars['String'];
    fee?: InputMaybe<Scalars['Float']>;
    limit?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    payed?: InputMaybe<Scalars['Boolean']>;
    periodUnit?: InputMaybe<PeriodUnitOffer>;
    profileStatus?: InputMaybe<ProfileStatus>;
    project: ProjectWhereUniqueInput;
    provider: ProviderWhereUniqueInput;
    statusPeriod?: InputMaybe<Scalars['Int']>;
};

export const OfferDistinctFieldEnum = {
    Active: 'active',
    Bonus: 'bonus',
    Coins: 'coins',
    Cost: 'cost',
    Currency: 'currency',
    Extra: 'extra',
    Limit: 'limit',
    Name: 'name',
    OfferId: 'offerId',
    Payed: 'payed',
    ProjectName: 'projectName',
    ProviderName: 'providerName',
} as const;

export type OfferDistinctFieldEnum = (typeof OfferDistinctFieldEnum)[keyof typeof OfferDistinctFieldEnum];
export type OfferIdProjectNameCompoundUniqueInput = {
    offerId: Scalars['String'];
    projectName: Scalars['String'];
};

export type OfferListRelationFilter = {
    every?: InputMaybe<OfferWhereInput>;
    none?: InputMaybe<OfferWhereInput>;
    some?: InputMaybe<OfferWhereInput>;
};

export type OfferOrderByInput = {
    active?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    cost?: InputMaybe<SortOrder>;
    currency?: InputMaybe<SortOrder>;
    extra?: InputMaybe<SortOrder>;
    limit?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    offerId?: InputMaybe<SortOrder>;
    payed?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerName?: InputMaybe<SortOrder>;
};

export type OfferUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    bonus?: InputMaybe<Scalars['Int']>;
    coins?: InputMaybe<Scalars['Int']>;
    cost?: InputMaybe<Scalars['Float']>;
    currency?: InputMaybe<Scalars['String']>;
    extra?: InputMaybe<Scalars['String']>;
    fee?: InputMaybe<Scalars['Float']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    payed?: InputMaybe<Scalars['Boolean']>;
    periodUnit?: InputMaybe<PeriodUnitOffer>;
    profileStatus?: InputMaybe<ProfileStatus>;
    statusPeriod?: InputMaybe<Scalars['Int']>;
};

export type OfferUpdateManyMutationInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    bonus?: InputMaybe<Scalars['Int']>;
    coins?: InputMaybe<Scalars['Int']>;
    cost?: InputMaybe<Scalars['Float']>;
    currency?: InputMaybe<Scalars['String']>;
    extra?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    payed?: InputMaybe<Scalars['Boolean']>;
};

export type OfferWhereInput = {
    active?: InputMaybe<BoolFilter>;
    bonus?: InputMaybe<IntFilter>;
    coins?: InputMaybe<IntFilter>;
    cost?: InputMaybe<FloatFilter>;
    currency?: InputMaybe<StringFilter>;
    extra?: InputMaybe<StringFilter>;
    limit?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    offerId?: InputMaybe<StringFilter>;
    payed?: InputMaybe<BoolFilter>;
    project?: InputMaybe<ProjectWhereOnlyScalarInput>;
    projectName?: InputMaybe<StringFilter>;
    provider?: InputMaybe<ProviderWhereOnlyScalarInput>;
    providerName?: InputMaybe<StringFilter>;
};

export type OfferWhereOnlyScalarInput = {
    active?: InputMaybe<BoolFilter>;
    bonus?: InputMaybe<IntFilter>;
    coins?: InputMaybe<IntFilter>;
    cost?: InputMaybe<FloatFilter>;
    currency?: InputMaybe<StringFilter>;
    extra?: InputMaybe<StringFilter>;
    limit?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    offerId?: InputMaybe<StringFilter>;
    payed?: InputMaybe<BoolFilter>;
    projectName?: InputMaybe<StringFilter>;
    providerName?: InputMaybe<StringFilter>;
};

export type OfferWhereUniqueInput = {
    offerId: Scalars['String'];
};

export type OldTeamsWhereInput = {
    /** timestamp for default path */
    array_contains: Scalars['String'];
    path?: Scalars['String'];
};

export const OperatingSystem = {
    Android: 'ANDROID',
    Ios: 'IOS',
    Web: 'WEB',
} as const;

export type OperatingSystem = (typeof OperatingSystem)[keyof typeof OperatingSystem];
export type OptionPoll = {
    __typename: 'OptionPoll';
    answers: Array<OptionPollAnswer>;
    languageCode: Scalars['String'];
    name: Scalars['String'];
    optionPollId: Scalars['String'];
    position: Scalars['Int'];
    projectName: Scalars['String'];
    text: Scalars['String'];
};

export type OptionPollAnswer = {
    __typename: 'OptionPollAnswer';
    languageCode: Scalars['String'];
    optionPollAnswerId: Scalars['String'];
    optionPollId: Scalars['String'];
    path?: Maybe<Scalars['String']>;
    text: Scalars['String'];
    votes: Scalars['Int'];
};

export type OptionPollAnswerCreateNestedManyWithoutOptionPollInput = {
    create: Array<OptionPollAnswerCreateWithoutOptionPollInput>;
};

export type OptionPollAnswerCreateWithoutOptionPollInput = {
    languageCode: Scalars['String'];
    text: Scalars['String'];
};

export type OptionPollCreateInput = {
    answers: OptionPollAnswerCreateNestedManyWithoutOptionPollInput;
    languageCode: Scalars['String'];
    name: Scalars['String'];
    position: Scalars['Int'];
    text: Scalars['String'];
};

export type OwnerIdPositionCompoundUniqueInput = {
    ownerId: Scalars['String'];
    position: Scalars['Int'];
};

export type Pi_MPi_PNCompoundUniqueInput = {
    moderatedProfileId: Scalars['String'];
    poolId: Scalars['String'];
    projectName: Scalars['String'];
};

export type Pi_TPi_PNCompoundUniqueInput = {
    poolId: Scalars['String'];
    projectName: Scalars['String'];
    targetProfileId: Scalars['String'];
};

export type Pn_PI_MPiCompoundUniqueInput = {
    moderatedProfileId: Scalars['String'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
};

export const PeriodUnit = {
    Days: 'days',
    Hours: 'hours',
    Minutes: 'minutes',
    Months: 'months',
    Weeks: 'weeks',
} as const;

export type PeriodUnit = (typeof PeriodUnit)[keyof typeof PeriodUnit];
export const PeriodUnitOffer = {
    Days: 'days',
    Months: 'months',
    Weeks: 'weeks',
} as const;

export type PeriodUnitOffer = (typeof PeriodUnitOffer)[keyof typeof PeriodUnitOffer];
export const PeriodUnitPool = {
    Days: 'days',
    Hours: 'hours',
    Minutes: 'minutes',
} as const;

export type PeriodUnitPool = (typeof PeriodUnitPool)[keyof typeof PeriodUnitPool];
export type Picture = {
    __typename: 'Picture';
    fsk18: Scalars['Boolean'];
    owner: Profile;
    ownerId: Scalars['String'];
    path: Scalars['String'];
    pictureId: Scalars['String'];
    projectName: Scalars['String'];
    verified: Scalars['Boolean'];
};

export type PicturePathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type PictureCreateInput = {
    base64: Scalars['String'];
};

export const PictureDistinctFieldEnum = {
    Fsk18: 'fsk18',
    OwnerId: 'ownerId',
    Path: 'path',
    PictureId: 'pictureId',
    ProjectName: 'projectName',
} as const;

export type PictureDistinctFieldEnum = (typeof PictureDistinctFieldEnum)[keyof typeof PictureDistinctFieldEnum];
export type PictureIdProjectNameCompoundUniqueInput = {
    pictureId: Scalars['String'];
    projectName: Scalars['String'];
};

export type PictureListRelationFilter = {
    every?: InputMaybe<PictureWhereInput>;
    none?: InputMaybe<PictureWhereInput>;
    some?: InputMaybe<PictureWhereInput>;
};

export type PictureOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    fsk18?: InputMaybe<SortOrder>;
    ownerId?: InputMaybe<SortOrder>;
    path?: InputMaybe<SortOrder>;
    pictureId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    verified?: InputMaybe<SortOrder>;
};

export type PictureUpdateInput = {
    fsk18?: InputMaybe<Scalars['Boolean']>;
    verified?: InputMaybe<Scalars['Boolean']>;
};

export type PictureUpdateManyInput = {
    data: PictureUpdateInput;
    where: PictureWhereUniqueInput;
};

export type PictureUpdateOneWithoutXInput = {
    connect: PictureWhereUniqueInput;
};

export type PictureWhereInput = {
    acquisitions?: InputMaybe<AcquisitionListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    fsk18?: InputMaybe<BoolFilter>;
    owner?: InputMaybe<ProfileWhereInput>;
    ownerId?: InputMaybe<StringFilter>;
    path?: InputMaybe<StringFilter>;
    pictureId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    verified?: InputMaybe<BoolFilter>;
};

export type PictureWhereUniqueInput = {
    path?: InputMaybe<Scalars['String']>;
    pictureId_projectName?: InputMaybe<PictureIdProjectNameCompoundUniqueInput>;
};

export type Pool = {
    __typename: 'Pool';
    activeFrom: Scalars['String'];
    activeTo: Scalars['String'];
    count: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    creator: AdminUser;
    creatorId: Scalars['String'];
    fillPeriod?: Maybe<Scalars['Int']>;
    filledAt?: Maybe<Scalars['DateTime']>;
    isDynamic?: Maybe<Scalars['Boolean']>;
    moderationTeam?: Maybe<ModerationTeam>;
    moderationTeamId?: Maybe<Scalars['String']>;
    moderationUser?: Maybe<ModerationUser>;
    moderationUserId?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    periodUnit?: Maybe<PeriodUnitPool>;
    poolId: Scalars['String'];
    poolModerationUsers: Array<PoolModerationUser>;
    poolTeams: Array<PoolTeam>;
    priority: Scalars['Int'];
    projectName: Scalars['String'];
    sourceFilter?: Maybe<Scalars['String']>;
    sourceProfiles: Array<PoolSourceProfile>;
    targetFilter?: Maybe<Scalars['String']>;
    targetProfiles: Array<PoolTargetProfile>;
    type: ModerationType;
    updatedAt: Scalars['DateTime'];
};

export type PoolPoolModerationUsersArgs = {
    cursor?: InputMaybe<PoolModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<PoolModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<PoolModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolModerationUserWhereInput>;
};

export type PoolPoolTeamsArgs = {
    cursor?: InputMaybe<PoolTeamWhereUniqueInput>;
    distinct?: InputMaybe<PoolTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<PoolTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolTeamWhereInput>;
};

export type PoolSourceProfilesArgs = {
    cursor?: InputMaybe<PoolSourceProfileWhereUniqueInput>;
    distinct?: InputMaybe<PoolSourceProfileDistinctFieldEnum>;
    orderBy?: InputMaybe<PoolSourceProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolSourceProfileWhereInput>;
};

export type PoolTargetProfilesArgs = {
    cursor?: InputMaybe<PoolTargetProfileWhereUniqueInput>;
    distinct?: InputMaybe<PoolTargetProfileDistinctFieldEnum>;
    orderBy?: InputMaybe<PoolTargetProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type PoolCreateInput = {
    activeFrom: Scalars['String'];
    activeTo: Scalars['String'];
    count?: InputMaybe<Scalars['Int']>;
    fillPeriod?: InputMaybe<Scalars['Int']>;
    isDynamic?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    periodUnit?: InputMaybe<PeriodUnitPool>;
    poolModerationUsers?: InputMaybe<PoolModerationUserCreateManyWithoutPoolInput>;
    poolTeams?: InputMaybe<PoolTeamCreateManyWithoutPoolInput>;
    priority?: InputMaybe<Scalars['Int']>;
    project: ProjectWhereUniqueInput;
    sourceFilter?: InputMaybe<ModeratedProfileWhereInput>;
    sourceProfiles?: InputMaybe<PoolSourceProfileCreateManyWithoutPoolInput>;
    targetFilter?: InputMaybe<UserRelativeWhereInput>;
    targetProfiles?: InputMaybe<PoolTargetProfileCreateManyWithoutPoolInput>;
    type: ModerationType;
};

export type PoolCreateOneWithoutX = {
    connect: PoolWhereUniqueInput;
};

export type PoolIdModerationTeamIdCompoundUniqueInput = {
    moderationTeamId: Scalars['String'];
    poolId: Scalars['String'];
};

export type PoolListRelationFilter = {
    every?: InputMaybe<PoolWhereInput>;
    none?: InputMaybe<PoolWhereInput>;
    some?: InputMaybe<PoolWhereInput>;
};

export type PoolModerationUser = {
    __typename: 'PoolModerationUser';
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    pool: Pool;
    poolId: Scalars['String'];
    poolModerationUserId: Scalars['String'];
};

export type PoolModerationUserCreateInput = {
    moderationUser: ModerationUserWhereUniqueInput;
    pool: PoolWhereUniqueInput;
};

export type PoolModerationUserCreateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolModerationUserCreateWithoutPoolInput>>;
};

export type PoolModerationUserCreateWithoutModerationUserInput = {
    pool: PoolCreateOneWithoutX;
};

export type PoolModerationUserCreateWithoutPoolInput = {
    moderationUser: ModerationUserCreateOneWithoutX;
};

export const PoolModerationUserDistinctFieldEnum = {
    ModerationUserId: 'moderationUserId',
    PoolId: 'poolId',
    PoolModerationUserId: 'poolModerationUserId',
} as const;

export type PoolModerationUserDistinctFieldEnum = (typeof PoolModerationUserDistinctFieldEnum)[keyof typeof PoolModerationUserDistinctFieldEnum];
export type PoolModerationUserListRelationFilter = {
    every?: InputMaybe<PoolModerationUserWhereInput>;
    none?: InputMaybe<PoolModerationUserWhereInput>;
    some?: InputMaybe<PoolModerationUserWhereInput>;
};

export type PoolModerationUserOrderByInput = {
    moderationUserId?: InputMaybe<SortOrder>;
    poolId?: InputMaybe<SortOrder>;
};

export type PoolModerationUserScalarWhereInput = {
    moderationUserId?: InputMaybe<StringFilter>;
    poolId?: InputMaybe<StringFilter>;
    poolModerationUserId?: InputMaybe<StringFilter>;
};

export type PoolModerationUserUpdateManyWithoutModerationUserInput = {
    create?: InputMaybe<Array<PoolModerationUserCreateWithoutModerationUserInput>>;
    delete?: InputMaybe<Array<PoolModerationUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolModerationUserScalarWhereInput>>;
};

export type PoolModerationUserUpdateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolModerationUserCreateWithoutPoolInput>>;
    delete?: InputMaybe<Array<PoolModerationUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolModerationUserScalarWhereInput>>;
    set?: InputMaybe<Array<PoolModerationUserWhereUniqueInput>>;
};

export type PoolModerationUserWhereInput = {
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    pool?: InputMaybe<PoolWhereInput>;
    poolId?: InputMaybe<StringFilter>;
};

export type PoolModerationUserWhereUniqueInput = {
    moderationUserId_poolId: ModerationUserIdPoolIdCompoundUniqueInput;
};

export type PoolOrderByInput = {
    activeFrom?: InputMaybe<SortOrder>;
    activeTo?: InputMaybe<SortOrder>;
    count?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    moderationTeamId?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    poolId?: InputMaybe<SortOrder>;
    priority?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PoolSourceProfile = {
    __typename: 'PoolSourceProfile';
    moderatedProfile: ModeratedProfile;
    moderatedProfileId: Scalars['String'];
    pool: Pool;
    poolId: Scalars['String'];
    poolSourceProfileId: Scalars['String'];
    projectName: Scalars['String'];
};

export type PoolSourceProfileCreateInput = {
    moderatedProfile: ModeratedProfileWhereUniqueInput;
    pool: PoolWhereUniqueInput;
    project: ProjectWhereUniqueInput;
};

export type PoolSourceProfileCreateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolSourceProfileCreateWithoutPoolInput>>;
};

export type PoolSourceProfileCreateWithoutModeratedProfileInput = {
    pool: PoolCreateOneWithoutX;
    project: ProjectCreateOneWithoutX;
};

export type PoolSourceProfileCreateWithoutPoolInput = {
    moderatedProfile: ModeratedProfileCreateOneWithoutX;
    project: ProjectCreateOneWithoutX;
};

export const PoolSourceProfileDistinctFieldEnum = {
    ModeratedProfileId: 'moderatedProfileId',
    PoolId: 'poolId',
    PoolSourceProfileId: 'poolSourceProfileId',
    ProjectName: 'projectName',
} as const;

export type PoolSourceProfileDistinctFieldEnum = (typeof PoolSourceProfileDistinctFieldEnum)[keyof typeof PoolSourceProfileDistinctFieldEnum];
export type PoolSourceProfileListRelationFilter = {
    every?: InputMaybe<PoolSourceProfileWhereInput>;
    none?: InputMaybe<PoolSourceProfileWhereInput>;
    some?: InputMaybe<PoolSourceProfileWhereInput>;
};

export type PoolSourceProfileOrderByInput = {
    moderatedProfileId?: InputMaybe<SortOrder>;
    poolId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type PoolSourceProfileScalarWhereInput = {
    moderatedProfileId?: InputMaybe<StringFilter>;
    poolId?: InputMaybe<StringFilter>;
    poolSourceProfileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type PoolSourceProfileUpdateManyWithoutModeratedProfileInput = {
    create?: InputMaybe<Array<PoolSourceProfileCreateWithoutModeratedProfileInput>>;
    delete?: InputMaybe<Array<PoolSourceProfileWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolSourceProfileScalarWhereInput>>;
};

export type PoolSourceProfileUpdateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolSourceProfileCreateWithoutPoolInput>>;
    delete?: InputMaybe<Array<PoolSourceProfileWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolSourceProfileScalarWhereInput>>;
};

export type PoolSourceProfileWhereInput = {
    moderatedProfile?: InputMaybe<ModeratedProfileWhereInput>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    pool?: InputMaybe<PoolWhereInput>;
    poolId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type PoolSourceProfileWhereUniqueInput = {
    pI_mPI_pN?: InputMaybe<Pi_MPi_PNCompoundUniqueInput>;
    poolSourceProfileId?: InputMaybe<Scalars['String']>;
};

export type PoolTargetProfile = {
    __typename: 'PoolTargetProfile';
    pool: Pool;
    poolId: Scalars['String'];
    poolTargetProfileId: Scalars['String'];
    projectName: Scalars['String'];
    targetProfile: Profile;
    targetProfileId: Scalars['String'];
};

export type PoolTargetProfileCreateInput = {
    pool: PoolWhereUniqueInput;
    project: ProjectWhereUniqueInput;
    userProfile: ProfileWhereUniqueInput;
};

export type PoolTargetProfileCreateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolTargetProfileCreateWithoutPoolInput>>;
};

export type PoolTargetProfileCreateWithoutPoolInput = {
    poolTargetProfileId?: InputMaybe<Scalars['String']>;
    project: ProjectCreateOneWithoutX;
    targetProfile: ProfileCreateOneWithoutX;
};

export const PoolTargetProfileDistinctFieldEnum = {
    PoolId: 'poolId',
    PoolTargetProfileId: 'poolTargetProfileId',
    ProjectName: 'projectName',
    TargetProfileId: 'targetProfileId',
} as const;

export type PoolTargetProfileDistinctFieldEnum = (typeof PoolTargetProfileDistinctFieldEnum)[keyof typeof PoolTargetProfileDistinctFieldEnum];
export type PoolTargetProfileListRelationFilter = {
    every?: InputMaybe<PoolTargetProfileWhereInput>;
    none?: InputMaybe<PoolTargetProfileWhereInput>;
    some?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type PoolTargetProfileOrderByInput = {
    poolId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    targetProfileId?: InputMaybe<SortOrder>;
};

export type PoolTargetProfileScalarWhereInput = {
    poolId?: InputMaybe<StringFilter>;
    poolTargetProfileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    targetProfileId?: InputMaybe<StringFilter>;
};

export type PoolTargetProfileUpdateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolTargetProfileCreateWithoutPoolInput>>;
    delete?: InputMaybe<Array<PoolTargetProfileWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolTargetProfileScalarWhereInput>>;
};

export type PoolTargetProfileWhereInput = {
    pool?: InputMaybe<PoolWhereInput>;
    poolId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    targetProfile?: InputMaybe<ProfileWhereInput>;
    targetProfileId?: InputMaybe<StringFilter>;
};

export type PoolTargetProfileWhereUniqueInput = {
    pI_tPI_pN?: InputMaybe<Pi_TPi_PNCompoundUniqueInput>;
    poolTargetProfileId?: InputMaybe<Scalars['String']>;
};

export type PoolTeam = {
    __typename: 'PoolTeam';
    moderationTeam: ModerationTeam;
    moderationTeamId: Scalars['String'];
    pool: Pool;
    poolId: Scalars['String'];
    poolTeamId: Scalars['String'];
};

export type PoolTeamCreateManyWithoutPoolInput = {
    create: Array<PoolTeamCreateWithoutPoolInput>;
};

export type PoolTeamCreateWithoutPoolInput = {
    moderationTeam: ModerationTeamCreateOneWithoutX;
};

export const PoolTeamDistinctFieldEnum = {
    ModerationTeamId: 'moderationTeamId',
    PoolId: 'poolId',
    PoolTeamId: 'poolTeamId',
} as const;

export type PoolTeamDistinctFieldEnum = (typeof PoolTeamDistinctFieldEnum)[keyof typeof PoolTeamDistinctFieldEnum];
export type PoolTeamListRelationFilter = {
    every?: InputMaybe<PoolTeamWhereInput>;
    none?: InputMaybe<PoolTeamWhereInput>;
    some?: InputMaybe<PoolTeamWhereInput>;
};

export type PoolTeamOrderByInput = {
    moderationTeamId?: InputMaybe<SortOrder>;
    poolId?: InputMaybe<SortOrder>;
    poolTeamId?: InputMaybe<SortOrder>;
};

export type PoolTeamScalarWhereInput = {
    moderationTeamId?: InputMaybe<StringFilter>;
    poolId?: InputMaybe<StringFilter>;
    poolTeamId?: InputMaybe<StringFilter>;
};

export type PoolTeamUpdateManyWithoutPoolInput = {
    create?: InputMaybe<Array<PoolTeamCreateWithoutPoolInput>>;
    delete?: InputMaybe<Array<PoolTeamWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PoolTeamScalarWhereInput>>;
    set?: InputMaybe<Array<PoolTeamWhereUniqueInput>>;
};

export type PoolTeamWhereInput = {
    moderationTeam?: InputMaybe<ModerationTeamWhereInput>;
    moderationTeamId?: InputMaybe<StringFilter>;
    pool?: InputMaybe<PoolWhereInput>;
    poolId?: InputMaybe<StringFilter>;
    poolTeamId?: InputMaybe<StringFilter>;
};

export type PoolTeamWhereUniqueInput = {
    poolId_moderationTeamId: PoolIdModerationTeamIdCompoundUniqueInput;
};

export type PoolUpdateInput = {
    activeFrom?: InputMaybe<Scalars['String']>;
    activeTo?: InputMaybe<Scalars['String']>;
    fillPeriod?: InputMaybe<Scalars['Int']>;
    isDynamic?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    periodUnit?: InputMaybe<PeriodUnitPool>;
    poolModerationUsers?: InputMaybe<PoolModerationUserUpdateManyWithoutPoolInput>;
    poolTeams?: InputMaybe<PoolTeamUpdateManyWithoutPoolInput>;
    priority?: InputMaybe<Scalars['Int']>;
    sourceFilter?: InputMaybe<ModeratedProfileWhereInput>;
    sourceProfiles?: InputMaybe<PoolSourceProfileUpdateManyWithoutPoolInput>;
    targetFilter?: InputMaybe<UserRelativeWhereInput>;
    targetProfiles?: InputMaybe<PoolTargetProfileUpdateManyWithoutPoolInput>;
    type?: InputMaybe<ModerationType>;
};

export type PoolWhereInput = {
    activeFrom?: InputMaybe<StringFilter>;
    activeTo?: InputMaybe<StringFilter>;
    count?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<StringFilter>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    name?: InputMaybe<StringFilter>;
    poolModerationUsers?: InputMaybe<ModerationUserListRelationFilter>;
    poolTeams?: InputMaybe<ModerationTeamListRelationFilter>;
    priority?: InputMaybe<IntFilter>;
    projectName?: InputMaybe<StringFilter>;
    type?: InputMaybe<ModerationTypeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PoolWhereUniqueInput = {
    name_projectName?: InputMaybe<NameProjectNameCompoundUniqueInput>;
    poolId?: InputMaybe<Scalars['String']>;
};

export type PredefinedMessage = {
    __typename: 'PredefinedMessage';
    path?: Maybe<Scalars['String']>;
    predefinedMessageId: Scalars['String'];
    projectName: Scalars['String'];
    translations: Array<Translation>;
};

export type PredefinedMessageCreateInput = {
    base64Image?: InputMaybe<Scalars['String']>;
    projectName: Scalars['String'];
    translations?: InputMaybe<TranslationCreateNestedManyWithoutXInput>;
};

export type PredefinedMessageOrderByInput = {
    predefinedMessageId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type PredefinedMessageWhereInput = {
    predefinedMessageId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type PredefinedMessageWhereUniqueInput = {
    predefinedMessageId: Scalars['String'];
};

export const Privilege = {
    Acquisition: 'Acquisition',
    AdminLog: 'AdminLog',
    AdminRoleCreate: 'AdminRoleCreate',
    AdminRoleEdit: 'AdminRoleEdit',
    AdminUserCreate: 'AdminUserCreate',
    AdminUserEdit: 'AdminUserEdit',
    AffiliateEdit: 'AffiliateEdit',
    AffiliateShow: 'AffiliateShow',
    AgencyEdit: 'AgencyEdit',
    AgencyShow: 'AgencyShow',
    AwrEdit: 'AwrEdit',
    AwrShow: 'AwrShow',
    CouponEdit: 'CouponEdit',
    CouponShow: 'CouponShow',
    DialogShow: 'DialogShow',
    FieldEmail: 'FieldEmail',
    MatchEdit: 'MatchEdit',
    ModerationProfileShow: 'ModerationProfileShow',
    ModerationUserCreate: 'ModerationUserCreate',
    ModerationUserEdit: 'ModerationUserEdit',
    ModeratorStatisticShow: 'ModeratorStatisticShow',
    NoAgencyFilter: 'NoAgencyFilter',
    NoTeamFilter: 'NoTeamFilter',
    OfferEdit: 'OfferEdit',
    OfferEditExtended: 'OfferEditExtended',
    OfferShow: 'OfferShow',
    PoolEdit: 'PoolEdit',
    PoolShow: 'PoolShow',
    ProfileCreate: 'ProfileCreate',
    ProfileEdit: 'ProfileEdit',
    ProviderCreate: 'ProviderCreate',
    ProviderEdit: 'ProviderEdit',
    ProviderSettingEdit: 'ProviderSettingEdit',
    ProviderShow: 'ProviderShow',
    QueueEdit: 'QueueEdit',
    QueueEditBlocked: 'QueueEditBlocked',
    QueueShow: 'QueueShow',
    Scenario: 'Scenario',
    Settings: 'Settings',
    Support: 'Support',
    TeamEdit: 'TeamEdit',
    TeamShow: 'TeamShow',
    TransactionCreate: 'TransactionCreate',
    TransactionShow: 'TransactionShow',
    UserEdit: 'UserEdit',
    UserShow: 'UserShow',
    UserStatistic: 'UserStatistic',
    VerifyComplain: 'VerifyComplain',
    VerifyImage: 'VerifyImage',
    VerifyText: 'VerifyText',
} as const;

export type Privilege = (typeof Privilege)[keyof typeof Privilege];
export type PrivilegeAdminUserIdCompoundUniqueInput = {
    adminUserId: Scalars['String'];
    privilege: Privilege;
};

export type PrivilegeFilter = {
    equals?: InputMaybe<Privilege>;
    in?: InputMaybe<Array<Privilege>>;
    not?: InputMaybe<Privilege>;
    notIn?: InputMaybe<Array<Privilege>>;
};

export type Profile = {
    __typename: 'Profile';
    Queue: Array<Queue>;
    acceptPicture: Scalars['Boolean'];
    active: Scalars['Boolean'];
    adminChanged?: Maybe<Scalars['DateTime']>;
    adminText?: Maybe<Scalars['String']>;
    avatar?: Maybe<Avatar>;
    avatarPath?: Maybe<Scalars['String']>;
    birthDate?: Maybe<Scalars['String']>;
    completed: Scalars['Boolean'];
    conversationProfiles: Array<ConversationProfile>;
    count: ProfileCountOutputType;
    country?: Maybe<Scalars['String']>;
    county?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    defaultComment?: Maybe<Comment>;
    defaultText?: Maybe<Scalars['String']>;
    flirtText?: Maybe<Scalars['String']>;
    flirtTextVerified: Scalars['Boolean'];
    furtherInformation?: Maybe<Scalars['String']>;
    gender: Gender;
    hidden: Scalars['Boolean'];
    hideLocation: Scalars['Boolean'];
    images: Array<Image>;
    language?: Maybe<Language>;
    languageCode?: Maybe<Scalars['String']>;
    messages: Array<Message>;
    moderatedProfile?: Maybe<ModeratedProfile>;
    pictures: Array<Picture>;
    poolTargetProfiles: Array<PoolTargetProfile>;
    postcode?: Maybe<Scalars['String']>;
    premiumStatus: Array<ProfileStatus>;
    profileFlags: Array<ProfileFlag>;
    profileId: Scalars['String'];
    profileInterests: Array<ProfileInterest>;
    profileName: Scalars['String'];
    profileTextId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    searches: Gender;
    shownLocation?: Maybe<Scalars['String']>;
    systemComplainUsers: Array<SystemComplainUser>;
    theme?: Maybe<Theme>;
    themeName?: Maybe<Scalars['String']>;
    timezone: Scalars['String'];
    town?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    user?: Maybe<User>;
    userComplains: Array<UserComplain>;
    zodiacSign?: Maybe<Scalars['String']>;
};

export type ProfileQueueArgs = {
    cursor?: InputMaybe<QueueWhereUniqueInput>;
    distinct?: InputMaybe<QueueDistinctFieldEnum>;
    orderBy?: InputMaybe<QueueOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueWhereInput>;
};

export type ProfileConversationProfilesArgs = {
    cursor?: InputMaybe<ConversationProfileWhereUniqueInput>;
    distinct?: InputMaybe<ConversationProfileDistinctFieldEnum>;
    orderBy?: InputMaybe<ConversationProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationProfileWhereInput>;
};

export type ProfileImagesArgs = {
    cursor?: InputMaybe<ImageWhereUniqueInput>;
    distinct?: InputMaybe<ImageDistinctFieldEnum>;
    orderBy?: InputMaybe<ImageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ImageWhereInput>;
};

export type ProfileMessagesArgs = {
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    distinct?: InputMaybe<MessageDistinctFieldEnum>;
    orderBy?: InputMaybe<MessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MessageWhereInput>;
};

export type ProfilePicturesArgs = {
    cursor?: InputMaybe<PictureWhereUniqueInput>;
    distinct?: InputMaybe<PictureDistinctFieldEnum>;
    orderBy?: InputMaybe<PictureOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PictureWhereInput>;
};

export type ProfilePoolTargetProfilesArgs = {
    cursor?: InputMaybe<PoolTargetProfileWhereUniqueInput>;
    distinct?: InputMaybe<PoolTargetProfileDistinctFieldEnum>;
    orderBy?: InputMaybe<PoolTargetProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type ProfileProfileFlagsArgs = {
    cursor?: InputMaybe<ProfileFlagWhereUniqueInput>;
    distinct?: InputMaybe<ProfileFlagDistinctFieldEnum>;
    orderBy?: InputMaybe<ProfileFlagOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProfileFlagWhereInput>;
};

export type ProfileProfileInterestsArgs = {
    cursor?: InputMaybe<ProfileInterestWhereUniqueInput>;
    distinct?: InputMaybe<ProfileInterestDistinctFieldEnum>;
    orderBy?: InputMaybe<ProfileInterestOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProfileInterestWhereInput>;
};

export type ProfileSystemComplainUsersArgs = {
    cursor?: InputMaybe<SystemComplainUserWhereUniqueInput>;
    distinct?: InputMaybe<SystemComplainUserDistinctFieldEnum>;
    orderBy?: InputMaybe<SystemComplainUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SystemComplainUserWhereInput>;
};

export type ProfileUserComplainsArgs = {
    cursor?: InputMaybe<UserComplainWhereUniqueInput>;
    distinct?: InputMaybe<UserComplainDistinctFieldEnum>;
    orderBy?: InputMaybe<UserComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserComplainWhereInput>;
};

export type ProfileAvgAggregateOutputType = {
    __typename: 'ProfileAvgAggregateOutputType';
    age?: Maybe<Scalars['Float']>;
};

export type ProfileAvgSumAggregateInput = {
    age: Scalars['true'];
};

export type ProfileAvgSumOrderByAggregateInput = {
    age?: InputMaybe<SortOrder>;
};

export type ProfileCountAggregateInput = {
    _all?: InputMaybe<Scalars['true']>;
    acceptPicture?: InputMaybe<Scalars['true']>;
    active?: InputMaybe<Scalars['true']>;
    age?: InputMaybe<Scalars['true']>;
    avatarPath?: InputMaybe<Scalars['true']>;
    birthDate?: InputMaybe<Scalars['true']>;
    completed?: InputMaybe<Scalars['true']>;
    country?: InputMaybe<Scalars['true']>;
    county?: InputMaybe<Scalars['true']>;
    flirtTextVerified?: InputMaybe<Scalars['true']>;
    gender?: InputMaybe<Scalars['true']>;
    hidden?: InputMaybe<Scalars['true']>;
    hideLocation?: InputMaybe<Scalars['true']>;
    languageCode?: InputMaybe<Scalars['true']>;
    postcode?: InputMaybe<Scalars['true']>;
    profileTextId?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    searches?: InputMaybe<Scalars['true']>;
    shownLocation?: InputMaybe<Scalars['true']>;
    themeName?: InputMaybe<Scalars['true']>;
    timezone?: InputMaybe<Scalars['true']>;
    town?: InputMaybe<Scalars['true']>;
    zodiacSign?: InputMaybe<Scalars['true']>;
};

export type ProfileCountAggregateOutputType = {
    __typename: 'ProfileCountAggregateOutputType';
    _all?: Maybe<Scalars['Int']>;
    active?: Maybe<Scalars['Int']>;
    age?: Maybe<Scalars['Int']>;
    completed?: Maybe<Scalars['Int']>;
    country?: Maybe<Scalars['Int']>;
    county?: Maybe<Scalars['Int']>;
    gender?: Maybe<Scalars['Int']>;
    hidden?: Maybe<Scalars['Int']>;
    languageCode?: Maybe<Scalars['Int']>;
    postcode?: Maybe<Scalars['Int']>;
    profileName?: Maybe<Scalars['Int']>;
    projectName?: Maybe<Scalars['Int']>;
    searches?: Maybe<Scalars['Int']>;
    shownLocation?: Maybe<Scalars['Int']>;
    timezone?: Maybe<Scalars['Int']>;
    town?: Maybe<Scalars['Int']>;
    zodiacSign?: Maybe<Scalars['Int']>;
};

export type ProfileCountMaxMinOrderByAggregateInput = {
    acceptPicture?: InputMaybe<SortOrder>;
    active?: InputMaybe<SortOrder>;
    adminChanged?: InputMaybe<SortOrder>;
    adminText?: InputMaybe<SortOrder>;
    age?: InputMaybe<SortOrder>;
    avatarPath?: InputMaybe<SortOrder>;
    birthDate?: InputMaybe<SortOrder>;
    completed?: InputMaybe<SortOrder>;
    country?: InputMaybe<SortOrder>;
    county?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    flirtText?: InputMaybe<SortOrder>;
    flirtTextVerified?: InputMaybe<SortOrder>;
    furtherInformation?: InputMaybe<SortOrder>;
    gender?: InputMaybe<SortOrder>;
    hidden?: InputMaybe<SortOrder>;
    hideLocation?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    postcode?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    profileName?: InputMaybe<SortOrder>;
    profileTextId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    searches?: InputMaybe<SortOrder>;
    shownLocation?: InputMaybe<SortOrder>;
    themeName?: InputMaybe<SortOrder>;
    timezone?: InputMaybe<SortOrder>;
    town?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    zodiacSign?: InputMaybe<SortOrder>;
};

export type ProfileCountOutputType = {
    __typename: 'ProfileCountOutputType';
    comments: Scalars['Int'];
    conversationProfiles: Scalars['Int'];
    friendByProfiles: Scalars['Int'];
    friends: Scalars['Int'];
    images: Scalars['Int'];
    messages: Scalars['Int'];
    ownMatches: Scalars['Int'];
    pictures: Scalars['Int'];
    playedMatches: Scalars['Int'];
    poolTargetProfiles: Scalars['Int'];
    profileFlags: Scalars['Int'];
    profileInterests: Scalars['Int'];
    queues: Scalars['Int'];
    receivedPictures: Scalars['Int'];
    systemComplainUsers: Scalars['Int'];
    userComplains: Scalars['Int'];
};

export type ProfileCreateOneWithoutModeratedProfileInput = {
    connect?: InputMaybe<ProfileWhereUniqueInput>;
    create?: InputMaybe<ProfileCreateWithoutModeratedProfileInput>;
};

export type ProfileCreateOneWithoutX = {
    connect: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutModeratedProfileInput = {
    acceptPicture?: InputMaybe<Scalars['Boolean']>;
    adminText?: InputMaybe<Scalars['String']>;
    avatar?: InputMaybe<AvatarCreateNestedOneWithoutProfilesInput>;
    birthDate: Scalars['String'];
    completed?: InputMaybe<Scalars['Boolean']>;
    country: Scalars['String'];
    county?: InputMaybe<Scalars['String']>;
    flirtText?: InputMaybe<Scalars['String']>;
    furtherInformation?: InputMaybe<Scalars['String']>;
    gender: Gender;
    hidden?: InputMaybe<Scalars['Boolean']>;
    hideLocation?: InputMaybe<Scalars['Boolean']>;
    languageCode: Scalars['String'];
    postcode?: InputMaybe<Scalars['String']>;
    profileFlags?: InputMaybe<ProfileFlagCreateNestedManyWithoutProfileInput>;
    profileInterests?: InputMaybe<ProfileInterestCreateManyWithoutProfileInput>;
    profileName: Scalars['String'];
    profileText?: InputMaybe<ProfileTextUpdateOneWithoutProfilesNestedInput>;
    searches: Gender;
    shownLocation?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Array<ProfileStatus>>;
    timezone: Scalars['String'];
    town?: InputMaybe<Scalars['String']>;
};

export type ProfileFlag = {
    __typename: 'ProfileFlag';
    createdAt: Scalars['DateTime'];
    flag: Flag;
    flagId: Scalars['String'];
    flagValue?: Maybe<FlagValue>;
    flagValueId?: Maybe<Scalars['String']>;
    profile: Profile;
    profileFlagId: Scalars['String'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type ProfileFlagCreateNestedManyWithoutProfileInput = {
    create: Array<ProfileFlagCreateWithoutProfileInput>;
};

export type ProfileFlagCreateWithoutProfileInput = {
    flag: FlagCreateNestedOneWithoutProfileFlagsInput;
    flagValue?: InputMaybe<FlagValueCreateNestedOneWithoutProfileFlagsInput>;
    projectName: Scalars['String'];
    value?: InputMaybe<Scalars['String']>;
};

export const ProfileFlagDistinctFieldEnum = {
    CreatedAt: 'createdAt',
    FlagId: 'flagId',
    ProfileFlagId: 'profileFlagId',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
} as const;

export type ProfileFlagDistinctFieldEnum = (typeof ProfileFlagDistinctFieldEnum)[keyof typeof ProfileFlagDistinctFieldEnum];
export type ProfileFlagListRelationFilter = {
    every?: InputMaybe<ProfileFlagWhereOnlyScalarInput>;
    none?: InputMaybe<ProfileFlagWhereOnlyScalarInput>;
    some?: InputMaybe<ProfileFlagWhereOnlyScalarInput>;
};

export type ProfileFlagOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    flagId?: InputMaybe<SortOrder>;
    profileFlagId?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ProfileFlagScalarWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<StringFilter>;
    flagId?: InputMaybe<StringFilter>;
    profileFlagId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProfileFlagUpdateManyWithoutProfileNestedInput = {
    create?: InputMaybe<Array<ProfileFlagCreateWithoutProfileInput>>;
    delete?: InputMaybe<Array<ProfileFlagWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ProfileFlagScalarWhereInput>>;
    update?: InputMaybe<Array<ProfileFlagUpdateWithWhereUniqueWithoutProfileInput>>;
};

export type ProfileFlagUpdateWithWhereUniqueWithoutProfileInput = {
    data: ProfileFlagUpdateWithoutProfileInput;
    where: ProfileFlagWhereUniqueInput;
};

export type ProfileFlagUpdateWithoutProfileInput = {
    flagValue?: InputMaybe<FlagValueUpdateOneWithoutProfileFlagsNestedInput>;
    value?: InputMaybe<Scalars['String']>;
};

export type ProfileFlagWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creator?: InputMaybe<AdminUserWhereInput>;
    creatorId?: InputMaybe<StringFilter>;
    flag?: InputMaybe<FlagWhereInput>;
    flagId?: InputMaybe<StringFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileFlagId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProfileFlagWhereOnlyScalarInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<StringFilter>;
    flagId?: InputMaybe<StringFilter>;
    profileFlagId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProfileFlagWhereUniqueInput = {
    profileFlagId: Scalars['String'];
};

export type ProfileIdInterestNameCompoundUniqueInput = {
    interestName: Scalars['String'];
    profileId: Scalars['String'];
};

export type ProfileIdProjectNameCompoundUniqueInput = {
    profileId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ProfileInterest = {
    __typename: 'ProfileInterest';
    interest: Interest;
    interestName: Scalars['String'];
    profileId: Scalars['String'];
    profileInterestId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ProfileInterestCreateManyWithoutProfileInput = {
    create?: InputMaybe<Array<ProfileInterestCreateWithoutProfileInput>>;
};

export type ProfileInterestCreateWithoutProfileInput = {
    interest: InterestCreateOneWithoutProfileInterestsInput;
    project: ProjectCreateOneWithoutX;
};

export const ProfileInterestDistinctFieldEnum = {
    InterestName: 'interestName',
    ProfileId: 'profileId',
    ProfileInterestId: 'profileInterestId',
    ProjectName: 'projectName',
} as const;

export type ProfileInterestDistinctFieldEnum = (typeof ProfileInterestDistinctFieldEnum)[keyof typeof ProfileInterestDistinctFieldEnum];
export type ProfileInterestIdProjectNameCompoundUniqueInput = {
    profileInterestId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ProfileInterestListRelationFilter = {
    every?: InputMaybe<ProfileInterestWhereInput>;
    none?: InputMaybe<ProfileInterestWhereInput>;
    some?: InputMaybe<ProfileInterestWhereInput>;
};

export type ProfileInterestOrderByInput = {
    interestId?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    profileInterestId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
};

export type ProfileInterestScalarWhereInput = {
    interestId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringFilter>;
    profileInterestId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProfileInterestUpdateManyWithoutProfileInput = {
    create?: InputMaybe<Array<ProfileInterestCreateWithoutProfileInput>>;
    delete?: InputMaybe<Array<ProfileInterestWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ProfileInterestScalarWhereInput>>;
};

export type ProfileInterestWhereInput = {
    interest?: InputMaybe<InterestWhereInput>;
    interestName?: InputMaybe<StringFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringFilter>;
    profileInterestId?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProfileInterestWhereUniqueInput = {
    profileId_interestName: ProfileIdInterestNameCompoundUniqueInput;
};

export type ProfileListRelationFilter = {
    every?: InputMaybe<ProfileWhereInput>;
    none?: InputMaybe<ProfileWhereInput>;
    some?: InputMaybe<ProfileWhereInput>;
};

export type ProfileMaxMinAggregateInput = {
    adminChanged?: InputMaybe<Scalars['true']>;
    age?: InputMaybe<Scalars['true']>;
    birthDate?: InputMaybe<Scalars['true']>;
    createdAt?: InputMaybe<Scalars['true']>;
    updatedAt?: InputMaybe<Scalars['true']>;
};

export type ProfileMaxMinAggregateOutputType = {
    __typename: 'ProfileMaxMinAggregateOutputType';
    age?: Maybe<Scalars['Int']>;
};

export type ProfileOrderByInput = {
    acceptPicture?: InputMaybe<SortOrder>;
    adminChanged?: InputMaybe<SortOrder>;
    adminText?: InputMaybe<SortOrder>;
    birthDate?: InputMaybe<SortOrder>;
    completed?: InputMaybe<SortOrder>;
    country?: InputMaybe<SortOrder>;
    county?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    flirtText?: InputMaybe<SortOrder>;
    furtherInformation?: InputMaybe<SortOrder>;
    gender?: InputMaybe<SortOrder>;
    hidden?: InputMaybe<SortOrder>;
    hideLocation?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    postcode?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    profileName?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    searches?: InputMaybe<SortOrder>;
    shownLocation?: InputMaybe<SortOrder>;
    themeName?: InputMaybe<SortOrder>;
    town?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ProfileOrderByWithAggregationInput = {
    _avg?: InputMaybe<ProfileAvgSumOrderByAggregateInput>;
    _count?: InputMaybe<ProfileCountMaxMinOrderByAggregateInput>;
    _max?: InputMaybe<ProfileCountMaxMinOrderByAggregateInput>;
    _min?: InputMaybe<ProfileCountMaxMinOrderByAggregateInput>;
    _sum?: InputMaybe<ProfileAvgSumOrderByAggregateInput>;
    acceptPicture?: InputMaybe<SortOrder>;
    active?: InputMaybe<SortOrder>;
    adminChanged?: InputMaybe<SortOrder>;
    adminText?: InputMaybe<SortOrder>;
    age?: InputMaybe<SortOrder>;
    avatarPath?: InputMaybe<SortOrder>;
    birthDate?: InputMaybe<SortOrder>;
    completed?: InputMaybe<SortOrder>;
    country?: InputMaybe<SortOrder>;
    county?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    flirtText?: InputMaybe<SortOrder>;
    flirtTextVerified?: InputMaybe<SortOrder>;
    furtherInformation?: InputMaybe<SortOrder>;
    gender?: InputMaybe<SortOrder>;
    hidden?: InputMaybe<SortOrder>;
    hideLocation?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    postcode?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    profileName?: InputMaybe<SortOrder>;
    profileTextId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    searches?: InputMaybe<SortOrder>;
    shownLocation?: InputMaybe<SortOrder>;
    themeName?: InputMaybe<SortOrder>;
    timezone?: InputMaybe<SortOrder>;
    town?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    zodiacSign?: InputMaybe<SortOrder>;
};

export type ProfileResult = InputErrors | Profile;

export const ProfileScalarFieldEnum = {
    Active: 'active',
    Age: 'age',
    Completed: 'completed',
    Country: 'country',
    County: 'county',
    Gender: 'gender',
    Hidden: 'hidden',
    LanguageCode: 'languageCode',
    Postcode: 'postcode',
    ProfileName: 'profileName',
    ProjectName: 'projectName',
    Searches: 'searches',
    ShownLocation: 'shownLocation',
    Timezone: 'timezone',
    Town: 'town',
    ZodiacSign: 'zodiacSign',
} as const;

export type ProfileScalarFieldEnum = (typeof ProfileScalarFieldEnum)[keyof typeof ProfileScalarFieldEnum];
export type ProfileScalarWhereInput = {
    acceptPicture?: InputMaybe<BoolFilter>;
    active?: InputMaybe<BoolFilter>;
    adminChanged?: InputMaybe<DateTimeNullableFilter>;
    adminText?: InputMaybe<StringNullableFilter>;
    birthDate?: InputMaybe<StringNullableFilter>;
    completed?: InputMaybe<BoolFilter>;
    country?: InputMaybe<StringNullableFilter>;
    county?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    flirtText?: InputMaybe<StringNullableFilter>;
    flirtTextVerified?: InputMaybe<BoolFilter>;
    furtherInformation?: InputMaybe<StringNullableFilter>;
    gender?: InputMaybe<EnumGenderFilter>;
    hidden?: InputMaybe<BoolFilter>;
    hideLocation?: InputMaybe<BoolFilter>;
    languageCode?: InputMaybe<StringNullableFilter>;
    moderatedProfileId?: InputMaybe<StringNullableFilter>;
    postcode?: InputMaybe<StringNullableFilter>;
    profileId?: InputMaybe<StringFilter>;
    profileName?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    searches?: InputMaybe<EnumGenderFilter>;
    shownLocation?: InputMaybe<StringNullableFilter>;
    themeName?: InputMaybe<StringNullableFilter>;
    town?: InputMaybe<StringNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProfileScalarWhereWithAggregatesInput = {
    active?: InputMaybe<BoolWithAggregatesFilter>;
    age?: InputMaybe<IntNullableWithAggregatesFilter>;
    birthDate?: InputMaybe<StringNullableWithAggregatesFilter>;
    completed?: InputMaybe<BoolWithAggregatesFilter>;
    country?: InputMaybe<StringNullableWithAggregatesFilter>;
    county?: InputMaybe<StringNullableWithAggregatesFilter>;
    gender?: InputMaybe<NestedEnumGenderWithAggregatesFilter>;
    hidden?: InputMaybe<BoolWithAggregatesFilter>;
    languageCode?: InputMaybe<StringWithAggregatesFilter>;
    postcode?: InputMaybe<StringNullableWithAggregatesFilter>;
    profileId?: InputMaybe<StringWithAggregatesFilter>;
    profileName?: InputMaybe<StringWithAggregatesFilter>;
    projectName?: InputMaybe<StringWithAggregatesFilter>;
    searches?: InputMaybe<NestedEnumGenderWithAggregatesFilter>;
    shownLocation?: InputMaybe<StringNullableWithAggregatesFilter>;
    timezone?: InputMaybe<StringWithAggregatesFilter>;
    town?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export const ProfileStatus = {
    Premium: 'Premium',
} as const;

export type ProfileStatus = (typeof ProfileStatus)[keyof typeof ProfileStatus];
export type ProfileSumAggregateOutputType = {
    __typename: 'ProfileSumAggregateOutputType';
    age?: Maybe<Scalars['Int']>;
};

export type ProfileText = {
    __typename: 'ProfileText';
    profileTextId: Scalars['String'];
    projectName: Scalars['String'];
    translation: Translation;
    translations: Array<Translation>;
};

export type ProfileTextTranslationArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
};

export type ProfileTextCreateInput = {
    projectName: Scalars['String'];
    translations: TranslationCreateNestedManyWithoutXInput;
};

export type ProfileTextUpdateOneWithoutProfilesNestedInput = {
    connect: ProfileTextWhereUniqueInput;
};

export type ProfileTextWhereUniqueInput = {
    profileTextId: Scalars['String'];
};

export type ProfileUpdateInput = {
    acceptPicture?: InputMaybe<Scalars['Boolean']>;
    adminText?: InputMaybe<Scalars['String']>;
    avatar?: InputMaybe<AvatarUpdateOneWithoutProfilesNestedInput>;
    birthDate?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    county?: InputMaybe<Scalars['String']>;
    defaultText?: InputMaybe<ProfileTextUpdateOneWithoutProfilesNestedInput>;
    flirtText?: InputMaybe<Scalars['String']>;
    flirtTextVerified?: InputMaybe<Scalars['Boolean']>;
    furtherInformation?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    hidden?: InputMaybe<Scalars['Boolean']>;
    hideLocation?: InputMaybe<Scalars['Boolean']>;
    postcode?: InputMaybe<Scalars['String']>;
    profileFlags?: InputMaybe<ProfileFlagUpdateManyWithoutProfileNestedInput>;
    profileInterests?: InputMaybe<ProfileInterestUpdateManyWithoutProfileInput>;
    profileName?: InputMaybe<Scalars['String']>;
    searches?: InputMaybe<Gender>;
    status?: InputMaybe<Array<ProfileStatus>>;
    timezone?: InputMaybe<Scalars['String']>;
    town?: InputMaybe<Scalars['String']>;
};

export type ProfileWhereInput = {
    AND?: InputMaybe<Array<ProfileScalarWhereInput>>;
    OR?: InputMaybe<Array<ProfileScalarWhereInput>>;
    acceptPicture?: InputMaybe<BoolFilter>;
    active?: InputMaybe<BoolFilter>;
    adminChanged?: InputMaybe<DateTimeNullableFilter>;
    adminText?: InputMaybe<StringNullableFilter>;
    age?: InputMaybe<AgeFilter>;
    birthDate?: InputMaybe<StringNullableFilter>;
    completed?: InputMaybe<BoolFilter>;
    conversationProfiles?: InputMaybe<ConversationProfileListRelationFilter>;
    country?: InputMaybe<StringNullableFilter>;
    county?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    flirtText?: InputMaybe<StringNullableFilter>;
    flirtTextVerified?: InputMaybe<BoolFilter>;
    furtherInformation?: InputMaybe<StringNullableFilter>;
    gender?: InputMaybe<EnumGenderFilter>;
    hidden?: InputMaybe<BoolFilter>;
    hideLocation?: InputMaybe<BoolFilter>;
    images?: InputMaybe<ImageWithoutProfileListRelationFilter>;
    languageCode?: InputMaybe<StringNullableFilter>;
    moderatedProfile?: InputMaybe<ModeratedProfileWhereInput>;
    postcode?: InputMaybe<StringNullableFilter>;
    profileFlags?: InputMaybe<ProfileFlagListRelationFilter>;
    profileId?: InputMaybe<StringFilter>;
    profileName?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    receivedPictures?: InputMaybe<PictureListRelationFilter>;
    searches?: InputMaybe<EnumGenderFilter>;
    shownLocation?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<JsonNullableArrayFilter>;
    systemComplainUsers?: InputMaybe<SystemComplainUserListRelationFilter>;
    themeName?: InputMaybe<StringNullableFilter>;
    town?: InputMaybe<StringNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    user?: InputMaybe<UserWhereInput>;
};

export type ProfileWhereUniqueInput = {
    profileId_projectName: ProfileIdProjectNameCompoundUniqueInput;
};

export type Project = {
    __typename: 'Project';
    config: Config;
    languages: Array<Language>;
    name: Scalars['String'];
};

export type ProjectCreateOneWithoutX = {
    connect: ProjectWhereUniqueInput;
};

export type ProjectListRelationFilter = {
    every?: InputMaybe<ProjectWhereInput>;
    none?: InputMaybe<ProjectWhereInput>;
    some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectNameInput = {
    projectName: Scalars['String'];
};

export type ProjectNameModerationUserIdCompoundUniqueInput = {
    moderationUserId: Scalars['String'];
    projectName: Scalars['String'];
};

export type ProjectOrderByInput = {
    name?: InputMaybe<SortOrder>;
};

export type ProjectStatistic = {
    __typename: 'ProjectStatistic';
    activations?: Maybe<Scalars['Int']>;
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    date: Scalars['String'];
    id: Scalars['String'];
    payments?: Maybe<Scalars['Int']>;
    projectName: Scalars['String'];
    projectStatisticExtended: Array<ProjectStatisticExtended>;
    registrations?: Maybe<Scalars['Int']>;
};

export type ProjectStatisticExtended = {
    __typename: 'ProjectStatisticExtended';
    activations: Scalars['Int'];
    gender: Gender;
    payments: Scalars['Int'];
    registrations: Scalars['Int'];
    turnover: Scalars['Int'];
};

export type ProjectStatisticOrderByInput = {
    activations?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    bonusIns?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ins?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    payments?: InputMaybe<SortOrder>;
    registrations?: InputMaybe<SortOrder>;
    turnover?: InputMaybe<SortOrder>;
};

export type ProjectStatisticWhereInput = {
    date?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
};

export type ProjectStatisticWhereUniqueInput = {
    id: Scalars['String'];
};

export type ProjectWhereInput = {
    name?: InputMaybe<StringFilter>;
};

export type ProjectWhereOnlyScalarInput = {
    name?: InputMaybe<StringFilter>;
};

export type ProjectWhereUniqueInput = {
    name: Scalars['String'];
};

export type Provider = {
    __typename: 'Provider';
    active: Scalars['Boolean'];
    currency: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    hidden: Scalars['Boolean'];
    limitTransaction: Scalars['Int'];
    logo: Scalars['String'];
    name: Scalars['String'];
    offers: Array<Offer>;
    os: OperatingSystem;
    projectName: Scalars['String'];
    providerId: Scalars['String'];
    settings: Array<ProviderSetting>;
    title: Scalars['String'];
};

export type ProviderOffersArgs = {
    cursor?: InputMaybe<OfferWhereUniqueInput>;
    distinct?: InputMaybe<OfferDistinctFieldEnum>;
    orderBy?: InputMaybe<OfferOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<OfferWhereInput>;
};

export type ProviderSettingsArgs = {
    cursor?: InputMaybe<ProviderSettingWhereUniqueInput>;
    distinct?: InputMaybe<ProviderSettingDistinctFieldEnum>;
    orderBy?: InputMaybe<ProviderSettingOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProviderSettingWhereInput>;
};

export type ProviderCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    currency: Scalars['String'];
    description?: InputMaybe<Scalars['String']>;
    hidden?: InputMaybe<Scalars['Boolean']>;
    limitTransaction?: InputMaybe<Scalars['Int']>;
    logo: Scalars['String'];
    name: Scalars['String'];
    os?: InputMaybe<OperatingSystem>;
    projectName: Scalars['String'];
    settings?: InputMaybe<Array<ProviderSettingCreateWithoutProviderInput>>;
    title: Scalars['String'];
};

export type ProviderIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    providerId: Scalars['String'];
};

export type ProviderOrderByInput = {
    aProviderId?: InputMaybe<SortOrder>;
    active?: InputMaybe<SortOrder>;
    currency?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    hidden?: InputMaybe<SortOrder>;
    limitTransaction?: InputMaybe<SortOrder>;
    logo?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerId?: InputMaybe<SortOrder>;
    title?: InputMaybe<SortOrder>;
};

export type ProviderSetting = {
    __typename: 'ProviderSetting';
    name: Scalars['String'];
    provider: Provider;
    providerId: Scalars['String'];
    providerSettingId: Scalars['String'];
    value: Scalars['String'];
};

export type ProviderSettingCreateInput = {
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
    provider: ProviderWhereUniqueInput;
    value: Scalars['String'];
};

export type ProviderSettingCreateWithoutProviderInput = {
    name: Scalars['String'];
    value: Scalars['String'];
};

export const ProviderSettingDistinctFieldEnum = {
    Name: 'name',
    ProviderId: 'providerId',
    ProviderSettingId: 'providerSettingId',
    Value: 'value',
} as const;

export type ProviderSettingDistinctFieldEnum = (typeof ProviderSettingDistinctFieldEnum)[keyof typeof ProviderSettingDistinctFieldEnum];
export type ProviderSettingIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    providerSettingId: Scalars['String'];
};

export type ProviderSettingListRelationFilter = {
    every?: InputMaybe<ProviderSettingWhereInput>;
    none?: InputMaybe<ProviderSettingWhereInput>;
    some?: InputMaybe<ProviderSettingWhereInput>;
};

export type ProviderSettingOrderByInput = {
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerId?: InputMaybe<SortOrder>;
    providerSettingId?: InputMaybe<SortOrder>;
    value?: InputMaybe<SortOrder>;
};

export type ProviderSettingUpdateInput = {
    value?: InputMaybe<Scalars['String']>;
};

export type ProviderSettingUpdateManyMutationInput = {
    value?: InputMaybe<Scalars['String']>;
};

export type ProviderSettingWhereInput = {
    name?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    provider?: InputMaybe<ProviderWhereInput>;
    providerId?: InputMaybe<StringFilter>;
    providerSettingId?: InputMaybe<StringFilter>;
    value?: InputMaybe<StringFilter>;
};

export type ProviderSettingWhereUniqueInput = {
    projectName: Scalars['String'];
    providerSettingId: Scalars['String'];
};

export type ProviderUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    currency?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    hidden?: InputMaybe<Scalars['Boolean']>;
    limitTransaction?: InputMaybe<Scalars['Int']>;
    logo?: InputMaybe<Scalars['String']>;
    os?: InputMaybe<OperatingSystem>;
    title?: InputMaybe<Scalars['String']>;
};

export type ProviderUpdateManyMutationInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    currency?: InputMaybe<Scalars['String']>;
    hidden?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderWhereInput = {
    aProviderId?: InputMaybe<StringFilter>;
    active?: InputMaybe<BoolNullableFilter>;
    currency?: InputMaybe<StringFilter>;
    description?: InputMaybe<StringNullableFilter>;
    hidden?: InputMaybe<BoolNullableFilter>;
    limitTransaction?: InputMaybe<IntNullableFilter>;
    logo?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    offers?: InputMaybe<OfferListRelationFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    providerId?: InputMaybe<StringFilter>;
    settings?: InputMaybe<ProviderSettingListRelationFilter>;
    title?: InputMaybe<StringFilter>;
    transactions?: InputMaybe<TransactionListRelationFilter>;
};

export type ProviderWhereOnlyScalarInput = {
    aProviderId?: InputMaybe<StringFilter>;
    active?: InputMaybe<BoolNullableFilter>;
    currency?: InputMaybe<StringFilter>;
    description?: InputMaybe<StringNullableFilter>;
    hidden?: InputMaybe<BoolNullableFilter>;
    limitTransaction?: InputMaybe<IntNullableFilter>;
    logo?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    providerId?: InputMaybe<StringFilter>;
    title?: InputMaybe<StringFilter>;
};

export type ProviderWhereUniqueInput = {
    projectName: Scalars['String'];
    providerId: Scalars['String'];
};

export type Query = {
    __typename: 'Query';
    ErrorCodes?: Maybe<Array<ErrorCode>>;
    adminUserId?: Maybe<Scalars['String']>;
    exportManyTransaction: TransactionExport;
    exportManyTransactionVAT: TransactionExport;
    exportManyUser: UserExport;
    findManyAWR: Array<AutomaticWriteRequest>;
    findManyAcquisition: Array<Acquisition>;
    findManyAcquisitionCount?: Maybe<Scalars['Int']>;
    findManyAdminActivity: Array<AdminActivity>;
    findManyAdminActivityCount?: Maybe<Scalars['Int']>;
    findManyAdminLog: Array<AdminLog>;
    findManyAdminLogCount: Scalars['Int'];
    findManyAdminRole: Array<AdminRole>;
    findManyAdminRoleCount?: Maybe<Scalars['Int']>;
    findManyAdminRolePrivilege?: Maybe<Array<AdminRolePrivilege>>;
    findManyAdminRolePrivilegeCount?: Maybe<Scalars['Int']>;
    findManyAdminSession: Array<AdminSession>;
    findManyAdminSession24: Array<AdminSession24>;
    findManyAdminSession24Count: Scalars['Int'];
    findManyAdminSessionCount: Scalars['Int'];
    findManyAdminSessionGroupBy: Array<AdminSessionGroupByResult>;
    findManyAdminText?: Maybe<Array<AdminText>>;
    findManyAdminTextCount?: Maybe<Scalars['Int']>;
    findManyAdminUser: Array<AdminUser>;
    findManyAdminUserCount?: Maybe<Scalars['Int']>;
    findManyAdminUserPrivilege?: Maybe<Array<AdminUserPrivilege>>;
    findManyAdminUserPrivilegeCount?: Maybe<Scalars['Int']>;
    findManyAdminUserSetting?: Maybe<Array<AdminUserSetting>>;
    findManyAdminUserText?: Maybe<Array<AdminUserText>>;
    findManyAdminUserTextCount?: Maybe<Scalars['Int']>;
    findManyAffiliateUser?: Maybe<Array<AffiliateUser>>;
    findManyAffiliateUserCount?: Maybe<Scalars['Int']>;
    findManyAgency: Array<Agency>;
    findManyAgencyCount: Scalars['Int'];
    findManyAgencyHourStatistic?: Maybe<Array<AgencyHourStatistic>>;
    findManyAgencyHourStatisticCount?: Maybe<Scalars['Int']>;
    findManyAgencyProject?: Maybe<Array<AgencyProject>>;
    findManyAgencyProjectCount?: Maybe<Scalars['Int']>;
    findManyAgencyProjectHourStatistic?: Maybe<Array<AgencyProjectHourStatistic>>;
    findManyAgencyProjectHourStatisticCount?: Maybe<Scalars['Int']>;
    findManyAgencyProjectStatistic?: Maybe<Array<AgencyProjectStatistic>>;
    findManyAgencyProjectStatisticCount?: Maybe<Scalars['Int']>;
    findManyAgencyStatistic?: Maybe<Array<AgencyStatistic>>;
    findManyAgencyStatisticCount?: Maybe<Scalars['Int']>;
    findManyAvatar: Array<Avatar>;
    findManyCoinsHistory?: Maybe<Array<CoinsHistory>>;
    findManyCoinsHistoryCount?: Maybe<Scalars['Int']>;
    findManyCommentCommentField?: Maybe<Array<CommentCommentField>>;
    findManyCommentCommentFieldCount?: Maybe<Scalars['Int']>;
    findManyCommentField?: Maybe<Array<CommentField>>;
    findManyCommentFieldCount?: Maybe<Scalars['Int']>;
    findManyCommentLog?: Maybe<Array<CommentLog>>;
    findManyComplainType?: Maybe<Array<ComplainType>>;
    findManyComplainTypeCount?: Maybe<Scalars['Int']>;
    findManyConversation?: Maybe<Array<Conversation>>;
    findManyConversationCount?: Maybe<Scalars['Int']>;
    findManyCoupon?: Maybe<Array<Coupon>>;
    findManyCouponCount?: Maybe<Scalars['Int']>;
    findManyDefaultComment: Array<DefaultComment>;
    findManyFeatures: Array<Feature>;
    findManyFlag: Array<Flag>;
    findManyGenderProject?: Maybe<Array<GenderProject>>;
    findManyGeolocation: Array<GeolocationFindManyByRangeResult>;
    findManyGift?: Maybe<Array<Gift>>;
    findManyGiftCategory?: Maybe<Array<GiftCategory>>;
    findManyImage?: Maybe<Array<Image>>;
    findManyImageCount?: Maybe<Scalars['Int']>;
    findManyInterest?: Maybe<Array<Interest>>;
    findManyInterestCategory?: Maybe<Array<InterestCategory>>;
    findManyLanguage?: Maybe<Array<Language>>;
    findManyMessage: Array<Message>;
    findManyMessageBadword: Array<MessageBadword>;
    findManyMessageBadwordCount: Scalars['Int'];
    findManyMessageCount?: Maybe<Scalars['Int']>;
    findManyModeratedProfile: Array<ModeratedProfile>;
    findManyModeratedProfileCount: Scalars['Int'];
    findManyModeratedProfileModerationUser?: Maybe<Array<ModeratedProfileModerationUser>>;
    findManyModeratedProfileModerationUserCount?: Maybe<Scalars['Int']>;
    findManyModeratedProfileTeam?: Maybe<Array<ModeratedProfileTeam>>;
    findManyModeratedProfileTeamCount?: Maybe<Scalars['Int']>;
    findManyModerationChat?: Maybe<Array<ModerationChat>>;
    findManyModerationChatCount?: Maybe<Scalars['Int']>;
    findManyModerationMessage?: Maybe<Array<ModerationMessage>>;
    findManyModerationMessageCount?: Maybe<Scalars['Int']>;
    findManyModerationSession?: Maybe<Array<ModerationSession>>;
    findManyModerationSessionCount?: Maybe<Scalars['Int']>;
    findManyModerationTeam: Array<ModerationTeam>;
    findManyModerationTeamCount: Scalars['Int'];
    findManyModerationUser: Array<ModerationUser>;
    findManyModerationUserCount: Scalars['Int'];
    findManyModerationUserPrivilege?: Maybe<Array<ModerationUserPrivilege>>;
    findManyModerationUserPrivilegeCount?: Maybe<Scalars['Int']>;
    findManyModeratorComplain: Array<ModeratorComplain>;
    findManyModeratorComplainCount: Scalars['Int'];
    findManyModeratorHourStatistic?: Maybe<Array<ModeratorHourStatisticFindManyResult>>;
    findManyModeratorHourStatisticAggregate?: Maybe<ModeratorHourStatisticAggregateResult>;
    findManyModeratorHourStatisticCount?: Maybe<Scalars['Int']>;
    findManyModeratorHourStatisticGroupBy?: Maybe<Array<ModeratorHourStatisticFindManyGroupByResult>>;
    findManyModeratorStatistic: Array<ModeratorStatisticFindManyResult>;
    findManyModeratorStatisticAggregate: ModeratorStatisticAggregateResult;
    findManyModeratorStatisticCount: Scalars['Int'];
    findManyModeratorStatisticGroupBy: Array<ModeratorStatisticFindManyGroupByResult>;
    findManyModeratorUserHourStatistic?: Maybe<Array<ModeratorUserHourStatistic>>;
    findManyModeratorUserHourStatisticCount?: Maybe<Scalars['Int']>;
    findManyModeratorUserStatistic?: Maybe<Array<ModeratorUserStatistic>>;
    findManyModeratorUserStatisticCount?: Maybe<Scalars['Int']>;
    findManyNotificationLog?: Maybe<Array<NotificationLog>>;
    findManyNotificationLogCount?: Maybe<Scalars['Int']>;
    findManyOffer?: Maybe<Array<Offer>>;
    findManyOfferCount?: Maybe<Scalars['Int']>;
    findManyOptionPoll?: Maybe<Array<OptionPoll>>;
    findManyPicture: Array<Picture>;
    findManyPictureCount: Scalars['Int'];
    findManyPool: Array<Pool>;
    findManyPoolCount: Scalars['Int'];
    findManyPoolModerationUser?: Maybe<Array<PoolModerationUser>>;
    findManyPoolModerationUserCount?: Maybe<Scalars['Int']>;
    findManyPoolSourceProfile: Array<PoolSourceProfile>;
    findManyPoolSourceProfileCount: Scalars['Int'];
    findManyPoolTargetProfile: Array<PoolTargetProfile>;
    findManyPoolTargetProfileCount: Scalars['Int'];
    findManyPredefinedMessage: Array<PredefinedMessage>;
    findManyProfile: Array<Profile>;
    findManyProfileCount: Scalars['Int'];
    findManyProfileText: Array<ProfileText>;
    findManyProject: Array<Project>;
    findManyProjectStatistic: Array<ProjectStatistic>;
    findManyProvider: Array<Provider>;
    findManyProviderCount?: Maybe<Scalars['Int']>;
    findManyProviderSetting?: Maybe<Array<ProviderSetting>>;
    findManyProviderSettingCount?: Maybe<Scalars['Int']>;
    findManyQueue?: Maybe<Array<Queue>>;
    findManyQueueCount?: Maybe<Scalars['Int']>;
    findManyQueueGroupBy: Array<QueueGroupBy>;
    findManyQueueTeam?: Maybe<Array<QueueTeam>>;
    findManyQueueTypePriority: Array<QueueTypePriority>;
    findManyScenario: Array<Scenario>;
    findManyScenarioCategory: Array<ScenarioCategory>;
    findManyScenarioStatistics: Array<ScenarioStatistic>;
    findManySupportMessage?: Maybe<Array<SupportMessage>>;
    findManySupportText?: Maybe<Array<SupportText>>;
    findManySupportTextAdmin?: Maybe<Array<SupportTextAdmin>>;
    findManySupportTextAdminCount?: Maybe<Scalars['Int']>;
    findManySupportTextCategory?: Maybe<Array<SupportTextCategory>>;
    findManySupportTextCategoryCount?: Maybe<Scalars['Int']>;
    findManySupportTextCount?: Maybe<Scalars['Int']>;
    findManySupportTicket?: Maybe<Array<SupportTicket>>;
    findManySupportTicketCount?: Maybe<Scalars['Int']>;
    findManySystemComplain?: Maybe<Array<SystemComplain>>;
    findManySystemComplainCount?: Maybe<Scalars['Int']>;
    findManyTheme?: Maybe<Array<Theme>>;
    findManyThemeImage?: Maybe<Array<ThemeImage>>;
    findManyTransaction: Array<Transaction>;
    findManyTransactionAggregate?: Maybe<TransactionAggregateResult>;
    findManyTransactionCount: Scalars['Int'];
    findManyTransactionGroupBy: Array<TransactionFindManyGroupByResult>;
    findManyTranslation?: Maybe<Array<Translation>>;
    findManyTranslationCount?: Maybe<Scalars['Int']>;
    findManyUser: Array<User>;
    findManyUserAggregate: AggregateUser;
    findManyUserComplain: Array<UserComplain>;
    findManyUserComplainCount: Scalars['Int'];
    findManyUserCount: Scalars['Int'];
    findManyUserCountEmailProvider: Array<UserCountEmailProvider>;
    findManyUserCountSocial: Array<UserCountSocial>;
    findManyUserCoupon?: Maybe<Array<UserCoupon>>;
    findManyUserCouponCount?: Maybe<Scalars['Int']>;
    findManyUserStatistic?: Maybe<Array<UserStatistic>>;
    findManyUserStatisticAggregate?: Maybe<AggregateUserStatistic>;
    findManyUserStatisticCount?: Maybe<Scalars['Int']>;
    findManyUserStatisticGroupBy?: Maybe<Array<UserStatisticGroupBy>>;
    findManyUsernameBadword: Array<UsernameBadword>;
    findManyUsernameBadwordCount: Scalars['Int'];
    findMySupportTicket?: Maybe<Array<SupportTicket>>;
    findOneAcquisition?: Maybe<Acquisition>;
    findOneAdminRole?: Maybe<AdminRole>;
    findOneAdminRolePrivilege?: Maybe<AdminRolePrivilege>;
    findOneAdminText?: Maybe<AdminText>;
    findOneAdminUser?: Maybe<AdminUser>;
    findOneAdminUserText?: Maybe<AdminUserText>;
    findOneAffiliateUser?: Maybe<AffiliateUser>;
    findOneAgency?: Maybe<Agency>;
    findOneAgencyProject?: Maybe<AgencyProject>;
    findOneComment?: Maybe<Comment>;
    findOneCommentField?: Maybe<CommentField>;
    findOneCommentLog?: Maybe<CommentLog>;
    findOneComplainType?: Maybe<ComplainType>;
    findOneConversation?: Maybe<Conversation>;
    findOneCoupon?: Maybe<Coupon>;
    findOneGift?: Maybe<Gift>;
    findOneInterest?: Maybe<Interest>;
    findOneInterestCategory?: Maybe<InterestCategory>;
    findOneLanguage?: Maybe<Language>;
    findOneMessage?: Maybe<Message>;
    findOneModeratedProfile: ModeratedProfile;
    findOneModerationUser?: Maybe<ModerationUser>;
    findOneModeratorUserHourStatistic?: Maybe<ModeratorUserHourStatistic>;
    findOneModeratorUserStatistic?: Maybe<ModeratorUserStatistic>;
    findOneOffer?: Maybe<Offer>;
    findOnePicture: Picture;
    findOnePool?: Maybe<Pool>;
    findOnePredefinedMessage?: Maybe<PredefinedMessage>;
    findOneProfile?: Maybe<Profile>;
    findOneProject?: Maybe<Project>;
    findOneProvider?: Maybe<Provider>;
    findOneProviderSetting?: Maybe<ProviderSetting>;
    findOneScenario: Scenario;
    findOneSupportText?: Maybe<SupportText>;
    findOneSupportTextAdmin?: Maybe<SupportTextAdmin>;
    findOneSupportTextCategory?: Maybe<SupportTextCategory>;
    findOneSupportTicket?: Maybe<SupportTicket>;
    findOneSystemComplain?: Maybe<SystemComplain>;
    findOneTheme?: Maybe<Theme>;
    findOneTransaction?: Maybe<Transaction>;
    findOneTranslation?: Maybe<Translation>;
    findOneUser?: Maybe<User>;
    findOneUserComplain: UserComplain;
    findUniqueMatchConfig: MatchConfig;
    getEnums: AllEnum;
    legacyUsers: Array<LegacyUser>;
    legacyUsersCount: Scalars['Int'];
    moderationMessageAggregate?: Maybe<AggregateModerationMessage>;
    moderationMessageGroupBy?: Maybe<AggregateModerationMessage>;
    privileges?: Maybe<Array<Maybe<Scalars['String']>>>;
    profileAggregate?: Maybe<AggregateProfile>;
    profileGroupBy?: Maybe<Array<CountProfile>>;
};

export type QueryExportManyTransactionArgs = {
    orderBy?: InputMaybe<TransactionOrderByInput>;
    where?: InputMaybe<TransactionWhereInput>;
};

export type QueryExportManyTransactionVatArgs = {
    orderBy?: InputMaybe<TransactionOrderByInput>;
    where?: InputMaybe<TransactionWhereInput>;
};

export type QueryExportManyUserArgs = {
    orderBy?: InputMaybe<UserOrderByInput>;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyAwrArgs = {
    where: AwrWhereInput;
};

export type QueryFindManyAcquisitionArgs = {
    cursor?: InputMaybe<AcquisitionWhereUniqueInput>;
    orderBy?: InputMaybe<AcquisitionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AcquisitionWhereInput>;
};

export type QueryFindManyAcquisitionCountArgs = {
    cursor?: InputMaybe<AcquisitionWhereUniqueInput>;
    orderBy?: InputMaybe<AcquisitionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AcquisitionWhereInput>;
};

export type QueryFindManyAdminActivityArgs = {
    cursor?: InputMaybe<AdminActivityWhereUniqueInput>;
    orderBy?: InputMaybe<AdminActivityOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminActivityWhereInput>;
};

export type QueryFindManyAdminActivityCountArgs = {
    cursor?: InputMaybe<AdminActivityWhereUniqueInput>;
    orderBy?: InputMaybe<AdminActivityOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: AdminActivityWhereInput;
};

export type QueryFindManyAdminLogArgs = {
    cursor?: InputMaybe<AdminLogWhereUniqueInput>;
    orderBy?: InputMaybe<AdminLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminLogWhereInput>;
};

export type QueryFindManyAdminLogCountArgs = {
    cursor?: InputMaybe<AdminLogWhereUniqueInput>;
    orderBy?: InputMaybe<AdminLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: AdminLogWhereInput;
};

export type QueryFindManyAdminRoleArgs = {
    cursor?: InputMaybe<AdminRoleWhereUniqueInput>;
    orderBy?: InputMaybe<AdminRoleOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminRoleWhereInput>;
};

export type QueryFindManyAdminRoleCountArgs = {
    cursor?: InputMaybe<AdminRoleWhereUniqueInput>;
    orderBy?: InputMaybe<AdminRoleOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminRoleWhereInput>;
};

export type QueryFindManyAdminRolePrivilegeArgs = {
    cursor?: InputMaybe<AdminRolePrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<AdminRolePrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminRolePrivilegeWhereInput>;
};

export type QueryFindManyAdminRolePrivilegeCountArgs = {
    cursor?: InputMaybe<AdminRolePrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<AdminRolePrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminRolePrivilegeWhereInput>;
};

export type QueryFindManyAdminSessionArgs = {
    cursor?: InputMaybe<AdminSessionWhereUniqueInput>;
    orderBy?: InputMaybe<AdminSessionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminSessionWhereInput>;
};

export type QueryFindManyAdminSession24Args = {
    cursor?: InputMaybe<AdminSession24WhereUniqueInput>;
    orderBy?: InputMaybe<AdminSession24OrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminSession24WhereInput>;
};

export type QueryFindManyAdminSession24CountArgs = {
    cursor?: InputMaybe<AdminSession24WhereUniqueInput>;
    orderBy?: InputMaybe<AdminSession24OrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: AdminSession24WhereInput;
};

export type QueryFindManyAdminSessionCountArgs = {
    cursor?: InputMaybe<AdminSessionWhereUniqueInput>;
    orderBy?: InputMaybe<AdminSessionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: AdminSessionWhereInput;
};

export type QueryFindManyAdminSessionGroupByArgs = {
    _count?: InputMaybe<AdminSessionCountAggregateInputType>;
    _sum?: InputMaybe<AdminSessionSumAggregateInputType>;
    by: Array<AdminSessionScalarFieldEnum>;
    orderBy: Array<AdminSessionOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminSessionWhereInput>;
};

export type QueryFindManyAdminTextArgs = {
    cursor?: InputMaybe<AdminTextWhereUniqueInput>;
    orderBy?: InputMaybe<AdminTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminTextWhereInput>;
};

export type QueryFindManyAdminTextCountArgs = {
    cursor?: InputMaybe<AdminTextWhereUniqueInput>;
    orderBy?: InputMaybe<AdminTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminTextWhereInput>;
};

export type QueryFindManyAdminUserArgs = {
    cursor?: InputMaybe<AdminUserWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserWhereInput>;
};

export type QueryFindManyAdminUserCountArgs = {
    cursor?: InputMaybe<AdminUserWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserWhereInput>;
};

export type QueryFindManyAdminUserPrivilegeArgs = {
    cursor?: InputMaybe<AdminUserPrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserPrivilegeWhereInput>;
};

export type QueryFindManyAdminUserPrivilegeCountArgs = {
    cursor?: InputMaybe<AdminUserPrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserPrivilegeWhereInput>;
};

export type QueryFindManyAdminUserSettingArgs = {
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserSettingWhereInput>;
};

export type QueryFindManyAdminUserTextArgs = {
    cursor?: InputMaybe<AdminUserTextWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserTextWhereInput>;
};

export type QueryFindManyAdminUserTextCountArgs = {
    cursor?: InputMaybe<AdminUserTextWhereUniqueInput>;
    orderBy?: InputMaybe<AdminUserTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserTextWhereInput>;
};

export type QueryFindManyAffiliateUserArgs = {
    cursor?: InputMaybe<AffiliateUserWhereUniqueInput>;
    orderBy?: InputMaybe<AffiliateUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AffiliateUserWhereInput>;
};

export type QueryFindManyAffiliateUserCountArgs = {
    cursor?: InputMaybe<AffiliateUserWhereUniqueInput>;
    orderBy?: InputMaybe<AffiliateUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AffiliateUserWhereInput>;
};

export type QueryFindManyAgencyArgs = {
    cursor?: InputMaybe<AgencyWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyWhereInput>;
};

export type QueryFindManyAgencyCountArgs = {
    cursor?: InputMaybe<AgencyWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyWhereInput>;
};

export type QueryFindManyAgencyHourStatisticArgs = {
    cursor?: InputMaybe<AgencyHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyHourStatisticWhereInput>;
};

export type QueryFindManyAgencyHourStatisticCountArgs = {
    cursor?: InputMaybe<AgencyHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyHourStatisticWhereInput>;
};

export type QueryFindManyAgencyProjectArgs = {
    cursor?: InputMaybe<AgencyProjectWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectWhereInput>;
};

export type QueryFindManyAgencyProjectCountArgs = {
    cursor?: InputMaybe<AgencyProjectWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectWhereInput>;
};

export type QueryFindManyAgencyProjectHourStatisticArgs = {
    cursor?: InputMaybe<AgencyProjectHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectHourStatisticWhereInput>;
};

export type QueryFindManyAgencyProjectHourStatisticCountArgs = {
    cursor?: InputMaybe<AgencyProjectHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectHourStatisticWhereInput>;
};

export type QueryFindManyAgencyProjectStatisticArgs = {
    cursor?: InputMaybe<AgencyProjectStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectStatisticWhereInput>;
};

export type QueryFindManyAgencyProjectStatisticCountArgs = {
    cursor?: InputMaybe<AgencyProjectStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyProjectStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyProjectStatisticWhereInput>;
};

export type QueryFindManyAgencyStatisticArgs = {
    cursor?: InputMaybe<AgencyStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyStatisticWhereInput>;
};

export type QueryFindManyAgencyStatisticCountArgs = {
    cursor?: InputMaybe<AgencyStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<AgencyStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AgencyStatisticWhereInput>;
};

export type QueryFindManyAvatarArgs = {
    where?: InputMaybe<AvatarWhereInput>;
};

export type QueryFindManyCoinsHistoryArgs = {
    cursor?: InputMaybe<CoinsHistoryWhereUniqueInput>;
    orderBy?: InputMaybe<CoinsHistoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CoinsHistoryWhereInput>;
};

export type QueryFindManyCoinsHistoryCountArgs = {
    cursor?: InputMaybe<CoinsHistoryWhereUniqueInput>;
    orderBy?: InputMaybe<CoinsHistoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CoinsHistoryWhereInput>;
};

export type QueryFindManyCommentCommentFieldArgs = {
    cursor?: InputMaybe<CommentCommentFieldWhereUniqueInput>;
    orderBy?: InputMaybe<CommentCommentFieldOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentCommentFieldWhereInput>;
};

export type QueryFindManyCommentCommentFieldCountArgs = {
    cursor?: InputMaybe<CommentCommentFieldWhereUniqueInput>;
    orderBy?: InputMaybe<CommentCommentFieldOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentCommentFieldWhereInput>;
};

export type QueryFindManyCommentFieldArgs = {
    cursor?: InputMaybe<CommentFieldWhereUniqueInput>;
    orderBy?: InputMaybe<CommentFieldOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentFieldWhereInput>;
};

export type QueryFindManyCommentFieldCountArgs = {
    cursor?: InputMaybe<CommentFieldWhereUniqueInput>;
    orderBy?: InputMaybe<CommentFieldOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentFieldWhereInput>;
};

export type QueryFindManyCommentLogArgs = {
    cursor?: InputMaybe<CommentLogWhereUniqueInput>;
    orderBy?: InputMaybe<CommentLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CommentLogWhereInput>;
};

export type QueryFindManyComplainTypeArgs = {
    cursor?: InputMaybe<ComplainTypeWhereUniqueInput>;
    orderBy?: InputMaybe<ComplainTypeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ComplainTypeWhereInput>;
};

export type QueryFindManyComplainTypeCountArgs = {
    cursor?: InputMaybe<ComplainTypeWhereUniqueInput>;
    orderBy?: InputMaybe<ComplainTypeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ComplainTypeWhereInput>;
};

export type QueryFindManyConversationArgs = {
    cursor?: InputMaybe<ConversationWhereUniqueInput>;
    orderBy?: InputMaybe<ConversationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationWhereInput>;
};

export type QueryFindManyConversationCountArgs = {
    cursor?: InputMaybe<ConversationWhereUniqueInput>;
    orderBy?: InputMaybe<ConversationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ConversationWhereInput>;
};

export type QueryFindManyCouponArgs = {
    cursor?: InputMaybe<CouponWhereUniqueInput>;
    orderBy?: InputMaybe<CouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CouponWhereInput>;
};

export type QueryFindManyCouponCountArgs = {
    cursor?: InputMaybe<CouponWhereUniqueInput>;
    orderBy?: InputMaybe<CouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CouponWhereInput>;
};

export type QueryFindManyDefaultCommentArgs = {
    cursor?: InputMaybe<DefaultCommentWhereUniqueInput>;
    orderBy?: InputMaybe<DefaultCommentOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<DefaultCommentWhereInput>;
};

export type QueryFindManyFlagArgs = {
    projectName?: InputMaybe<Scalars['String']>;
};

export type QueryFindManyGenderProjectArgs = {
    cursor?: InputMaybe<GenderProjectWhereUniqueInput>;
    orderBy?: InputMaybe<GenderProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<GenderProjectWhereInput>;
};

export type QueryFindManyGeolocationArgs = {
    orderBy?: InputMaybe<GeolocationOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: GeolocationWhereInput;
};

export type QueryFindManyGiftArgs = {
    cursor?: InputMaybe<GiftWhereUniqueInput>;
    languageCode?: InputMaybe<Scalars['String']>;
    orderBy?: InputMaybe<GiftOrderByInput>;
    projectName?: InputMaybe<Scalars['String']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<GiftWhereInput>;
};

export type QueryFindManyGiftCategoryArgs = {
    cursor?: InputMaybe<GiftCategoryWhereUniqueInput>;
    orderBy?: InputMaybe<GiftCategoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<GiftCategoryWhereInput>;
};

export type QueryFindManyImageArgs = {
    cursor?: InputMaybe<ImageWhereUniqueInput>;
    orderBy?: InputMaybe<ImageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ImageWhereInput>;
};

export type QueryFindManyImageCountArgs = {
    cursor?: InputMaybe<ImageWhereUniqueInput>;
    orderBy?: InputMaybe<ImageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ImageWhereInput>;
};

export type QueryFindManyInterestArgs = {
    cursor?: InputMaybe<InterestWhereUniqueInput>;
    orderBy?: InputMaybe<InterestOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<InterestWhereInput>;
};

export type QueryFindManyInterestCategoryArgs = {
    cursor?: InputMaybe<InterestCategoryWhereUniqueInput>;
    languageCode?: InputMaybe<Scalars['String']>;
    orderBy?: InputMaybe<InterestCategoryOrderByInput>;
    projectName?: InputMaybe<Scalars['String']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<InterestCategoryWhereInput>;
};

export type QueryFindManyLanguageArgs = {
    cursor?: InputMaybe<LanguageWhereUniqueInput>;
    orderBy?: InputMaybe<LanguageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<LanguageWhereInput>;
};

export type QueryFindManyMessageArgs = {
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    orderBy?: InputMaybe<MessageOrderByInput>;
    showAcquisition?: InputMaybe<Scalars['Boolean']>;
    showScenario?: InputMaybe<Scalars['Boolean']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MessageWhereInput>;
};

export type QueryFindManyMessageBadwordArgs = {
    cursor?: InputMaybe<MessageBadwordWhereUniqueInput>;
    orderBy?: InputMaybe<MessageBadwordOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MessageBadwordWhereInput>;
};

export type QueryFindManyMessageBadwordCountArgs = {
    cursor?: InputMaybe<MessageBadwordWhereUniqueInput>;
    orderBy?: InputMaybe<MessageBadwordOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: MessageBadwordWhereInput;
};

export type QueryFindManyMessageCountArgs = {
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    orderBy?: InputMaybe<MessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<MessageWhereInput>;
};

export type QueryFindManyModeratedProfileArgs = {
    cursor?: InputMaybe<ModeratedProfileWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileWhereInput>;
};

export type QueryFindManyModeratedProfileCountArgs = {
    cursor?: InputMaybe<ModeratedProfileWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileWhereInput>;
};

export type QueryFindManyModeratedProfileModerationUserArgs = {
    cursor?: InputMaybe<ModeratedProfileModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
};

export type QueryFindManyModeratedProfileModerationUserCountArgs = {
    cursor?: InputMaybe<ModeratedProfileModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileModerationUserWhereInput>;
};

export type QueryFindManyModeratedProfileTeamArgs = {
    cursor?: InputMaybe<ModeratedProfileTeamWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileTeamWhereInput>;
};

export type QueryFindManyModeratedProfileTeamCountArgs = {
    cursor?: InputMaybe<ModeratedProfileTeamWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratedProfileTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratedProfileTeamWhereInput>;
};

export type QueryFindManyModerationChatArgs = {
    cursor?: InputMaybe<ModerationChatWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationChatOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationChatWhereInput>;
};

export type QueryFindManyModerationChatCountArgs = {
    cursor?: InputMaybe<ModerationChatWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationChatOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationChatWhereInput>;
};

export type QueryFindManyModerationMessageArgs = {
    cursor?: InputMaybe<ModerationMessageWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationMessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationMessageWhereInput>;
};

export type QueryFindManyModerationMessageCountArgs = {
    cursor?: InputMaybe<ModerationMessageWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationMessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationMessageWhereInput>;
};

export type QueryFindManyModerationSessionArgs = {
    cursor?: InputMaybe<ModerationSessionWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationSessionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationSessionWhereInput>;
};

export type QueryFindManyModerationSessionCountArgs = {
    cursor?: InputMaybe<ModerationSessionWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationSessionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationSessionWhereInput>;
};

export type QueryFindManyModerationTeamArgs = {
    cursor?: InputMaybe<ModerationTeamWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationTeamWhereInput>;
};

export type QueryFindManyModerationTeamCountArgs = {
    cursor?: InputMaybe<ModerationTeamWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationTeamWhereInput>;
};

export type QueryFindManyModerationUserArgs = {
    cursor?: InputMaybe<ModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserWhereInput>;
};

export type QueryFindManyModerationUserCountArgs = {
    cursor?: InputMaybe<ModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserWhereInput>;
};

export type QueryFindManyModerationUserPrivilegeArgs = {
    cursor?: InputMaybe<ModerationUserPrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserPrivilegeWhereInput>;
};

export type QueryFindManyModerationUserPrivilegeCountArgs = {
    cursor?: InputMaybe<ModerationUserPrivilegeWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationUserPrivilegeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationUserPrivilegeWhereInput>;
};

export type QueryFindManyModeratorComplainArgs = {
    cursor?: InputMaybe<ModeratorComplainWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorComplainWhereInput>;
};

export type QueryFindManyModeratorComplainCountArgs = {
    cursor?: InputMaybe<ModeratorComplainWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorComplainWhereInput>;
};

export type QueryFindManyModeratorHourStatisticArgs = {
    cursor?: InputMaybe<ModeratorHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorHourStatisticWhereInput>;
};

export type QueryFindManyModeratorHourStatisticAggregateArgs = {
    aggregate: ModeratorHourStatisticAggregateArgs;
};

export type QueryFindManyModeratorHourStatisticCountArgs = {
    cursor?: InputMaybe<ModeratorHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorHourStatisticWhereInput>;
};

export type QueryFindManyModeratorHourStatisticGroupByArgs = {
    _avg?: InputMaybe<ModeratorHourStatisticAvgAggregateInputType>;
    _count?: InputMaybe<ModeratorHourStatisticCountAggregateInputType>;
    _max?: InputMaybe<ModeratorHourStatisticMaxAggregateInputType>;
    _min?: InputMaybe<ModeratorHourStatisticMinAggregateInputType>;
    _sum?: InputMaybe<ModeratorHourStatisticSumAggregateInputType>;
    by: Array<ModeratorHourStatisticScalarFieldEnum>;
    orderBy: Array<ModeratorHourStatisticOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorHourStatisticWhereInputWithoutReference>;
};

export type QueryFindManyModeratorStatisticArgs = {
    cursor?: InputMaybe<ModeratorStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorStatisticWhereInput>;
};

export type QueryFindManyModeratorStatisticAggregateArgs = {
    aggregate: ModeratorStatisticAggregateArgs;
};

export type QueryFindManyModeratorStatisticCountArgs = {
    cursor?: InputMaybe<ModeratorStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorStatisticWhereInput>;
};

export type QueryFindManyModeratorStatisticGroupByArgs = {
    _avg?: InputMaybe<ModeratorStatisticAvgAggregateInputType>;
    _count?: InputMaybe<ModeratorStatisticCountAggregateInputType>;
    _max?: InputMaybe<ModeratorStatisticMaxAggregateInputType>;
    _min?: InputMaybe<ModeratorStatisticMinAggregateInputType>;
    _sum?: InputMaybe<ModeratorStatisticSumAggregateInputType>;
    by: Array<ModeratorStatisticScalarFieldEnum>;
    orderBy: Array<ModeratorStatisticOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorStatisticWhereInputWithoutReference>;
};

export type QueryFindManyModeratorUserHourStatisticArgs = {
    cursor?: InputMaybe<ModeratorUserHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorUserHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorUserHourStatisticWhereInput>;
};

export type QueryFindManyModeratorUserHourStatisticCountArgs = {
    cursor?: InputMaybe<ModeratorUserHourStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorUserHourStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorUserHourStatisticWhereInput>;
};

export type QueryFindManyModeratorUserStatisticArgs = {
    cursor?: InputMaybe<ModeratorUserStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorUserStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorUserStatisticWhereInput>;
};

export type QueryFindManyModeratorUserStatisticCountArgs = {
    cursor?: InputMaybe<ModeratorUserStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ModeratorUserStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModeratorUserStatisticWhereInput>;
};

export type QueryFindManyNotificationLogArgs = {
    cursor?: InputMaybe<NotificationLogWhereUniqueInput>;
    orderBy?: InputMaybe<NotificationLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<NotificationLogWhereInput>;
};

export type QueryFindManyNotificationLogCountArgs = {
    cursor?: InputMaybe<NotificationLogWhereUniqueInput>;
    orderBy?: InputMaybe<NotificationLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<NotificationLogWhereInput>;
};

export type QueryFindManyOfferArgs = {
    cursor?: InputMaybe<OfferWhereUniqueInput>;
    orderBy?: InputMaybe<OfferOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<OfferWhereInput>;
};

export type QueryFindManyOfferCountArgs = {
    cursor?: InputMaybe<OfferWhereUniqueInput>;
    orderBy?: InputMaybe<OfferOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<OfferWhereInput>;
};

export type QueryFindManyOptionPollArgs = {
    projectName: Scalars['String'];
};

export type QueryFindManyPictureArgs = {
    cursor?: InputMaybe<PictureWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PictureOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PictureWhereInput>;
};

export type QueryFindManyPictureCountArgs = {
    cursor?: InputMaybe<PictureWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PictureOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PictureWhereInput>;
};

export type QueryFindManyPoolArgs = {
    cursor?: InputMaybe<PoolWhereUniqueInput>;
    orderBy?: InputMaybe<PoolOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolWhereInput>;
};

export type QueryFindManyPoolCountArgs = {
    cursor?: InputMaybe<PoolWhereUniqueInput>;
    orderBy?: InputMaybe<PoolOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolWhereInput>;
};

export type QueryFindManyPoolModerationUserArgs = {
    cursor?: InputMaybe<PoolModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<PoolModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolModerationUserWhereInput>;
};

export type QueryFindManyPoolModerationUserCountArgs = {
    cursor?: InputMaybe<PoolModerationUserWhereUniqueInput>;
    orderBy?: InputMaybe<PoolModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolModerationUserWhereInput>;
};

export type QueryFindManyPoolSourceProfileArgs = {
    cursor?: InputMaybe<PoolSourceProfileWhereUniqueInput>;
    orderBy?: InputMaybe<PoolSourceProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolSourceProfileWhereInput>;
};

export type QueryFindManyPoolSourceProfileCountArgs = {
    cursor?: InputMaybe<PoolSourceProfileWhereUniqueInput>;
    orderBy?: InputMaybe<PoolSourceProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolSourceProfileWhereInput>;
};

export type QueryFindManyPoolTargetProfileArgs = {
    cursor?: InputMaybe<PoolTargetProfileWhereUniqueInput>;
    orderBy?: InputMaybe<PoolTargetProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type QueryFindManyPoolTargetProfileCountArgs = {
    cursor?: InputMaybe<PoolTargetProfileWhereUniqueInput>;
    orderBy?: InputMaybe<PoolTargetProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<PoolTargetProfileWhereInput>;
};

export type QueryFindManyPredefinedMessageArgs = {
    projectName: Scalars['String'];
};

export type QueryFindManyProfileArgs = {
    cursor?: InputMaybe<ProfileWhereUniqueInput>;
    orderBy?: InputMaybe<ProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProfileWhereInput>;
};

export type QueryFindManyProfileCountArgs = {
    cursor?: InputMaybe<ProfileWhereUniqueInput>;
    orderBy?: InputMaybe<ProfileOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProfileWhereInput>;
};

export type QueryFindManyProfileTextArgs = {
    projectName?: InputMaybe<Scalars['String']>;
};

export type QueryFindManyProjectArgs = {
    cursor?: InputMaybe<ProjectWhereUniqueInput>;
    orderBy?: InputMaybe<ProjectOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProjectWhereInput>;
};

export type QueryFindManyProjectStatisticArgs = {
    cursor?: InputMaybe<ProjectStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<ProjectStatisticOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProjectStatisticWhereInput>;
};

export type QueryFindManyProviderArgs = {
    cursor?: InputMaybe<ProviderWhereUniqueInput>;
    orderBy?: InputMaybe<ProviderOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProviderWhereInput>;
};

export type QueryFindManyProviderCountArgs = {
    cursor?: InputMaybe<ProviderWhereUniqueInput>;
    orderBy?: InputMaybe<ProviderOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProviderWhereInput>;
};

export type QueryFindManyProviderSettingArgs = {
    cursor?: InputMaybe<ProviderSettingWhereUniqueInput>;
    orderBy?: InputMaybe<ProviderSettingOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProviderSettingWhereInput>;
};

export type QueryFindManyProviderSettingCountArgs = {
    cursor?: InputMaybe<ProviderSettingWhereUniqueInput>;
    orderBy?: InputMaybe<ProviderSettingOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProviderSettingWhereInput>;
};

export type QueryFindManyQueueArgs = {
    cursor?: InputMaybe<QueueWhereUniqueInput>;
    orderBy?: InputMaybe<QueueOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueWhereInput>;
};

export type QueryFindManyQueueCountArgs = {
    cursor?: InputMaybe<QueueWhereUniqueInput>;
    orderBy?: InputMaybe<QueueOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueWhereInput>;
};

export type QueryFindManyQueueGroupByArgs = {
    where?: InputMaybe<QueueWhereInput>;
};

export type QueryFindManyQueueTeamArgs = {
    cursor?: InputMaybe<QueueTeamWhereUniqueInput>;
    orderBy?: InputMaybe<QueueTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueTeamWhereInput>;
};

export type QueryFindManyQueueTypePriorityArgs = {
    cursor?: InputMaybe<QueueTypePriorityWhereUniqueInput>;
    orderBy?: InputMaybe<QueueTypePriorityOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueTypePriorityWhereInput>;
};

export type QueryFindManyScenarioArgs = {
    cursor?: InputMaybe<ScenarioWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ScenarioOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ScenarioWhereInput>;
};

export type QueryFindManyScenarioStatisticsArgs = {
    cursor?: InputMaybe<ScenarioStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ScenarioStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: Scalars['Int'];
    where?: InputMaybe<ScenarioStatisticWhereInput>;
};

export type QueryFindManySupportMessageArgs = {
    cursor?: InputMaybe<SupportMessageWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SupportMessageOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportMessageWhereInput>;
};

export type QueryFindManySupportTextArgs = {
    cursor?: InputMaybe<SupportTextWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextWhereInput>;
};

export type QueryFindManySupportTextAdminArgs = {
    cursor?: InputMaybe<SupportTextAdminWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextAdminOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextAdminWhereInput>;
};

export type QueryFindManySupportTextAdminCountArgs = {
    cursor?: InputMaybe<SupportTextAdminWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextAdminOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextAdminWhereInput>;
};

export type QueryFindManySupportTextCategoryArgs = {
    cursor?: InputMaybe<SupportTextCategoryWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextCategoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextCategoryWhereInput>;
};

export type QueryFindManySupportTextCategoryCountArgs = {
    cursor?: InputMaybe<SupportTextCategoryWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextCategoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextCategoryWhereInput>;
};

export type QueryFindManySupportTextCountArgs = {
    cursor?: InputMaybe<SupportTextWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextWhereInput>;
};

export type QueryFindManySupportTicketArgs = {
    cursor?: InputMaybe<SupportTicketWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SupportTicketOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTicketWhereInput>;
};

export type QueryFindManySupportTicketCountArgs = {
    cursor?: InputMaybe<SupportTicketWhereUniqueInput>;
    orderBy?: InputMaybe<SupportTicketOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTicketWhereInput>;
};

export type QueryFindManySystemComplainArgs = {
    cursor?: InputMaybe<SystemComplainWhereUniqueInput>;
    orderBy?: InputMaybe<SystemComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SystemComplainWhereInput>;
};

export type QueryFindManySystemComplainCountArgs = {
    cursor?: InputMaybe<SystemComplainWhereUniqueInput>;
    orderBy?: InputMaybe<SystemComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SystemComplainWhereInput>;
};

export type QueryFindManyThemeArgs = {
    cursor?: InputMaybe<ThemeWhereUniqueInput>;
    orderBy?: InputMaybe<ThemeOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ThemeWhereInput>;
};

export type QueryFindManyThemeImageArgs = {
    cursor?: InputMaybe<ThemeImageWhereUniqueInput>;
    orderBy?: InputMaybe<ThemeImageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ThemeImageWhereInput>;
};

export type QueryFindManyTransactionArgs = {
    cursor?: InputMaybe<TransactionWhereUniqueInput>;
    orderBy?: InputMaybe<TransactionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindManyTransactionAggregateArgs = {
    _avg?: InputMaybe<TransactionAvgSumAggregateInput>;
    _count?: InputMaybe<TransactionCountAggregateInput>;
    _max?: InputMaybe<TransactionMinMaxAggregateInput>;
    _min?: InputMaybe<TransactionMinMaxAggregateInput>;
    _sum?: InputMaybe<TransactionAvgSumAggregateInput>;
    cursor?: InputMaybe<TransactionWhereUniqueInput>;
    orderBy?: InputMaybe<Array<TransactionOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TransactionWhereInputWithoutUser>;
};

export type QueryFindManyTransactionCountArgs = {
    cursor?: InputMaybe<TransactionWhereUniqueInput>;
    orderBy?: InputMaybe<TransactionOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindManyTransactionGroupByArgs = {
    _avg?: InputMaybe<TransactionAvgSumAggregateInput>;
    _count?: InputMaybe<TransactionCountAggregateInput>;
    _max?: InputMaybe<TransactionMinMaxAggregateInput>;
    _min?: InputMaybe<TransactionMinMaxAggregateInput>;
    _sum?: InputMaybe<TransactionAvgSumAggregateInput>;
    by: Array<TransactionScalarFieldEnum>;
    orderBy: Array<TransactionOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TransactionWhereInputWithoutUser>;
};

export type QueryFindManyTranslationArgs = {
    cursor?: InputMaybe<TranslationWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TranslationWhereInput>;
};

export type QueryFindManyTranslationCountArgs = {
    cursor?: InputMaybe<TranslationWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<TranslationWhereInput>;
};

export type QueryFindManyUserArgs = {
    cursor?: InputMaybe<UserWhereUniqueInput>;
    orderBy?: InputMaybe<UserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyUserAggregateArgs = {
    _avg?: InputMaybe<UserAvgSumAggregateInputType>;
    _count?: InputMaybe<UserCountMaxMinAggregateInputType>;
    _max?: InputMaybe<UserCountMaxMinAggregateInputType>;
    _min?: InputMaybe<UserCountMaxMinAggregateInputType>;
    _sum?: InputMaybe<UserAvgSumAggregateInputType>;
    cursor?: InputMaybe<UserWhereUniqueInput>;
    orderBy?: InputMaybe<UserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyUserComplainArgs = {
    cursor?: InputMaybe<UserComplainWhereUniqueInput>;
    orderBy?: InputMaybe<UserComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserComplainWhereInput>;
};

export type QueryFindManyUserComplainCountArgs = {
    cursor?: InputMaybe<UserComplainWhereUniqueInput>;
    orderBy?: InputMaybe<UserComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserComplainWhereInput>;
};

export type QueryFindManyUserCountArgs = {
    cursor?: InputMaybe<UserWhereUniqueInput>;
    orderBy?: InputMaybe<UserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyUserCountEmailProviderArgs = {
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyUserCountSocialArgs = {
    where?: InputMaybe<UserWhereInput>;
};

export type QueryFindManyUserCouponArgs = {
    cursor?: InputMaybe<UserCouponWhereUniqueInput>;
    orderBy?: InputMaybe<UserCouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserCouponWhereInput>;
};

export type QueryFindManyUserCouponCountArgs = {
    cursor?: InputMaybe<UserCouponWhereUniqueInput>;
    orderBy?: InputMaybe<UserCouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserCouponWhereInput>;
};

export type QueryFindManyUserStatisticArgs = {
    cursor?: InputMaybe<UserStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UserStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserStatisticWhereInput>;
};

export type QueryFindManyUserStatisticAggregateArgs = {
    _avg?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _count?: InputMaybe<UserStatisticCountAggregateInputType>;
    _max?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _min?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _sum?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    cursor?: InputMaybe<UserStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UserStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserStatisticWhereInput>;
};

export type QueryFindManyUserStatisticCountArgs = {
    cursor?: InputMaybe<UserStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UserStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserStatisticWhereInput>;
};

export type QueryFindManyUserStatisticGroupByArgs = {
    _avg?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _count?: InputMaybe<UserStatisticCountAggregateInputType>;
    _max?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _min?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    _sum?: InputMaybe<UserStatisticAvgMinMaxSumAggregateInputType>;
    by: Array<UserStatisticScalarFieldEnum>;
    orderBy: Array<UserStatisticOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserStatisticWhereInput>;
};

export type QueryFindManyUsernameBadwordArgs = {
    cursor?: InputMaybe<UsernameBadwordWhereUniqueInput>;
    orderBy?: InputMaybe<UsernameBadwordOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UsernameBadwordWhereInput>;
};

export type QueryFindManyUsernameBadwordCountArgs = {
    cursor?: InputMaybe<UsernameBadwordWhereUniqueInput>;
    orderBy?: InputMaybe<UsernameBadwordOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: UsernameBadwordWhereInput;
};

export type QueryFindMySupportTicketArgs = {
    where: SupportTicketWhereInput;
};

export type QueryFindOneAcquisitionArgs = {
    where: AcquisitionWhereUniqueInput;
};

export type QueryFindOneAdminRoleArgs = {
    where: AdminRoleWhereUniqueInput;
};

export type QueryFindOneAdminRolePrivilegeArgs = {
    where: AdminRolePrivilegeWhereUniqueInput;
};

export type QueryFindOneAdminTextArgs = {
    where: AdminTextWhereUniqueInput;
};

export type QueryFindOneAdminUserArgs = {
    where: AdminUserWhereUniqueInput;
};

export type QueryFindOneAdminUserTextArgs = {
    where: AdminUserTextWhereUniqueInput;
};

export type QueryFindOneAffiliateUserArgs = {
    where: AffiliateUserWhereUniqueInput;
};

export type QueryFindOneAgencyArgs = {
    where: AgencyWhereUniqueInput;
};

export type QueryFindOneAgencyProjectArgs = {
    where: AgencyProjectWhereUniqueInput;
};

export type QueryFindOneCommentArgs = {
    where: CommentWhereUniqueInput;
};

export type QueryFindOneCommentFieldArgs = {
    where: CommentFieldWhereUniqueInput;
};

export type QueryFindOneCommentLogArgs = {
    where: CommentLogWhereUniqueInput;
};

export type QueryFindOneComplainTypeArgs = {
    where: ComplainTypeWhereUniqueInput;
};

export type QueryFindOneConversationArgs = {
    where: ConversationWhereUniqueInput;
};

export type QueryFindOneCouponArgs = {
    where: CouponWhereUniqueInput;
};

export type QueryFindOneGiftArgs = {
    languageCode?: InputMaybe<Scalars['String']>;
    projectName?: InputMaybe<Scalars['String']>;
    where: GiftWhereUniqueInput;
};

export type QueryFindOneInterestArgs = {
    where: InterestWhereUniqueInput;
};

export type QueryFindOneInterestCategoryArgs = {
    where: InterestCategoryWhereUniqueInput;
};

export type QueryFindOneLanguageArgs = {
    where: LanguageWhereUniqueInput;
};

export type QueryFindOneMessageArgs = {
    where: MessageWhereUniqueInput;
};

export type QueryFindOneModeratedProfileArgs = {
    where: ModeratedProfileWhereUniqueInput;
};

export type QueryFindOneModerationUserArgs = {
    where: ModerationUserWhereUniqueInput;
};

export type QueryFindOneModeratorUserHourStatisticArgs = {
    where: ModeratorUserHourStatisticWhereUniqueInput;
};

export type QueryFindOneModeratorUserStatisticArgs = {
    where: ModeratorUserStatisticWhereUniqueInput;
};

export type QueryFindOneOfferArgs = {
    where: OfferWhereUniqueInput;
};

export type QueryFindOnePictureArgs = {
    where: PictureWhereUniqueInput;
};

export type QueryFindOnePoolArgs = {
    where: PoolWhereUniqueInput;
};

export type QueryFindOnePredefinedMessageArgs = {
    projectName: Scalars['String'];
    where: PredefinedMessageWhereUniqueInput;
};

export type QueryFindOneProfileArgs = {
    where: ProfileWhereUniqueInput;
};

export type QueryFindOneProjectArgs = {
    where: ProjectWhereUniqueInput;
};

export type QueryFindOneProviderArgs = {
    where: ProviderWhereUniqueInput;
};

export type QueryFindOneProviderSettingArgs = {
    where: ProviderSettingWhereUniqueInput;
};

export type QueryFindOneScenarioArgs = {
    where: ScenarioWhereUniqueInput;
};

export type QueryFindOneSupportTextArgs = {
    where: SupportTextWhereUniqueInput;
};

export type QueryFindOneSupportTextAdminArgs = {
    where: SupportTextAdminWhereUniqueInput;
};

export type QueryFindOneSupportTextCategoryArgs = {
    where: SupportTextCategoryWhereUniqueInput;
};

export type QueryFindOneSupportTicketArgs = {
    where: SupportTicketWhereUniqueInput;
};

export type QueryFindOneSystemComplainArgs = {
    where: SystemComplainWhereUniqueInput;
};

export type QueryFindOneThemeArgs = {
    where: ThemeWhereUniqueInput;
};

export type QueryFindOneTransactionArgs = {
    where: TransactionWhereUniqueInput;
};

export type QueryFindOneTranslationArgs = {
    where: TranslationWhereUniqueInput;
};

export type QueryFindOneUserArgs = {
    where: UserWhereUniqueInput;
};

export type QueryFindOneUserComplainArgs = {
    where: UserComplainWhereUniqueInput;
};

export type QueryFindUniqueMatchConfigArgs = {
    where?: MatchConfigWhereUniqueInput;
};

export type QueryLegacyUsersArgs = {
    orderBy?: InputMaybe<LegacyUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where: LegacyUserWhereInput;
};

export type QueryLegacyUsersCountArgs = {
    where: LegacyUserWhereInput;
};

export type QueryModerationMessageAggregateArgs = {
    _avg?: InputMaybe<ModerationMessageAvgSumAggregateInputType>;
    _count?: InputMaybe<ModerationMessageCountAggregateInputType>;
    _sum?: InputMaybe<ModerationMessageAvgSumAggregateInputType>;
    cursor?: InputMaybe<ModerationMessageWhereUniqueInput>;
    orderBy?: InputMaybe<ModerationMessageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationMessageWhereInput>;
};

export type QueryModerationMessageGroupByArgs = {
    _avg?: InputMaybe<ModerationMessageAvgSumAggregateInputType>;
    _sum?: InputMaybe<ModerationMessageAvgSumAggregateInputType>;
    by: Array<ModerationMessageScalarFieldEnum>;
    orderBy: Array<ModerationMessageOrderByWithAggregationInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ModerationMessageWhereInput>;
};

export type QueryProfileAggregateArgs = {
    _avg?: InputMaybe<ProfileAvgSumAggregateInput>;
    _count?: InputMaybe<ProfileCountAggregateInput>;
    _max?: InputMaybe<ProfileMaxMinAggregateInput>;
    _min?: InputMaybe<ProfileMaxMinAggregateInput>;
    _sum?: InputMaybe<ProfileAvgSumAggregateInput>;
    cursor?: InputMaybe<ProfileWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ProfileOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ProfileWhereInput>;
};

export type QueryProfileGroupByArgs = {
    _avg?: InputMaybe<ProfileAvgSumAggregateInput>;
    _count?: InputMaybe<ProfileCountAggregateInput>;
    _max?: InputMaybe<ProfileMaxMinAggregateInput>;
    _min?: InputMaybe<ProfileMaxMinAggregateInput>;
    _sum?: InputMaybe<ProfileAvgSumAggregateInput>;
    by: Array<ProfileScalarFieldEnum>;
    having?: InputMaybe<ProfileScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ProfileOrderByWithAggregationInput>>;
    where?: InputMaybe<ProfileWhereInput>;
};

export type Queue = {
    __typename: 'Queue';
    availableAt: Scalars['DateTime'];
    blocked: Scalars['Boolean'];
    conversation?: Maybe<Conversation>;
    conversationId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    customerProfile: Profile;
    languageCode: Scalars['String'];
    lastMessageCost: Scalars['Int'];
    moderatedProfile: ModeratedProfile;
    moderatedProfileId: Scalars['String'];
    moderationTeam?: Maybe<ModerationTeam>;
    moderationTeamId?: Maybe<Scalars['String']>;
    moderationTeams: Array<QueueTeam>;
    moderationType: ModerationType;
    moderationUser?: Maybe<ModerationUser>;
    moderationUserId?: Maybe<Scalars['String']>;
    moderationUsers: Array<QueueModerationUser>;
    payingCustomer: Scalars['Boolean'];
    priority: Scalars['Int'];
    profileId: Scalars['String'];
    projectName: Scalars['String'];
    queueId: Scalars['String'];
    reservedUntil?: Maybe<Scalars['DateTime']>;
    type: QueueType;
    updatedAt: Scalars['DateTime'];
};

export type QueueModerationTeamsArgs = {
    cursor?: InputMaybe<QueueTeamWhereUniqueInput>;
    distinct?: InputMaybe<QueueTeamDistinctFieldEnum>;
    orderBy?: InputMaybe<QueueTeamOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueTeamWhereInput>;
};

export type QueueModerationUsersArgs = {
    cursor?: InputMaybe<QueueModerationUserWhereUniqueInput>;
    distinct?: InputMaybe<QueueModerationUserDistinctFieldEnum>;
    orderBy?: InputMaybe<QueueModerationUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<QueueModerationUserWhereInput>;
};

export type QueueCountAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    awrLevel?: InputMaybe<Scalars['true']>;
    moderatedProfileId?: InputMaybe<Scalars['true']>;
    moderationTeamId?: InputMaybe<Scalars['true']>;
    moderationType?: InputMaybe<Scalars['true']>;
    moderationUserId?: InputMaybe<Scalars['true']>;
    priority?: InputMaybe<Scalars['true']>;
    profileId?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    queueId?: InputMaybe<Scalars['true']>;
    type?: InputMaybe<Scalars['true']>;
};

export type QueueCountAggregateOutputType = {
    __typename: 'QueueCountAggregateOutputType';
    _all: Scalars['Int'];
    awrLevel: Scalars['Int'];
    moderatedProfileId: Scalars['Int'];
    moderationType: Scalars['Int'];
    priority: Scalars['Int'];
    profileId: Scalars['Int'];
    projectName: Scalars['Int'];
    queueId: Scalars['Int'];
    type: Scalars['Int'];
};

export type QueueCreateInput = {
    availableAt?: InputMaybe<Scalars['DateTime']>;
    /** profileId */
    customerProfileId: Scalars['String'];
    languageCode: Scalars['String'];
    /** profileId */
    moderatedProfileId: Scalars['String'];
    moderationTeamIds?: InputMaybe<Array<Scalars['String']>>;
    moderationType: ModerationType;
    moderationUserIds?: InputMaybe<Array<Scalars['String']>>;
    priority: Scalars['Int'];
    projectName: Scalars['String'];
    type: QueueType;
};

export const QueueDistinctFieldEnum = {
    AvailableAt: 'availableAt',
    Blocked: 'blocked',
    ConversationId: 'conversationId',
    CreatedAt: 'createdAt',
    LanguageCode: 'languageCode',
    LastMessageCost: 'lastMessageCost',
    ModeratedProfileId: 'moderatedProfileId',
    ModerationTeamId: 'moderationTeamId',
    ModerationType: 'moderationType',
    ModerationUserId: 'moderationUserId',
    PayingCustomer: 'payingCustomer',
    Priority: 'priority',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    QueueId: 'queueId',
    ReservedUntil: 'reservedUntil',
    Type: 'type',
    UpdatedAt: 'updatedAt',
} as const;

export type QueueDistinctFieldEnum = (typeof QueueDistinctFieldEnum)[keyof typeof QueueDistinctFieldEnum];
export type QueueGroupBy = {
    __typename: 'QueueGroupBy';
    _count?: Maybe<Scalars['Int']>;
    moderationTeamId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    stats: Array<QueueStats>;
    teams: Array<QueueTeamGroupBy>;
};

export type QueueGroupByStatsArgs = {
    where?: InputMaybe<QueueWhereInput>;
};

export type QueueGroupByTeamsArgs = {
    where?: InputMaybe<QueueWhereInput>;
};

export type QueueListRelationFilter = {
    every?: InputMaybe<QueueWhereInput>;
    none?: InputMaybe<QueueWhereInput>;
    some?: InputMaybe<QueueWhereInput>;
};

export type QueueModerationUser = {
    __typename: 'QueueModerationUser';
    moderationUser: ModerationUser;
    moderationUserId: Scalars['String'];
    queue: Queue;
    queueId: Scalars['String'];
    queueModerationUserId: Scalars['String'];
};

export type QueueModerationUserCreateManyWithoutQueueInput = {
    create?: InputMaybe<Array<QueueModerationUserCreateWithoutQueueInput>>;
};

export type QueueModerationUserCreateWithoutQueueInput = {
    moderationUser: ModerationUserCreateOneWithoutX;
};

export const QueueModerationUserDistinctFieldEnum = {
    ModerationUserId: 'moderationUserId',
    QueueId: 'queueId',
    QueueModerationUserId: 'queueModerationUserId',
} as const;

export type QueueModerationUserDistinctFieldEnum = (typeof QueueModerationUserDistinctFieldEnum)[keyof typeof QueueModerationUserDistinctFieldEnum];
export type QueueModerationUserListRelationFilter = {
    every?: InputMaybe<QueueModerationUserWhereInput>;
    none?: InputMaybe<QueueModerationUserWhereInput>;
    some?: InputMaybe<QueueModerationUserWhereInput>;
};

export type QueueModerationUserOrderByInput = {
    moderationUserId?: InputMaybe<SortOrder>;
    queueId?: InputMaybe<SortOrder>;
    queueModerationUserId?: InputMaybe<SortOrder>;
};

export type QueueModerationUserUpdateManyWithoutQueueInput = {
    create?: InputMaybe<Array<QueueModerationUserCreateWithoutQueueInput>>;
    delete?: InputMaybe<Array<QueueModerationUserWhereUniqueInput>>;
};

export type QueueModerationUserWhereInput = {
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringFilter>;
    queue?: InputMaybe<QueueWhereInput>;
    queueId?: InputMaybe<StringFilter>;
    queueModerationUserId?: InputMaybe<StringFilter>;
};

export type QueueModerationUserWhereUniqueInput = {
    queueModerationUserId: Scalars['String'];
};

export type QueueModeratorGroupBy = {
    __typename: 'QueueModeratorGroupBy';
    _count?: Maybe<Scalars['Int']>;
    moderationTeamId?: Maybe<Scalars['String']>;
    moderationUser?: Maybe<ModerationUser>;
    moderationUserId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    stats: Array<QueueStats>;
    type?: Maybe<Scalars['String']>;
};

export type QueueOrderByInput = {
    availableAt?: InputMaybe<SortOrder>;
    blocked?: InputMaybe<SortOrder>;
    conversationId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    lastMessageCost?: InputMaybe<SortOrder>;
    moderatedProfileId?: InputMaybe<SortOrder>;
    moderationTeamId?: InputMaybe<SortOrder>;
    moderationType?: InputMaybe<SortOrder>;
    moderationUserId?: InputMaybe<SortOrder>;
    payingCustomer?: InputMaybe<SortOrder>;
    priority?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    queueId?: InputMaybe<SortOrder>;
    reservedUntil?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export const QueueScalarFieldEnum = {
    AcquisitionId: 'acquisitionId',
    AvailableAt: 'availableAt',
    AwrLevel: 'awrLevel',
    Blocked: 'blocked',
    ConversationId: 'conversationId',
    ConversationProjectName: 'conversationProjectName',
    CreatedAt: 'createdAt',
    FirstContact: 'firstContact',
    LanguageCode: 'languageCode',
    LastMessageCost: 'lastMessageCost',
    MessageCount: 'messageCount',
    ModeratedProfileId: 'moderatedProfileId',
    ModerationTeamId: 'moderationTeamId',
    ModerationType: 'moderationType',
    ModerationUserId: 'moderationUserId',
    PayingCustomer: 'payingCustomer',
    Priority: 'priority',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    QueueId: 'queueId',
    ReservedUntil: 'reservedUntil',
    ScenarioId: 'scenarioId',
    Type: 'type',
    UpdatedAt: 'updatedAt',
} as const;

export type QueueScalarFieldEnum = (typeof QueueScalarFieldEnum)[keyof typeof QueueScalarFieldEnum];
export type QueueStats = {
    __typename: 'QueueStats';
    _count?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type QueueTeam = {
    __typename: 'QueueTeam';
    moderationTeam: ModerationTeam;
    moderationTeamId: Scalars['String'];
    queue: Queue;
    queueId: Scalars['String'];
    queueTeamId: Scalars['String'];
};

export type QueueTeamCreateManyWithoutQueueInput = {
    create?: InputMaybe<Array<QueueTeamCreateWithoutQueueInput>>;
};

export type QueueTeamCreateWithoutQueueInput = {
    moderationTeam: ModerationTeamCreateOneWithoutX;
};

export const QueueTeamDistinctFieldEnum = {
    ModerationTeamId: 'moderationTeamId',
    QueueId: 'queueId',
    QueueTeamId: 'queueTeamId',
} as const;

export type QueueTeamDistinctFieldEnum = (typeof QueueTeamDistinctFieldEnum)[keyof typeof QueueTeamDistinctFieldEnum];
export type QueueTeamGroupBy = {
    __typename: 'QueueTeamGroupBy';
    _count?: Maybe<Scalars['Int']>;
    moderationTeam?: Maybe<ModerationTeam>;
    moderationTeamId?: Maybe<Scalars['String']>;
    moderationUserId?: Maybe<Scalars['String']>;
    moderators: Array<QueueModeratorGroupBy>;
    projectName: Scalars['String'];
    stats: Array<QueueStats>;
    type?: Maybe<Scalars['String']>;
};

export type QueueTeamListRelationFilter = {
    every?: InputMaybe<QueueTeamWhereInput>;
    none?: InputMaybe<QueueTeamWhereInput>;
    some?: InputMaybe<QueueTeamWhereInput>;
};

export type QueueTeamOrderByInput = {
    moderationTeamId?: InputMaybe<SortOrder>;
    queueId?: InputMaybe<SortOrder>;
    queueTeamId?: InputMaybe<SortOrder>;
};

export type QueueTeamScalarWhereInput = {
    moderationTeamId?: InputMaybe<StringFilter>;
    queueId?: InputMaybe<StringFilter>;
    queueTeamId?: InputMaybe<StringFilter>;
};

export type QueueTeamUpdateManyWithoutQueueInput = {
    create?: InputMaybe<Array<QueueTeamCreateWithoutQueueInput>>;
    delete?: InputMaybe<Array<QueueTeamWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<QueueTeamScalarWhereInput>>;
};

export type QueueTeamWhereInput = {
    moderationTeam?: InputMaybe<ModerationTeamWhereInput>;
    moderationTeamId?: InputMaybe<StringFilter>;
    queue?: InputMaybe<QueueWhereInput>;
    queueId?: InputMaybe<StringFilter>;
    queueTeamId?: InputMaybe<StringFilter>;
};

export type QueueTeamWhereUniqueInput = {
    queueTeamId: Scalars['String'];
};

export const QueueType = {
    Awr: 'Awr',
    Fawr: 'Fawr',
    FriendshipAccept: 'FriendshipAccept',
    FriendshipDelete: 'FriendshipDelete',
    FriendshipRequest: 'FriendshipRequest',
    Gift: 'Gift',
    Like: 'Like',
    Match: 'Match',
    Message: 'Message',
    Nawr: 'Nawr',
    Picture: 'Picture',
    Poke: 'Poke',
    Pool: 'Pool',
    PredefinedMessage: 'PredefinedMessage',
    Sleep: 'Sleep',
} as const;

export type QueueType = (typeof QueueType)[keyof typeof QueueType];
export type QueueTypeFilter = {
    equals?: InputMaybe<QueueType>;
    in?: InputMaybe<Array<QueueType>>;
    not?: InputMaybe<QueueType>;
    notIn?: InputMaybe<Array<QueueType>>;
};

export type QueueTypePriority = {
    __typename: 'QueueTypePriority';
    priority: Scalars['Int'];
    type: QueueType;
};

export type QueueTypePriorityCreateOneWithoutQueueInput = {
    connect?: InputMaybe<QueueTypePriorityWhereUniqueInput>;
};

export type QueueTypePriorityOrderByInput = {
    priority?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export type QueueTypePriorityUpdateManyMutationInput = {
    priority: Scalars['Int'];
    type: QueueType;
};

export type QueueTypePriorityUpdateOneRequiredWithoutQueueInput = {
    connect?: InputMaybe<QueueTypePriorityWhereUniqueInput>;
};

export type QueueTypePriorityWhereInput = {
    Queue?: InputMaybe<QueueListRelationFilter>;
    priority?: InputMaybe<IntFilter>;
    type?: InputMaybe<QueueTypeFilter>;
};

export type QueueTypePriorityWhereUniqueInput = {
    type: QueueType;
};

export type QueueUpdateInput = {
    availableAt?: InputMaybe<Scalars['DateTime']>;
    blocked?: InputMaybe<Scalars['Boolean']>;
    moderationTeamIds?: InputMaybe<Array<Scalars['String']>>;
    moderationType: ModerationType;
    moderationUserIds?: InputMaybe<Array<Scalars['String']>>;
    queueTypePriority?: InputMaybe<QueueTypePriorityUpdateOneRequiredWithoutQueueInput>;
    reservedUntil?: InputMaybe<Scalars['DateTime']>;
};

export type QueueUpdateManyDataInput = {
    availableAt?: InputMaybe<Scalars['DateTime']>;
    blocked?: InputMaybe<Scalars['Boolean']>;
    reservedUntil?: InputMaybe<Scalars['DateTime']>;
};

export type QueueWhereInput = {
    availableAt?: InputMaybe<DateTimeFilter>;
    blocked?: InputMaybe<BoolFilter>;
    conversation?: InputMaybe<ConversationWhereInput>;
    conversationId?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    customerProfile?: InputMaybe<ProfileWhereInput>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    lastMessageCost?: InputMaybe<IntFilter>;
    moderatedProfile?: InputMaybe<ModeratedProfileWhereInput>;
    moderatedProfileId?: InputMaybe<StringFilter>;
    moderationTeam?: InputMaybe<ModerationTeamWhereInput>;
    moderationTeamId?: InputMaybe<StringNullableFilter>;
    moderationTeams?: InputMaybe<QueueTeamListRelationFilter>;
    moderationType?: InputMaybe<ModerationTypeFilter>;
    moderationUser?: InputMaybe<ModerationUserWhereInput>;
    moderationUserId?: InputMaybe<StringNullableFilter>;
    moderationUsers?: InputMaybe<QueueModerationUserListRelationFilter>;
    payingCustomer?: InputMaybe<BoolFilter>;
    priority?: InputMaybe<IntFilter>;
    profileId?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    queueId?: InputMaybe<StringFilter>;
    queueTypePriority?: InputMaybe<QueueTypePriorityWhereInput>;
    reservedUntil?: InputMaybe<DateTimeNullableFilter>;
    type?: InputMaybe<QueueTypeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type QueueWhereUniqueInput = {
    conversationId_projectName?: InputMaybe<ConversationIdProjectNameCompoundUniqueInput>;
    pN_pI_mPI?: InputMaybe<Pn_PI_MPiCompoundUniqueInput>;
    queueId?: InputMaybe<Scalars['String']>;
};

export type ResultError = {
    __typename: 'ResultError';
    errors?: Maybe<Array<Error>>;
};

export type Scenario = {
    __typename: 'Scenario';
    actionType: ScenarioActionType;
    active: Scalars['Boolean'];
    categoryName: Scalars['String'];
    /** minutes */
    interval: Scalars['Int'];
    lastExecution?: Maybe<Scalars['DateTime']>;
    maxExecutions?: Maybe<Scalars['Int']>;
    moderatedProfiles: Array<ModeratedProfile>;
    moderationTeams: Array<ModerationTeam>;
    moderationType?: Maybe<ModerationType>;
    moderationUsers: Array<ModerationUser>;
    name: Scalars['String'];
    nextExecution?: Maybe<Scalars['DateTime']>;
    picture?: Maybe<Picture>;
    pictureId?: Maybe<Scalars['String']>;
    probability: Scalars['Int'];
    projectName: Scalars['String'];
    scenarioId: Scalars['Cuid'];
    scenarioStatistics: Array<ScenarioStatistic>;
    scenarioUsers: Array<ScenarioUser>;
    /** ScenarioSearchInput */
    search: Scalars['JSON'];
    startHour: Scalars['Int'];
    stopHour: Scalars['Int'];
    /** Array<string> */
    texts: Array<Scalars['String']>;
    type: ScenarioType;
};

export type ScenarioScenarioStatisticsArgs = {
    cursor?: InputMaybe<ScenarioStatisticWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ScenarioStatisticOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: Scalars['Int'];
    where?: InputMaybe<ScenarioStatisticWhereInput>;
};

export const ScenarioActionType = {
    Awr: 'Awr',
    Friendship: 'Friendship',
    Message: 'Message',
    Picture: 'Picture',
    Poke: 'Poke',
    Visit: 'Visit',
} as const;

export type ScenarioActionType = (typeof ScenarioActionType)[keyof typeof ScenarioActionType];
export type ScenarioCategory = {
    __typename: 'ScenarioCategory';
    count: Scalars['Int'];
    name: Scalars['String'];
    scenarioCategoryId: Scalars['Cuid'];
    scenarioProject: Array<ScenarioProject>;
};

export type ScenarioCategoryCreateInput = {
    name: Scalars['String'];
};

export type ScenarioCategoryUpdateInput = {
    name: Scalars['String'];
};

export type ScenarioCategoryUpdateOneRequiredWithoutScenarioNestedInput = {
    connect: ScenarioCategoryWhereUniqueInput;
};

export type ScenarioCategoryWhereUniqueInput = {
    name: Scalars['String'];
};

export type ScenarioCreateInput = {
    actionType: ScenarioActionType;
    active?: Scalars['Boolean'];
    category: ScenarioCategoryUpdateOneRequiredWithoutScenarioNestedInput;
    interval: Scalars['Int'];
    maxExecutions?: Scalars['Int'];
    moderatedProfiles?: InputMaybe<ModeratedProfileCreateUpdateNestedOneWithoutXInput>;
    moderationTeams?: InputMaybe<ModerationTeamCreateUpdateNestedManyWithoutXInput>;
    moderationType?: InputMaybe<ModerationType>;
    moderationUsers?: InputMaybe<ModerationUserCreateUpdateNestedManyWithoutXInput>;
    name: Scalars['String'];
    picture?: InputMaybe<PictureUpdateOneWithoutXInput>;
    /** 0-100, per user/event */
    probability?: Scalars['Int'];
    projectName: Scalars['String'];
    search: ScenarioSearchInput;
    startHour: Scalars['Int'];
    stopHour: Scalars['Int'];
    texts?: InputMaybe<Array<Scalars['String']>>;
    type: ScenarioType;
};

export type ScenarioError = {
    __typename: 'ScenarioError';
    errorType: ScenarioErrorType;
    field: Scalars['String'];
    message: Scalars['String'];
};

export const ScenarioErrorType = {
    MessageTextsEmpty: 'Message_texts_empty',
    ModerationTypeModerationTeamsCount: 'ModerationType_moderationTeams_count',
    ModerationTypeModerationTeamsEmpty: 'ModerationType_moderationTeams_empty',
    ModerationTypeModerationUsersCount: 'ModerationType_moderationUsers_count',
    ModerationTypeModerationUsersEmpty: 'ModerationType_moderationUsers_empty',
    PictureActionTypeWrong: 'Picture_actionType_wrong',
    PictureModeratedProfilesCount: 'Picture_moderatedProfiles_count',
    PictureModeratedProfilesWrong: 'Picture_moderatedProfiles_wrong',
    PicturePictureIdWrong: 'Picture_pictureId_wrong',
    PictureVisitLogRelativeWhereInputSet: 'Picture_visitLogRelativeWhereInput_set',
    ReanimationUserStatisticsEmpty: 'Reanimation_userStatistics_empty',
    VisitVisitLogRelativeWhereInputEmpty: 'Visit_visitLogRelativeWhereInput_empty',
} as const;

export type ScenarioErrorType = (typeof ScenarioErrorType)[keyof typeof ScenarioErrorType];
export type ScenarioOrderByInput = {
    categoryName?: InputMaybe<SortOrder>;
    lastExecution?: InputMaybe<SortOrder>;
    moderationType?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    nextExecution?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    scenarioId?: InputMaybe<SortOrder>;
    startHour?: InputMaybe<SortOrder>;
    stopHour?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
};

export type ScenarioProject = {
    __typename: 'ScenarioProject';
    count: Scalars['Int'];
    projectName: Scalars['String'];
    scenarioCategoryName: Scalars['String'];
    scenarios: Array<ScenarioSmall>;
};

export type ScenarioResult = Scenario | ScenarioError;

export type ScenarioSearchInput = {
    conversationProfileRelativeWhereInput?: InputMaybe<ConversationProfileRelativeWhereInput>;
    conversationRelativeWhereInput?: InputMaybe<ConversationRelativeWhereInput>;
    userWhereInput: UserRelativeWhereInput;
    visitLogRelativeWhereInput?: InputMaybe<VisitLogRelativeWhereInput>;
};

export type ScenarioSmall = {
    __typename: 'ScenarioSmall';
    name: Scalars['String'];
    projectName: Scalars['String'];
    scenarioId: Scalars['Cuid'];
    type: ScenarioType;
};

export type ScenarioStatistic = {
    __typename: 'ScenarioStatistic';
    bonus: Scalars['Int'];
    coins: Scalars['Int'];
    dailyCounter: Scalars['Int'];
    date: Scalars['String'];
    outs: Scalars['Int'];
    reactions: Scalars['Int'];
    reactionsBonus: Scalars['Int'];
    reactionsCoins: Scalars['Int'];
    received: Scalars['Int'];
    scenario: Scenario;
    scenarioId: Scalars['String'];
    scenarioStatisticId: Scalars['String'];
};

export type ScenarioStatisticOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    dailyCounter?: InputMaybe<SortOrder>;
    date?: InputMaybe<SortOrder>;
    outs?: InputMaybe<SortOrder>;
    reactions?: InputMaybe<SortOrder>;
    reactionsBonus?: InputMaybe<SortOrder>;
    reactionsCoins?: InputMaybe<SortOrder>;
    received?: InputMaybe<SortOrder>;
    scenarioId?: InputMaybe<SortOrder>;
};

export type ScenarioStatisticWhereInput = {
    date?: InputMaybe<StringFilter>;
    scenarioId?: InputMaybe<StringFilter>;
};

export type ScenarioStatisticWhereUniqueInput = {
    date: Scalars['String'];
    scenarioId: Scalars['String'];
};

export const ScenarioType = {
    Default: 'Default',
    Reanimation: 'Reanimation',
    Visit: 'Visit',
} as const;

export type ScenarioType = (typeof ScenarioType)[keyof typeof ScenarioType];
export type ScenarioUpdateInput = {
    actionType?: InputMaybe<ScenarioActionType>;
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<ScenarioCategoryUpdateOneRequiredWithoutScenarioNestedInput>;
    interval?: InputMaybe<Scalars['Int']>;
    maxExecutions?: InputMaybe<Scalars['Int']>;
    moderatedProfiles?: InputMaybe<ModeratedProfileSetUpdateNestedOneWithoutXInput>;
    moderationTeams?: InputMaybe<ModerationTeamUpdateManyWithoutXInput>;
    moderationType?: InputMaybe<ModerationType>;
    moderationUsers?: InputMaybe<ModerationUserUpdateManyWithoutXInput>;
    name?: InputMaybe<Scalars['String']>;
    picture?: InputMaybe<PictureUpdateOneWithoutXInput>;
    /** 0-100, per user/event */
    probability?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<ScenarioSearchInput>;
    startHour?: InputMaybe<Scalars['Int']>;
    stopHour?: InputMaybe<Scalars['Int']>;
    texts?: InputMaybe<Array<Scalars['String']>>;
    type?: InputMaybe<ScenarioType>;
};

export type ScenarioUser = {
    __typename: 'ScenarioUser';
    count: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    scenario: Scenario;
    scenarioId: Scalars['String'];
    user: User;
    userId: Scalars['String'];
};

export type ScenarioWhereInput = {
    categoryName?: InputMaybe<StringFilter>;
    lastExecution?: InputMaybe<DateTimeNullableFilter>;
    moderatedProfileProfileId?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    nextExecution?: InputMaybe<DateTimeNullableFilter>;
    projectName?: InputMaybe<Scalars['String']>;
    startHour?: InputMaybe<IntFilter>;
    stopHour?: InputMaybe<IntFilter>;
    type?: InputMaybe<EnumScenarioTypeFilter>;
};

export type ScenarioWhereUniqueInput = {
    scenarioId: Scalars['Cuid'];
};

export type SetProvisionInput = {
    lead: Scalars['Decimal'];
    provision: Scalars['Decimal'];
};

export type Social = {
    __typename: 'Social';
    projectName: Scalars['String'];
    providerId: Scalars['String'];
    providerName: Scalars['String'];
    registerDomain: Scalars['String'];
    socialId: Scalars['String'];
    userId: Scalars['String'];
};

export type SocialListRelationFilter = {
    every?: InputMaybe<SocialWhereInput>;
    none?: InputMaybe<SocialWhereInput>;
    some?: InputMaybe<SocialWhereInput>;
};

export type SocialWhereInput = {
    data?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    providerId?: InputMaybe<StringFilter>;
    providerName?: InputMaybe<StringFilter>;
    registerDomain?: InputMaybe<StringFilter>;
    socialId?: InputMaybe<StringFilter>;
    userId?: InputMaybe<StringFilter>;
};

export type SocialWhereUniqueInput = {
    socialId: Scalars['String'];
};

export const SortOrder = {
    Asc: 'asc',
    Desc: 'desc',
} as const;

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];
export type StringFilter = {
    contains?: InputMaybe<Scalars['String']>;
    endsWith?: InputMaybe<Scalars['String']>;
    equals?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    gte?: InputMaybe<Scalars['String']>;
    in?: InputMaybe<Array<Scalars['String']>>;
    lt?: InputMaybe<Scalars['String']>;
    lte?: InputMaybe<Scalars['String']>;
    not?: InputMaybe<Scalars['String']>;
    notIn?: InputMaybe<Array<Scalars['String']>>;
    startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
    contains?: InputMaybe<Scalars['String']>;
    endsWith?: InputMaybe<Scalars['String']>;
    equals?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    gte?: InputMaybe<Scalars['String']>;
    in?: InputMaybe<Array<Scalars['String']>>;
    lt?: InputMaybe<Scalars['String']>;
    lte?: InputMaybe<Scalars['String']>;
    not?: InputMaybe<Scalars['String']>;
    notIn?: InputMaybe<Array<Scalars['String']>>;
    startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
    _count?: InputMaybe<IntNullableFilter>;
    _max?: InputMaybe<StringNullableFilter>;
    _min?: InputMaybe<StringNullableFilter>;
    contains?: InputMaybe<Scalars['String']>;
    endsWith?: InputMaybe<Scalars['String']>;
    equals?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    gte?: InputMaybe<Scalars['String']>;
    in?: InputMaybe<Array<Scalars['String']>>;
    lt?: InputMaybe<Scalars['String']>;
    lte?: InputMaybe<Scalars['String']>;
    not?: InputMaybe<Scalars['String']>;
    notIn?: InputMaybe<Array<Scalars['String']>>;
    startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
    _count?: InputMaybe<IntFilter>;
    _max?: InputMaybe<StringFilter>;
    _min?: InputMaybe<StringFilter>;
    contains?: InputMaybe<Scalars['String']>;
    endsWith?: InputMaybe<Scalars['String']>;
    equals?: InputMaybe<Scalars['String']>;
    gt?: InputMaybe<Scalars['String']>;
    gte?: InputMaybe<Scalars['String']>;
    in?: InputMaybe<Array<Scalars['String']>>;
    lt?: InputMaybe<Scalars['String']>;
    lte?: InputMaybe<Scalars['String']>;
    not?: InputMaybe<Scalars['String']>;
    notIn?: InputMaybe<Array<Scalars['String']>>;
    startsWith?: InputMaybe<Scalars['String']>;
};

export const SupportCategory = {
    Bug: 'Bug',
    Other: 'Other',
    Payment: 'Payment',
    UserComplain: 'UserComplain',
} as const;

export type SupportCategory = (typeof SupportCategory)[keyof typeof SupportCategory];
export type SupportMessage = {
    __typename: 'SupportMessage';
    createdAt: Scalars['DateTime'];
    creator?: Maybe<AdminUser>;
    creatorId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    read: Scalars['Boolean'];
    supportMessageId: Scalars['String'];
    supportTicket: SupportTicket;
    supportTicketId: Scalars['String'];
    text: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type SupportMessageCreateInput = {
    category?: InputMaybe<SupportCategory>;
    priority?: InputMaybe<Scalars['Int']>;
    projectName: Scalars['String'];
    status?: InputMaybe<SupportTicketStatus>;
    supportTicketId?: InputMaybe<Scalars['String']>;
    text: Scalars['String'];
    userId?: InputMaybe<Scalars['String']>;
};

export type SupportMessageOrderByInput = {
    createdAt?: InputMaybe<SortOrder>;
    creatorId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    read?: InputMaybe<SortOrder>;
    supportMessageId?: InputMaybe<SortOrder>;
    supportTicketId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SupportMessageSupportMessageIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    supportMessageId: Scalars['String'];
};

export type SupportMessageWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    creatorId?: InputMaybe<Scalars['String']>;
    projectName?: InputMaybe<StringNullableFilter>;
    read?: InputMaybe<BoolFilter>;
    supportMessageId?: InputMaybe<StringFilter>;
    supportTicketId?: InputMaybe<Scalars['String']>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SupportMessageWhereUniqueInput = {
    supportMessageId_projectName: SupportMessageSupportMessageIdProjectNameCompoundUniqueInput;
};

export type SupportText = {
    __typename: 'SupportText';
    active: Scalars['Boolean'];
    category: SupportTextCategory;
    categoryId: Scalars['String'];
    languageCode: Scalars['String'];
    name: Scalars['String'];
    supportTextAdmins: Array<SupportTextAdmin>;
    supportTextId: Scalars['String'];
    text: Scalars['String'];
};

export type SupportTextSupportTextAdminsArgs = {
    cursor?: InputMaybe<SupportTextAdminWhereUniqueInput>;
    distinct?: InputMaybe<SupportTextAdminDistinctFieldEnum>;
    orderBy?: InputMaybe<SupportTextAdminOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextAdminWhereInput>;
};

export type SupportTextAdmin = {
    __typename: 'SupportTextAdmin';
    owner: AdminUser;
    ownerId: Scalars['String'];
    position: Scalars['Int'];
    supportText: SupportText;
    supportTextAdminId: Scalars['String'];
    supportTextId: Scalars['String'];
};

export type SupportTextAdminCreateInput = {
    position: Scalars['Int'];
    supportText: SupportTextWhereUniqueInput;
};

export type SupportTextAdminCreateWithoutOwnerInput = {
    position: Scalars['Int'];
    supportText: SupportTextWhereUniqueInput;
};

export const SupportTextAdminDistinctFieldEnum = {
    OwnerId: 'ownerId',
    Position: 'position',
    SupportTextAdminId: 'supportTextAdminId',
    SupportTextId: 'supportTextId',
} as const;

export type SupportTextAdminDistinctFieldEnum = (typeof SupportTextAdminDistinctFieldEnum)[keyof typeof SupportTextAdminDistinctFieldEnum];
export type SupportTextAdminListRelationFilter = {
    every?: InputMaybe<SupportTextAdminWhereInput>;
    none?: InputMaybe<SupportTextAdminWhereInput>;
    some?: InputMaybe<SupportTextAdminWhereInput>;
};

export type SupportTextAdminOrderByInput = {
    ownerId?: InputMaybe<SortOrder>;
    position?: InputMaybe<SortOrder>;
    supportTextAdminId?: InputMaybe<SortOrder>;
    supportTextId?: InputMaybe<SortOrder>;
};

export type SupportTextAdminScalarWhereInput = {
    ownerId?: InputMaybe<StringFilter>;
    position?: InputMaybe<IntFilter>;
    supportTextAdminId?: InputMaybe<StringFilter>;
    supportTextId?: InputMaybe<StringFilter>;
};

export type SupportTextAdminUpdateManyMutationInput = {
    position?: InputMaybe<Scalars['Int']>;
    supportText: SupportTextWhereUniqueInput;
};

export type SupportTextAdminUpdateManyWithoutOwnerInput = {
    connect?: InputMaybe<Array<SupportTextAdminWhereUniqueInput>>;
    create?: InputMaybe<Array<SupportTextAdminCreateWithoutOwnerInput>>;
    delete?: InputMaybe<Array<SupportTextAdminWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SupportTextAdminScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SupportTextAdminWhereUniqueInput>>;
    set?: InputMaybe<Array<SupportTextAdminWhereUniqueInput>>;
};

export type SupportTextAdminWhereInput = {
    owner?: InputMaybe<AdminUserWhereInput>;
    ownerId?: InputMaybe<StringFilter>;
    position?: InputMaybe<IntFilter>;
    supportText?: InputMaybe<SupportTextWhereInput>;
    supportTextAdminId?: InputMaybe<StringFilter>;
    supportTextId?: InputMaybe<StringFilter>;
};

export type SupportTextAdminWhereUniqueInput = {
    supportTextAdminId: Scalars['String'];
};

export type SupportTextCategory = {
    __typename: 'SupportTextCategory';
    active: Scalars['Boolean'];
    name: Scalars['String'];
    supportTextCategoryId: Scalars['String'];
    supportTexts: Array<SupportText>;
};

export type SupportTextCategorySupportTextsArgs = {
    cursor?: InputMaybe<SupportTextWhereUniqueInput>;
    distinct?: InputMaybe<SupportTextDistinctFieldEnum>;
    orderBy?: InputMaybe<SupportTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportTextWhereInput>;
};

export type SupportTextCategoryCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
};

export type SupportTextCategoryOrderByInput = {
    active?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    supportTextCategoryId?: InputMaybe<SortOrder>;
};

export type SupportTextCategoryUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    supportTexts?: InputMaybe<SupportTextUpdateManyWithoutCategoryInput>;
};

export type SupportTextCategoryWhereInput = {
    active?: InputMaybe<BoolFilter>;
    name?: InputMaybe<StringFilter>;
    supportTextCategoryId?: InputMaybe<StringFilter>;
    supportTexts?: InputMaybe<SupportTextListRelationFilter>;
};

export type SupportTextCategoryWhereUniqueInput = {
    supportTextCategoryId: Scalars['String'];
};

export type SupportTextCreateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category: SupportTextCategoryWhereUniqueInput;
    language: LanguageWhereUniqueInput;
    name: Scalars['String'];
    text: Scalars['String'];
};

export const SupportTextDistinctFieldEnum = {
    Active: 'active',
    CategoryId: 'categoryId',
    LanguageCode: 'languageCode',
    Name: 'name',
    SupportTextId: 'supportTextId',
    Text: 'text',
} as const;

export type SupportTextDistinctFieldEnum = (typeof SupportTextDistinctFieldEnum)[keyof typeof SupportTextDistinctFieldEnum];
export type SupportTextListRelationFilter = {
    every?: InputMaybe<SupportTextWhereInput>;
    none?: InputMaybe<SupportTextWhereInput>;
    some?: InputMaybe<SupportTextWhereInput>;
};

export type SupportTextOrderByInput = {
    active?: InputMaybe<SortOrder>;
    categoryId?: InputMaybe<SortOrder>;
    languageCode?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    supportTextId?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
};

export type SupportTextScalarWhereInput = {
    active?: InputMaybe<BoolFilter>;
    categoryId?: InputMaybe<StringFilter>;
    languageCode?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    supportTextAdmins?: InputMaybe<SupportTextAdminListRelationFilter>;
    supportTextId?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
};

export type SupportTextUpdateInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<SupportTextCategoryWhereUniqueInput>;
    language?: InputMaybe<LanguageWhereUniqueInput>;
    name?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
};

export type SupportTextUpdateManyWithoutCategoryInput = {
    connect?: InputMaybe<Array<SupportTextWhereUniqueInput>>;
    delete?: InputMaybe<Array<SupportTextWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SupportTextScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SupportTextWhereUniqueInput>>;
    set?: InputMaybe<Array<SupportTextWhereUniqueInput>>;
};

export type SupportTextWhereInput = {
    active?: InputMaybe<BoolFilter>;
    category?: InputMaybe<SupportTextCategoryWhereInput>;
    categoryId?: InputMaybe<StringFilter>;
    language?: InputMaybe<LanguageWhereInput>;
    languageCode?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    supportTextAdmins?: InputMaybe<SupportTextAdminListRelationFilter>;
    supportTextId?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
};

export type SupportTextWhereUniqueInput = {
    supportTextId: Scalars['String'];
};

export type SupportTicket = {
    __typename: 'SupportTicket';
    assignee?: Maybe<AdminUser>;
    assigneeId?: Maybe<Scalars['String']>;
    category: SupportCategory;
    createdAt: Scalars['DateTime'];
    priority: Scalars['Int'];
    projectName: Scalars['String'];
    status: SupportTicketStatus;
    supportMessages?: Maybe<Array<SupportMessage>>;
    supportTicketId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    user: User;
    userComplain?: Maybe<UserComplain>;
    userId: Scalars['String'];
};

export type SupportTicketSupportMessagesArgs = {
    cursor?: InputMaybe<SupportMessageWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SupportMessageOrderByInput>>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SupportMessageWhereInput>;
};

export type SupportTicketNestedWhereInput = {
    assignee?: InputMaybe<AdminUserWhereInput>;
    assigneeId?: InputMaybe<StringNullableFilter>;
    category?: InputMaybe<SupportCategory>;
    createdAt?: InputMaybe<DateTimeFilter>;
    priority?: InputMaybe<IntFilter>;
    projectName?: InputMaybe<StringFilter>;
    status?: InputMaybe<EnumSupportTicketStatusFilter>;
    supportTicketId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    user?: InputMaybe<UserWhereInput>;
};

export type SupportTicketOrderByInput = {
    assigneeId?: InputMaybe<SortOrder>;
    category?: InputMaybe<SortOrder>;
    priority?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    supportTicketId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export const SupportTicketStatus = {
    Answered: 'Answered',
    Closed: 'Closed',
    Open: 'Open',
    Pending: 'Pending',
} as const;

export type SupportTicketStatus = (typeof SupportTicketStatus)[keyof typeof SupportTicketStatus];
export type SupportTicketSupportTicketIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    supportTicketId: Scalars['String'];
};

export type SupportTicketUpdateInput = {
    assigneeId?: InputMaybe<Scalars['String']>;
    category?: InputMaybe<SupportCategory>;
    priority?: InputMaybe<Scalars['Int']>;
    status?: InputMaybe<SupportTicketStatus>;
};

export type SupportTicketWhereInput = {
    AND?: InputMaybe<Array<SupportTicketNestedWhereInput>>;
    OR?: InputMaybe<Array<SupportTicketNestedWhereInput>>;
    assignee?: InputMaybe<AdminUserWhereInput>;
    assigneeId?: InputMaybe<StringNullableFilter>;
    category?: InputMaybe<SupportCategory>;
    createdAt?: InputMaybe<DateTimeFilter>;
    priority?: InputMaybe<IntFilter>;
    projectName?: InputMaybe<StringFilter>;
    status?: InputMaybe<EnumSupportTicketStatusFilter>;
    supportTicketId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    user?: InputMaybe<UserWhereInput>;
};

export type SupportTicketWhereUniqueInput = {
    supportTicketId_projectName: SupportTicketSupportTicketIdProjectNameCompoundUniqueInput;
};

export type SystemComplain = {
    __typename: 'SystemComplain';
    admin?: Maybe<AdminUser>;
    adminId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    message?: Maybe<Message>;
    messageId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    status: ComplainStatus;
    systemComplainId: Scalars['String'];
    systemComplainUsers: Array<SystemComplainUser>;
    type: SystemComplainType;
    updatedAt: Scalars['DateTime'];
};

export type SystemComplainSystemComplainUsersArgs = {
    cursor?: InputMaybe<SystemComplainUserWhereUniqueInput>;
    distinct?: InputMaybe<SystemComplainUserDistinctFieldEnum>;
    orderBy?: InputMaybe<SystemComplainUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SystemComplainUserWhereInput>;
};

export type SystemComplainListRelationFilter = {
    every?: InputMaybe<SystemComplainWhereInput>;
    none?: InputMaybe<SystemComplainWhereInput>;
    some?: InputMaybe<SystemComplainWhereInput>;
};

export type SystemComplainOrderByInput = {
    adminId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    messageId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    systemComplainId?: InputMaybe<SortOrder>;
    type?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export const SystemComplainType = {
    Clone: 'Clone',
    Message: 'Message',
} as const;

export type SystemComplainType = (typeof SystemComplainType)[keyof typeof SystemComplainType];
export type SystemComplainTypeFilter = {
    equals?: InputMaybe<SystemComplainType>;
    in?: InputMaybe<Array<SystemComplainType>>;
    not?: InputMaybe<SystemComplainType>;
    notIn?: InputMaybe<Array<SystemComplainType>>;
};

export type SystemComplainUpdateInput = {
    status?: InputMaybe<ComplainStatus>;
    type?: InputMaybe<SystemComplainType>;
};

export type SystemComplainUpdateManyMutationInput = {
    status?: InputMaybe<ComplainStatus>;
    type?: InputMaybe<SystemComplainType>;
};

export type SystemComplainUser = {
    __typename: 'SystemComplainUser';
    complain: SystemComplain;
    complainId: Scalars['String'];
    profile?: Maybe<Profile>;
    profileId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    systemComplainUserId: Scalars['String'];
    user?: Maybe<User>;
    userId?: Maybe<Scalars['String']>;
};

export const SystemComplainUserDistinctFieldEnum = {
    ComplainId: 'complainId',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    SystemComplainUserId: 'systemComplainUserId',
    UserId: 'userId',
} as const;

export type SystemComplainUserDistinctFieldEnum = (typeof SystemComplainUserDistinctFieldEnum)[keyof typeof SystemComplainUserDistinctFieldEnum];
export type SystemComplainUserListRelationFilter = {
    every?: InputMaybe<SystemComplainUserWhereOnlyScalarInput>;
    none?: InputMaybe<SystemComplainUserWhereOnlyScalarInput>;
    some?: InputMaybe<SystemComplainUserWhereOnlyScalarInput>;
};

export type SystemComplainUserOrderByInput = {
    complainId?: InputMaybe<SortOrder>;
    profileId?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    systemComplainUserId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type SystemComplainUserWhereInput = {
    complain?: InputMaybe<SystemComplainWhereInput>;
    complainId?: InputMaybe<StringFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringNullableFilter>;
    systemComplainUserId?: InputMaybe<StringFilter>;
    user?: InputMaybe<UserWhereInput>;
    userId?: InputMaybe<StringNullableFilter>;
};

export type SystemComplainUserWhereOnlyScalarInput = {
    complainId?: InputMaybe<StringFilter>;
    profileId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    systemComplainUserId?: InputMaybe<StringFilter>;
    userId?: InputMaybe<StringNullableFilter>;
};

export type SystemComplainUserWhereUniqueInput = {
    systemComplainUserId: Scalars['String'];
};

export type SystemComplainWhereInput = {
    admin?: InputMaybe<AdminUserWhereInput>;
    adminId?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    message?: InputMaybe<MessageWhereInput>;
    messageId?: InputMaybe<StringNullableFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<ComplainStatusFilter>;
    systemComplainId?: InputMaybe<StringFilter>;
    systemComplainUsers?: InputMaybe<SystemComplainUserListRelationFilter>;
    type?: InputMaybe<SystemComplainTypeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SystemComplainWhereOnlyScalarInput = {
    adminId?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    messageId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<ComplainStatusFilter>;
    systemComplainId?: InputMaybe<StringFilter>;
    type?: InputMaybe<SystemComplainTypeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SystemComplainWhereUniqueInput = {
    systemComplainId: Scalars['String'];
};

export type Ti_CI_PNCompoundUniqueInput = {
    conversationId: Scalars['String'];
    projectName: Scalars['String'];
    teamId: Scalars['String'];
};

export type TeamIdChatIdCompoundUniqueInput = {
    chatId: Scalars['String'];
    teamId: Scalars['String'];
};

export type Theme = {
    __typename: 'Theme';
    createdAt: Scalars['DateTime'];
    images?: Maybe<Array<ThemeImage>>;
    label: Scalars['String'];
    name: Scalars['String'];
    projectName: Scalars['String'];
    themeId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ThemeImagesArgs = {
    cursor?: InputMaybe<ThemeImageWhereUniqueInput>;
    distinct?: InputMaybe<ThemeImageDistinctFieldEnum>;
    orderBy?: InputMaybe<ThemeImageOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ThemeImageWhereInput>;
};

export type ThemeCreateInput = {
    label: Scalars['String'];
    name: Scalars['String'];
    project: ProjectWhereUniqueInput;
};

export type ThemeImage = {
    __typename: 'ThemeImage';
    label: Scalars['String'];
    path: Scalars['String'];
    projectName: Scalars['String'];
    theme: Theme;
    themeImageId: Scalars['String'];
    themeName: Scalars['String'];
};

export type ThemeImagePathArgs = {
    relative?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type ThemeImageCreateInput = {
    aTheme: ThemeWhereUniqueInput;
    base64Image: Scalars['String'];
    label: Scalars['String'];
};

export type ThemeImageCreateManyWithoutThemeInput = {
    connect?: InputMaybe<Array<ThemeImageWhereUniqueInput>>;
    create?: InputMaybe<Array<ThemeImageCreateWithoutThemeInput>>;
};

export type ThemeImageCreateWithoutThemeInput = {
    base64Image: Scalars['String'];
    label: Scalars['String'];
};

export const ThemeImageDistinctFieldEnum = {
    Label: 'label',
    Path: 'path',
    ProjectName: 'projectName',
    ThemeImageId: 'themeImageId',
    ThemeName: 'themeName',
} as const;

export type ThemeImageDistinctFieldEnum = (typeof ThemeImageDistinctFieldEnum)[keyof typeof ThemeImageDistinctFieldEnum];
export type ThemeImageIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    themeImageId: Scalars['String'];
};

export type ThemeImageListRelationFilter = {
    every?: InputMaybe<ThemeImageWhereInput>;
    none?: InputMaybe<ThemeImageWhereInput>;
    some?: InputMaybe<ThemeImageWhereInput>;
};

export type ThemeImageOrderByInput = {
    label?: InputMaybe<SortOrder>;
    path?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    themeName?: InputMaybe<SortOrder>;
};

export type ThemeImageWhereInput = {
    label?: InputMaybe<StringFilter>;
    path?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    theme?: InputMaybe<ThemeWhereInput>;
    themeName?: InputMaybe<StringFilter>;
};

export type ThemeImageWhereUniqueInput = {
    projectName: Scalars['String'];
    themeImageId: Scalars['String'];
};

export type ThemeOrderByInput = {
    aThemeId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    label?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ThemeUpdateInput = {
    label?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ThemeWhereInput = {
    aThemeId?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    images?: InputMaybe<ThemeImageListRelationFilter>;
    label?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    profiles?: InputMaybe<ProfileListRelationFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ThemeWhereUniqueInput = {
    name_projectName: NameProjectNameCompoundUniqueInput;
};

export type Transaction = {
    __typename: 'Transaction';
    bonus?: Maybe<Scalars['Int']>;
    coins: Scalars['Int'];
    cost: Scalars['Float'];
    countryBilling?: Maybe<Scalars['String']>;
    countryGeo?: Maybe<Scalars['String']>;
    countryUser?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    currency?: Maybe<Scalars['String']>;
    error?: Maybe<Scalars['String']>;
    extra?: Maybe<Scalars['String']>;
    invoiceId?: Maybe<Scalars['String']>;
    ip: Scalars['String'];
    net?: Maybe<Scalars['Float']>;
    offer: Scalars['String'];
    projectName: Scalars['String'];
    provider: Provider;
    providerName: Scalars['String'];
    recurring: Scalars['Boolean'];
    reference?: Maybe<Scalars['String']>;
    status: TransactionStatus;
    transactionId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    user?: Maybe<User>;
    userId: Scalars['String'];
    vatRate?: Maybe<Scalars['Int']>;
};

export type TransactionAggregateResult = {
    __typename: 'TransactionAggregateResult';
    _avg?: Maybe<TransactionAvgAggregateOutput>;
    _count?: Maybe<TransactionCountAggregateOutput>;
    _max?: Maybe<TransactionMinMaxAggregateOutput>;
    _min?: Maybe<TransactionMinMaxAggregateOutput>;
    _sum?: Maybe<TransactionSumAggregateOutput>;
};

export type TransactionAvgAggregateOutput = {
    __typename: 'TransactionAvgAggregateOutput';
    bonus?: Maybe<Scalars['Float']>;
    coins?: Maybe<Scalars['Float']>;
    cost?: Maybe<Scalars['Float']>;
};

export type TransactionAvgSumAggregateInput = {
    bonus?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    cost?: InputMaybe<Scalars['true']>;
};

export type TransactionAvgSumOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    cost?: InputMaybe<SortOrder>;
};

export type TransactionCountAggregateInput = {
    _all?: InputMaybe<Scalars['true']>;
    bonus?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    cost?: InputMaybe<Scalars['true']>;
    countryBilling?: InputMaybe<Scalars['true']>;
    countryGeo?: InputMaybe<Scalars['true']>;
    countryUser?: InputMaybe<Scalars['true']>;
    createdAt?: InputMaybe<Scalars['true']>;
    error?: InputMaybe<Scalars['true']>;
    extra?: InputMaybe<Scalars['true']>;
    ip?: InputMaybe<Scalars['true']>;
    offer?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    providerName?: InputMaybe<Scalars['true']>;
    recurring?: InputMaybe<Scalars['true']>;
    reference?: InputMaybe<Scalars['true']>;
    status?: InputMaybe<Scalars['true']>;
    transactionId?: InputMaybe<Scalars['true']>;
    updatedAt?: InputMaybe<Scalars['true']>;
    userId?: InputMaybe<Scalars['true']>;
};

export type TransactionCountAggregateOutput = {
    __typename: 'TransactionCountAggregateOutput';
    _all?: Maybe<Scalars['Int']>;
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    cost?: Maybe<Scalars['Int']>;
    countryBilling?: Maybe<Scalars['Int']>;
    countryGeo?: Maybe<Scalars['Int']>;
    countryUser?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Int']>;
    error?: Maybe<Scalars['Int']>;
    extra?: Maybe<Scalars['Int']>;
    ip?: Maybe<Scalars['Int']>;
    offer?: Maybe<Scalars['Int']>;
    projectName?: Maybe<Scalars['Int']>;
    providerName?: Maybe<Scalars['Int']>;
    recurring?: Maybe<Scalars['Int']>;
    reference?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['Int']>;
    transactionId?: Maybe<Scalars['Int']>;
    updatedAt?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['Int']>;
};

export type TransactionCountMinMaxOrderByAggregateInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    cost?: InputMaybe<SortOrder>;
    countryBilling?: InputMaybe<SortOrder>;
    countryGeo?: InputMaybe<SortOrder>;
    countryUser?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    error?: InputMaybe<SortOrder>;
    extra?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    offer?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerName?: InputMaybe<SortOrder>;
    recurring?: InputMaybe<SortOrder>;
    reference?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    transactionId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
    bonus?: InputMaybe<Scalars['Int']>;
    coins: Scalars['Int'];
    cost: Scalars['Float'];
    countryBilling?: InputMaybe<Scalars['String']>;
    countryGeo?: InputMaybe<Scalars['String']>;
    countryUser?: InputMaybe<Scalars['String']>;
    extra?: InputMaybe<Scalars['String']>;
    offer: Scalars['String'];
    project: ProjectWhereUniqueInput;
    reference?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<TransactionStatus>;
    user: UserWhereUniqueInput;
};

export const TransactionDistinctFieldEnum = {
    Bonus: 'bonus',
    Coins: 'coins',
    Cost: 'cost',
    CountryBilling: 'countryBilling',
    CountryGeo: 'countryGeo',
    CountryUser: 'countryUser',
    Error: 'error',
    Extra: 'extra',
    Ip: 'ip',
    ProjectName: 'projectName',
    ProviderName: 'providerName',
    Recurring: 'recurring',
    Reference: 'reference',
    Status: 'status',
    TransactionId: 'transactionId',
    UserId: 'userId',
} as const;

export type TransactionDistinctFieldEnum = (typeof TransactionDistinctFieldEnum)[keyof typeof TransactionDistinctFieldEnum];
export type TransactionExport = {
    __typename: 'TransactionExport';
    path?: Maybe<Scalars['String']>;
    status: Scalars['Boolean'];
};

export type TransactionFindManyGroupByResult = {
    __typename: 'TransactionFindManyGroupByResult';
    _avg?: Maybe<TransactionAvgAggregateOutput>;
    _count?: Maybe<TransactionCountAggregateOutput>;
    _max?: Maybe<TransactionMinMaxAggregateOutput>;
    _min?: Maybe<TransactionMinMaxAggregateOutput>;
    _sum?: Maybe<TransactionSumAggregateOutput>;
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    cost?: Maybe<Scalars['Float']>;
    countryBilling?: Maybe<Scalars['String']>;
    countryGeo?: Maybe<Scalars['String']>;
    countryUser?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    error?: Maybe<Scalars['String']>;
    extra?: Maybe<Scalars['String']>;
    ip?: Maybe<Scalars['String']>;
    offer?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    providerName?: Maybe<Scalars['String']>;
    recurring?: Maybe<Scalars['Boolean']>;
    reference?: Maybe<Scalars['String']>;
    status?: Maybe<TransactionStatus>;
    transactionId?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userId?: Maybe<Scalars['String']>;
};

export type TransactionIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    transactionId: Scalars['String'];
};

export type TransactionListRelationFilter = {
    every?: InputMaybe<TransactionWhereInput>;
    none?: InputMaybe<TransactionWhereInput>;
    some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMinMaxAggregateInput = {
    bonus?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    cost?: InputMaybe<Scalars['true']>;
    countryBilling?: InputMaybe<Scalars['true']>;
    countryGeo?: InputMaybe<Scalars['true']>;
    countryUser?: InputMaybe<Scalars['true']>;
    createdAt?: InputMaybe<Scalars['true']>;
    error?: InputMaybe<Scalars['true']>;
    extra?: InputMaybe<Scalars['true']>;
    ip?: InputMaybe<Scalars['true']>;
    offer?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    providerName?: InputMaybe<Scalars['true']>;
    recurring?: InputMaybe<Scalars['true']>;
    reference?: InputMaybe<Scalars['true']>;
    status?: InputMaybe<Scalars['true']>;
    transactionId?: InputMaybe<Scalars['true']>;
    updatedAt?: InputMaybe<Scalars['true']>;
    userId?: InputMaybe<Scalars['true']>;
};

export type TransactionMinMaxAggregateOutput = {
    __typename: 'TransactionMinMaxAggregateOutput';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    cost?: Maybe<Scalars['Decimal']>;
    countryBilling?: Maybe<Scalars['String']>;
    countryGeo?: Maybe<Scalars['String']>;
    countryUser?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    error?: Maybe<Scalars['String']>;
    extra?: Maybe<Scalars['String']>;
    ip?: Maybe<Scalars['String']>;
    offer?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    providerName?: Maybe<Scalars['String']>;
    recurring?: Maybe<Scalars['Boolean']>;
    reference?: Maybe<Scalars['String']>;
    status?: Maybe<TransactionStatus>;
    transactionId?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userId?: Maybe<Scalars['String']>;
};

export type TransactionOrderByInput = {
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    cost?: InputMaybe<SortOrder>;
    countryBilling?: InputMaybe<SortOrder>;
    countryGeo?: InputMaybe<SortOrder>;
    countryUser?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    error?: InputMaybe<SortOrder>;
    extra?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerName?: InputMaybe<SortOrder>;
    recurring?: InputMaybe<SortOrder>;
    reference?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    transactionId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
    _avg?: InputMaybe<TransactionAvgSumOrderByAggregateInput>;
    _count?: InputMaybe<TransactionCountMinMaxOrderByAggregateInput>;
    _max?: InputMaybe<TransactionCountMinMaxOrderByAggregateInput>;
    _min?: InputMaybe<TransactionCountMinMaxOrderByAggregateInput>;
    _sum?: InputMaybe<TransactionAvgSumOrderByAggregateInput>;
    bonus?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    cost?: InputMaybe<SortOrder>;
    countryBilling?: InputMaybe<SortOrder>;
    countryGeo?: InputMaybe<SortOrder>;
    countryUser?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    error?: InputMaybe<SortOrder>;
    extra?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    offer?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    providerName?: InputMaybe<SortOrder>;
    recurring?: InputMaybe<SortOrder>;
    reference?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    transactionId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export const TransactionScalarFieldEnum = {
    AdminUserName: 'adminUserName',
    Bonus: 'bonus',
    Coins: 'coins',
    Cost: 'cost',
    CountryBilling: 'countryBilling',
    CountryGeo: 'countryGeo',
    CountryUser: 'countryUser',
    CreatedAt: 'createdAt',
    Currency: 'currency',
    Error: 'error',
    Extra: 'extra',
    InvoiceId: 'invoiceId',
    Ip: 'ip',
    Net: 'net',
    Offer: 'offer',
    OfferId: 'offerId',
    ProjectName: 'projectName',
    ProviderName: 'providerName',
    Recurring: 'recurring',
    Reference: 'reference',
    Status: 'status',
    Tracked: 'tracked',
    TransactionId: 'transactionId',
    UpdatedAt: 'updatedAt',
    UserId: 'userId',
    VatRate: 'vatRate',
} as const;

export type TransactionScalarFieldEnum = (typeof TransactionScalarFieldEnum)[keyof typeof TransactionScalarFieldEnum];
export type TransactionScalarWhereInput = {
    adminUserName?: InputMaybe<StringNullableFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntFilter>;
    cost?: InputMaybe<FloatFilter>;
    countryBilling?: InputMaybe<StringNullableFilter>;
    countryGeo?: InputMaybe<StringNullableFilter>;
    countryUser?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    error?: InputMaybe<StringNullableFilter>;
    extra?: InputMaybe<StringNullableFilter>;
    ip?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    providerName?: InputMaybe<StringFilter>;
    recurring?: InputMaybe<BoolFilter>;
    reference?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<TransactionStatusFilter>;
    transactionId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
};

export const TransactionStatus = {
    Canceled: 'Canceled',
    Complete: 'Complete',
    Error: 'Error',
    Open: 'Open',
    Pending: 'Pending',
} as const;

export type TransactionStatus = (typeof TransactionStatus)[keyof typeof TransactionStatus];
export type TransactionStatusFilter = {
    equals?: InputMaybe<TransactionStatus>;
    in?: InputMaybe<Array<TransactionStatus>>;
    not?: InputMaybe<TransactionStatus>;
    notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type TransactionSumAggregateOutput = {
    __typename: 'TransactionSumAggregateOutput';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    cost?: Maybe<Scalars['Float']>;
};

export type TransactionWhereInput = {
    AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
    NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
    OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
    adminUserName?: InputMaybe<StringNullableFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntFilter>;
    cost?: InputMaybe<FloatFilter>;
    countryBilling?: InputMaybe<StringNullableFilter>;
    countryGeo?: InputMaybe<StringNullableFilter>;
    countryUser?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    email?: InputMaybe<StringNullableFilter>;
    error?: InputMaybe<StringNullableFilter>;
    extra?: InputMaybe<StringNullableFilter>;
    ip?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    provider?: InputMaybe<ProviderWhereInput>;
    providerName?: InputMaybe<StringFilter>;
    recurring?: InputMaybe<BoolFilter>;
    reference?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<TransactionStatusFilter>;
    transactionId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
    username?: InputMaybe<StringNullableFilter>;
};

export type TransactionWhereInputWithoutUser = {
    adminUserName?: InputMaybe<StringNullableFilter>;
    bonus?: InputMaybe<IntNullableFilter>;
    coins?: InputMaybe<IntFilter>;
    cost?: InputMaybe<FloatFilter>;
    countryBilling?: InputMaybe<StringNullableFilter>;
    countryGeo?: InputMaybe<StringNullableFilter>;
    countryUser?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    error?: InputMaybe<StringNullableFilter>;
    extra?: InputMaybe<StringNullableFilter>;
    ip?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    provider?: InputMaybe<ProviderWhereInput>;
    providerName?: InputMaybe<StringFilter>;
    recurring?: InputMaybe<BoolFilter>;
    reference?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<TransactionStatusFilter>;
    transactionId?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
};

export type TransactionWhereUniqueInput = {
    transactionId_projectName: TransactionIdProjectNameCompoundUniqueInput;
};

export type Translation = {
    __typename: 'Translation';
    avatarId?: Maybe<Scalars['String']>;
    complainTypeId?: Maybe<Scalars['String']>;
    flagId?: Maybe<Scalars['String']>;
    flagValueId?: Maybe<Scalars['String']>;
    giftCategoryId?: Maybe<Scalars['String']>;
    giftId?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    interestCategoryId?: Maybe<Scalars['String']>;
    interestId?: Maybe<Scalars['String']>;
    languageCode: Scalars['String'];
    predefinedMessageId?: Maybe<Scalars['String']>;
    profileTextId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    translation: Scalars['String'];
    translationId: Scalars['String'];
    type: TranslationType;
};

export type TranslationCreateInput = {
    avatarId?: InputMaybe<Scalars['String']>;
    id: Scalars['String'];
    languageCode: Scalars['String'];
    profileTextId?: InputMaybe<Scalars['String']>;
    projectName: Scalars['String'];
    translation: Scalars['String'];
    type: TranslationType;
};

export type TranslationCreateNestedManyWithoutXInput = {
    create: Array<TranslationCreateWithoutXInput>;
};

export type TranslationCreateWithoutXInput = {
    languageCode: Scalars['String'];
    projectName: Scalars['String'];
    translation: Scalars['String'];
};

export type TranslationLanguageCodeAvatarIdTranslationViewCompoundUniqueInput = {
    avatarId: Scalars['String'];
    languageCode: Scalars['String'];
    translationView?: TranslationView;
};

export type TranslationLanguageCodePredefinedMessageIdTranslationViewCompoundUniqueInput = {
    languageCode: Scalars['String'];
    predefinedMessageId: Scalars['String'];
    translationView?: TranslationView;
};

export type TranslationLanguageCodeProfileTextIdTranslationViewCompoundUniqueInput = {
    languageCode: Scalars['String'];
    profileTextId: Scalars['String'];
    translationView?: TranslationView;
};

export const TranslationType = {
    Avatar: 'Avatar',
    ComplainType: 'ComplainType',
    Flag: 'Flag',
    FlagValue: 'FlagValue',
    Gift: 'Gift',
    GiftCategory: 'GiftCategory',
    Interest: 'Interest',
    InterestCategory: 'InterestCategory',
    PredefinedMessage: 'PredefinedMessage',
    ProfileText: 'ProfileText',
} as const;

export type TranslationType = (typeof TranslationType)[keyof typeof TranslationType];
export type TranslationUpdateInput = {
    avatarId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    languageCode?: InputMaybe<Scalars['String']>;
    profileTextId?: InputMaybe<Scalars['String']>;
    projectName?: InputMaybe<Scalars['String']>;
    translation?: InputMaybe<Scalars['String']>;
    translationView?: InputMaybe<TranslationView>;
    type?: InputMaybe<TranslationType>;
};

export type TranslationUpdateManyWithoutXNestedInput = {
    create?: InputMaybe<Array<TranslationCreateWithoutXInput>>;
    delete?: InputMaybe<Array<TranslationWhereUniqueInput>>;
    update?: InputMaybe<Array<TranslationUpdateWithWhereUniqueWithoutXInput>>;
};

export type TranslationUpdateWithWhereUniqueWithoutXInput = {
    data: TranslationUpdateWithoutXInput;
    where: TranslationWhereUniqueInput;
};

export type TranslationUpdateWithoutXInput = {
    languageCode?: InputMaybe<Scalars['String']>;
    translation?: InputMaybe<Scalars['String']>;
};

export const TranslationView = {
    Edit: 'Edit',
    Show: 'Show',
} as const;

export type TranslationView = (typeof TranslationView)[keyof typeof TranslationView];
export type TranslationWhereInput = {
    avatarId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    languageCode?: InputMaybe<StringFilter>;
    predefinedMessageId?: InputMaybe<Scalars['String']>;
    translationId?: InputMaybe<StringFilter>;
    type?: InputMaybe<TranslationType>;
};

export type TranslationWhereUniqueInput = {
    translationId: Scalars['String'];
};

export type TypePriorityCompoundUniqueInput = {
    priority: Scalars['Int'];
    type: QueueType;
};

export type Ui_D_H_PN_TCompoundUniqueInput = {
    date: Scalars['String'];
    hour: Scalars['Int'];
    moderatorId: Scalars['String'];
    projectName: Scalars['String'];
    type: ModerationMessageType;
    userId: Scalars['String'];
};

export type Ui_D_PN_TCompoundUniqueInput = {
    date: Scalars['String'];
    moderatorId: Scalars['String'];
    projectName: Scalars['String'];
    type: ModerationMessageType;
    userId: Scalars['String'];
};

export type User = {
    __typename: 'User';
    adminChanged: Scalars['DateTime'];
    adminUserId?: Maybe<Scalars['String']>;
    adminUserText: Array<AdminUserText>;
    bonus: Scalars['Int'];
    c4fAction?: Maybe<Scalars['String']>;
    c4fId?: Maybe<Scalars['String']>;
    client?: Maybe<Scalars['String']>;
    clientVersion?: Maybe<Scalars['String']>;
    coins: Scalars['Int'];
    coinsHistory?: Maybe<Array<CoinsHistory>>;
    conditionAcceptedAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    createdIP: Scalars['String'];
    domain?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    emailBounce: Scalars['String'];
    emailSuspendedUntil?: Maybe<Scalars['DateTime']>;
    emailValidated: Scalars['Boolean'];
    emailValidatedAt?: Maybe<Scalars['DateTime']>;
    flirtTextCompleted: Scalars['Boolean'];
    hasPayed: Scalars['Boolean'];
    imageCompleted: Scalars['Boolean'];
    lastAction?: Maybe<Scalars['String']>;
    lastActivity?: Maybe<Scalars['DateTime']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    lastMessageReceived?: Maybe<Scalars['DateTime']>;
    lastMessageSend?: Maybe<Scalars['DateTime']>;
    lastPayedAt?: Maybe<Scalars['DateTime']>;
    latitude?: Maybe<Scalars['String']>;
    legacyUserId?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['String']>;
    mobileNumber?: Maybe<Scalars['String']>;
    mobileNumberValidated?: Maybe<Scalars['Boolean']>;
    mobileNumberValidatedAt?: Maybe<Scalars['DateTime']>;
    online: Scalars['Boolean'];
    profile?: Maybe<Profile>;
    profileFlagsCompleted: Scalars['Boolean'];
    profileId?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    registerDomain: Scalars['String'];
    role: UserRole;
    sendMarketingEmail: Scalars['Boolean'];
    sendSMS: Scalars['Boolean'];
    sendTransactionalEmail: Scalars['Boolean'];
    socials: Array<Social>;
    systemComplainUsers?: Maybe<Array<SystemComplainUser>>;
    transactionCount: Scalars['Int'];
    unModerated: Scalars['Boolean'];
    updatedAt: Scalars['DateTime'];
    userChanged: Scalars['DateTime'];
    userComplains?: Maybe<Array<UserComplain>>;
    userCoupons: Array<UserCoupon>;
    userId: Scalars['String'];
    userPremiumStatus: Array<UserPremiumStatus>;
    userStatistic?: Maybe<UserStatistic>;
    username?: Maybe<Scalars['String']>;
    verified: Scalars['Boolean'];
    verifiedBy?: Maybe<AdminUser>;
};

export type UserAdminUserTextArgs = {
    cursor?: InputMaybe<AdminUserTextWhereUniqueInput>;
    distinct?: InputMaybe<Array<AdminUserTextDistinctFieldEnum>>;
    orderBy?: InputMaybe<AdminUserTextOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<AdminUserTextWhereInput>;
};

export type UserCoinsHistoryArgs = {
    cursor?: InputMaybe<CoinsHistoryWhereUniqueInput>;
    distinct?: InputMaybe<CoinsHistoryDistinctFieldEnum>;
    orderBy?: InputMaybe<CoinsHistoryOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<CoinsHistoryWhereInput>;
};

export type UserSystemComplainUsersArgs = {
    cursor?: InputMaybe<SystemComplainUserWhereUniqueInput>;
    distinct?: InputMaybe<Array<SystemComplainUserDistinctFieldEnum>>;
    orderBy?: InputMaybe<SystemComplainUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<SystemComplainUserWhereInput>;
};

export type UserUserComplainsArgs = {
    cursor?: InputMaybe<UserComplainWhereUniqueInput>;
    distinct?: InputMaybe<Array<UserComplainDistinctFieldEnum>>;
    orderBy?: InputMaybe<UserComplainOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserComplainWhereInput>;
};

export type UserUserCouponsArgs = {
    cursor?: InputMaybe<UserCouponWhereUniqueInput>;
    orderBy?: InputMaybe<UserCouponOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<UserCouponWhereInput>;
};

export type UserAvgSumAggregateInputType = {
    bonus?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
};

export type UserAvgSumAggregateOutputType = {
    __typename: 'UserAvgSumAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
};

export type UserCoinsUpdateInput = {
    bonus?: InputMaybe<Scalars['Int']>;
    coins?: InputMaybe<Scalars['Int']>;
};

export type UserComplain = {
    __typename: 'UserComplain';
    complainType: ComplainType;
    complainTypeName: Scalars['String'];
    createdAt: Scalars['DateTime'];
    /** identifier cuid */
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    projectName: Scalars['String'];
    reporter: User;
    reporterId: Scalars['String'];
    supportTicketId?: Maybe<Scalars['String']>;
    target: Profile;
    targetId: Scalars['String'];
    text?: Maybe<Scalars['String']>;
    userComplainId: Scalars['String'];
};

export const UserComplainDistinctFieldEnum = {
    ComplainTypeName: 'complainTypeName',
    CreatedAt: 'createdAt',
    Name: 'name',
    ProjectName: 'projectName',
    ReporterId: 'reporterId',
    TargetId: 'targetId',
    Text: 'text',
    UserComplainId: 'userComplainId',
} as const;

export type UserComplainDistinctFieldEnum = (typeof UserComplainDistinctFieldEnum)[keyof typeof UserComplainDistinctFieldEnum];
export type UserComplainIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    userComplainId: Scalars['String'];
};

export type UserComplainListRelationFilter = {
    every?: InputMaybe<UserComplainWhereInput>;
    none?: InputMaybe<UserComplainWhereInput>;
    some?: InputMaybe<UserComplainWhereInput>;
};

export type UserComplainOrderByInput = {
    complainTypeName?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    reporterId?: InputMaybe<SortOrder>;
    targetId?: InputMaybe<SortOrder>;
    text?: InputMaybe<SortOrder>;
    userComplainId?: InputMaybe<SortOrder>;
};

export type UserComplainWhereInput = {
    D?: InputMaybe<Array<UserComplainWhereInput>>;
    NOT?: InputMaybe<Array<UserComplainWhereInput>>;
    OR?: InputMaybe<Array<UserComplainWhereInput>>;
    complainType?: InputMaybe<ComplainTypeWhereInput>;
    complainTypeName?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    name?: InputMaybe<StringFilter>;
    project?: InputMaybe<ProjectWhereInput>;
    projectName?: InputMaybe<StringFilter>;
    reporter?: InputMaybe<UserWhereInput>;
    reporterId?: InputMaybe<StringFilter>;
    target?: InputMaybe<ProfileWhereInput>;
    targetId?: InputMaybe<StringFilter>;
    text?: InputMaybe<StringFilter>;
    userComplainId?: InputMaybe<StringFilter>;
};

export type UserComplainWhereUniqueInput = {
    userComplainId_projectName: UserComplainIdProjectNameCompoundUniqueInput;
};

export type UserCountAggregateOutputType = {
    __typename: 'UserCountAggregateOutputType';
    _all?: Maybe<Scalars['Int']>;
    bonus?: Maybe<Scalars['Int']>;
    c4fAction?: Maybe<Scalars['Int']>;
    c4fId?: Maybe<Scalars['Int']>;
    client?: Maybe<Scalars['Int']>;
    clientVersion?: Maybe<Scalars['Int']>;
    coins?: Maybe<Scalars['Int']>;
    conditionAcceptedAt?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Int']>;
    emailValidated?: Maybe<Scalars['Int']>;
    emailValidatedAt?: Maybe<Scalars['Int']>;
    flirtTextCompleted?: Maybe<Scalars['Int']>;
    hasPayed?: Maybe<Scalars['Int']>;
    imageCompleted?: Maybe<Scalars['Int']>;
    lastAction?: Maybe<Scalars['Int']>;
    lastActivity?: Maybe<Scalars['Int']>;
    lastLogin?: Maybe<Scalars['Int']>;
    lastMessageReceived?: Maybe<Scalars['Int']>;
    lastMessageSend?: Maybe<Scalars['Int']>;
    mobileNumberValidated?: Maybe<Scalars['Int']>;
    mobileNumberValidatedAt?: Maybe<Scalars['Int']>;
    online?: Maybe<Scalars['Int']>;
    profileFlagsCompleted?: Maybe<Scalars['Int']>;
    projectName?: Maybe<Scalars['Int']>;
    pushToken?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['Int']>;
    sendMarketingEmail?: Maybe<Scalars['Int']>;
    sendSMS?: Maybe<Scalars['Int']>;
    sendTransactionalEmail?: Maybe<Scalars['Int']>;
    updatedAt?: Maybe<Scalars['Int']>;
    userChanged?: Maybe<Scalars['Int']>;
    verified?: Maybe<Scalars['Int']>;
};

export type UserCountEmailProvider = {
    __typename: 'UserCountEmailProvider';
    _count?: Maybe<Scalars['Int']>;
    emailProvider?: Maybe<Scalars['String']>;
};

export type UserCountMaxMinAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    bonus?: InputMaybe<Scalars['true']>;
    c4fAction?: InputMaybe<Scalars['true']>;
    c4fId?: InputMaybe<Scalars['true']>;
    client?: InputMaybe<Scalars['true']>;
    clientVersion?: InputMaybe<Scalars['true']>;
    coins?: InputMaybe<Scalars['true']>;
    conditionAcceptedAt?: InputMaybe<Scalars['true']>;
    createdAt?: InputMaybe<Scalars['true']>;
    emailValidated?: InputMaybe<Scalars['true']>;
    emailValidatedAt?: InputMaybe<Scalars['true']>;
    flirtTextCompleted?: InputMaybe<Scalars['true']>;
    hasPayed?: InputMaybe<Scalars['true']>;
    imageCompleted?: InputMaybe<Scalars['true']>;
    lastAction?: InputMaybe<Scalars['true']>;
    lastActivity?: InputMaybe<Scalars['true']>;
    lastLogin?: InputMaybe<Scalars['true']>;
    lastMessageReceived?: InputMaybe<Scalars['true']>;
    lastMessageSend?: InputMaybe<Scalars['true']>;
    mobileNumberValidated?: InputMaybe<Scalars['true']>;
    mobileNumberValidatedAt?: InputMaybe<Scalars['true']>;
    online?: InputMaybe<Scalars['true']>;
    profileFlagsCompleted?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    pushToken?: InputMaybe<Scalars['true']>;
    role?: InputMaybe<Scalars['true']>;
    sendMarketingEmail?: InputMaybe<Scalars['true']>;
    sendSMS?: InputMaybe<Scalars['true']>;
    sendTransactionalEmail?: InputMaybe<Scalars['true']>;
    updatedAt?: InputMaybe<Scalars['true']>;
    userChanged?: InputMaybe<Scalars['true']>;
    verified?: InputMaybe<Scalars['true']>;
};

export type UserCountSocial = {
    __typename: 'UserCountSocial';
    _count?: Maybe<Scalars['Int']>;
    providerName: Scalars['String'];
};

export type UserCoupon = {
    __typename: 'UserCoupon';
    coupon: Coupon;
    couponCode: Scalars['String'];
    createdAt: Scalars['DateTime'];
    projectName: Scalars['String'];
    user: User;
    userCouponId: Scalars['String'];
    userId: Scalars['String'];
};

export type UserCouponOrderByInput = {
    couponCode?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    userCouponId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type UserCouponUserIdCouponCodeCompoundUniqueInput = {
    couponCode: Scalars['String'];
    userId: Scalars['String'];
};

export type UserCouponWhereInput = {
    couponCode?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    profileName?: InputMaybe<StringFilter>;
    projectName?: InputMaybe<StringFilter>;
    userCouponId?: InputMaybe<StringFilter>;
    userId?: InputMaybe<StringFilter>;
};

export type UserCouponWhereUniqueInput = {
    userId_couponCode: UserCouponUserIdCouponCodeCompoundUniqueInput;
};

export const UserDistinctFieldEnum = {
    AdminChanged: 'adminChanged',
    Bonus: 'bonus',
    C4fAction: 'c4fAction',
    C4fId: 'c4fId',
    Client: 'client',
    ClientVersion: 'clientVersion',
    Coins: 'coins',
    ConditionAcceptedAt: 'conditionAcceptedAt',
    CreatedAt: 'createdAt',
    CreatedIp: 'createdIP',
    Domain: 'domain',
    Email: 'email',
    EmailValidated: 'emailValidated',
    EmailValidatedAt: 'emailValidatedAt',
    HasPayed: 'hasPayed',
    LastLogin: 'lastLogin',
    Latitude: 'latitude',
    Longitude: 'longitude',
    MobileNumber: 'mobileNumber',
    MobileNumberValidated: 'mobileNumberValidated',
    MobileNumberValidatedAt: 'mobileNumberValidatedAt',
    Password: 'password',
    ProfileId: 'profileId',
    ProjectName: 'projectName',
    Role: 'role',
    SendMarketingEmail: 'sendMarketingEmail',
    SendSms: 'sendSMS',
    SendTransactionalEmail: 'sendTransactionalEmail',
    UpdatedAt: 'updatedAt',
    UserChanged: 'userChanged',
    UserId: 'userId',
    Username: 'username',
    Verified: 'verified',
} as const;

export type UserDistinctFieldEnum = (typeof UserDistinctFieldEnum)[keyof typeof UserDistinctFieldEnum];
export type UserExport = {
    __typename: 'UserExport';
    path?: Maybe<Scalars['String']>;
    status: Scalars['Boolean'];
};

export type UserIdProjectNameCompoundUniqueInput = {
    projectName: Scalars['String'];
    userId: Scalars['String'];
};

export type UserListRelationFilter = {
    every?: InputMaybe<UserWhereInput>;
    none?: InputMaybe<UserWhereInput>;
    some?: InputMaybe<UserWhereInput>;
};

export type UserMaxMinAggregateOutputType = {
    __typename: 'UserMaxMinAggregateOutputType';
    bonus?: Maybe<Scalars['Int']>;
    c4fAction?: Maybe<Scalars['String']>;
    c4fId?: Maybe<Scalars['String']>;
    client?: Maybe<Scalars['String']>;
    clientVersion?: Maybe<Scalars['String']>;
    coins?: Maybe<Scalars['Int']>;
    conditionAcceptedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    emailValidated?: Maybe<Scalars['Boolean']>;
    emailValidatedAt?: Maybe<Scalars['DateTime']>;
    flirtTextCompleted?: Maybe<Scalars['Boolean']>;
    hasPayed?: Maybe<Scalars['Boolean']>;
    imageCompleted?: Maybe<Scalars['Boolean']>;
    lastAction?: Maybe<Scalars['String']>;
    lastActivity?: Maybe<Scalars['DateTime']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    lastMessageReceived?: Maybe<Scalars['DateTime']>;
    lastMessageSend?: Maybe<Scalars['DateTime']>;
    mobileNumberValidated?: Maybe<Scalars['Boolean']>;
    mobileNumberValidatedAt?: Maybe<Scalars['DateTime']>;
    online?: Maybe<Scalars['Boolean']>;
    profileFlagsCompleted?: Maybe<Scalars['Boolean']>;
    projectName?: Maybe<Scalars['String']>;
    pushToken?: Maybe<Scalars['String']>;
    role?: Maybe<UserRole>;
    sendMarketingEmail?: Maybe<Scalars['Boolean']>;
    sendSMS?: Maybe<Scalars['Boolean']>;
    sendTransactionalEmail?: Maybe<Scalars['Boolean']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userChanged?: Maybe<Scalars['DateTime']>;
    verified?: Maybe<Scalars['Boolean']>;
};

export type UserOrderByInput = {
    adminChanged?: InputMaybe<SortOrder>;
    bonus?: InputMaybe<SortOrder>;
    c4fAction?: InputMaybe<SortOrder>;
    c4fId?: InputMaybe<SortOrder>;
    client?: InputMaybe<SortOrder>;
    clientVersion?: InputMaybe<SortOrder>;
    coins?: InputMaybe<SortOrder>;
    conditionAcceptedAt?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    createdIP?: InputMaybe<SortOrder>;
    domain?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    emailValidated?: InputMaybe<SortOrder>;
    emailValidatedAt?: InputMaybe<SortOrder>;
    hasPayed?: InputMaybe<SortOrder>;
    lastLogin?: InputMaybe<SortOrder>;
    legacyUserId?: InputMaybe<SortOrder>;
    mobileNumber?: InputMaybe<SortOrder>;
    mobileNumberValidated?: InputMaybe<SortOrder>;
    mobileNumberValidatedAt?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    role?: InputMaybe<SortOrder>;
    sendMarketingEmail?: InputMaybe<SortOrder>;
    sendSMS?: InputMaybe<SortOrder>;
    sendTransactionalEmail?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    userChanged?: InputMaybe<SortOrder>;
    username?: InputMaybe<SortOrder>;
    verified?: InputMaybe<SortOrder>;
};

export type UserPremiumStatus = {
    __typename: 'UserPremiumStatus';
    createdAt: Scalars['DateTime'];
    profilePremiumStatusId: Scalars['String'];
    status: ProfileStatus;
    userId: Scalars['String'];
    validTo: Scalars['DateTime'];
};

export type UserPremiumStatusCreateWithoutUserInput = {
    status: ProfileStatus;
    validTo: Scalars['DateTime'];
};

export type UserPremiumStatusListRelationFilter = {
    every?: InputMaybe<UserPremiumStatusWhereInput>;
    none?: InputMaybe<UserPremiumStatusWhereInput>;
    some?: InputMaybe<UserPremiumStatusWhereInput>;
};

export type UserPremiumStatusUpdateManyWithoutUserNestedInput = {
    create?: InputMaybe<Array<UserPremiumStatusCreateWithoutUserInput>>;
    delete?: InputMaybe<Array<UserPremiumStatusWhereUniqueInput>>;
    update?: InputMaybe<Array<UserPremiumStatusUpdateWithWhereUniqueWithoutUserInput>>;
};

export type UserPremiumStatusUpdateWithWhereUniqueWithoutUserInput = {
    data: UserPremiumStatusUpdateWithoutUserInput;
    where: UserPremiumStatusWhereUniqueInput;
};

export type UserPremiumStatusUpdateWithoutUserInput = {
    status?: InputMaybe<ProfileStatus>;
    validTo?: InputMaybe<Scalars['DateTime']>;
};

export type UserPremiumStatusWhereInput = {
    createdAt?: InputMaybe<DateTimeFilter>;
    profilePremiumStatusId?: InputMaybe<StringFilter>;
    status?: InputMaybe<EnumProfileStatusFilter>;
    userId?: InputMaybe<StringFilter>;
    validTo?: InputMaybe<DateTimeFilter>;
};

export type UserPremiumStatusWhereUniqueInput = {
    profilePremiumStatusId: Scalars['String'];
};

export type UserRelativeWhereInput = {
    bonus?: InputMaybe<IntFilter>;
    coins?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeRelativeFilter>;
    domain?: InputMaybe<StringNullableFilter>;
    email?: InputMaybe<StringFilter>;
    hasPayed?: InputMaybe<BoolFilter>;
    lastActivity?: InputMaybe<DateTimeRelativeFilter>;
    lastLogin?: InputMaybe<DateTimeRelativeFilter>;
    lastMessageReceived?: InputMaybe<DateTimeRelativeFilter>;
    lastMessageSend?: InputMaybe<DateTimeRelativeFilter>;
    legacyUserId?: InputMaybe<StringNullableFilter>;
    online?: InputMaybe<BoolFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    registerDomain?: InputMaybe<StringNullableFilter>;
    role?: InputMaybe<UserRoleFilter>;
    socials?: InputMaybe<SocialListRelationFilter>;
    unModerated?: InputMaybe<BoolFilter>;
    userPremiumStatus?: InputMaybe<UserPremiumStatusListRelationFilter>;
    userStatistics?: InputMaybe<UserStatisticListRelationFilter>;
};

export const UserRole = {
    Blocked: 'Blocked',
    Deleted: 'Deleted',
    Guest: 'Guest',
    Import: 'Import',
    Incomplete: 'Incomplete',
    Lead: 'Lead',
    Member: 'Member',
    Premium: 'Premium',
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export type UserRoleFilter = {
    equals?: InputMaybe<UserRole>;
    in?: InputMaybe<Array<UserRole>>;
    not?: InputMaybe<UserRole>;
    notIn?: InputMaybe<Array<UserRole>>;
};

export type UserScalarWhereInput = {
    adminChanged?: InputMaybe<DateTimeFilter>;
    bonus?: InputMaybe<IntFilter>;
    c4fAction?: InputMaybe<StringNullableFilter>;
    c4fId?: InputMaybe<StringNullableFilter>;
    client?: InputMaybe<StringNullableFilter>;
    clientVersion?: InputMaybe<StringNullableFilter>;
    coins?: InputMaybe<IntFilter>;
    conditionAcceptedAt?: InputMaybe<DateTimeNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    createdIP?: InputMaybe<StringFilter>;
    domain?: InputMaybe<StringNullableFilter>;
    email?: InputMaybe<StringFilter>;
    emailValidated?: InputMaybe<BoolFilter>;
    emailValidatedAt?: InputMaybe<DateTimeNullableFilter>;
    hasPayed?: InputMaybe<BoolFilter>;
    lastAction?: InputMaybe<StringNullableFilter>;
    lastActivity?: InputMaybe<DateTimeNullableFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    lastMessageReceived?: InputMaybe<DateTimeNullableFilter>;
    lastMessageSend?: InputMaybe<DateTimeNullableFilter>;
    latitude?: InputMaybe<StringNullableFilter>;
    legacyUserId?: InputMaybe<StringNullableFilter>;
    longitude?: InputMaybe<StringNullableFilter>;
    mobileNumber?: InputMaybe<StringFilter>;
    mobileNumberValidated?: InputMaybe<BoolFilter>;
    mobileNumberValidatedAt?: InputMaybe<DateTimeNullableFilter>;
    online?: InputMaybe<BoolFilter>;
    profileId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringNullableFilter>;
    pushToken?: InputMaybe<StringNullableFilter>;
    role?: InputMaybe<UserRoleFilter>;
    sendMarketingEmail?: InputMaybe<BoolFilter>;
    sendSMS?: InputMaybe<BoolFilter>;
    sendTransactionalEmail?: InputMaybe<BoolFilter>;
    unModerated?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userChanged?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
    username?: InputMaybe<StringFilter>;
    verified?: InputMaybe<BoolFilter>;
};

export type UserStatistic = {
    __typename: 'UserStatistic';
    ins: Scalars['Decimal'];
    projectName: Scalars['String'];
    received: Scalars['Int'];
    receivedByMod: Array<ModeratedProfile>;
    receivedGifts: Scalars['Int'];
    receivedMessages: Scalars['Int'];
    receivedPictures: Scalars['Int'];
    receivedPokes: Scalars['Int'];
    sendToMod: Array<ModeratedProfile>;
    sent: Scalars['Int'];
    sentGifts: Scalars['Int'];
    sentMessages: Scalars['Int'];
    sentPictures: Scalars['Int'];
    sentPokes: Scalars['Int'];
    spentBonus: Scalars['Int'];
    spentCoins: Scalars['Int'];
    transactions: Scalars['Int'];
    turnover: Scalars['Decimal'];
    user?: Maybe<User>;
    userId: Scalars['String'];
    userStatisticId: Scalars['String'];
};

export type UserStatisticAvgMinMaxSumAggregateInputType = {
    ins?: InputMaybe<Scalars['true']>;
    received?: InputMaybe<Scalars['true']>;
    receivedGifts?: InputMaybe<Scalars['true']>;
    receivedMessages?: InputMaybe<Scalars['true']>;
    receivedPictures?: InputMaybe<Scalars['true']>;
    receivedPokes?: InputMaybe<Scalars['true']>;
    sentGifts?: InputMaybe<Scalars['true']>;
    sentMessages?: InputMaybe<Scalars['true']>;
    sentPictures?: InputMaybe<Scalars['true']>;
    sentPokes?: InputMaybe<Scalars['true']>;
    spentBonus?: InputMaybe<Scalars['true']>;
    spentCoins?: InputMaybe<Scalars['true']>;
    transactions?: InputMaybe<Scalars['true']>;
    turnover?: InputMaybe<Scalars['true']>;
};

export type UserStatisticAvgSumAggregateOutputType = {
    __typename: 'UserStatisticAvgSumAggregateOutputType';
    ins?: Maybe<Scalars['Decimal']>;
    received?: Maybe<Scalars['Float']>;
    receivedGifts?: Maybe<Scalars['Float']>;
    receivedMessages?: Maybe<Scalars['Float']>;
    receivedPictures?: Maybe<Scalars['Float']>;
    receivedPokes?: Maybe<Scalars['Float']>;
    sentGifts?: Maybe<Scalars['Float']>;
    sentMessages?: Maybe<Scalars['Float']>;
    sentPictures?: Maybe<Scalars['Float']>;
    sentPokes?: Maybe<Scalars['Float']>;
    spentBonus?: Maybe<Scalars['Float']>;
    spentCoins?: Maybe<Scalars['Float']>;
    transactions?: Maybe<Scalars['Float']>;
    turnover?: Maybe<Scalars['Decimal']>;
};

export type UserStatisticAvgSumOrderByAggregateInput = {
    ins?: InputMaybe<SortOrder>;
    received?: InputMaybe<SortOrder>;
    receivedGifts?: InputMaybe<SortOrder>;
    receivedMessages?: InputMaybe<SortOrder>;
    receivedPictures?: InputMaybe<SortOrder>;
    receivedPokes?: InputMaybe<SortOrder>;
    sentGifts?: InputMaybe<SortOrder>;
    sentMessages?: InputMaybe<SortOrder>;
    sentPictures?: InputMaybe<SortOrder>;
    sentPokes?: InputMaybe<SortOrder>;
    spentBonus?: InputMaybe<SortOrder>;
    spentCoins?: InputMaybe<SortOrder>;
    transactions?: InputMaybe<SortOrder>;
    turnover?: InputMaybe<SortOrder>;
};

export type UserStatisticCountAggregateInputType = {
    _all?: InputMaybe<Scalars['true']>;
    bonusIns?: InputMaybe<Scalars['true']>;
    ins?: InputMaybe<Scalars['true']>;
    projectName?: InputMaybe<Scalars['true']>;
    received?: InputMaybe<Scalars['true']>;
    receivedGifts?: InputMaybe<Scalars['true']>;
    receivedMessages?: InputMaybe<Scalars['true']>;
    receivedPictures?: InputMaybe<Scalars['true']>;
    receivedPokes?: InputMaybe<Scalars['true']>;
    sent?: InputMaybe<Scalars['true']>;
    sentGifts?: InputMaybe<Scalars['true']>;
    sentMessages?: InputMaybe<Scalars['true']>;
    sentPictures?: InputMaybe<Scalars['true']>;
    sentPokes?: InputMaybe<Scalars['true']>;
    spentBonus?: InputMaybe<Scalars['true']>;
    spentCoins?: InputMaybe<Scalars['true']>;
    transactions?: InputMaybe<Scalars['true']>;
    turnover?: InputMaybe<Scalars['true']>;
    userId?: InputMaybe<Scalars['true']>;
    userStatisticId?: InputMaybe<Scalars['true']>;
};

export type UserStatisticCountAggregateOutputType = {
    __typename: 'UserStatisticCountAggregateOutputType';
    _all: Scalars['Int'];
    bonusIns: Scalars['Int'];
    ins: Scalars['Int'];
    projectName: Scalars['Int'];
    received: Scalars['Int'];
    receivedGifts: Scalars['Int'];
    receivedMessages: Scalars['Int'];
    receivedPictures: Scalars['Int'];
    receivedPokes: Scalars['Int'];
    sent: Scalars['Int'];
    sentGifts: Scalars['Int'];
    sentMessages: Scalars['Int'];
    sentPictures: Scalars['Int'];
    sentPokes: Scalars['Int'];
    spentBonus: Scalars['Int'];
    spentCoins: Scalars['Int'];
    transactions: Scalars['Int'];
    turnover: Scalars['Int'];
    userId: Scalars['Int'];
    userStatisticId: Scalars['Int'];
};

export type UserStatisticCountMinMaxOrderByAggregateInput = {
    ins?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    received?: InputMaybe<SortOrder>;
    receivedGifts?: InputMaybe<SortOrder>;
    receivedMessages?: InputMaybe<SortOrder>;
    receivedPictures?: InputMaybe<SortOrder>;
    receivedPokes?: InputMaybe<SortOrder>;
    sentGifts?: InputMaybe<SortOrder>;
    sentMessages?: InputMaybe<SortOrder>;
    sentPictures?: InputMaybe<SortOrder>;
    sentPokes?: InputMaybe<SortOrder>;
    spentBonus?: InputMaybe<SortOrder>;
    spentCoins?: InputMaybe<SortOrder>;
    transactions?: InputMaybe<SortOrder>;
    turnover?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    userStatisticId?: InputMaybe<SortOrder>;
};

export type UserStatisticGroupBy = {
    __typename: 'UserStatisticGroupBy';
    _avg?: Maybe<UserStatisticAvgSumAggregateOutputType>;
    _count?: Maybe<UserStatisticCountAggregateOutputType>;
    _max?: Maybe<UserStatisticMinMaxAggregateOutputType>;
    _min?: Maybe<UserStatisticMinMaxAggregateOutputType>;
    _sum?: Maybe<UserStatisticAvgSumAggregateOutputType>;
    bonusIns?: Maybe<Scalars['Decimal']>;
    ins?: Maybe<Scalars['Decimal']>;
    projectName?: Maybe<Scalars['String']>;
    received?: Maybe<Scalars['Int']>;
    receivedGifts?: Maybe<Scalars['Int']>;
    receivedMessages?: Maybe<Scalars['Int']>;
    receivedPictures?: Maybe<Scalars['Int']>;
    receivedPokes?: Maybe<Scalars['Int']>;
    sent?: Maybe<Scalars['Int']>;
    sentGifts?: Maybe<Scalars['Int']>;
    sentMessages?: Maybe<Scalars['Int']>;
    sentPictures?: Maybe<Scalars['Int']>;
    sentPokes?: Maybe<Scalars['Int']>;
    spentBonus?: Maybe<Scalars['Int']>;
    spentCoins?: Maybe<Scalars['Int']>;
    transactions?: Maybe<Scalars['Int']>;
    turnover?: Maybe<Scalars['Decimal']>;
    userId?: Maybe<Scalars['String']>;
    userStatisticId?: Maybe<Scalars['String']>;
};

export type UserStatisticListRelationFilter = {
    every?: InputMaybe<UserStatisticWhereInput>;
    none?: InputMaybe<UserStatisticWhereInput>;
    some?: InputMaybe<UserStatisticWhereInput>;
};

export type UserStatisticMinMaxAggregateOutputType = {
    __typename: 'UserStatisticMinMaxAggregateOutputType';
    ins?: Maybe<Scalars['Decimal']>;
    projectName?: Maybe<Scalars['String']>;
    received?: Maybe<Scalars['Int']>;
    receivedGifts?: Maybe<Scalars['Int']>;
    receivedMessages?: Maybe<Scalars['Int']>;
    receivedPictures?: Maybe<Scalars['Int']>;
    receivedPokes?: Maybe<Scalars['Int']>;
    sentGifts?: Maybe<Scalars['Int']>;
    sentMessages?: Maybe<Scalars['Int']>;
    sentPictures?: Maybe<Scalars['Int']>;
    sentPokes?: Maybe<Scalars['Int']>;
    spentBonus?: Maybe<Scalars['Int']>;
    spentCoins?: Maybe<Scalars['Int']>;
    transactions?: Maybe<Scalars['Int']>;
    turnover?: Maybe<Scalars['Decimal']>;
    userId?: Maybe<Scalars['String']>;
    userStatisticId?: Maybe<Scalars['String']>;
};

export type UserStatisticOrderByInput = {
    ins?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    sentGifts?: InputMaybe<SortOrder>;
    sentMessages?: InputMaybe<SortOrder>;
    sentPictures?: InputMaybe<SortOrder>;
    sentPokes?: InputMaybe<SortOrder>;
    spentBonus?: InputMaybe<SortOrder>;
    spentCoins?: InputMaybe<SortOrder>;
    transactions?: InputMaybe<SortOrder>;
    turnover?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    userStatisticId?: InputMaybe<SortOrder>;
};

export type UserStatisticOrderByWithAggregationInput = {
    _avg?: InputMaybe<UserStatisticAvgSumOrderByAggregateInput>;
    _count?: InputMaybe<UserStatisticCountMinMaxOrderByAggregateInput>;
    _max?: InputMaybe<UserStatisticCountMinMaxOrderByAggregateInput>;
    _min?: InputMaybe<UserStatisticCountMinMaxOrderByAggregateInput>;
    _sum?: InputMaybe<UserStatisticAvgSumOrderByAggregateInput>;
    ins?: InputMaybe<SortOrder>;
    projectName?: InputMaybe<SortOrder>;
    sentGifts?: InputMaybe<SortOrder>;
    sentMessages?: InputMaybe<SortOrder>;
    sentPictures?: InputMaybe<SortOrder>;
    sentPokes?: InputMaybe<SortOrder>;
    spentBonus?: InputMaybe<SortOrder>;
    spentCoins?: InputMaybe<SortOrder>;
    transactions?: InputMaybe<SortOrder>;
    turnover?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    userStatisticId?: InputMaybe<SortOrder>;
};

export const UserStatisticScalarFieldEnum = {
    BonusIns: 'bonusIns',
    Ins: 'ins',
    ProjectName: 'projectName',
    Received: 'received',
    ReceivedGifts: 'receivedGifts',
    ReceivedMessages: 'receivedMessages',
    ReceivedPictures: 'receivedPictures',
    ReceivedPokes: 'receivedPokes',
    Sent: 'sent',
    SentGifts: 'sentGifts',
    SentMessages: 'sentMessages',
    SentPictures: 'sentPictures',
    SentPokes: 'sentPokes',
    SpentBonus: 'spentBonus',
    SpentCoins: 'spentCoins',
    Transactions: 'transactions',
    Turnover: 'turnover',
    UserId: 'userId',
    UserStatisticId: 'userStatisticId',
} as const;

export type UserStatisticScalarFieldEnum = (typeof UserStatisticScalarFieldEnum)[keyof typeof UserStatisticScalarFieldEnum];
export type UserStatisticWhereInput = {
    ins?: InputMaybe<DecimalNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    received?: InputMaybe<IntNullableFilter>;
    receivedByMod?: InputMaybe<ModeratedProfileListRelationUniqueFilter>;
    receivedGifts?: InputMaybe<IntNullableFilter>;
    receivedMessages?: InputMaybe<IntNullableFilter>;
    receivedPictures?: InputMaybe<IntNullableFilter>;
    receivedPokes?: InputMaybe<IntNullableFilter>;
    sendToMod?: InputMaybe<ModeratedProfileListRelationUniqueFilter>;
    sent?: InputMaybe<IntNullableFilter>;
    sentGifts?: InputMaybe<IntNullableFilter>;
    sentMessages?: InputMaybe<IntNullableFilter>;
    sentPictures?: InputMaybe<IntNullableFilter>;
    sentPokes?: InputMaybe<IntNullableFilter>;
    spentBonus?: InputMaybe<IntNullableFilter>;
    spentCoins?: InputMaybe<IntNullableFilter>;
    transactions?: InputMaybe<IntFilter>;
    turnover?: InputMaybe<DecimalNullableFilter>;
    userId?: InputMaybe<StringFilter>;
    userStatisticId?: InputMaybe<StringFilter>;
};

export type UserStatisticWhereUniqueInput = {
    userStatisticId: Scalars['String'];
};

export const UserStatus = {
    Active: 'Active',
    Deleted: 'Deleted',
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
export type UserStatusFilter = {
    equals?: InputMaybe<UserStatus>;
    in?: InputMaybe<Array<UserStatus>>;
    not?: InputMaybe<UserStatus>;
    notIn?: InputMaybe<Array<UserStatus>>;
};

export type UserUpdateInput = {
    domain?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    hasPayed?: InputMaybe<Scalars['Boolean']>;
    latitude?: InputMaybe<Scalars['String']>;
    longitude?: InputMaybe<Scalars['String']>;
    mobileNumber?: InputMaybe<Scalars['String']>;
    password?: InputMaybe<Scalars['String']>;
    role?: InputMaybe<UserRole>;
    sendMarketingEmail?: InputMaybe<Scalars['Boolean']>;
    sendSMS?: InputMaybe<Scalars['Boolean']>;
    sendTransactionalEmail?: InputMaybe<Scalars['Boolean']>;
    unModerated?: InputMaybe<Scalars['Boolean']>;
    userPremiumStatus?: InputMaybe<UserPremiumStatusUpdateManyWithoutUserNestedInput>;
    username?: InputMaybe<Scalars['String']>;
    verified?: InputMaybe<Scalars['Boolean']>;
};

export type UserVerifyInput = {
    flirtText?: InputMaybe<Scalars['String']>;
};

export type UserWhereInput = {
    AND?: InputMaybe<Array<UserScalarWhereInput>>;
    NOT?: InputMaybe<Array<UserScalarWhereInput>>;
    OR?: InputMaybe<Array<UserScalarWhereInput>>;
    adminChanged?: InputMaybe<DateTimeFilter>;
    bonus?: InputMaybe<IntFilter>;
    c4fAction?: InputMaybe<StringNullableFilter>;
    c4fId?: InputMaybe<StringNullableFilter>;
    client?: InputMaybe<StringNullableFilter>;
    clientVersion?: InputMaybe<StringNullableFilter>;
    coins?: InputMaybe<IntFilter>;
    conditionAcceptedAt?: InputMaybe<DateTimeNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    createdIP?: InputMaybe<StringFilter>;
    domain?: InputMaybe<StringNullableFilter>;
    email?: InputMaybe<StringFilter>;
    emailValidated?: InputMaybe<BoolFilter>;
    emailValidatedAt?: InputMaybe<DateTimeNullableFilter>;
    hasPayed?: InputMaybe<BoolFilter>;
    lastAction?: InputMaybe<StringNullableFilter>;
    lastActivity?: InputMaybe<DateTimeNullableFilter>;
    lastLogin?: InputMaybe<DateTimeNullableFilter>;
    lastMessageReceived?: InputMaybe<DateTimeNullableFilter>;
    lastMessageSend?: InputMaybe<DateTimeNullableFilter>;
    latitude?: InputMaybe<StringNullableFilter>;
    legacyUserId?: InputMaybe<StringNullableFilter>;
    longitude?: InputMaybe<StringNullableFilter>;
    mobileNumber?: InputMaybe<StringNullableFilter>;
    mobileNumberValidated?: InputMaybe<BoolFilter>;
    mobileNumberValidatedAt?: InputMaybe<DateTimeNullableFilter>;
    online?: InputMaybe<BoolFilter>;
    profile?: InputMaybe<ProfileWhereInput>;
    profileId?: InputMaybe<StringNullableFilter>;
    projectName?: InputMaybe<StringFilter>;
    pushToken?: InputMaybe<StringNullableFilter>;
    registerDomain?: InputMaybe<StringFilter>;
    role?: InputMaybe<UserRoleFilter>;
    sendMarketingEmail?: InputMaybe<BoolFilter>;
    sendSMS?: InputMaybe<BoolFilter>;
    sendTransactionalEmail?: InputMaybe<BoolFilter>;
    socials?: InputMaybe<SocialListRelationFilter>;
    unModerated?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    userChanged?: InputMaybe<DateTimeFilter>;
    userId?: InputMaybe<StringFilter>;
    userPremiumStatus?: InputMaybe<UserPremiumStatusListRelationFilter>;
    userStatistics?: InputMaybe<UserStatisticListRelationFilter>;
    username?: InputMaybe<StringFilter>;
    verified?: InputMaybe<BoolFilter>;
    verifiedBy?: InputMaybe<AdminUserWhereInput>;
};

export type UserWhereUniqueInput = {
    userId_projectName: UserIdProjectNameCompoundUniqueInput;
};

export type UsernameBadword = {
    __typename: 'UsernameBadword';
    usernameBadwordId: Scalars['String'];
    word: Scalars['String'];
};

export type UsernameBadwordOrderByInput = {
    usernameBadwordId?: InputMaybe<SortOrder>;
    word?: InputMaybe<SortOrder>;
};

export type UsernameBadwordWhereInput = {
    usernameBadwordId?: InputMaybe<StringFilter>;
    word?: InputMaybe<StringFilter>;
};

export type UsernameBadwordWhereUniqueInput = {
    usernameBadwordId?: InputMaybe<Scalars['String']>;
    word?: InputMaybe<Scalars['String']>;
};

export type VisitLogRelativeWhereInput = {
    count: IntFilter;
    createdAt: DateTimeRelativeFilter;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
    __typename: '__EnumValue';
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isDeprecated: Scalars['Boolean'];
    deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
    __typename: '__Field';
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    args: Array<__InputValue>;
    type: __Type;
    isDeprecated: Scalars['Boolean'];
    deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
    includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
    __typename: '__InputValue';
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    type: __Type;
    /** A GraphQL-formatted string representing the default value for this input value. */
    defaultValue?: Maybe<Scalars['String']>;
    isDeprecated: Scalars['Boolean'];
    deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
    __typename: '__Type';
    kind: __TypeKind;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    specifiedByURL?: Maybe<Scalars['String']>;
    fields?: Maybe<Array<__Field>>;
    interfaces?: Maybe<Array<__Type>>;
    possibleTypes?: Maybe<Array<__Type>>;
    enumValues?: Maybe<Array<__EnumValue>>;
    inputFields?: Maybe<Array<__InputValue>>;
    ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
    includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
    includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
    includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export const __TypeKind = {
    /** Indicates this type is a scalar. */
    Scalar: 'SCALAR',
    /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
    Object: 'OBJECT',
    /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
    Interface: 'INTERFACE',
    /** Indicates this type is a union. `possibleTypes` is a valid field. */
    Union: 'UNION',
    /** Indicates this type is an enum. `enumValues` is a valid field. */
    Enum: 'ENUM',
    /** Indicates this type is an input object. `inputFields` is a valid field. */
    InputObject: 'INPUT_OBJECT',
    /** Indicates this type is a list. `ofType` is a valid field. */
    List: 'LIST',
    /** Indicates this type is a non-null. `ofType` is a valid field. */
    NonNull: 'NON_NULL',
} as const;

export type __TypeKind = (typeof __TypeKind)[keyof typeof __TypeKind];
export type EnumsFragment = {
    __typename: 'AllEnum';
    ComplainStatus: Array<ComplainStatus>;
    GiftStatus: Array<GiftStatus>;
    Gender: Array<Gender>;
    MessageType: Array<MessageType>;
    ModerationRole: Array<ModerationRole>;
    ModerationType: Array<ModerationType>;
    ModerationPrivilege: Array<ModerationPrivilege>;
    ModerationMessageType: Array<ModerationMessageType>;
    Privilege: Array<Privilege>;
    QueueType: Array<QueueType>;
    SortOrder: Array<SortOrder>;
    SystemComplainType: Array<SystemComplainType>;
    SupportCategory: Array<SupportCategory>;
    SupportTicketStatus: Array<SupportTicketStatus>;
    TransactionStatus: Array<TransactionStatus>;
    UserRole: Array<UserRole>;
    UserStatus: Array<UserStatus>;
    AWRType: Array<AwrType>;
    FlagGender: Array<FlagGender>;
    FlagType: Array<FlagType>;
    ProfileStatus: Array<ProfileStatus>;
    PeriodUnit: Array<PeriodUnit>;
    NotificationLogPayloadType: Array<NotificationLogPayloadType>;
    NotificationLogSource: Array<NotificationLogSource>;
    NotificationLogStatus: Array<NotificationLogStatus>;
    NotificationLogChannel: Array<NotificationLogChannel>;
};

export type GlobalQueryVariables = Exact<{
    withAgencies?: InputMaybe<Scalars['Boolean']>;
    withTeams?: InputMaybe<Scalars['Boolean']>;
    withProvider?: InputMaybe<Scalars['Boolean']>;
    withProjectConfig?: InputMaybe<Scalars['Boolean']>;
    withAdmins?: InputMaybe<Scalars['Boolean']>;
}>;

export type GlobalQuery = {
    __typename: 'Query';
    agencies: Array<{ __typename: 'Agency'; agencyId: string; name: string }>;
    teams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string; default: boolean }>;
    admins: Array<{ __typename: 'AdminUser'; adminUserId: string; name: string }>;
    providers: Array<{ __typename: 'Provider'; name: string }>;
    enums: {
        __typename: 'AllEnum';
        ComplainStatus: Array<ComplainStatus>;
        GiftStatus: Array<GiftStatus>;
        Gender: Array<Gender>;
        MessageType: Array<MessageType>;
        ModerationRole: Array<ModerationRole>;
        ModerationType: Array<ModerationType>;
        ModerationPrivilege: Array<ModerationPrivilege>;
        ModerationMessageType: Array<ModerationMessageType>;
        Privilege: Array<Privilege>;
        QueueType: Array<QueueType>;
        SortOrder: Array<SortOrder>;
        SystemComplainType: Array<SystemComplainType>;
        SupportCategory: Array<SupportCategory>;
        SupportTicketStatus: Array<SupportTicketStatus>;
        TransactionStatus: Array<TransactionStatus>;
        UserRole: Array<UserRole>;
        UserStatus: Array<UserStatus>;
        AWRType: Array<AwrType>;
        FlagGender: Array<FlagGender>;
        FlagType: Array<FlagType>;
        ProfileStatus: Array<ProfileStatus>;
        PeriodUnit: Array<PeriodUnit>;
        NotificationLogPayloadType: Array<NotificationLogPayloadType>;
        NotificationLogSource: Array<NotificationLogSource>;
        NotificationLogStatus: Array<NotificationLogStatus>;
        NotificationLogChannel: Array<NotificationLogChannel>;
    };
    languages?: Array<{ __typename: 'Language'; code: string; name: string }> | null;
    projects: Array<{
        __typename: 'Project';
        name: string;
        config?: {
            __typename: 'Config';
            countries: Array<string>;
            cost: {
                __typename: 'ConfigCost';
                message: { __typename: 'ConfigCostNode'; cost: number; length?: number | null };
                friendship: { __typename: 'ConfigCostNode'; cost: number };
                poke: { __typename: 'ConfigCostNode'; cost: number; free?: number | null };
                eyeCatcher: { __typename: 'ConfigCostNode'; cost: number };
                predefineMessage?: { __typename: 'ConfigCostNode'; cost: number; free?: number | null } | null;
            };
            completeProfile?: {
                __typename: 'ConfigCompleteProfile';
                flirtText?: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null } | null;
                image?: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null } | null;
                interests?: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null } | null;
                profileFlags?: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null } | null;
                email: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null };
            } | null;
            dailyLogin?: { __typename: 'ConfigCoins'; bonus?: number | null; coins?: number | null } | null;
        };
        languages?: Array<{ __typename: 'Language'; code: string; name: string }>;
    }>;
    AdminRole: Array<{
        __typename: 'AdminRole';
        name: string;
        AdminRolePrivilege: Array<{ __typename: 'AdminRolePrivilege'; privilege: Privilege; adminRolePrivilegeId: string }>;
    }>;
    features: Array<{ __typename: 'Feature'; name: FeatureName }>;
    defaultComments: Array<{ __typename: 'DefaultComment'; languageCode: string; text: string; projectName: string }>;
};

export type EnumsQueryVariables = Exact<{ [key: string]: never }>;

export type EnumsQuery = {
    __typename: 'Query';
    getEnums: {
        __typename: 'AllEnum';
        ComplainStatus: Array<ComplainStatus>;
        GiftStatus: Array<GiftStatus>;
        Gender: Array<Gender>;
        MessageType: Array<MessageType>;
        ModerationRole: Array<ModerationRole>;
        ModerationType: Array<ModerationType>;
        ModerationPrivilege: Array<ModerationPrivilege>;
        ModerationMessageType: Array<ModerationMessageType>;
        Privilege: Array<Privilege>;
        QueueType: Array<QueueType>;
        SortOrder: Array<SortOrder>;
        SystemComplainType: Array<SystemComplainType>;
        SupportCategory: Array<SupportCategory>;
        SupportTicketStatus: Array<SupportTicketStatus>;
        TransactionStatus: Array<TransactionStatus>;
        UserRole: Array<UserRole>;
        UserStatus: Array<UserStatus>;
        AWRType: Array<AwrType>;
        FlagGender: Array<FlagGender>;
        FlagType: Array<FlagType>;
        ProfileStatus: Array<ProfileStatus>;
        PeriodUnit: Array<PeriodUnit>;
        NotificationLogPayloadType: Array<NotificationLogPayloadType>;
        NotificationLogSource: Array<NotificationLogSource>;
        NotificationLogStatus: Array<NotificationLogStatus>;
        NotificationLogChannel: Array<NotificationLogChannel>;
    };
};

export type StatsCustomersCountQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
}>;

export type StatsCustomersCountQuery = { __typename: 'Query'; findManyUserCount: number };

export type StatsCustomersAggregateCountQueryVariables = Exact<{
    createdAt?: InputMaybe<DateTimeFilter>;
    projectName?: InputMaybe<StringFilter>;
    clients?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type StatsCustomersAggregateCountQuery = { __typename: 'Query'; member: number; all: number; app: number };

export type StatsCustomersSalesQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInputWithoutUser>;
}>;

export type StatsCustomersSalesQuery = {
    __typename: 'Query';
    findManyTransactionAggregate?: {
        __typename: 'TransactionAggregateResult';
        _sum?: { __typename: 'TransactionSumAggregateOutput'; cost?: number | null } | null;
    } | null;
};

export type StatsQueueCountQueryVariables = Exact<{
    where?: InputMaybe<QueueWhereInput>;
}>;

export type StatsQueueCountQuery = { __typename: 'Query'; findManyQueueCount?: number | null };

export type StatsInsQueryVariables = Exact<{
    where?: InputMaybe<ModeratorStatisticWhereInput>;
}>;

export type StatsInsQuery = {
    __typename: 'Query';
    findManyModeratorStatisticAggregate: {
        __typename: 'ModeratorStatisticAggregateResult';
        _sum?: { __typename: 'ModeratorStatisticSumAggregateOutputType'; ins?: number | null; bonus?: number | null } | null;
    };
};

export type StatsQueueGroupQueryVariables = Exact<{
    where?: InputMaybe<QueueWhereInput>;
}>;

export type StatsQueueGroupQuery = {
    __typename: 'Query';
    findManyQueueGroupBy: Array<{
        __typename: 'QueueGroupBy';
        projectName: string;
        _count?: number | null;
        stats: Array<{ __typename: 'QueueStats'; type?: string | null; _count?: number | null }>;
    }>;
};

export type QuickSearchQueryVariables = Exact<{
    search?: InputMaybe<Scalars['String']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
}>;

export type QuickSearchQuery = {
    __typename: 'Query';
    transactions: Array<{
        __typename: 'Transaction';
        transactionId: string;
        providerName: string;
        cost: number;
        user?: { __typename: 'User'; username?: string | null; userId: string } | null;
    }>;
    users: Array<{ __typename: 'User'; userId: string; email?: string | null; username?: string | null; profileId?: string | null }>;
    profiles: Array<{ __typename: 'Profile'; profileId: string; profileName: string; gender: Gender }>;
};

export type TasksQueryVariables = Exact<{
    withSupport?: InputMaybe<Scalars['Boolean']>;
    withFlirtText?: InputMaybe<Scalars['Boolean']>;
    withModComplains?: InputMaybe<Scalars['Boolean']>;
    withPictures?: InputMaybe<Scalars['Boolean']>;
}>;

export type TasksQuery = {
    __typename: 'Query';
    verifyFlirtText: number;
    verifyPicture: number;
    support?: number | null;
    modComplains: number;
    verifyImage?: number | null;
    complains: number;
};

export type StatsUsersSocialCountQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
}>;

export type StatsUsersSocialCountQuery = {
    __typename: 'Query';
    result: Array<{ __typename: 'UserCountSocial'; _count?: number | null; providerName: string }>;
};

export type AuthQueryVariables = Exact<{ [key: string]: never }>;

export type AuthQuery = { __typename: 'Query'; privileges?: Array<string | null> | null; adminUserId?: string | null };

export type FeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type FeaturesQuery = { __typename: 'Query'; findManyFeatures: Array<{ __typename: 'Feature'; name: FeatureName }> };

export type FindManyUserCountEmailProviderQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
}>;

export type FindManyUserCountEmailProviderQuery = {
    __typename: 'Query';
    result: Array<{ __typename: 'UserCountEmailProvider'; _count?: number | null; emailProvider?: string | null }>;
};

export type RestartOneAcquisitionMutationVariables = Exact<{
    where: AcquisitionWhereUniqueInput;
}>;

export type RestartOneAcquisitionMutation = {
    __typename: 'Mutation';
    restartOneAcquisition?: { __typename: 'Acquisition'; acquisitionId: string } | null;
};

export type NotificationLogsQueryVariables = Exact<{
    where?: InputMaybe<NotificationLogWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<NotificationLogOrderByInput>;
}>;

export type NotificationLogsQuery = {
    __typename: 'Query';
    findManyNotificationLog?: Array<{
        __typename: 'NotificationLog';
        notificationLogId: string;
        payloadType: NotificationLogPayloadType;
        status: NotificationLogStatus;
        source: NotificationLogSource;
        channel: NotificationLogChannel;
        createdAt: Date | string;
        updatedAt: Date | string;
        user?: {
            __typename: 'User';
            userId: string;
            username?: string | null;
            email?: string | null;
            role: UserRole;
            profile?: { __typename: 'Profile'; profileId: string; profileName: string; birthDate?: string | null; gender: Gender } | null;
        } | null;
    }> | null;
};

export type NotificationLogsCountQueryVariables = Exact<{
    where?: InputMaybe<NotificationLogWhereInput>;
}>;

export type NotificationLogsCountQuery = { __typename: 'Query'; findManyNotificationLogCount?: number | null };

export type OfferFragmentFragment = {
    __typename: 'Offer';
    offerId: string;
    name: string;
    cost: number;
    fee?: number | null;
    coins: number;
    currency: string;
    active: boolean;
    bonus: number;
    limit: number;
    payed?: boolean | null;
    projectName: string;
    extra: string;
    profileStatus?: ProfileStatus | null;
    periodUnit?: PeriodUnitOffer | null;
    statusPeriod?: number | null;
};

export type FindManyProviderQueryVariables = Exact<{ [key: string]: never }>;

export type FindManyProviderQuery = {
    __typename: 'Query';
    findManyProvider: Array<{
        __typename: 'Provider';
        providerId: string;
        projectName: string;
        logo: string;
        name: string;
        title: string;
        active: boolean;
        description?: string | null;
        hidden: boolean;
        currency: string;
        offers: Array<{
            __typename: 'Offer';
            offerId: string;
            name: string;
            cost: number;
            fee?: number | null;
            coins: number;
            currency: string;
            active: boolean;
            bonus: number;
            limit: number;
            payed?: boolean | null;
            projectName: string;
            extra: string;
            profileStatus?: ProfileStatus | null;
            periodUnit?: PeriodUnitOffer | null;
            statusPeriod?: number | null;
        }>;
        settings: Array<{ __typename: 'ProviderSetting'; providerSettingId: string; providerId: string; name: string; value: string }>;
    }>;
};

export type UpdateOneProviderMutationVariables = Exact<{
    data: ProviderUpdateInput;
    where: ProviderWhereUniqueInput;
}>;

export type UpdateOneProviderMutation = { __typename: 'Mutation'; updateOneProvider: { __typename: 'Provider'; providerId: string } };

export type CreateOneProviderMutationVariables = Exact<{
    data: ProviderCreateInput;
}>;

export type CreateOneProviderMutation = { __typename: 'Mutation'; createOneProvider: { __typename: 'Provider'; providerId: string } };

export type CreateOneProviderSettingMutationVariables = Exact<{
    data: ProviderSettingCreateInput;
}>;

export type CreateOneProviderSettingMutation = {
    __typename: 'Mutation';
    createOneProviderSetting: { __typename: 'ProviderSetting'; providerSettingId: string };
};

export type UpdateOneProviderSettingMutationVariables = Exact<{
    data: ProviderSettingUpdateInput;
    where: ProviderSettingWhereUniqueInput;
}>;

export type UpdateOneProviderSettingMutation = {
    __typename: 'Mutation';
    updateOneProviderSetting: { __typename: 'ProviderSetting'; providerSettingId: string };
};

export type CreateOneOfferMutationVariables = Exact<{
    data: OfferCreateInput;
}>;

export type CreateOneOfferMutation = {
    __typename: 'Mutation';
    createOneOffer: {
        __typename: 'Offer';
        offerId: string;
        name: string;
        cost: number;
        fee?: number | null;
        coins: number;
        currency: string;
        active: boolean;
        bonus: number;
        limit: number;
        payed?: boolean | null;
        projectName: string;
        extra: string;
        profileStatus?: ProfileStatus | null;
        periodUnit?: PeriodUnitOffer | null;
        statusPeriod?: number | null;
    };
};

export type DeleteOneOfferMutationVariables = Exact<{
    where: OfferWhereUniqueInput;
}>;

export type DeleteOneOfferMutation = {
    __typename: 'Mutation';
    deleteOneOffer?: { __typename: 'Offer'; name: string; cost: number; providerName: string } | null;
};

export type UpdateOneOfferMutationVariables = Exact<{
    where: OfferWhereUniqueInput;
    data: OfferUpdateInput;
}>;

export type UpdateOneOfferMutation = {
    __typename: 'Mutation';
    updateOneOffer: {
        __typename: 'Offer';
        offerId: string;
        name: string;
        cost: number;
        fee?: number | null;
        coins: number;
        currency: string;
        active: boolean;
        bonus: number;
        limit: number;
        payed?: boolean | null;
        projectName: string;
        extra: string;
        profileStatus?: ProfileStatus | null;
        periodUnit?: PeriodUnitOffer | null;
        statusPeriod?: number | null;
    };
};

export type FindManyProjectQueryVariables = Exact<{ [key: string]: never }>;

export type FindManyProjectQuery = { __typename: 'Query'; findManyProject: Array<{ __typename: 'Project'; name: string }> };

export type FindManyTransactionGroupByQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInputWithoutUser>;
}>;

export type FindManyTransactionGroupByQuery = {
    __typename: 'Query';
    findManyTransactionGroupBy: Array<{
        __typename: 'TransactionFindManyGroupByResult';
        providerName?: string | null;
        _sum?: { __typename: 'TransactionSumAggregateOutput'; cost?: number | null } | null;
    }>;
};

export type FindManyCouponQueryVariables = Exact<{
    where?: InputMaybe<CouponWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyCouponQuery = {
    __typename: 'Query';
    findManyCoupon?: Array<{
        __typename: 'Coupon';
        code: string;
        coins: number;
        couponId: string;
        bonus: number;
        validFrom: Date | string;
        validTo: Date | string;
        limit: number;
        projectName: string;
        userCouponCount: number;
    }> | null;
};

export type FindManyCouponCountQueryVariables = Exact<{
    where?: InputMaybe<CouponWhereInput>;
}>;

export type FindManyCouponCountQuery = { __typename: 'Query'; findManyCouponCount?: number | null };

export type CreateOneCouponMutationVariables = Exact<{
    data: CouponCreateInput;
}>;

export type CreateOneCouponMutation = { __typename: 'Mutation'; createOneCoupon: { __typename: 'Coupon'; couponId: string } };

export type UpdateOneCouponMutationVariables = Exact<{
    data: CouponUpdateInput;
    where: CouponWhereUniqueInput;
}>;

export type UpdateOneCouponMutation = { __typename: 'Mutation'; updateOneCoupon: { __typename: 'Coupon'; couponId: string } };

export type FindManyUserCouponQueryVariables = Exact<{
    where: UserCouponWhereInput;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyUserCouponQuery = {
    __typename: 'Query';
    findManyUserCoupon?: Array<{
        __typename: 'UserCoupon';
        couponCode: string;
        userCouponId: string;
        createdAt: Date | string;
        coupon: { __typename: 'Coupon'; coins: number; bonus: number };
        user: {
            __typename: 'User';
            userId: string;
            username?: string | null;
            hasPayed: boolean;
            lastPayedAt?: Date | string | null;
            transactionCount: number;
            profile?: { __typename: 'Profile'; profileName: string; birthDate?: string | null; gender: Gender; profileId: string } | null;
        };
    }> | null;
};

export type FindManyUserCouponCountQueryVariables = Exact<{
    where?: InputMaybe<UserCouponWhereInput>;
}>;

export type FindManyUserCouponCountQuery = { __typename: 'Query'; findManyUserCouponCount?: number | null };

export type BookTransactionMutationVariables = Exact<{
    where: TransactionWhereUniqueInput;
}>;

export type BookTransactionMutation = { __typename: 'Mutation'; bookTransaction: { __typename: 'Transaction'; transactionId: string } };

export type CreateOneTransactionMutationVariables = Exact<{
    data: TransactionCreateInput;
}>;

export type CreateOneTransactionMutation = { __typename: 'Mutation'; createOneTransaction: { __typename: 'Transaction'; transactionId: string } };

export type FindManyOfferQueryVariables = Exact<{
    where?: InputMaybe<OfferWhereInput>;
}>;

export type FindManyOfferQuery = {
    __typename: 'Query';
    findManyOffer?: Array<{
        __typename: 'Offer';
        offerId: string;
        name: string;
        cost: number;
        fee?: number | null;
        coins: number;
        currency: string;
        active: boolean;
        bonus: number;
        limit: number;
        payed?: boolean | null;
        projectName: string;
        extra: string;
        profileStatus?: ProfileStatus | null;
        periodUnit?: PeriodUnitOffer | null;
        statusPeriod?: number | null;
    }> | null;
};

export type ExportManyTransactionQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInput>;
}>;

export type ExportManyTransactionQuery = {
    __typename: 'Query';
    exportManyTransaction: { __typename: 'TransactionExport'; status: boolean; path?: string | null };
};

export type ExportManyTransactionVatQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInput>;
}>;

export type ExportManyTransactionVatQuery = {
    __typename: 'Query';
    exportManyTransactionVAT: { __typename: 'TransactionExport'; status: boolean; path?: string | null };
};

export type LoginMutationVariables = Exact<{
    username: Scalars['String'];
    password: Scalars['String'];
}>;

export type LoginMutation = {
    __typename: 'Mutation';
    login?:
        | {
              __typename: 'AdminLoginResultSuccess';
              token: string;
              adminUserId: string;
              privileges: Array<Privilege>;
              languageCode: string;
              c4fAuth: Array<{ __typename: 'C4F_Auth'; checksum: string; productid: number; projectName: string; userid: number }>;
              projects: Array<{ __typename: 'Project'; name: string }>;
              adminUserTeams: Array<{ __typename: 'AdminUserTeam'; adminUserTeamId: string }>;
              agency?: { __typename: 'Agency'; agencyId: string; name: string } | null;
          }
        | { __typename: 'ResultError'; errors?: Array<{ __typename: 'Error'; errorMessage?: string | null; errorCode?: number | null }> | null }
        | null;
};

export type FindManyAdminRoleQueryVariables = Exact<{
    where?: InputMaybe<AdminRoleWhereInput>;
}>;

export type FindManyAdminRoleQuery = {
    __typename: 'Query';
    findManyAdminRole: Array<{
        __typename: 'AdminRole';
        adminRoleId: string;
        name: string;
        AdminRolePrivilege: Array<{ __typename: 'AdminRolePrivilege'; privilege: Privilege; adminRoleName: string; createdAt: Date | string }>;
    }>;
};

export type UpdateOneAdminRoleMutationVariables = Exact<{
    where: AdminRoleWhereUniqueInput;
    data: AdminRoleUpdateInput;
}>;

export type UpdateOneAdminRoleMutation = {
    __typename: 'Mutation';
    updateOneAdminRole: {
        __typename: 'AdminRole';
        name: string;
        AdminRolePrivilege: Array<{ __typename: 'AdminRolePrivilege'; privilege: Privilege; adminRoleName: string; createdAt: Date | string }>;
    };
};

export type CreateOneAdminRoleMutationVariables = Exact<{
    data: AdminRoleCreateInput;
}>;

export type CreateOneAdminRoleMutation = {
    __typename: 'Mutation';
    createOneAdminRole: {
        __typename: 'AdminRole';
        name: string;
        AdminRolePrivilege: Array<{ __typename: 'AdminRolePrivilege'; privilege: Privilege; adminRoleName: string; createdAt: Date | string }>;
    };
};

export type AvatarsQueryVariables = Exact<{
    languageCode?: InputMaybe<Scalars['String']>;
}>;

export type AvatarsQuery = {
    __typename: 'Query';
    findManyAvatar: Array<{
        __typename: 'Avatar';
        avatarId: string;
        gender: Gender;
        path: string;
        translation?: { __typename: 'Translation'; translation: string } | null;
    }>;
};

export type FindManyTransactionQueryVariables = Exact<{
    orderBy?: InputMaybe<TransactionOrderByInput>;
    where?: InputMaybe<TransactionWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyTransactionQuery = {
    __typename: 'Query';
    findManyTransaction: Array<{
        __typename: 'Transaction';
        transactionId: string;
        providerName: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        status: TransactionStatus;
        cost: number;
        currency?: string | null;
        coins: number;
        reference?: string | null;
        recurring: boolean;
        projectName: string;
        offer: string;
        extra?: string | null;
        ip: string;
        userId: string;
        countryBilling?: string | null;
        countryUser?: string | null;
        countryGeo?: string | null;
        invoiceId?: string | null;
        net?: number | null;
        vatRate?: number | null;
        user?: {
            __typename: 'User';
            userId: string;
            profileId?: string | null;
            email?: string | null;
            createdAt: Date | string;
            role: UserRole;
            lastLogin?: Date | string | null;
            online: boolean;
            profile?: {
                __typename: 'Profile';
                profileId: string;
                profileName: string;
                shownLocation?: string | null;
                postcode?: string | null;
                gender: Gender;
                country?: string | null;
                images: Array<{ __typename: 'Image'; main: boolean; path: string }>;
            } | null;
        } | null;
    }>;
};

export type FindManyTransactionCountQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInput>;
}>;

export type FindManyTransactionCountQuery = { __typename: 'Query'; findManyTransactionCount: number };

export type FindManyCoinsHistoryQueryVariables = Exact<{
    where?: InputMaybe<CoinsHistoryWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyCoinsHistoryQuery = {
    __typename: 'Query';
    findManyCoinsHistory?: Array<{
        __typename: 'CoinsHistory';
        action: string;
        coinsChanged: number;
        bonusChanged: number;
        createdAt: Date | string;
    }> | null;
};

export type FindManyTransactionAggregateQueryVariables = Exact<{
    where?: InputMaybe<TransactionWhereInputWithoutUser>;
}>;

export type FindManyTransactionAggregateQuery = {
    __typename: 'Query';
    findManyTransactionAggregate?: {
        __typename: 'TransactionAggregateResult';
        _sum?: { __typename: 'TransactionSumAggregateOutput'; coins?: number | null; cost?: number | null; bonus?: number | null } | null;
    } | null;
};

export type CreateOneAdminUserTextMutationVariables = Exact<{
    data: AdminUserTextCreateInput;
}>;

export type CreateOneAdminUserTextMutation = {
    __typename: 'Mutation';
    createOneAdminUserText: { __typename: 'AdminUserText'; adminUserTextId: string };
};

export type FindManyAdminUserTextQueryVariables = Exact<{
    where?: InputMaybe<AdminUserTextWhereInput>;
}>;

export type FindManyAdminUserTextQuery = {
    __typename: 'Query';
    findManyAdminUserText?: Array<{
        __typename: 'AdminUserText';
        adminUserTextId: string;
        text: string;
        createdAt: Date | string;
        creator?: { __typename: 'AdminUser'; name: string } | null;
    }> | null;
};

export type CreateOneAdminTextMutationVariables = Exact<{
    data: AdminTextCreateInput;
}>;

export type CreateOneAdminTextMutation = { __typename: 'Mutation'; createOneAdminText: { __typename: 'AdminText'; adminTextId: string } };

export type FindManyAdminTextQueryVariables = Exact<{
    where?: InputMaybe<AdminTextWhereInput>;
}>;

export type FindManyAdminTextQuery = {
    __typename: 'Query';
    findManyAdminText?: Array<{
        __typename: 'AdminText';
        adminTextId: string;
        text: string;
        createdAt: Date | string;
        type: AdminTextType;
        entityType: AdminTextEntityType;
        entityId: string;
        creator?: { __typename: 'AdminUser'; name: string } | null;
    }> | null;
};

export type FindManyUserComplainQueryVariables = Exact<{
    where?: InputMaybe<UserComplainWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyUserComplainQuery = {
    __typename: 'Query';
    findManyUserComplain: Array<{
        __typename: 'UserComplain';
        complainTypeName: string;
        createdAt: Date | string;
        name?: string | null;
        projectName: string;
        reporterId: string;
        targetId: string;
        text?: string | null;
        userComplainId: string;
        reporter: { __typename: 'User'; username?: string | null; userId: string; profileId?: string | null };
        target: { __typename: 'Profile'; profileId: string; profileName: string };
    }>;
};

export type DeleteOneUserComplainMutationVariables = Exact<{
    userComplainId: Scalars['String'];
    projectName: Scalars['String'];
}>;

export type DeleteOneUserComplainMutation = {
    __typename: 'Mutation';
    deleteOneUserComplain?:
        | { __typename: 'ResultError'; errors?: Array<{ __typename: 'Error'; errorMessage?: string | null; errorCode?: number | null }> | null }
        | { __typename: 'UserComplain'; id?: string | null }
        | null;
};

export type FindManyModeratorComplainQueryVariables = Exact<{
    where?: InputMaybe<ModeratorComplainWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyModeratorComplainQuery = {
    __typename: 'Query';
    findManyModeratorComplain: Array<{
        __typename: 'ModeratorComplain';
        moderationComplainType: ModerationComplainType;
        createdAt: Date | string;
        languageCode: string;
        status: ModerationComplainStatus;
        projectName: string;
        reporterId: string;
        targetId: string;
        text?: string | null;
        moderatorComplainId: string;
        reporter: { __typename: 'ModerationUser'; moderationUserId: string; name: string };
        target: { __typename: 'User'; userId: string; profileId?: string | null; username?: string | null };
    }>;
};

export type UpdateOneModeratorComplainMutationVariables = Exact<{
    where: ModeratorComplainWhereUniqueInput;
    data: ModeratorComplainUpdateInput;
}>;

export type UpdateOneModeratorComplainMutation = {
    __typename: 'Mutation';
    updateOneModeratorComplain: {
        __typename: 'ModeratorComplain';
        moderationComplainType: ModerationComplainType;
        createdAt: Date | string;
        languageCode: string;
        status: ModerationComplainStatus;
        projectName: string;
        reporterId: string;
        targetId: string;
        text?: string | null;
        moderatorComplainId: string;
        reporter: { __typename: 'ModerationUser'; moderationUserId: string; name: string };
        target: { __typename: 'User'; profileId?: string | null; username?: string | null };
    };
};

export type DefaultTextQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultTextQuery = {
    __typename: 'Query';
    findManyProfileText: Array<{
        __typename: 'ProfileText';
        profileTextId: string;
        translations: Array<{ __typename: 'Translation'; languageCode: string; translation: string }>;
    }>;
};

export type UserFragmentFragment = {
    __typename: 'User';
    userId: string;
    email?: string | null;
    mobileNumber?: string | null;
    lastLogin?: Date | string | null;
    role: UserRole;
    username?: string | null;
    updatedAt: Date | string;
    createdAt: Date | string;
    createdIP: string;
    sendTransactionalEmail: boolean;
    sendMarketingEmail: boolean;
    sendSMS: boolean;
    emailValidated: boolean;
    emailValidatedAt?: Date | string | null;
    mobileNumberValidated?: boolean | null;
    mobileNumberValidatedAt?: Date | string | null;
    emailBounce: string;
    emailSuspendedUntil?: Date | string | null;
    conditionAcceptedAt?: Date | string | null;
    client?: string | null;
    clientVersion?: string | null;
    c4fId?: string | null;
    hasPayed: boolean;
    verified: boolean;
    domain?: string | null;
    registerDomain: string;
    unModerated: boolean;
    coins: number;
    bonus: number;
    latitude?: string | null;
    longitude?: string | null;
    projectName: string;
    socials: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
};

export type ProfileFragmentFragment = {
    __typename: 'Profile';
    profileId: string;
    profileName: string;
    gender: Gender;
    searches: Gender;
    country?: string | null;
    timezone: string;
    postcode?: string | null;
    furtherInformation?: string | null;
    themeName?: string | null;
    adminText?: string | null;
    languageCode?: string | null;
    hidden: boolean;
    hideLocation: boolean;
    birthDate?: string | null;
    createdAt: Date | string;
    shownLocation?: string | null;
    flirtText?: string | null;
    active: boolean;
    premiumStatus: Array<ProfileStatus>;
    completed: boolean;
};

export type ModeratedProfileFragmentFragment = {
    __typename: 'ModeratedProfile';
    moderatedProfileId: string;
    fixed: ModerationType;
    dynamic: boolean;
    isFSK18: boolean;
    support: boolean;
    imageSource?: string | null;
    noNAWR: boolean;
    teams: Array<{
        __typename: 'ModeratedProfileTeam';
        projectName: string;
        team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
    }>;
    moderators: Array<{
        __typename: 'ModeratedProfileModerationUser';
        moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
    }>;
    creator?: { __typename: 'AdminUser'; name: string } | null;
};

export type ProfileInterestsFragmentFragment = {
    __typename: 'Profile';
    profileInterests: Array<{
        __typename: 'ProfileInterest';
        interest: {
            __typename: 'Interest';
            interestId: string;
            name: string;
            categoryId: string;
            translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
        };
    }>;
};

export type ProfileFlagsFragmentFragment = {
    __typename: 'Profile';
    profileFlags: Array<{
        __typename: 'ProfileFlag';
        createdAt: Date | string;
        flag: {
            __typename: 'Flag';
            flagId: string;
            name: string;
            translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
        };
        flagValue?: {
            __typename: 'FlagValue';
            flagValueId: string;
            translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
        } | null;
    }>;
};

export type FindManyProfileCountQueryVariables = Exact<{
    where?: InputMaybe<ProfileWhereInput>;
}>;

export type FindManyProfileCountQuery = { __typename: 'Query'; findManyProfileCount: number };

export type FindManyProfileFeaturesQueryVariables = Exact<{
    where?: InputMaybe<ProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    withModeratedProfiles?: InputMaybe<Scalars['Boolean']>;
    withConversationProfiles?: InputMaybe<Scalars['Boolean']>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withPictures?: InputMaybe<Scalars['Boolean']>;
    picturesTake?: InputMaybe<Scalars['Int']>;
    picturesSkip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyProfileFeaturesQuery = {
    __typename: 'Query';
    findManyProfile: Array<{
        __typename: 'Profile';
        defaultText?: string | null;
        profileTextId?: string | null;
        projectName: string;
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        createdAt: Date | string;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        avatar?: {
            __typename: 'Avatar';
            path: string;
            avatarId: string;
            name?: string | null;
            gender: Gender;
            translations: Array<{ __typename: 'Translation'; translation: string }>;
        } | null;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        pictures?: Array<{ __typename: 'Picture'; pictureId: string; path: string }>;
        conversationProfiles?: Array<{
            __typename: 'ConversationProfile';
            messagesSend?: number | null;
            send?: number | null;
            pokesSend?: number | null;
            giftsSend?: number | null;
        }>;
        user?: {
            __typename: 'User';
            transactionCount: number;
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    }>;
};

export type FindManyProfileQueryVariables = Exact<{
    where?: InputMaybe<ProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    withModeratedProfiles?: InputMaybe<Scalars['Boolean']>;
    withConversationProfiles?: InputMaybe<Scalars['Boolean']>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
    withPictures?: InputMaybe<Scalars['Boolean']>;
    picturesTake?: InputMaybe<Scalars['Int']>;
    picturesSkip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyProfileQuery = {
    __typename: 'Query';
    findManyProfile: Array<{
        __typename: 'Profile';
        createdAt: Date | string;
        projectName: string;
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        pictures?: Array<{ __typename: 'Picture'; pictureId: string; path: string }>;
        conversationProfiles?: Array<{
            __typename: 'ConversationProfile';
            messagesSend?: number | null;
            send?: number | null;
            pokesSend?: number | null;
            giftsSend?: number | null;
        }>;
        user?: {
            __typename: 'User';
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    }>;
};

export type FindManyProfileHazelzQueryVariables = Exact<{
    where?: InputMaybe<ProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    withModeratedProfiles?: InputMaybe<Scalars['Boolean']>;
    withConversationProfiles?: InputMaybe<Scalars['Boolean']>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withPictures?: InputMaybe<Scalars['Boolean']>;
    picturesTake?: InputMaybe<Scalars['Int']>;
    picturesSkip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyProfileHazelzQuery = {
    __typename: 'Query';
    findManyProfile: Array<{
        __typename: 'Profile';
        projectName: string;
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        createdAt: Date | string;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        avatar?: {
            __typename: 'Avatar';
            path: string;
            avatarId: string;
            name?: string | null;
            gender: Gender;
            translations: Array<{ __typename: 'Translation'; translation: string }>;
        } | null;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        pictures?: Array<{ __typename: 'Picture'; pictureId: string; path: string }>;
        conversationProfiles?: Array<{
            __typename: 'ConversationProfile';
            messagesSend?: number | null;
            send?: number | null;
            pokesSend?: number | null;
            giftsSend?: number | null;
        }>;
        user?: {
            __typename: 'User';
            transactionCount: number;
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    }>;
};

export type FindOnlineUserQueryVariables = Exact<{
    gte?: InputMaybe<Scalars['DateTime']>;
    withUsers?: InputMaybe<Scalars['Boolean']>;
    withAdmins?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindOnlineUserQuery = {
    __typename: 'Query';
    admins: Array<{
        __typename: 'AdminUser';
        adminUserId: string;
        lastLogin?: Date | string | null;
        name: string;
        adminRoleName: string;
        privileges: Array<{ __typename: 'AdminUserPrivilege'; privilege: Privilege }>;
    }>;
    users: Array<{
        __typename: 'User';
        userId: string;
        username?: string | null;
        coins: number;
        bonus: number;
        client?: string | null;
        clientVersion?: string | null;
        createdAt: Date | string;
        createdIP: string;
        lastLogin?: Date | string | null;
        email?: string | null;
        mobileNumber?: string | null;
        emailValidated: boolean;
        mobileNumberValidated?: boolean | null;
        profileId?: string | null;
        role: UserRole;
        lastAction?: string | null;
        lastActivity?: Date | string | null;
        profile?: {
            __typename: 'Profile';
            profileId: string;
            profileName: string;
            gender: Gender;
            searches: Gender;
            birthDate?: string | null;
            postcode?: string | null;
            country?: string | null;
        } | null;
    }>;
    mods: Array<{
        __typename: 'ModerationUser';
        moderationUserId: string;
        name: string;
        chatStatus: ChatStatus;
        activeQueue?: { __typename: 'Queue'; queueId: string; type: QueueType } | null;
        teams: Array<{ __typename: 'ModerationTeam'; name: string; moderationTeamId: string }>;
        agency: { __typename: 'Agency'; name: string };
        statsHour: {
            __typename: 'ModeratorHourStatisticAggregateResult';
            _sum?: { __typename: 'ModeratorHourStatisticSumAggregateOutputType'; ins?: number | null; outs?: number | null } | null;
        };
        statsToday: {
            __typename: 'ModeratorStatisticAggregateResult';
            _sum?: { __typename: 'ModeratorStatisticSumAggregateOutputType'; ins?: number | null; outs?: number | null } | null;
        };
    }>;
};

export type FindManyUserQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withStatistics?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<UserOrderByInput>;
}>;

export type FindManyUserQuery = {
    __typename: 'Query';
    findManyUser: Array<{
        __typename: 'User';
        transactionCount: number;
        userId: string;
        legacyUserId?: string | null;
        email?: string | null;
        mobileNumber?: string | null;
        lastAction?: string | null;
        lastMessageReceived?: Date | string | null;
        lastMessageSend?: Date | string | null;
        role: UserRole;
        username?: string | null;
        updatedAt: Date | string;
        createdAt: Date | string;
        createdIP: string;
        sendTransactionalEmail: boolean;
        sendMarketingEmail: boolean;
        sendSMS: boolean;
        emailValidated: boolean;
        emailValidatedAt?: Date | string | null;
        mobileNumberValidated?: boolean | null;
        mobileNumberValidatedAt?: Date | string | null;
        conditionAcceptedAt?: Date | string | null;
        client?: string | null;
        clientVersion?: string | null;
        c4fId?: string | null;
        hasPayed: boolean;
        verified: boolean;
        online: boolean;
        lastLogin?: Date | string | null;
        domain?: string | null;
        registerDomain: string;
        coins: number;
        bonus: number;
        latitude?: string | null;
        longitude?: string | null;
        projectName: string;
        profileId?: string | null;
        userPremiumStatus?: Array<{
            __typename: 'UserPremiumStatus';
            profilePremiumStatusId: string;
            userId: string;
            status: ProfileStatus;
            validTo: Date | string;
            createdAt: Date | string;
        }>;
        userStatistic?: {
            __typename: 'UserStatistic';
            ins: number;
            sentMessages: number;
            spentBonus: number;
            spentCoins: number;
            transactions: number;
        } | null;
        profile?: {
            __typename: 'Profile';
            profileId: string;
            profileName: string;
            gender: Gender;
            searches: Gender;
            country?: string | null;
            timezone: string;
            postcode?: string | null;
            furtherInformation?: string | null;
            themeName?: string | null;
            adminText?: string | null;
            languageCode?: string | null;
            hidden: boolean;
            hideLocation: boolean;
            birthDate?: string | null;
            createdAt: Date | string;
            shownLocation?: string | null;
            flirtText?: string | null;
            premiumStatus: Array<ProfileStatus>;
            profileFlags?: Array<{ __typename: 'ProfileFlag'; createdAt: Date | string; flag: { __typename: 'Flag'; name: string } }>;
            images?: Array<{
                __typename: 'Image';
                imageId: string;
                path: string;
                main: boolean;
                private: boolean;
                fsk18: boolean;
                projectName: string;
                verified: boolean;
            }>;
            profileInterests?: Array<{ __typename: 'ProfileInterest'; interest: { __typename: 'Interest'; name: string } }>;
        } | null;
        socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string }>;
    }>;
};

export type FindManyUserCountQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
}>;

export type FindManyUserCountQuery = { __typename: 'Query'; findManyUserCount: number };

export type UsersFindManyModerationUserQueryVariables = Exact<{
    where?: InputMaybe<ModerationUserWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type UsersFindManyModerationUserQuery = {
    __typename: 'Query';
    findManyModerationUser: Array<{
        __typename: 'ModerationUser';
        moderationUserId: string;
        name: string;
        role: ModerationRole;
        minCharAwr?: number | null;
        minCharMessage?: number | null;
        fixedConversation: boolean;
        factor?: number | null;
        createdAt: Date | string;
        status: UserStatus;
        lastLogin?: Date | string | null;
        languageCode: string;
        teams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
        creator: { __typename: 'AdminUser'; name: string };
        agency: { __typename: 'Agency'; agencyId: string; name: string };
        privileges: Array<{ __typename: 'ModerationUserPrivilege'; moderationPrivilege: ModerationPrivilege }>;
        projects: Array<{ __typename: 'ModerationUserProject'; projectName: string; moderationUserProjectId: string }>;
        adminTexts: Array<{
            __typename: 'AdminText';
            text: string;
            type: AdminTextType;
            entityType: AdminTextEntityType;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        }>;
    }>;
};

export type FindManyModerationUserCountQueryVariables = Exact<{
    where?: InputMaybe<ModerationUserWhereInput>;
}>;

export type FindManyModerationUserCountQuery = { __typename: 'Query'; findManyModerationUserCount: number };

export type CreateOneModerationUserMutationVariables = Exact<{
    data: ModerationUserCreateInput;
}>;

export type CreateOneModerationUserMutation = {
    __typename: 'Mutation';
    createOneModerationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
};

export type UpdateOneModerationUserMutationVariables = Exact<{
    where: ModerationUserWhereUniqueInput;
    data: ModerationUserUpdateInput;
}>;

export type UpdateOneModerationUserMutation = {
    __typename: 'Mutation';
    updateOneModerationUser: {
        __typename: 'ModerationUser';
        moderationUserId: string;
        name: string;
        role: ModerationRole;
        minCharAwr?: number | null;
        minCharMessage?: number | null;
        fixedConversation: boolean;
        factor?: number | null;
        createdAt: Date | string;
        privileges: Array<{ __typename: 'ModerationUserPrivilege'; moderationPrivilege: ModerationPrivilege }>;
        teams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
        agency: { __typename: 'Agency'; agencyId: string; name: string };
    };
};

export type FindManyAdminUserQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyAdminUserQuery = {
    __typename: 'Query';
    findManyAdminUser: Array<{
        __typename: 'AdminUser';
        adminUserId: string;
        name: string;
        status: UserStatus;
        languageCode: string;
        adminRoleName: string;
        agencyId?: string | null;
        projects: Array<{ __typename: 'Project'; name: string }>;
        privileges: Array<{ __typename: 'AdminUserPrivilege'; privilege: Privilege }>;
        adminUserTeam: Array<{ __typename: 'AdminUserTeam'; moderationTeamId: string }>;
    }>;
};

export type CreateOneAdminUserMutationVariables = Exact<{
    data: AdminUserCreateInput;
}>;

export type CreateOneAdminUserMutation = {
    __typename: 'Mutation';
    createOneAdminUser: { __typename: 'AdminUser'; name: string; adminUserId: string; status: UserStatus };
};

export type UpdateOneAdminUserMutationVariables = Exact<{
    data: AdminUserUpdateInput;
    where: AdminUserWhereUniqueInput;
}>;

export type UpdateOneAdminUserMutation = {
    __typename: 'Mutation';
    updateOneAdminUser: { __typename: 'AdminUser'; adminUserId: string; name: string; status: UserStatus };
};

export type CreateOneAdminUserPrivilegeMutationVariables = Exact<{
    data: AdminUserPrivilegeCreateInput;
}>;

export type CreateOneAdminUserPrivilegeMutation = {
    __typename: 'Mutation';
    createOneAdminUserPrivilege: { __typename: 'AdminUserPrivilege'; adminUserPrivilegeId: string };
};

export type UploadPictureMutationVariables = Exact<{
    data: PictureCreateInput;
    where: ProfileWhereUniqueInput;
}>;

export type UploadPictureMutation = {
    __typename: 'Mutation';
    uploadPicture: { __typename: 'Picture'; pictureId: string; path: string; fsk18: boolean; projectName: string };
};

export type CreateOneImageMutationVariables = Exact<{
    data: ImageCreateInput;
}>;

export type CreateOneImageMutation = {
    __typename: 'Mutation';
    createOneImage: {
        __typename: 'Image';
        imageId: string;
        main: boolean;
        path: string;
        private: boolean;
        fsk18: boolean;
        verified: boolean;
        projectName: string;
    };
};

export type DeleteOneImageMutationVariables = Exact<{
    where: ImageWhereUniqueInput;
}>;

export type DeleteOneImageMutation = { __typename: 'Mutation'; deleteOneImage?: { __typename: 'Image'; imageId: string } | null };

export type UpdateOneImageMutationVariables = Exact<{
    where: ImageWhereUniqueInput;
    data: ImageUpdateInput;
}>;

export type UpdateOneImageMutation = {
    __typename: 'Mutation';
    updateOneImage: {
        __typename: 'Image';
        imageId: string;
        main: boolean;
        path: string;
        private: boolean;
        fsk18: boolean;
        verified: boolean;
        projectName: string;
    };
};

export type UpdateManyImageMutationVariables = Exact<{
    data: Array<ImageUpdateManyInput> | ImageUpdateManyInput;
}>;

export type UpdateManyImageMutation = { __typename: 'Mutation'; updateManyImage: { __typename: 'BatchPayload'; count: number } };

export type ImagesQueryVariables = Exact<{
    where?: InputMaybe<ImageWhereInput>;
    orderBy?: InputMaybe<ImageOrderByInput>;
    cursor?: InputMaybe<ImageWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type ImagesQuery = {
    __typename: 'Query';
    findManyImage?: Array<{
        __typename: 'Image';
        imageId: string;
        path: string;
        main: boolean;
        private: boolean;
        verified: boolean;
        fsk18: boolean;
        projectName: string;
        profile: {
            __typename: 'Profile';
            profileName: string;
            profileId: string;
            images: Array<{ __typename: 'Image'; path: string }>;
            moderatedProfile?: { __typename: 'ModeratedProfile'; moderatedProfileId: string } | null;
        };
    }> | null;
};

export type ImagesCountQueryVariables = Exact<{
    where?: InputMaybe<ImageWhereInput>;
}>;

export type ImagesCountQuery = { __typename: 'Query'; findManyImageCount?: number | null };

export type UpdateOneProfileMutationVariables = Exact<{
    where: ProfileWhereUniqueInput;
    data: ProfileUpdateInput;
}>;

export type UpdateOneProfileMutation = {
    __typename: 'Mutation';
    updateOneProfile:
        | { __typename: 'InputErrors'; errors: Array<{ __typename: 'InputError'; errors: Array<InputErrorEnum>; fieldName: string }> }
        | { __typename: 'Profile'; profileId: string };
};

export type VerifyOneUserMutationVariables = Exact<{
    where: UserWhereUniqueInput;
    data: UserVerifyInput;
}>;

export type VerifyOneUserMutation = { __typename: 'Mutation'; verifyOneUser: { __typename: 'User'; userId: string } };

export type UpdateOneUserMutationVariables = Exact<{
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withSocials?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    where: UserWhereUniqueInput;
    data: UserUpdateInput;
}>;

export type UpdateOneUserMutation = {
    __typename: 'Mutation';
    updateOneUser: {
        __typename: 'User';
        transactionCount: number;
        userId: string;
        email?: string | null;
        lastLogin?: Date | string | null;
        role: UserRole;
        username?: string | null;
        updatedAt: Date | string;
        createdAt: Date | string;
        createdIP: string;
        sendTransactionalEmail: boolean;
        sendMarketingEmail: boolean;
        sendSMS: boolean;
        emailValidated: boolean;
        emailValidatedAt?: Date | string | null;
        mobileNumber?: string | null;
        mobileNumberValidated?: boolean | null;
        mobileNumberValidatedAt?: Date | string | null;
        emailBounce: string;
        emailSuspendedUntil?: Date | string | null;
        conditionAcceptedAt?: Date | string | null;
        client?: string | null;
        clientVersion?: string | null;
        c4fId?: string | null;
        hasPayed: boolean;
        verified: boolean;
        unModerated: boolean;
        coins: number;
        bonus: number;
        latitude?: string | null;
        longitude?: string | null;
        userPremiumStatus?: Array<{ __typename: 'UserPremiumStatus'; profilePremiumStatusId: string; status: ProfileStatus; validTo: Date | string }>;
        socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
    };
};

export type UpdateOneModeratedProfileMutationVariables = Exact<{
    where: ModeratedProfileProjectWhereUniqueInput;
    data: ModeratedProfileUpdateInput;
}>;

export type UpdateOneModeratedProfileMutation = {
    __typename: 'Mutation';
    updateOneModeratedProfile: { __typename: 'ModeratedProfile'; moderatedProfileId: string };
};

export type UnbindSocialMutationVariables = Exact<{
    where: SocialWhereUniqueInput;
}>;

export type UnbindSocialMutation = { __typename: 'Mutation'; unbindSocial: { __typename: 'Social'; socialId: string } };

export type CreateMatchProfileMutationVariables = Exact<{
    data: MatchProfileCreateInput;
}>;

export type CreateMatchProfileMutation = {
    __typename: 'Mutation';
    createMatchProfile:
        | { __typename: 'CreateMatchProfileResultSuccess'; success?: boolean | null }
        | { __typename: 'Error'; errorCode?: number | null };
};

export type FindOneUserQueryVariables = Exact<{
    where: UserWhereUniqueInput;
    withProfile?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindOneUserQuery = {
    __typename: 'Query';
    findOneUser?: {
        __typename: 'User';
        userId: string;
        email?: string | null;
        mobileNumber?: string | null;
        lastLogin?: Date | string | null;
        role: UserRole;
        username?: string | null;
        updatedAt: Date | string;
        createdAt: Date | string;
        createdIP: string;
        sendTransactionalEmail: boolean;
        sendMarketingEmail: boolean;
        sendSMS: boolean;
        emailValidated: boolean;
        emailValidatedAt?: Date | string | null;
        mobileNumberValidated?: boolean | null;
        mobileNumberValidatedAt?: Date | string | null;
        emailBounce: string;
        emailSuspendedUntil?: Date | string | null;
        conditionAcceptedAt?: Date | string | null;
        client?: string | null;
        clientVersion?: string | null;
        c4fId?: string | null;
        hasPayed: boolean;
        verified: boolean;
        domain?: string | null;
        registerDomain: string;
        unModerated: boolean;
        coins: number;
        bonus: number;
        latitude?: string | null;
        longitude?: string | null;
        projectName: string;
        profile?: {
            __typename: 'Profile';
            profileId: string;
            profileName: string;
            gender: Gender;
            searches: Gender;
            country?: string | null;
            timezone: string;
            postcode?: string | null;
            furtherInformation?: string | null;
            themeName?: string | null;
            adminText?: string | null;
            languageCode?: string | null;
            hidden: boolean;
            hideLocation: boolean;
            birthDate?: string | null;
            createdAt: Date | string;
            shownLocation?: string | null;
            flirtText?: string | null;
            active: boolean;
            premiumStatus: Array<ProfileStatus>;
            completed: boolean;
        } | null;
        socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
    } | null;
};

export type FindOneProfileQueryVariables = Exact<{
    where: ProfileWhereUniqueInput;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindOneProfileQuery = {
    __typename: 'Query';
    findOneProfile?: {
        __typename: 'Profile';
        createdAt: Date | string;
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        theme?: { __typename: 'Theme'; name: string; label: string; images?: Array<{ __typename: 'ThemeImage'; path: string }> | null } | null;
        user?: {
            __typename: 'User';
            transactionCount: number;
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    } | null;
};

export type FindOneProfileFeaturesQueryVariables = Exact<{
    where: ProfileWhereUniqueInput;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindOneProfileFeaturesQuery = {
    __typename: 'Query';
    findOneProfile?: {
        __typename: 'Profile';
        defaultText?: string | null;
        profileTextId?: string | null;
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        createdAt: Date | string;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        avatar?: {
            __typename: 'Avatar';
            path: string;
            avatarId: string;
            name?: string | null;
            gender: Gender;
            translations: Array<{ __typename: 'Translation'; translation: string }>;
        } | null;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        theme?: { __typename: 'Theme'; name: string; label: string; images?: Array<{ __typename: 'ThemeImage'; path: string }> | null } | null;
        user?: {
            __typename: 'User';
            transactionCount: number;
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    } | null;
};

export type FindOneProfileHazelzQueryVariables = Exact<{
    where: ProfileWhereUniqueInput;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withTransactions?: InputMaybe<Scalars['Boolean']>;
    withUserPremiumStatus?: InputMaybe<Scalars['Boolean']>;
    withAvatar?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindOneProfileHazelzQuery = {
    __typename: 'Query';
    findOneProfile?: {
        __typename: 'Profile';
        profileId: string;
        profileName: string;
        gender: Gender;
        searches: Gender;
        country?: string | null;
        timezone: string;
        postcode?: string | null;
        furtherInformation?: string | null;
        themeName?: string | null;
        adminText?: string | null;
        languageCode?: string | null;
        hidden: boolean;
        hideLocation: boolean;
        birthDate?: string | null;
        createdAt: Date | string;
        shownLocation?: string | null;
        flirtText?: string | null;
        active: boolean;
        premiumStatus: Array<ProfileStatus>;
        completed: boolean;
        avatar?: {
            __typename: 'Avatar';
            path: string;
            avatarId: string;
            name?: string | null;
            gender: Gender;
            translations: Array<{ __typename: 'Translation'; translation: string }>;
        } | null;
        images?: Array<{
            __typename: 'Image';
            imageId: string;
            path: string;
            main: boolean;
            private: boolean;
            fsk18: boolean;
            projectName: string;
            verified: boolean;
        }>;
        theme?: { __typename: 'Theme'; name: string; label: string; images?: Array<{ __typename: 'ThemeImage'; path: string }> | null } | null;
        user?: {
            __typename: 'User';
            transactionCount: number;
            userId: string;
            email?: string | null;
            mobileNumber?: string | null;
            lastLogin?: Date | string | null;
            role: UserRole;
            username?: string | null;
            updatedAt: Date | string;
            createdAt: Date | string;
            createdIP: string;
            sendTransactionalEmail: boolean;
            sendMarketingEmail: boolean;
            sendSMS: boolean;
            emailValidated: boolean;
            emailValidatedAt?: Date | string | null;
            mobileNumberValidated?: boolean | null;
            mobileNumberValidatedAt?: Date | string | null;
            emailBounce: string;
            emailSuspendedUntil?: Date | string | null;
            conditionAcceptedAt?: Date | string | null;
            client?: string | null;
            clientVersion?: string | null;
            c4fId?: string | null;
            hasPayed: boolean;
            verified: boolean;
            domain?: string | null;
            registerDomain: string;
            unModerated: boolean;
            coins: number;
            bonus: number;
            latitude?: string | null;
            longitude?: string | null;
            projectName: string;
            userPremiumStatus?: Array<{
                __typename: 'UserPremiumStatus';
                profilePremiumStatusId: string;
                status: ProfileStatus;
                validTo: Date | string;
            }>;
            socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string; registerDomain: string }>;
        } | null;
        moderatedProfile?: {
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            fixed: ModerationType;
            dynamic: boolean;
            isFSK18: boolean;
            support: boolean;
            imageSource?: string | null;
            noNAWR: boolean;
            teams: Array<{
                __typename: 'ModeratedProfileTeam';
                projectName: string;
                team: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string };
            }>;
            moderators: Array<{
                __typename: 'ModeratedProfileModerationUser';
                moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
            }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        } | null;
        profileFlags?: Array<{
            __typename: 'ProfileFlag';
            createdAt: Date | string;
            flag: {
                __typename: 'Flag';
                flagId: string;
                name: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
            flagValue?: {
                __typename: 'FlagValue';
                flagValueId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            } | null;
        }>;
        profileInterests?: Array<{
            __typename: 'ProfileInterest';
            interest: {
                __typename: 'Interest';
                interestId: string;
                name: string;
                categoryId: string;
                translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
            };
        }>;
    } | null;
};

export type PicturesQueryVariables = Exact<{
    where?: InputMaybe<PictureWhereInput>;
    orderBy?: InputMaybe<Array<PictureOrderByInput> | PictureOrderByInput>;
    cursor?: InputMaybe<PictureWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type PicturesQuery = {
    __typename: 'Query';
    findManyPicture: Array<{
        __typename: 'Picture';
        path: string;
        ownerId: string;
        verified: boolean;
        fsk18: boolean;
        projectName: string;
        imageId: string;
        profile: {
            __typename: 'Profile';
            profileName: string;
            profileId: string;
            images: Array<{ __typename: 'Image'; path: string }>;
            moderatedProfile?: { __typename: 'ModeratedProfile'; moderatedProfileId: string } | null;
        };
    }>;
};

export type PicturesCountQueryVariables = Exact<{
    where?: InputMaybe<PictureWhereInput>;
}>;

export type PicturesCountQuery = { __typename: 'Query'; findManyPictureCount: number };

export type UpdateManyPictureMutationVariables = Exact<{
    data: Array<PictureUpdateManyInput> | PictureUpdateManyInput;
}>;

export type UpdateManyPictureMutation = { __typename: 'Mutation'; updateManyPicture: { __typename: 'BatchPayload'; count: number } };

export type ExportManyUserQueryVariables = Exact<{
    where?: InputMaybe<UserWhereInput>;
}>;

export type ExportManyUserQuery = { __typename: 'Query'; exportManyUser: { __typename: 'UserExport'; status: boolean; path?: string | null } };

export type InterestCategoriesQueryVariables = Exact<{
    where?: InputMaybe<InterestCategoryWhereInput>;
}>;

export type InterestCategoriesQuery = {
    __typename: 'Query';
    findManyInterestCategory?: Array<{
        __typename: 'InterestCategory';
        name: string;
        interestCategoryId: string;
        translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
        interests?: Array<{
            __typename: 'Interest';
            interestId: string;
            categoryId: string;
            active: boolean;
            name: string;
            translations: Array<{ __typename: 'Translation'; translation: string; languageCode: string }>;
        }> | null;
    }> | null;
};

export type LegacyUsersCountQueryVariables = Exact<{
    where: LegacyUserWhereInput;
}>;

export type LegacyUsersCountQuery = { __typename: 'Query'; legacyUsersCount: number };

export type LegacyUsersQueryVariables = Exact<{
    where: LegacyUserWhereInput;
    orderBy?: InputMaybe<LegacyUserOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type LegacyUsersQuery = {
    __typename: 'Query';
    legacyUsers: Array<{
        __typename: 'LegacyUser';
        username: string;
        email: string;
        userId: number;
        projectName: string;
        createdAt: Date | string;
        necomUserId?: string | null;
    }>;
};

export type ImportLegacyUserMutationVariables = Exact<{
    data: LegacyUserImportInput;
}>;

export type ImportLegacyUserMutation = {
    __typename: 'Mutation';
    importLegacyUser: { __typename: 'User'; username?: string | null; email?: string | null; userId: string };
};

export type CreateOneModeratedProfileMutationVariables = Exact<{
    data: ModeratedProfileCreateInput;
}>;

export type CreateOneModeratedProfileMutation = {
    __typename: 'Mutation';
    createOneModeratedProfile:
        | { __typename: 'InputErrors'; errors: Array<{ __typename: 'InputError'; errors: Array<InputErrorEnum>; fieldName: string }> }
        | { __typename: 'ModeratedProfile'; moderatedProfileId: string; profileId: string };
};

export type DeletePictureMutationVariables = Exact<{
    where: PictureWhereUniqueInput;
}>;

export type DeletePictureMutation = { __typename: 'Mutation'; deletePicture: boolean };

export type EmailValidateMutationVariables = Exact<{
    where: UserWhereUniqueInput;
}>;

export type EmailValidateMutation = {
    __typename: 'Mutation';
    validateEmail: { __typename: 'User'; userId: string; adminChanged: Date | string; updatedAt: Date | string; adminUserId?: string | null };
};

export type ValidateMobileNumberMutationVariables = Exact<{
    where: UserWhereUniqueInput;
}>;

export type ValidateMobileNumberMutation = {
    __typename: 'Mutation';
    validateMobileNumber: { __typename: 'User'; userId: string; adminChanged: Date | string; updatedAt: Date | string; adminUserId?: string | null };
};

export type FindManyUserStatisticAggregateQueryVariables = Exact<{
    where?: InputMaybe<UserStatisticWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyUserStatisticAggregateQuery = {
    __typename: 'Query';
    findManyUserStatisticAggregate?: {
        __typename: 'AggregateUserStatistic';
        _sum?: {
            __typename: 'UserStatisticAvgSumAggregateOutputType';
            ins?: number | null;
            sentPictures?: number | null;
            sentGifts?: number | null;
            sentMessages?: number | null;
            sentPokes?: number | null;
            spentBonus?: number | null;
            spentCoins?: number | null;
        } | null;
    } | null;
};

export type FindManyUserStatisticQueryVariables = Exact<{
    where?: InputMaybe<UserStatisticWhereInput>;
    take: Scalars['Int'];
}>;

export type FindManyUserStatisticQuery = {
    __typename: 'Query';
    findManyUserStatistic?: Array<{
        __typename: 'UserStatistic';
        userStatisticId: string;
        ins: number;
        transactions: number;
        turnover: number;
        sentPictures: number;
        sentGifts: number;
        sentMessages: number;
        sentPokes: number;
        spentBonus: number;
        spentCoins: number;
        user?: {
            __typename: 'User';
            userId: string;
            username?: string | null;
            profile?: { __typename: 'Profile'; profileId: string; profileName: string } | null;
        } | null;
    }> | null;
};

export type ChangeCoinsMutationVariables = Exact<{
    where: UserWhereUniqueInput;
    data: UserCoinsUpdateInput;
}>;

export type ChangeCoinsMutation = {
    __typename: 'Mutation';
    changeCoins: { __typename: 'User'; userId: string; coins: number; bonus: number; email?: string | null; username?: string | null };
};

export type CountUnverifiedUserQueryVariables = Exact<{ [key: string]: never }>;

export type CountUnverifiedUserQuery = { __typename: 'Query'; findManyUserCount: number };

export type FindUnverifiedUsersQueryVariables = Exact<{ [key: string]: never }>;

export type FindUnverifiedUsersQuery = {
    __typename: 'Query';
    findManyProfile: Array<{
        __typename: 'Profile';
        profileId: string;
        projectName: string;
        profileName: string;
        birthDate?: string | null;
        country?: string | null;
        postcode?: string | null;
        gender: Gender;
        searches: Gender;
        flirtText?: string | null;
        user?: {
            __typename: 'User';
            username?: string | null;
            email?: string | null;
            createdAt: Date | string;
            userId: string;
            role: UserRole;
        } | null;
    }>;
};

export type FindManyAffiliateUserQueryVariables = Exact<{
    where?: InputMaybe<AffiliateUserWhereInput>;
}>;

export type FindManyAffiliateUserQuery = {
    __typename: 'Query';
    findManyAffiliateUser?: Array<{
        __typename: 'AffiliateUser';
        affiliateUserId: string;
        name: string;
        email: string;
        linkCode?: string | null;
        createdAt: Date | string;
        c4fAuth: { __typename: 'C4F_Auth'; checksum: string; productid: number; projectName: string; userid: number };
        creator?: { __typename: 'AdminUser'; creatorId?: string | null; name: string } | null;
    }> | null;
};

export type CreateOneAffiliateUserMutationVariables = Exact<{
    data: AffiliateUserCreateInput;
}>;

export type CreateOneAffiliateUserMutation = {
    __typename: 'Mutation';
    createOneAffiliateUser: {
        __typename: 'AffiliateUserCreate';
        active: boolean;
        newUser: boolean;
        user: { __typename: 'AffiliateUser'; affiliateUserId: string; name: string };
    };
};

export type SetProvisionMutationVariables = Exact<{
    data: SetProvisionInput;
    where: AffiliateUserWhereUniqueInput;
}>;

export type SetProvisionMutation = { __typename: 'Mutation'; setProvision: boolean };

export type ScenarioSmallFragmentFragment = {
    __typename: 'ScenarioSmall';
    type: ScenarioType;
    name: string;
    projectName: string;
    scenarioId: string;
};

export type ScenarioCategoryFragmentFragment = {
    __typename: 'ScenarioCategory';
    scenarioCategoryId: string;
    name: string;
    count: number;
    scenarioProject: Array<{
        __typename: 'ScenarioProject';
        projectName: string;
        count: number;
        scenarioCategoryName: string;
        scenarios: Array<{ __typename: 'ScenarioSmall'; type: ScenarioType; name: string; projectName: string; scenarioId: string }>;
    }>;
};

export type ScenarioErrorFragmentFragment = { __typename: 'ScenarioError'; field: string; message: string; errorType: ScenarioErrorType };

export type ScenarioFragmentFragment = {
    __typename: 'Scenario';
    active: boolean;
    actionType: ScenarioActionType;
    categoryName: string;
    interval: number;
    lastExecution?: Date | string | null;
    maxExecutions?: number | null;
    moderationType?: ModerationType | null;
    name: string;
    nextExecution?: Date | string | null;
    pictureId?: string | null;
    probability: number;
    projectName: string;
    scenarioId: string;
    search: string;
    startHour: number;
    stopHour: number;
    texts: Array<string>;
    type: ScenarioType;
    moderatedProfiles: Array<{
        __typename: 'ModeratedProfile';
        moderatedProfileId: string;
        profileId: string;
        profile: { __typename: 'Profile'; profileId: string; profileName: string };
    }>;
    moderationTeams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
    moderationUsers: Array<{ __typename: 'ModerationUser'; moderationUserId: string; name: string }>;
    picture?: { __typename: 'Picture'; path: string } | null;
};

export type ModeratedProfileFragmentAiFragment = {
    __typename: 'ModeratedProfile';
    moderatedProfileId: string;
    profileId: string;
    projectName: string;
    profile: { __typename: 'Profile'; profileId: string; pictures: Array<{ __typename: 'Picture'; pictureId: string; path: string }> };
};

export type CreateOneScenarioCategoryMutationVariables = Exact<{
    data: ScenarioCategoryCreateInput;
}>;

export type CreateOneScenarioCategoryMutation = {
    __typename: 'Mutation';
    createOneScenarioCategory: {
        __typename: 'ScenarioCategory';
        scenarioCategoryId: string;
        name: string;
        count: number;
        scenarioProject: Array<{
            __typename: 'ScenarioProject';
            projectName: string;
            count: number;
            scenarioCategoryName: string;
            scenarios: Array<{ __typename: 'ScenarioSmall'; type: ScenarioType; name: string; projectName: string; scenarioId: string }>;
        }>;
    };
};

export type DeleteOneScenarioCategoryMutationVariables = Exact<{
    where: ScenarioCategoryWhereUniqueInput;
}>;

export type DeleteOneScenarioCategoryMutation = {
    __typename: 'Mutation';
    deleteOneScenarioCategory: {
        __typename: 'ScenarioCategory';
        scenarioCategoryId: string;
        name: string;
        count: number;
        scenarioProject: Array<{
            __typename: 'ScenarioProject';
            projectName: string;
            count: number;
            scenarioCategoryName: string;
            scenarios: Array<{ __typename: 'ScenarioSmall'; type: ScenarioType; name: string; projectName: string; scenarioId: string }>;
        }>;
    };
};

export type FindManyScenarioCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type FindManyScenarioCategoryQuery = {
    __typename: 'Query';
    findManyScenarioCategory: Array<{
        __typename: 'ScenarioCategory';
        scenarioCategoryId: string;
        name: string;
        count: number;
        scenarioProject: Array<{
            __typename: 'ScenarioProject';
            projectName: string;
            count: number;
            scenarioCategoryName: string;
            scenarios: Array<{ __typename: 'ScenarioSmall'; type: ScenarioType; name: string; projectName: string; scenarioId: string }>;
        }>;
    }>;
};

export type ScenarioFindOneModeratedProfileQueryVariables = Exact<{
    where: ModeratedProfileWhereUniqueInput;
}>;

export type ScenarioFindOneModeratedProfileQuery = {
    __typename: 'Query';
    findOneModeratedProfile: {
        __typename: 'ModeratedProfile';
        moderatedProfileId: string;
        profileId: string;
        projectName: string;
        profile: { __typename: 'Profile'; profileId: string; pictures: Array<{ __typename: 'Picture'; pictureId: string; path: string }> };
    };
};

export type FindManyScenarioQueryVariables = Exact<{
    cursor?: InputMaybe<ScenarioWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ScenarioOrderByInput> | ScenarioOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    where?: InputMaybe<ScenarioWhereInput>;
}>;

export type FindManyScenarioQuery = {
    __typename: 'Query';
    findManyScenario: Array<{
        __typename: 'Scenario';
        active: boolean;
        actionType: ScenarioActionType;
        categoryName: string;
        interval: number;
        lastExecution?: Date | string | null;
        maxExecutions?: number | null;
        moderationType?: ModerationType | null;
        name: string;
        nextExecution?: Date | string | null;
        pictureId?: string | null;
        probability: number;
        projectName: string;
        scenarioId: string;
        search: string;
        startHour: number;
        stopHour: number;
        texts: Array<string>;
        type: ScenarioType;
        moderatedProfiles: Array<{
            __typename: 'ModeratedProfile';
            moderatedProfileId: string;
            profileId: string;
            profile: { __typename: 'Profile'; profileId: string; profileName: string };
        }>;
        moderationTeams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
        moderationUsers: Array<{ __typename: 'ModerationUser'; moderationUserId: string; name: string }>;
        picture?: { __typename: 'Picture'; path: string } | null;
    }>;
};

export type UpdateOneScenarioMutationVariables = Exact<{
    data: ScenarioUpdateInput;
    where: ScenarioWhereUniqueInput;
}>;

export type UpdateOneScenarioMutation = {
    __typename: 'Mutation';
    updateOneScenario:
        | {
              __typename: 'Scenario';
              active: boolean;
              actionType: ScenarioActionType;
              categoryName: string;
              interval: number;
              lastExecution?: Date | string | null;
              maxExecutions?: number | null;
              moderationType?: ModerationType | null;
              name: string;
              nextExecution?: Date | string | null;
              pictureId?: string | null;
              probability: number;
              projectName: string;
              scenarioId: string;
              search: string;
              startHour: number;
              stopHour: number;
              texts: Array<string>;
              type: ScenarioType;
              moderatedProfiles: Array<{
                  __typename: 'ModeratedProfile';
                  moderatedProfileId: string;
                  profileId: string;
                  profile: { __typename: 'Profile'; profileId: string; profileName: string };
              }>;
              moderationTeams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
              moderationUsers: Array<{ __typename: 'ModerationUser'; moderationUserId: string; name: string }>;
              picture?: { __typename: 'Picture'; path: string } | null;
          }
        | { __typename: 'ScenarioError'; field: string; message: string; errorType: ScenarioErrorType };
};

export type CreateOneScenarioMutationVariables = Exact<{
    data: ScenarioCreateInput;
}>;

export type CreateOneScenarioMutation = {
    __typename: 'Mutation';
    createOneScenario:
        | {
              __typename: 'Scenario';
              active: boolean;
              actionType: ScenarioActionType;
              categoryName: string;
              interval: number;
              lastExecution?: Date | string | null;
              maxExecutions?: number | null;
              moderationType?: ModerationType | null;
              name: string;
              nextExecution?: Date | string | null;
              pictureId?: string | null;
              probability: number;
              projectName: string;
              scenarioId: string;
              search: string;
              startHour: number;
              stopHour: number;
              texts: Array<string>;
              type: ScenarioType;
              moderatedProfiles: Array<{
                  __typename: 'ModeratedProfile';
                  moderatedProfileId: string;
                  profileId: string;
                  profile: { __typename: 'Profile'; profileId: string; profileName: string };
              }>;
              moderationTeams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
              moderationUsers: Array<{ __typename: 'ModerationUser'; moderationUserId: string; name: string }>;
              picture?: { __typename: 'Picture'; path: string } | null;
          }
        | { __typename: 'ScenarioError'; field: string; message: string; errorType: ScenarioErrorType };
};

export type FindManyScenarioStatisticsQueryVariables = Exact<{
    where?: InputMaybe<ScenarioStatisticWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ScenarioStatisticOrderByInput> | ScenarioStatisticOrderByInput>;
}>;

export type FindManyScenarioStatisticsQuery = {
    __typename: 'Query';
    findManyScenarioStatistics: Array<{
        __typename: 'ScenarioStatistic';
        scenarioStatisticId: string;
        date: string;
        reactions: number;
        reactionsBonus: number;
        reactionsCoins: number;
        coins: number;
        bonus: number;
        outs: number;
        dailyCounter: number;
        received: number;
        scenario: { __typename: 'Scenario'; name: string; projectName: string };
    }>;
};

export type FindManyAdminSession24QueryVariables = Exact<{
    where?: InputMaybe<AdminSession24WhereInput>;
    orderBy?: InputMaybe<AdminSession24OrderByInput>;
    whereSession?: InputMaybe<AdminSessionWhereInput>;
}>;

export type FindManyAdminSession24Query = {
    __typename: 'Query';
    findManyAdminSession24: Array<{
        __typename: 'AdminSession24';
        adminSession24Id: string;
        adminName: string;
        date: Date | string;
        click: number;
        image: number;
        modComplain: number;
        supportMessage: number;
        supportTicket: number;
        systemComplain: number;
        transaction: number;
        userComplain: number;
        verifyUser: number;
        admin: { __typename: 'AdminUser'; adminUserId: string };
        adminSessions: Array<{
            __typename: 'AdminSession';
            adminSessionId: string;
            adminName: string;
            start: Date | string;
            end: Date | string;
            click: number;
            image: number;
            modComplain: number;
            projectName: string;
            supportMessage: number;
            supportTicket: number;
            systemComplain: number;
            transaction: number;
            userComplain: number;
            verifyUser: number;
        }>;
    }>;
};

export type CreateOneLogMutationVariables = Exact<{
    data: LogCreateInput;
}>;

export type CreateOneLogMutation = {
    __typename: 'Mutation';
    createOneLog?:
        | { __typename: 'AdminLogResultSuccess'; adminLogId?: string | null }
        | { __typename: 'ResultError'; errors?: Array<{ __typename: 'Error'; errorMessage?: string | null; errorCode?: number | null }> | null }
        | null;
};

export type FindManyAdminLogQueryVariables = Exact<{
    where?: InputMaybe<AdminLogWhereInput>;
    orderBy?: InputMaybe<AdminLogOrderByInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyAdminLogQuery = {
    __typename: 'Query';
    findManyAdminLog: Array<{
        __typename: 'AdminLog';
        adminLogId: string;
        adminName: string;
        logData: string;
        createdAt: Date | string;
        type: string;
    }>;
};

export type FindManyPictureQueryVariables = Exact<{
    where?: InputMaybe<PictureWhereInput>;
    cursor?: InputMaybe<PictureWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PictureOrderByInput> | PictureOrderByInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyPictureQuery = {
    __typename: 'Query';
    findManyPictureCount: number;
    findManyPicture: Array<{ __typename: 'Picture'; pictureId: string; path: string; fsk18: boolean }>;
};

export type ProfileDataSmallFragment = {
    __typename: 'Profile';
    profileName: string;
    profileId: string;
    gender: Gender;
    searches: Gender;
    country?: string | null;
    flirtText?: string | null;
    birthDate?: string | null;
    shownLocation?: string | null;
    hidden: boolean;
    images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
};

export type QueueStatsFragment = {
    __typename: 'QueueGroupBy';
    _count?: number | null;
    stats: Array<{ __typename: 'QueueStats'; type?: string | null; _count?: number | null }>;
};

export type MessageFragmentFragment = {
    __typename: 'Message';
    messageId: string;
    senderId: string;
    text?: string | null;
    type: MessageType;
    pictureId?: string | null;
    giftId?: string | null;
    createdAt: Date | string;
    path?: string | null;
    sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
    gift?: { __typename: 'Gift'; path: string } | null;
    picture?: { __typename: 'Picture'; path: string } | null;
};

export type FindManyProfileChatCountQueryVariables = Exact<{
    where?: InputMaybe<ProfileWhereInput>;
}>;

export type FindManyProfileChatCountQuery = { __typename: 'Query'; findManyProfileCount: number };

export type ChatFindManyModeratedProfileQueryVariables = Exact<{
    where?: InputMaybe<ModeratedProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type ChatFindManyModeratedProfileQuery = {
    __typename: 'Query';
    findManyModeratedProfile: Array<{
        __typename: 'ModeratedProfile';
        profileId: string;
        projectName: string;
        fixed: ModerationType;
        dynamic: boolean;
        isFSK18: boolean;
        moderators: Array<{ __typename: 'ModeratedProfileModerationUser'; moderationUserId: string }>;
        teams: Array<{ __typename: 'ModeratedProfileTeam'; team: { __typename: 'ModerationTeam'; name: string } }>;
        profile: {
            __typename: 'Profile';
            profileName: string;
            profileId: string;
            gender: Gender;
            searches: Gender;
            country?: string | null;
            flirtText?: string | null;
            birthDate?: string | null;
            shownLocation?: string | null;
            hidden: boolean;
            count: { __typename: 'ProfileCountOutputType'; pictures: number };
            images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
        };
        creator?: { __typename: 'AdminUser'; name: string } | null;
    }>;
};

export type FindManyAcquisitionQueryVariables = Exact<{
    where?: InputMaybe<AcquisitionWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyAcquisitionQuery = {
    __typename: 'Query';
    findManyAcquisition: Array<{
        __typename: 'Acquisition';
        acquisitionId: string;
        reachedReceivers: number;
        projectedReceivers?: number | null;
        projectName?: string | null;
        executionDate: Date | string;
        createdAt: Date | string;
        finishedAt?: Date | string | null;
        text?: string | null;
        search: string;
        model: AcquisitionType;
        profile: {
            __typename: 'ModeratedProfile';
            profileId: string;
            profile: { __typename: 'Profile'; profileName: string; gender: Gender; birthDate?: string | null; profileId: string };
        };
        creator: { __typename: 'AdminUser'; name: string };
        teams: Array<{ __typename: 'ModerationTeam'; name: string; moderationTeamId: string }>;
    }>;
};

export type CreateOneAcquisitionMutationVariables = Exact<{
    data: AcquisitionCreateInput;
}>;

export type CreateOneAcquisitionMutation = {
    __typename: 'Mutation';
    createOneAcquisition: {
        __typename: 'Acquisition';
        acquisitionId: string;
        reachedReceivers: number;
        projectName?: string | null;
        executionDate: Date | string;
        createdAt: Date | string;
        model: AcquisitionType;
    };
};

export type CreateOneDelayedAcquisitionMutationVariables = Exact<{
    data: AcquisitionDelayedCreateInput;
}>;

export type CreateOneDelayedAcquisitionMutation = {
    __typename: 'Mutation';
    createOneDelayedAcquisition: {
        __typename: 'Acquisition';
        acquisitionId: string;
        reachedReceivers: number;
        projectName?: string | null;
        executionDate: Date | string;
        createdAt: Date | string;
        model: AcquisitionType;
    };
};

export type FindManyConversationCountQueryVariables = Exact<{
    where: ConversationWhereInput;
}>;

export type FindManyConversationCountQuery = { __typename: 'Query'; findManyConversationCount?: number | null };

export type FindManyConversationQueryVariables = Exact<{
    where: ConversationWhereInput;
    withImages?: InputMaybe<Scalars['Boolean']>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyConversationQuery = {
    __typename: 'Query';
    findManyConversation?: Array<{
        __typename: 'Conversation';
        conversationId: string;
        projectName: string;
        support?: boolean | null;
        messageCount: number;
        moderated?: boolean | null;
        lastAction?: Date | string | null;
        lastMessageId?: string | null;
        profiles: Array<{
            __typename: 'ConversationProfile';
            profileId: string;
            send?: number | null;
            messagesSend?: number | null;
            giftsSend?: number | null;
            picturesSend?: number | null;
            pokesSend?: number | null;
            profile: {
                __typename: 'Profile';
                profileId: string;
                profileName: string;
                birthDate?: string | null;
                gender: Gender;
                languageCode?: string | null;
                shownLocation?: string | null;
                premiumStatus: Array<ProfileStatus>;
                images?: Array<{ __typename: 'Image'; path: string; main: boolean }>;
                user?: { __typename: 'User'; userId: string; online: boolean; hasPayed: boolean } | null;
            };
        }>;
        messages: Array<{
            __typename: 'Message';
            messageId: string;
            senderId: string;
            text?: string | null;
            type: MessageType;
            pictureId?: string | null;
            giftId?: string | null;
            createdAt: Date | string;
            path?: string | null;
            sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
            gift?: { __typename: 'Gift'; path: string } | null;
            picture?: { __typename: 'Picture'; path: string } | null;
        }>;
        firstMessage: Array<{
            __typename: 'Message';
            messageId: string;
            senderId: string;
            text?: string | null;
            type: MessageType;
            pictureId?: string | null;
            giftId?: string | null;
            createdAt: Date | string;
            path?: string | null;
            sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
            gift?: { __typename: 'Gift'; path: string } | null;
            picture?: { __typename: 'Picture'; path: string } | null;
        }>;
        moderationTeam?: { __typename: 'ModerationTeam'; name: string } | null;
        moderationUser?: { __typename: 'ModerationUser'; name: string } | null;
    }> | null;
};

export type ConversationQueryVariables = Exact<{
    where: ConversationWhereUniqueInput;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type ConversationQuery = {
    __typename: 'Query';
    findOneConversation?: {
        __typename: 'Conversation';
        conversationId: string;
        projectName: string;
        support?: boolean | null;
        moderated?: boolean | null;
        type?: ModerationType | null;
        lastAction?: Date | string | null;
        lastMessageId?: string | null;
        firstMessageId?: string | null;
        firstAction?: Date | string | null;
        comment?: string | null;
        moderationUsers: Array<{ __typename: 'ConversationModerationUser'; moderationUser: { __typename: 'ModerationUser'; name: string } }>;
        moderationTeam?: { __typename: 'ModerationTeam'; name: string; moderationTeamId: string } | null;
        moderationUser?: { __typename: 'ModerationUser'; name: string } | null;
        conversationTeams: Array<{
            __typename: 'ConversationTeam';
            conversationTeamId: string;
            team: { __typename: 'ModerationTeam'; name: string };
        }>;
        profiles: Array<{
            __typename: 'ConversationProfile';
            profile: {
                __typename: 'Profile';
                profileId: string;
                profileName: string;
                gender: Gender;
                searches: Gender;
                flirtText?: string | null;
                birthDate?: string | null;
                country?: string | null;
                county?: string | null;
                shownLocation?: string | null;
                postcode?: string | null;
                languageCode?: string | null;
                projectName: string;
                user?: { __typename: 'User'; userId: string; hasPayed: boolean } | null;
                images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
                moderatedProfile?: {
                    __typename: 'ModeratedProfile';
                    moderatedProfileId: string;
                    dynamic: boolean;
                    isFSK18: boolean;
                    fixed: ModerationType;
                } | null;
            };
        }>;
        queue?: { __typename: 'Queue'; reservedUntil?: Date | string | null; availableAt: Date | string } | null;
        messages: Array<{
            __typename: 'Message';
            messageId: string;
            senderId: string;
            text?: string | null;
            type: MessageType;
            pictureId?: string | null;
            giftId?: string | null;
            createdAt: Date | string;
            path?: string | null;
            moderationMessage?: {
                __typename: 'ModerationMessage';
                moderatorName?: string | null;
                processingTime: number;
                queueAvailableAt?: Date | string | null;
            } | null;
            sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
            gift?: { __typename: 'Gift'; path: string } | null;
            picture?: { __typename: 'Picture'; path: string } | null;
        }>;
        comments: Array<{
            __typename: 'Comment';
            commentId: string;
            conversationId?: string | null;
            profileId: string;
            moderated: boolean;
            text: string;
            projectName: string;
            commentFields: Array<{
                __typename: 'CommentCommentField';
                commentCommentFieldId: string;
                commentId: string;
                commentFieldId: string;
                value: string;
                commentField: {
                    __typename: 'CommentField';
                    commentFieldId: string;
                    name: string;
                    label: string;
                    languageCode: string;
                    projectName: string;
                };
            }>;
        }>;
    } | null;
};

export type FirstMessageQueryVariables = Exact<{
    where: MessageWhereUniqueInput;
}>;

export type FirstMessageQuery = {
    __typename: 'Query';
    findOneMessage?: {
        __typename: 'Message';
        messageId: string;
        senderId: string;
        text?: string | null;
        type: MessageType;
        pictureId?: string | null;
        giftId?: string | null;
        createdAt: Date | string;
        path?: string | null;
        sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
        gift?: { __typename: 'Gift'; path: string } | null;
        picture?: { __typename: 'Picture'; path: string } | null;
    } | null;
};

export type FindManyQueueGroupByQueryVariables = Exact<{
    where?: InputMaybe<QueueWhereInput>;
}>;

export type FindManyQueueGroupByQuery = {
    __typename: 'Query';
    findManyQueueGroupBy: Array<{
        __typename: 'QueueGroupBy';
        projectName: string;
        teams: Array<{
            __typename: 'QueueTeamGroupBy';
            moderationTeamId?: string | null;
            moderationTeam?: { __typename: 'ModerationTeam'; name: string } | null;
            moderators: Array<{
                __typename: 'QueueModeratorGroupBy';
                moderationUserId?: string | null;
                moderationUser?: { __typename: 'ModerationUser'; name: string } | null;
            }>;
        }>;
    }>;
};

export type FindManyQueueCountQueryVariables = Exact<{
    where?: InputMaybe<QueueWhereInput>;
}>;

export type FindManyQueueCountQuery = { __typename: 'Query'; findManyQueueCount?: number | null };

export type FindManyQueueQueryVariables = Exact<{
    where?: InputMaybe<QueueWhereInput>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<QueueOrderByInput>;
}>;

export type FindManyQueueQuery = {
    __typename: 'Query';
    findManyQueue?: Array<{
        __typename: 'Queue';
        queueId: string;
        payingCustomer: boolean;
        createdAt: Date | string;
        updatedAt: Date | string;
        availableAt: Date | string;
        type: QueueType;
        moderationType: ModerationType;
        reservedUntil?: Date | string | null;
        priority: number;
        projectName: string;
        customerProfile: {
            __typename: 'Profile';
            profileName: string;
            profileId: string;
            birthDate?: string | null;
            gender: Gender;
            languageCode?: string | null;
            shownLocation?: string | null;
            images?: Array<{ __typename: 'Image'; path: string }>;
        };
        moderatedProfile: {
            __typename: 'ModeratedProfile';
            fixed: ModerationType;
            profile: {
                __typename: 'Profile';
                profileName: string;
                birthDate?: string | null;
                gender: Gender;
                profileId: string;
                languageCode?: string | null;
                shownLocation?: string | null;
                images?: Array<{ __typename: 'Image'; path: string }>;
            };
        };
        moderationUser?: { __typename: 'ModerationUser'; name: string; chatStatus: ChatStatus } | null;
        moderationTeam?: { __typename: 'ModerationTeam'; name: string } | null;
        moderationTeams: Array<{ __typename: 'QueueTeam'; moderationTeam: { __typename: 'ModerationTeam'; name: string } }>;
        moderationUsers: Array<{ __typename: 'QueueModerationUser'; moderationUser: { __typename: 'ModerationUser'; name: string } }>;
        conversation?: { __typename: 'Conversation'; messageCount: number; moderated?: boolean | null; conversationId: string } | null;
    }> | null;
};

export type FindManyMessageQueryVariables = Exact<{
    where?: InputMaybe<MessageWhereInput>;
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<MessageOrderByInput>;
    showAcquisition?: InputMaybe<Scalars['Boolean']>;
    showScenario?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindManyMessageQuery = {
    __typename: 'Query';
    findManyMessage: Array<{
        __typename: 'Message';
        text?: string | null;
        conversationId: string;
        messageId: string;
        projectName: string;
        senderId: string;
        type: MessageType;
        createdAt: Date | string;
        conversation?: {
            __typename: 'Conversation';
            moderated?: boolean | null;
            profiles: Array<{
                __typename: 'ConversationProfile';
                profile: {
                    __typename: 'Profile';
                    profileName: string;
                    profileId: string;
                    gender: Gender;
                    birthDate?: string | null;
                    images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
                    moderatedProfile?: { __typename: 'ModeratedProfile'; moderatedProfileId: string } | null;
                };
            }>;
            queue?: { __typename: 'Queue'; reservedUntil?: Date | string | null; availableAt: Date | string } | null;
        } | null;
        moderationMessage?: {
            __typename: 'ModerationMessage';
            length: number;
            processingTime: number;
            queueAvailableAt?: Date | string | null;
            moderatorName?: string | null;
            adminName?: string | null;
            queueType?: string | null;
            moderationMessageType: ModerationMessageType;
        } | null;
    }>;
};

export type FindManyCommentFieldQueryVariables = Exact<{ [key: string]: never }>;

export type FindManyCommentFieldQuery = {
    __typename: 'Query';
    findManyCommentField?: Array<{
        __typename: 'CommentField';
        commentFieldId: string;
        name: string;
        label: string;
        languageCode: string;
        projectName: string;
    }> | null;
};

export type DefaultCommentQueryVariables = Exact<{
    where?: InputMaybe<DefaultCommentWhereInput>;
}>;

export type DefaultCommentQuery = { __typename: 'Query'; findManyDefaultComment: Array<{ __typename: 'DefaultComment'; text: string }> };

export type GiftsQueryVariables = Exact<{
    where?: InputMaybe<GiftWhereInput>;
    orderBy?: InputMaybe<GiftOrderByInput>;
    cursor?: InputMaybe<GiftWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type GiftsQuery = {
    __typename: 'Query';
    findManyGift?: Array<{
        __typename: 'Gift';
        giftId: string;
        name: string;
        cost: number;
        path: string;
        status: GiftStatus;
        projectName: string;
        categories: Array<{
            __typename: 'GiftCategory';
            giftCategoryId: string;
            name: string;
            private: boolean;
            status: GiftStatus;
            projectName: string;
        }>;
    }> | null;
};

export type CreateOneGiftMutationVariables = Exact<{
    data: GiftCreateInput;
}>;

export type CreateOneGiftMutation = {
    __typename: 'Mutation';
    createOneGift: {
        __typename: 'Gift';
        giftId: string;
        name: string;
        cost: number;
        path: string;
        status: GiftStatus;
        projectName: string;
        categories: Array<{ __typename: 'GiftCategory'; name: string }>;
    };
};

export type UpdateOneGiftMutationVariables = Exact<{
    where: GiftWhereUniqueInput;
    data: GiftUpdateInput;
}>;

export type UpdateOneGiftMutation = { __typename: 'Mutation'; updateOneGift: { __typename: 'Gift'; name: string; cost: number; status: GiftStatus } };

export type DeleteOneGiftMutationVariables = Exact<{
    where: GiftWhereUniqueInput;
}>;

export type DeleteOneGiftMutation = { __typename: 'Mutation'; deleteOneGift?: { __typename: 'Gift'; name: string } | null };

export type GiftCategoryQueryVariables = Exact<{
    where?: InputMaybe<GiftCategoryWhereInput>;
    orderBy?: InputMaybe<GiftCategoryOrderByInput>;
    cursor?: InputMaybe<GiftCategoryWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type GiftCategoryQuery = {
    __typename: 'Query';
    findManyGiftCategory?: Array<{
        __typename: 'GiftCategory';
        giftCategoryId: string;
        name: string;
        private: boolean;
        status: GiftStatus;
        projectName: string;
    }> | null;
};

export type CreateOneGiftCategoryMutationVariables = Exact<{
    data: GiftCategoryCreateInput;
}>;

export type CreateOneGiftCategoryMutation = {
    __typename: 'Mutation';
    createOneGiftCategory: {
        __typename: 'GiftCategory';
        giftCategoryId: string;
        name: string;
        private: boolean;
        status: GiftStatus;
        projectName: string;
    };
};

export type UpdateOneGiftCategoryMutationVariables = Exact<{
    where: GiftCategoryWhereUniqueInput;
    data: GiftCategoryUpdateInput;
}>;

export type UpdateOneGiftCategoryMutation = {
    __typename: 'Mutation';
    updateOneGiftCategory: {
        __typename: 'GiftCategory';
        giftCategoryId: string;
        name: string;
        private: boolean;
        status: GiftStatus;
        projectName: string;
    };
};

export type DeleteOneGiftCategoryMutationVariables = Exact<{
    where: GiftCategoryWhereUniqueInput;
}>;

export type DeleteOneGiftCategoryMutation = { __typename: 'Mutation'; deleteOneGiftCategory?: { __typename: 'GiftCategory'; name: string } | null };

export type UpdateOneQueueMutationVariables = Exact<{
    where: QueueWhereUniqueInput;
    data: QueueUpdateInput;
}>;

export type UpdateOneQueueMutation = { __typename: 'Mutation'; updateOneQueue?: { __typename: 'Queue'; queueId: string } | null };

export type UpdateOneConversationMutationVariables = Exact<{
    where: ConversationWhereUniqueInput;
    data: ConversationUpdateInput;
}>;

export type UpdateOneConversationMutation = { __typename: 'Mutation'; updateOneConversation: { __typename: 'Conversation'; conversationId: string } };

export type DeleteOneQueueMutationVariables = Exact<{
    where: QueueWhereUniqueInput;
}>;

export type DeleteOneQueueMutation = { __typename: 'Mutation'; deleteOneQueue?: { __typename: 'Queue'; queueId: string } | null };

export type CreateOneCommentMutationVariables = Exact<{
    data: CommentCreateInput;
}>;

export type CreateOneCommentMutation = { __typename: 'Mutation'; createOneComment: { __typename: 'Comment'; commentId: string } };

export type CreateOneCommentCommentFieldMutationVariables = Exact<{
    data: CommentCommentFieldCreateInput;
}>;

export type CreateOneCommentCommentFieldMutation = {
    __typename: 'Mutation';
    createOneCommentCommentField: { __typename: 'CommentCommentField'; commentCommentFieldId: string };
};

export type SaveCommentMutationVariables = Exact<{
    where: CommentWhereUniqueInput;
    data: CommentUpdateInput;
}>;

export type SaveCommentMutation = { __typename: 'Mutation'; updateOneComment: { __typename: 'Comment'; commentId: string } };

export type SaveCommentCommentFieldMutationVariables = Exact<{
    data: CommentCommentFieldUpdateInput;
    where: CommentCommentFieldWhereUniqueInput;
}>;

export type SaveCommentCommentFieldMutation = {
    __typename: 'Mutation';
    updateOneCommentCommentField: { __typename: 'CommentCommentField'; commentCommentFieldId: string };
};

export type FindManyMessageConversationQueryVariables = Exact<{
    where?: InputMaybe<MessageWhereInput>;
    cursor?: InputMaybe<MessageWhereUniqueInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyMessageConversationQuery = {
    __typename: 'Query';
    findManyMessage: Array<{
        __typename: 'Message';
        messageId: string;
        senderId: string;
        text?: string | null;
        type: MessageType;
        pictureId?: string | null;
        giftId?: string | null;
        createdAt: Date | string;
        path?: string | null;
        moderationMessage?: {
            __typename: 'ModerationMessage';
            moderatorName?: string | null;
            queueAvailableAt?: Date | string | null;
            processingTime: number;
        } | null;
        sender?: { __typename: 'Profile'; profileId: string; gender: Gender; profileName: string } | null;
        gift?: { __typename: 'Gift'; path: string } | null;
        picture?: { __typename: 'Picture'; path: string } | null;
    }>;
};

export type FindManyMessageCountQueryVariables = Exact<{
    where?: InputMaybe<MessageWhereInput>;
}>;

export type FindManyMessageCountQuery = { __typename: 'Query'; findManyMessageCount?: number | null };

export type FindManyModeratorHourStatisticGroupByQueryVariables = Exact<{
    days?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
    projectName: Scalars['String'];
    utcOffset?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyModeratorHourStatisticGroupByQuery = {
    __typename: 'Query';
    today?: Array<{
        __typename: 'ModeratorHourStatisticFindManyGroupByResult';
        date?: string | null;
        hour?: number | null;
        _sum?: {
            __typename: 'ModeratorHourStatisticSumAggregateOutputType';
            ins?: number | null;
            bonus?: number | null;
            outs?: number | null;
        } | null;
    }> | null;
};

export type FindManyModeratorHourStatisticGroupByRangeQueryVariables = Exact<{
    from: Scalars['String'];
    to: Scalars['String'];
    projectName: Scalars['String'];
    utcOffset?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyModeratorHourStatisticGroupByRangeQuery = {
    __typename: 'Query';
    range?: Array<{
        __typename: 'ModeratorHourStatisticFindManyGroupByResult';
        date?: string | null;
        hour?: number | null;
        _sum?: {
            __typename: 'ModeratorHourStatisticSumAggregateOutputType';
            ins?: number | null;
            bonus?: number | null;
            outs?: number | null;
        } | null;
    }> | null;
};

export type FindManyProjectStatisticQueryVariables = Exact<{
    where?: InputMaybe<ProjectStatisticWhereInput>;
}>;

export type FindManyProjectStatisticQuery = {
    __typename: 'Query';
    findManyProjectStatistic: Array<{
        __typename: 'ProjectStatistic';
        registrations?: number | null;
        activations?: number | null;
        date: string;
        projectStatisticExtended: Array<{ __typename: 'ProjectStatisticExtended'; gender: Gender; registrations: number; activations: number }>;
    }>;
};

export type ProjectsQueryVariables = Exact<{
    where?: InputMaybe<ProjectWhereInput>;
    orderBy?: InputMaybe<ProjectOrderByInput>;
    cursor?: InputMaybe<ProjectWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type ProjectsQuery = { __typename: 'Query'; findManyProject: Array<{ __typename: 'Project'; name: string }> };

export type FindManyModerationTeamQueryVariables = Exact<{
    where?: InputMaybe<ModerationTeamWhereInput>;
}>;

export type FindManyModerationTeamQuery = {
    __typename: 'Query';
    findManyModerationTeam: Array<{
        __typename: 'ModerationTeam';
        name: string;
        createdAt: Date | string;
        moderationTeamId: string;
        creator: { __typename: 'AdminUser'; name: string };
        moderators: Array<{ __typename: 'ModerationUser'; name: string }>;
    }>;
};

export type ModerationTeamsQueryVariables = Exact<{
    where?: InputMaybe<ModerationTeamWhereInput>;
    orderBy?: InputMaybe<ModerationTeamOrderByInput>;
    cursor?: InputMaybe<ModerationTeamWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    withModerators?: InputMaybe<Scalars['Boolean']>;
    withCreator?: InputMaybe<Scalars['Boolean']>;
}>;

export type ModerationTeamsQuery = {
    __typename: 'Query';
    findManyModerationTeam: Array<{
        __typename: 'ModerationTeam';
        moderationTeamId: string;
        name: string;
        updatedAt: Date | string;
        createdAt: Date | string;
        creator?: { __typename: 'AdminUser'; name: string };
        moderators?: Array<{ __typename: 'ModerationUser'; name: string }>;
    }>;
};

export type ModerationUsersQueryVariables = Exact<{
    where?: InputMaybe<ModerationUserWhereInput>;
    orderBy?: InputMaybe<ModerationUserOrderByInput>;
    cursor?: InputMaybe<ModerationUserWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
    withTeam?: InputMaybe<Scalars['Boolean']>;
    withAgency?: InputMaybe<Scalars['Boolean']>;
    withPrivileges?: InputMaybe<Scalars['Boolean']>;
    withCreator?: InputMaybe<Scalars['Boolean']>;
}>;

export type ModerationUsersQuery = {
    __typename: 'Query';
    findManyModerationUser: Array<{
        __typename: 'ModerationUser';
        moderationUserId: string;
        lastLogin?: Date | string | null;
        name: string;
        role: ModerationRole;
        creatorId: string;
        updatedAt: Date | string;
        createdAt: Date | string;
        minCharAwr?: number | null;
        minCharMessage?: number | null;
        agencyId: string;
        fixedConversation: boolean;
        factor?: number | null;
        languageCode: string;
        chatStatus: ChatStatus;
        creator?: { __typename: 'AdminUser'; name: string };
        privileges?: Array<{ __typename: 'ModerationUserPrivilege'; moderationUserPrivilegeId: string }>;
        teams?: Array<{ __typename: 'ModerationTeam'; name: string; moderationTeamId: string }>;
        agency?: { __typename: 'Agency'; name: string };
        projects?: Array<{ __typename: 'ModerationUserProject'; projectName: string }>;
    }>;
};

export type PoolsQueryVariables = Exact<{
    where?: InputMaybe<PoolWhereInput>;
    orderBy?: InputMaybe<PoolOrderByInput>;
    cursor?: InputMaybe<PoolWhereUniqueInput>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type PoolsQuery = {
    __typename: 'Query';
    findManyPool: Array<{
        __typename: 'Pool';
        poolId: string;
        name: string;
        updatedAt: Date | string;
        createdAt: Date | string;
        priority: number;
        activeFrom: string;
        activeTo: string;
        type: ModerationType;
        isDynamic?: boolean | null;
        filledAt?: Date | string | null;
        periodUnit?: PeriodUnitPool | null;
        fillPeriod?: number | null;
        projectName: string;
        count: number;
        creator: { __typename: 'AdminUser'; name: string };
        poolTeams: Array<{ __typename: 'PoolTeam'; moderationTeam: { __typename: 'ModerationTeam'; name: string; moderationTeamId: string } }>;
        poolModerationUsers: Array<{
            __typename: 'PoolModerationUser';
            moderationUser: { __typename: 'ModerationUser'; name: string; moderationUserId: string };
        }>;
        moderationTeam?: { __typename: 'ModerationTeam'; moderationTeamId: string; name: string } | null;
        moderationUser?: { __typename: 'ModerationUser'; moderationUserId: string; name: string } | null;
        targetProfiles: Array<{ __typename: 'PoolTargetProfile'; poolTargetProfileId: string }>;
        sourceProfiles: Array<{ __typename: 'PoolSourceProfile'; poolSourceProfileId: string }>;
    }>;
};

export type CreateOneModerationTeamMutationVariables = Exact<{
    data: ModerationTeamCreateInput;
}>;

export type CreateOneModerationTeamMutation = {
    __typename: 'Mutation';
    createOneModerationTeam: { __typename: 'ModerationTeam'; name: string; moderationTeamId: string };
};

export type UpdateOneModerationTeamMutationVariables = Exact<{
    data: ModerationTeamUpdateInput;
    where: ModerationTeamWhereUniqueInput;
}>;

export type UpdateOneModerationTeamMutation = {
    __typename: 'Mutation';
    updateOneModerationTeam: { __typename: 'ModerationTeam'; moderationTeamId: string };
};

export type CreateOnePoolMutationVariables = Exact<{
    data: PoolCreateInput;
}>;

export type CreateOnePoolMutation = { __typename: 'Mutation'; createOnePool: { __typename: 'Pool'; poolId: string; name: string } };

export type DeleteOneModerationTeamMutationVariables = Exact<{
    where: ModerationTeamWhereUniqueInput;
}>;

export type DeleteOneModerationTeamMutation = {
    __typename: 'Mutation';
    deleteOneModerationTeam?: { __typename: 'ModerationTeam'; name: string } | null;
};

export type DeleteOnePoolMutationVariables = Exact<{
    where: PoolWhereUniqueInput;
}>;

export type DeleteOnePoolMutation = { __typename: 'Mutation'; deleteOnePool?: { __typename: 'Pool'; name: string } | null };

export type DeleteManyPoolSourceProfileMutationVariables = Exact<{
    poolId: Scalars['String'];
}>;

export type DeleteManyPoolSourceProfileMutation = {
    __typename: 'Mutation';
    deleteManyPoolSourceProfile?: { __typename: 'BatchPayload'; count: number } | null;
};

export type DeleteOnePoolSourceProfileMutationVariables = Exact<{
    poolId: Scalars['String'];
    moderatedProfileId: Scalars['String'];
    projectName: Scalars['String'];
}>;

export type DeleteOnePoolSourceProfileMutation = {
    __typename: 'Mutation';
    deleteOnePoolSourceProfile?: {
        __typename: 'PoolSourceProfile';
        poolSourceProfileId: string;
        moderatedProfile: { __typename: 'ModeratedProfile'; moderatedProfileId: string; profile: { __typename: 'Profile'; profileName: string } };
    } | null;
};

export type DeleteManyPoolTargetProfileMutationVariables = Exact<{
    poolId: Scalars['String'];
}>;

export type DeleteManyPoolTargetProfileMutation = {
    __typename: 'Mutation';
    deleteManyPoolTargetProfile?: { __typename: 'BatchPayload'; count: number } | null;
};

export type DeleteOnePoolTargetProfileMutationVariables = Exact<{
    poolTargetProfileId: Scalars['String'];
}>;

export type DeleteOnePoolTargetProfileMutation = {
    __typename: 'Mutation';
    deleteOnePoolTargetProfile?: {
        __typename: 'PoolTargetProfile';
        poolTargetProfileId: string;
        targetProfile: { __typename: 'Profile'; profileName: string };
    } | null;
};

export type FindManyModeratorStatisticGroupByQueryVariables = Exact<{
    where?: InputMaybe<ModeratorStatisticWhereInputWithoutReference>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyModeratorStatisticGroupByQuery = {
    __typename: 'Query';
    findManyModeratorStatisticGroupBy: Array<{
        __typename: 'ModeratorStatisticFindManyGroupByResult';
        moderatorId?: string | null;
        moderator?: {
            __typename: 'ModerationUser';
            name: string;
            lastLogin?: Date | string | null;
            role: ModerationRole;
            minCharAwr?: number | null;
            minCharMessage?: number | null;
            fixedConversation: boolean;
            factor?: number | null;
            createdAt: Date | string;
            teams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
            agency: { __typename: 'Agency'; agencyId: string; name: string };
            projects: Array<{ __typename: 'ModerationUserProject'; projectName: string; moderationUserProjectId: string }>;
            creator: { __typename: 'AdminUser'; name: string };
            privileges: Array<{ __typename: 'ModerationUserPrivilege'; moderationPrivilege: ModerationPrivilege }>;
        } | null;
        _sum?: {
            __typename: 'ModeratorStatisticSumAggregateOutputType';
            try?: number | null;
            success?: number | null;
            coins?: number | null;
            bonus?: number | null;
            ins?: number | null;
            outs?: number | null;
            chars?: number | null;
            processingTime?: number | null;
            messages?: number | null;
            pokes?: number | null;
            pictures?: number | null;
            friendRequests?: number | null;
            gifts?: number | null;
        } | null;
        _avg?: { __typename: 'ModeratorStatisticAvgAggregateOutputType'; processingTime?: number | null; chars?: number | null } | null;
    }>;
};

export type FindManyModerationUserQueryVariables = Exact<{
    where?: InputMaybe<ModerationUserWhereInput>;
}>;

export type FindManyModerationUserQuery = {
    __typename: 'Query';
    findManyModerationUser: Array<{
        __typename: 'ModerationUser';
        moderationUserId: string;
        name: string;
        lastLogin?: Date | string | null;
        role: ModerationRole;
        minCharAwr?: number | null;
        minCharMessage?: number | null;
        fixedConversation: boolean;
        factor?: number | null;
        createdAt: Date | string;
        languageCode: string;
        teams: Array<{ __typename: 'ModerationTeam'; moderationTeamId: string; name: string }>;
        agency: { __typename: 'Agency'; agencyId: string; name: string };
        projects: Array<{ __typename: 'ModerationUserProject'; projectName: string; moderationUserProjectId: string }>;
        creator: { __typename: 'AdminUser'; name: string };
    }>;
};

export type FindManyModeratorStatisticAggregateQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type FindManyModeratorStatisticAggregateQuery = {
    __typename: 'Query';
    findManyModeratorStatisticAggregate: {
        __typename: 'ModeratorStatisticAggregateResult';
        _sum?: {
            __typename: 'ModeratorStatisticSumAggregateOutputType';
            try?: number | null;
            success?: number | null;
            coins?: number | null;
            bonus?: number | null;
            ins?: number | null;
            outs?: number | null;
            chars?: number | null;
            processingTime?: number | null;
            messages?: number | null;
            pokes?: number | null;
            pictures?: number | null;
            friendRequests?: number | null;
            gifts?: number | null;
        } | null;
    };
};

export type UpdateOnePoolMutationVariables = Exact<{
    data: PoolUpdateInput;
    poolId: Scalars['String'];
}>;

export type UpdateOnePoolMutation = { __typename: 'Mutation'; updateOnePool: { __typename: 'Pool'; poolId: string; name: string } };

export type FindManyModeratedProfileCountQueryVariables = Exact<{
    where?: InputMaybe<ModeratedProfileWhereInput>;
}>;

export type FindManyModeratedProfileCountQuery = { __typename: 'Query'; findManyModeratedProfileCount: number };

export type FindManyModeratedProfileQueryVariables = Exact<{
    where?: InputMaybe<ModeratedProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyModeratedProfileQuery = {
    __typename: 'Query';
    findManyModeratedProfile: Array<{
        __typename: 'ModeratedProfile';
        moderatedProfileId: string;
        profileId: string;
        isFSK18: boolean;
        support: boolean;
        dynamic: boolean;
        projectName: string;
        fixed: ModerationType;
        noNAWR: boolean;
        profile: {
            __typename: 'Profile';
            profileId: string;
            profileName: string;
            gender: Gender;
            searches: Gender;
            country?: string | null;
            flirtText?: string | null;
            hidden: boolean;
            active: boolean;
            birthDate?: string | null;
            shownLocation?: string | null;
            createdAt: Date | string;
            images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
            count: { __typename: 'ProfileCountOutputType'; pictures: number };
        };
        teams: Array<{ __typename: 'ModeratedProfileTeam'; teamId: string; team: { __typename: 'ModerationTeam'; name: string } }>;
        creator?: { __typename: 'AdminUser'; name: string } | null;
    }>;
};

export type FindManyPoolTargetProfileQueryVariables = Exact<{
    poolId?: InputMaybe<Scalars['String']>;
    where?: InputMaybe<UserWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    withImages?: InputMaybe<Scalars['Boolean']>;
    withProfileInterests?: InputMaybe<Scalars['Boolean']>;
    withProfileFlags?: InputMaybe<Scalars['Boolean']>;
    withStatistics?: InputMaybe<Scalars['Boolean']>;
}>;

export type FindManyPoolTargetProfileQuery = {
    __typename: 'Query';
    findManyPoolTargetProfile: Array<{
        __typename: 'PoolTargetProfile';
        poolId: string;
        poolTargetProfileId: string;
        targetProfile: {
            __typename: 'Profile';
            user?: {
                __typename: 'User';
                userId: string;
                email?: string | null;
                mobileNumber?: string | null;
                lastAction?: string | null;
                lastMessageReceived?: Date | string | null;
                lastMessageSend?: Date | string | null;
                role: UserRole;
                username?: string | null;
                updatedAt: Date | string;
                createdAt: Date | string;
                createdIP: string;
                sendTransactionalEmail: boolean;
                sendMarketingEmail: boolean;
                sendSMS: boolean;
                mobileNumberValidated?: boolean | null;
                mobileNumberValidatedAt?: Date | string | null;
                emailValidated: boolean;
                emailValidatedAt?: Date | string | null;
                conditionAcceptedAt?: Date | string | null;
                client?: string | null;
                clientVersion?: string | null;
                c4fId?: string | null;
                hasPayed: boolean;
                verified: boolean;
                coins: number;
                bonus: number;
                latitude?: string | null;
                longitude?: string | null;
                projectName: string;
                profileId?: string | null;
                userStatistic?: { __typename: 'UserStatistic'; ins: number; sentMessages: number; spentBonus: number; spentCoins: number } | null;
                profile?: {
                    __typename: 'Profile';
                    profileId: string;
                    profileName: string;
                    gender: Gender;
                    searches: Gender;
                    country?: string | null;
                    timezone: string;
                    postcode?: string | null;
                    furtherInformation?: string | null;
                    themeName?: string | null;
                    adminText?: string | null;
                    languageCode?: string | null;
                    hidden: boolean;
                    hideLocation: boolean;
                    birthDate?: string | null;
                    createdAt: Date | string;
                    shownLocation?: string | null;
                    flirtText?: string | null;
                    profileFlags?: Array<{ __typename: 'ProfileFlag'; createdAt: Date | string; flag: { __typename: 'Flag'; name: string } }>;
                    images?: Array<{
                        __typename: 'Image';
                        imageId: string;
                        path: string;
                        main: boolean;
                        private: boolean;
                        fsk18: boolean;
                        projectName: string;
                        verified: boolean;
                    }>;
                    profileInterests?: Array<{ __typename: 'ProfileInterest'; interest: { __typename: 'Interest'; name: string } }>;
                } | null;
                socials?: Array<{ __typename: 'Social'; providerName: string; socialId: string; providerId: string }>;
            } | null;
        };
    }>;
};

export type FindManyPoolTargetProfileCountQueryVariables = Exact<{
    poolId?: InputMaybe<Scalars['String']>;
    where?: InputMaybe<UserWhereInput>;
}>;

export type FindManyPoolTargetProfileCountQuery = { __typename: 'Query'; findManyPoolTargetProfileCount: number };

export type FindManyPoolSourceProfileQueryVariables = Exact<{
    poolId?: InputMaybe<Scalars['String']>;
    where?: InputMaybe<ModeratedProfileWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyPoolSourceProfileQuery = {
    __typename: 'Query';
    findManyPoolSourceProfile: Array<{
        __typename: 'PoolSourceProfile';
        poolId: string;
        poolSourceProfileId: string;
        moderatedProfileId: string;
        moderatedProfile: {
            __typename: 'ModeratedProfile';
            profileId: string;
            isFSK18: boolean;
            support: boolean;
            dynamic: boolean;
            projectName: string;
            fixed: ModerationType;
            profile: {
                __typename: 'Profile';
                profileId: string;
                profileName: string;
                gender: Gender;
                searches: Gender;
                country?: string | null;
                flirtText?: string | null;
                birthDate?: string | null;
                shownLocation?: string | null;
                createdAt: Date | string;
                images: Array<{ __typename: 'Image'; path: string; main: boolean; private: boolean }>;
            };
            teams: Array<{ __typename: 'ModeratedProfileTeam'; teamId: string; team: { __typename: 'ModerationTeam'; name: string } }>;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        };
    }>;
};

export type FindManyAgencyQueryVariables = Exact<{
    where?: InputMaybe<AgencyWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyAgencyQuery = {
    __typename: 'Query';
    findManyAgency: Array<{
        __typename: 'Agency';
        agencyId: string;
        name: string;
        factor: number;
        createdAt: Date | string;
        creator: { __typename: 'AdminUser'; name: string };
        projects: Array<{ __typename: 'AgencyProject'; projectName: string }>;
        _count: { __typename: 'AgencyCountOutputType'; adminUsers: number; moderationUsers: number; agencyStatistics: number };
    }>;
};

export type CreateOneAgencyMutationVariables = Exact<{
    data: AgencyCreateInput;
}>;

export type CreateOneAgencyMutation = { __typename: 'Mutation'; createOneAgency: { __typename: 'Agency'; agencyId: string } };

export type UpdateOneAgencyMutationVariables = Exact<{
    data: AgencyUpdateInput;
    where: AgencyWhereUniqueInput;
}>;

export type UpdateOneAgencyMutation = { __typename: 'Mutation'; updateOneAgency: { __typename: 'Agency'; agencyId: string } };

export type DeleteOneAgencyMutationVariables = Exact<{
    where: AgencyWhereUniqueInput;
}>;

export type DeleteOneAgencyMutation = { __typename: 'Mutation'; deleteOneAgency?: { __typename: 'Agency'; name: string } | null };

export type DeleteOneAgencyProjectMutationVariables = Exact<{
    where: AgencyProjectWhereUniqueInput;
}>;

export type DeleteOneAgencyProjectMutation = {
    __typename: 'Mutation';
    deleteOneAgencyProject: { __typename: 'AgencyProject'; agencyProjectId: string };
};

export type UpdateManyModeratedProfileMutationVariables = Exact<{
    data: ModeratedProfileUpdateManyInput;
    where: ModeratedProfileWhereInput;
}>;

export type UpdateManyModeratedProfileMutation = {
    __typename: 'Mutation';
    updateManyModeratedProfile: { __typename: 'BatchPayload'; count: number };
};

export type ModStatsTodayQueryVariables = Exact<{
    id: Scalars['String'];
    date: StringFilter;
}>;

export type ModStatsTodayQuery = {
    __typename: 'Query';
    findManyModeratorStatisticAggregate: {
        __typename: 'ModeratorStatisticAggregateResult';
        _sum?: {
            __typename: 'ModeratorStatisticSumAggregateOutputType';
            try?: number | null;
            success?: number | null;
            coins?: number | null;
            bonus?: number | null;
            ins?: number | null;
            outs?: number | null;
            chars?: number | null;
            processingTime?: number | null;
            messages?: number | null;
            pokes?: number | null;
            pictures?: number | null;
            friendRequests?: number | null;
            gifts?: number | null;
        } | null;
    };
};

export type ModStatsHourQueryVariables = Exact<{
    id: Scalars['String'];
    date: StringFilter;
    hour: IntFilter;
}>;

export type ModStatsHourQuery = {
    __typename: 'Query';
    findManyModeratorHourStatisticAggregate?: {
        __typename: 'ModeratorHourStatisticAggregateResult';
        _sum?: {
            __typename: 'ModeratorHourStatisticSumAggregateOutputType';
            try?: number | null;
            success?: number | null;
            coins?: number | null;
            bonus?: number | null;
            ins?: number | null;
            outs?: number | null;
            chars?: number | null;
            processingTime?: number | null;
            messages?: number | null;
            pokes?: number | null;
            pictures?: number | null;
            friendRequests?: number | null;
            gifts?: number | null;
        } | null;
    } | null;
};

export type ModStatsTop10QueryVariables = Exact<{
    where: ModeratorStatisticWhereInputWithoutReference;
}>;

export type ModStatsTop10Query = {
    __typename: 'Query';
    result: Array<{
        __typename: 'ModeratorStatisticFindManyGroupByResult';
        moderatorId?: string | null;
        moderator?: { __typename: 'ModerationUser'; name: string } | null;
        _sum?: {
            __typename: 'ModeratorStatisticSumAggregateOutputType';
            try?: number | null;
            success?: number | null;
            coins?: number | null;
            bonus?: number | null;
            ins?: number | null;
            outs?: number | null;
            chars?: number | null;
            processingTime?: number | null;
            messages?: number | null;
            pokes?: number | null;
            pictures?: number | null;
            friendRequests?: number | null;
            gifts?: number | null;
        } | null;
        _avg?: { __typename: 'ModeratorStatisticAvgAggregateOutputType'; processingTime?: number | null; chars?: number | null } | null;
    }>;
};

export type FindManyBadwordQueryVariables = Exact<{
    word?: InputMaybe<StringFilter>;
}>;

export type FindManyBadwordQuery = {
    __typename: 'Query';
    usernameBadword: Array<{ __typename: 'UsernameBadword'; usernameBadwordId: string; word: string }>;
    messageBadword: Array<{ __typename: 'MessageBadword'; messageBadwordId: string; word: string }>;
};

export type CreateOneUsernameBadwordMutationVariables = Exact<{
    data: BadwordCreateInput;
}>;

export type CreateOneUsernameBadwordMutation = {
    __typename: 'Mutation';
    createOneUsernameBadword?:
        | { __typename: 'BadwordResultSuccess'; badwordId?: string | null }
        | { __typename: 'ResultError'; errors?: Array<{ __typename: 'Error'; errorMessage?: string | null; errorCode?: number | null }> | null }
        | null;
};

export type CreateOneMessageBadwordMutationVariables = Exact<{
    data: BadwordCreateInput;
}>;

export type CreateOneMessageBadwordMutation = {
    __typename: 'Mutation';
    createOneMessageBadword?:
        | { __typename: 'BadwordResultSuccess'; badwordId?: string | null }
        | { __typename: 'ResultError'; errors?: Array<{ __typename: 'Error'; errorMessage?: string | null; errorCode?: number | null }> | null }
        | null;
};

export type GetMatchConfigQueryVariables = Exact<{
    where: MatchConfigWhereUniqueInput;
}>;

export type GetMatchConfigQuery = {
    __typename: 'Query';
    findUniqueMatchConfig: {
        __typename: 'MatchConfig';
        matchConfigId: string;
        adminUserId?: string | null;
        buyMore: boolean;
        chanceBefore: number;
        chanceMatch: number;
        cost: number;
        deleteAfter: number;
        freeMessages: number;
        maxMatches: number;
        raiseAwr: boolean;
        updatedAt: Date | string;
        name: string;
    };
};

export type UpdateMatchConfigMutationVariables = Exact<{
    data: MatchConfigUpdateInput;
    where: MatchConfigWhereUniqueInput;
}>;

export type UpdateMatchConfigMutation = {
    __typename: 'Mutation';
    updateMatchConfig: {
        __typename: 'MatchConfig';
        buyMore: boolean;
        chanceBefore: number;
        chanceMatch: number;
        cost: number;
        deleteAfter: number;
        freeMessages: number;
        matchConfigId: string;
        maxMatches: number;
        raiseAwr: boolean;
        name: string;
    };
};

export type FlagCreateMutationVariables = Exact<{
    data: FlagCreateInput;
}>;

export type FlagCreateMutation = {
    __typename: 'Mutation';
    createOneFlag: {
        __typename: 'Flag';
        flagId: string;
        gender: FlagGender;
        max?: number | null;
        maxLength: number;
        min?: number | null;
        minLength: number;
        name: string;
        numberStep?: string | null;
        projectName: string;
        regEx?: string | null;
        type: FlagType;
        translations: Array<{
            __typename: 'Translation';
            flagId?: string | null;
            translationId: string;
            translation: string;
            languageCode: string;
            projectName: string;
        }>;
    };
};

export type FlagUpdateMutationVariables = Exact<{
    where: FlagWhereUniqueInput;
    data: FlagUpdateInput;
}>;

export type FlagUpdateMutation = {
    __typename: 'Mutation';
    updateOneFlag: {
        __typename: 'Flag';
        flagId: string;
        gender: FlagGender;
        max?: number | null;
        maxLength: number;
        min?: number | null;
        minLength: number;
        name: string;
        numberStep?: string | null;
        projectName: string;
        regEx?: string | null;
        type: FlagType;
        translations: Array<{
            __typename: 'Translation';
            flagId?: string | null;
            translationId: string;
            translation: string;
            languageCode: string;
            projectName: string;
        }>;
    };
};

export type FlagsQueryVariables = Exact<{ [key: string]: never }>;

export type FlagsQuery = {
    __typename: 'Query';
    findManyFlag: Array<{
        __typename: 'Flag';
        flagId: string;
        gender: FlagGender;
        max?: number | null;
        maxLength: number;
        min?: number | null;
        minLength: number;
        name: string;
        numberStep?: string | null;
        projectName: string;
        regEx?: string | null;
        type: FlagType;
        translation: { __typename: 'Translation'; translation: string; languageCode: string };
        flagValues: Array<{
            __typename: 'FlagValue';
            flagId: string;
            flagValueId: string;
            translations: Array<{
                __typename: 'Translation';
                flagValueId?: string | null;
                translationId: string;
                translation: string;
                languageCode: string;
                projectName: string;
            }>;
        }>;
        translations: Array<{
            __typename: 'Translation';
            flagId?: string | null;
            translationId: string;
            translation: string;
            languageCode: string;
            projectName: string;
        }>;
    }>;
};

export type FindManyAwrQueryVariables = Exact<{
    where: AwrWhereInput;
}>;

export type FindManyAwrQuery = {
    __typename: 'Query';
    findManyAWR: Array<{
        __typename: 'AutomaticWriteRequest';
        automaticWriteRequestId: string;
        level: number;
        timeFrom: number;
        timeTo: number;
        flex: number;
        minHour: number;
        maxHour: number;
        type: AwrType;
        priority: number;
        maxLastLogin?: number | null;
        minLastLogin?: number | null;
        projectName: string;
        active: boolean;
        endRaise: boolean;
    }>;
};

export type CreateOneAwrMutationVariables = Exact<{
    data: AwrCreateInput;
}>;

export type CreateOneAwrMutation = {
    __typename: 'Mutation';
    createOneAWR: {
        __typename: 'AutomaticWriteRequest';
        automaticWriteRequestId: string;
        level: number;
        timeFrom: number;
        timeTo: number;
        flex: number;
        minHour: number;
        maxHour: number;
        type: AwrType;
        priority: number;
        maxLastLogin?: number | null;
        minLastLogin?: number | null;
        projectName: string;
        active: boolean;
        endRaise: boolean;
    };
};

export type UpdateOneAwrMutationVariables = Exact<{
    where: AwrWhereUniqueInput;
    data: AwrUpdateInput;
}>;

export type UpdateOneAwrMutation = {
    __typename: 'Mutation';
    updateOneAWR: {
        __typename: 'AutomaticWriteRequest';
        automaticWriteRequestId: string;
        level: number;
        timeFrom: number;
        timeTo: number;
        flex: number;
        minHour: number;
        maxHour: number;
        type: AwrType;
        priority: number;
        maxLastLogin?: number | null;
        minLastLogin?: number | null;
        projectName: string;
        active: boolean;
        endRaise: boolean;
    };
};

export type DeleteOneAwrMutationVariables = Exact<{
    where: AwrWhereUniqueInput;
}>;

export type DeleteOneAwrMutation = { __typename: 'Mutation'; deleteOneAWR: { __typename: 'AutomaticWriteRequest'; level: number } };

export type FindManyDefaultCommentQueryVariables = Exact<{
    where: DefaultCommentWhereInput;
}>;

export type FindManyDefaultCommentQuery = {
    __typename: 'Query';
    findManyDefaultComment: Array<{
        __typename: 'DefaultComment';
        defaultCommentId: string;
        projectName: string;
        text: string;
        languageCode: string;
    }>;
};

export type UpdateDefaultCommentMutationVariables = Exact<{
    data: DefaultCommentUpdateInput;
    where: DefaultCommentWhereUniqueInput;
}>;

export type UpdateDefaultCommentMutation = {
    __typename: 'Mutation';
    updateDefaultComment: { __typename: 'DefaultComment'; defaultCommentId: string };
};

export type FindManyQueueTypePriorityQueryVariables = Exact<{
    where?: InputMaybe<QueueTypePriorityWhereInput>;
}>;

export type FindManyQueueTypePriorityQuery = {
    __typename: 'Query';
    findManyQueueTypePriority: Array<{ __typename: 'QueueTypePriority'; priority: number; type: QueueType }>;
};

export type UpdateManyQueueTypePriorityMutationVariables = Exact<{
    data: Array<QueueTypePriorityUpdateManyMutationInput> | QueueTypePriorityUpdateManyMutationInput;
}>;

export type UpdateManyQueueTypePriorityMutation = {
    __typename: 'Mutation';
    updateManyQueueTypePriority: { __typename: 'BatchPayload'; count: number };
};

export type ResetCacheMutationVariables = Exact<{ [key: string]: never }>;

export type ResetCacheMutation = { __typename: 'Mutation'; resetCache: boolean };

export type ModProfileGroupByAgeQueryVariables = Exact<{ [key: string]: never }>;

export type ModProfileGroupByAgeQuery = {
    __typename: 'Query';
    profileGroupBy?: Array<{
        __typename: 'CountProfile';
        gender?: Gender | null;
        age?: number | null;
        _count?: { __typename: 'ProfileCountAggregateOutputType'; _all?: number | null } | null;
    }> | null;
};

export type ModProfileGroupByPostcodeQueryVariables = Exact<{ [key: string]: never }>;

export type ModProfileGroupByPostcodeQuery = {
    __typename: 'Query';
    profileGroupBy?: Array<{
        __typename: 'CountProfile';
        postcode?: string | null;
        gender?: Gender | null;
        _count?: { __typename: 'ProfileCountAggregateOutputType'; _all?: number | null } | null;
    }> | null;
};

export type SupportFindManyAdminUserQueryVariables = Exact<{ [key: string]: never }>;

export type SupportFindManyAdminUserQuery = {
    __typename: 'Query';
    findManyAdminUser: Array<{
        __typename: 'AdminUser';
        adminUserId: string;
        adminRoleName: string;
        name: string;
        privileges: Array<{ __typename: 'AdminUserPrivilege'; privilege: Privilege }>;
    }>;
};

export type FindManySupportTicketQueryVariables = Exact<{
    where?: InputMaybe<SupportTicketWhereInput>;
    take?: InputMaybe<Scalars['Int']>;
}>;

export type FindManySupportTicketQuery = {
    __typename: 'Query';
    findManySupportTicket?: Array<{
        __typename: 'SupportTicket';
        supportTicketId: string;
        status: SupportTicketStatus;
        category: SupportCategory;
        userId: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        priority: number;
        projectName: string;
        supportMessages?: Array<{ __typename: 'SupportMessage'; supportMessageId: string; text: string; read: boolean }> | null;
        assignee?: { __typename: 'AdminUser'; name: string; adminUserId: string; languageCode: string; status: UserStatus } | null;
        user: {
            __typename: 'User';
            userId: string;
            username?: string | null;
            createdAt: Date | string;
            profileId?: string | null;
            online: boolean;
            profile?: {
                __typename: 'Profile';
                profileId: string;
                profileName: string;
                country?: string | null;
                images: Array<{ __typename: 'Image'; path: string }>;
            } | null;
        };
    }> | null;
};

export type FindManySupportTicketCountQueryVariables = Exact<{
    where?: InputMaybe<SupportTicketWhereInput>;
}>;

export type FindManySupportTicketCountQuery = { __typename: 'Query'; findManySupportTicketCount?: number | null };

export type FindOneSupportTicketQueryVariables = Exact<{
    where: SupportTicketWhereUniqueInput;
}>;

export type FindOneSupportTicketQuery = {
    __typename: 'Query';
    findOneSupportTicket?: {
        __typename: 'SupportTicket';
        supportTicketId: string;
        status: SupportTicketStatus;
        category: SupportCategory;
        userId: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        priority: number;
        projectName: string;
        assignee?: { __typename: 'AdminUser'; adminUserId: string; name: string; languageCode: string; status: UserStatus } | null;
        supportMessages?: Array<{
            __typename: 'SupportMessage';
            text: string;
            createdAt: Date | string;
            creatorId?: string | null;
            read: boolean;
            updatedAt: Date | string;
            creator?: { __typename: 'AdminUser'; name: string } | null;
        }> | null;
        user: {
            __typename: 'User';
            userId: string;
            username?: string | null;
            createdAt: Date | string;
            profileId?: string | null;
            online: boolean;
            profile?: { __typename: 'Profile'; profileId: string; profileName: string; images: Array<{ __typename: 'Image'; path: string }> } | null;
        };
    } | null;
};

export type SendSupportMessageMutationVariables = Exact<{
    data: SupportMessageCreateInput;
}>;

export type SendSupportMessageMutation = {
    __typename: 'Mutation';
    sendSupportMessage?: {
        __typename: 'SupportMessage';
        text: string;
        createdAt: Date | string;
        creatorId?: string | null;
        read: boolean;
        updatedAt: Date | string;
        creator?: { __typename: 'AdminUser'; name: string } | null;
    } | null;
};

export type UpdateSupportTicketMutationVariables = Exact<{
    where: SupportTicketWhereUniqueInput;
    data: SupportTicketUpdateInput;
}>;

export type UpdateSupportTicketMutation = {
    __typename: 'Mutation';
    updateSupportTicket?: { __typename: 'SupportTicket'; supportTicketId: string } | null;
};

export type QueueTypeQueryVariables = Exact<{ [key: string]: never }>;

export type QueueTypeQuery = {
    __typename: 'Query';
    __type?: { __typename: '__Type'; name?: string | null; enumValues?: Array<{ __typename: '__EnumValue'; name: string }> | null } | null;
};

export type FindManySupportTextQueryVariables = Exact<{
    where?: InputMaybe<SupportTextWhereInput>;
}>;

export type FindManySupportTextQuery = {
    __typename: 'Query';
    findManySupportText?: Array<{
        __typename: 'SupportText';
        supportTextId: string;
        active: boolean;
        languageCode: string;
        name: string;
        text: string;
        category: { __typename: 'SupportTextCategory'; supportTextCategoryId: string; name: string };
    }> | null;
};

export type FindManySupportTextCategoryQueryVariables = Exact<{
    where?: InputMaybe<SupportTextCategoryWhereInput>;
}>;

export type FindManySupportTextCategoryQuery = {
    __typename: 'Query';
    findManySupportTextCategory?: Array<{
        __typename: 'SupportTextCategory';
        supportTextCategoryId: string;
        name: string;
        active: boolean;
        supportTexts: Array<{ __typename: 'SupportText'; name: string; text: string; active: boolean }>;
    }> | null;
};

export type CreateOneSupportTextMutationVariables = Exact<{
    data: SupportTextCreateInput;
}>;

export type CreateOneSupportTextMutation = {
    __typename: 'Mutation';
    createOneSupportText: { __typename: 'SupportText'; name: string; text: string };
};

export type UpdateOneSupportTextMutationVariables = Exact<{
    data: SupportTextUpdateInput;
    where: SupportTextWhereUniqueInput;
}>;

export type UpdateOneSupportTextMutation = {
    __typename: 'Mutation';
    updateOneSupportText: { __typename: 'SupportText'; name: string; text: string };
};

export type DeleteOneSupportTextMutationVariables = Exact<{
    where: SupportTextWhereUniqueInput;
}>;

export type DeleteOneSupportTextMutation = { __typename: 'Mutation'; deleteOneSupportText?: { __typename: 'SupportText'; name: string } | null };

export type CreateOneSupportTextCategoryMutationVariables = Exact<{
    data: SupportTextCategoryCreateInput;
}>;

export type CreateOneSupportTextCategoryMutation = {
    __typename: 'Mutation';
    createOneSupportTextCategory: { __typename: 'SupportTextCategory'; supportTextCategoryId: string; name: string; active: boolean };
};

export type UpdateOneSupportTextCategoryMutationVariables = Exact<{
    data: SupportTextCategoryUpdateInput;
    where: SupportTextCategoryWhereUniqueInput;
}>;

export type UpdateOneSupportTextCategoryMutation = {
    __typename: 'Mutation';
    updateOneSupportTextCategory: { __typename: 'SupportTextCategory'; supportTextCategoryId: string; name: string; active: boolean };
};

export type DeleteOneSupportTextCategoryMutationVariables = Exact<{
    where: SupportTextCategoryWhereUniqueInput;
}>;

export type DeleteOneSupportTextCategoryMutation = {
    __typename: 'Mutation';
    deleteOneSupportTextCategory?: { __typename: 'SupportTextCategory'; supportTextCategoryId: string } | null;
};

export type FindManyGeolocationQueryVariables = Exact<{
    where: GeolocationWhereInput;
    take?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type FindManyGeolocationQuery = {
    __typename: 'Query';
    findManyGeolocation: Array<{
        __typename: 'GeolocationFindManyByRangeResult';
        location: string;
        iso: string;
        postcode: string;
        distance?: string | null;
    }>;
};
